import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import ReactDrawer from 'react-drawer';
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from "../CommonForBoth/RightSidebar"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import moment from 'moment';
import MainService from '../../Services/MainService';
import { MERCHANT_USER_TYPE_ID } from '../../Constants/MainKeys';
import { useDispatch } from "react-redux"
import { setSiteTourIsNotified } from '../../store/intro/actions';

const Header = props => {

  const { stepsData } = useSelector((state) => state.Intro);
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.Login)
  // const [position, setPosition] = useState();
  // const [open, setOpen] = useState(false);

  // const toggleTopDrawer = () => {
  //   setPosition('right');
  //   setOpen(!open)
  // }

  // const onDrawerClose = () => {
  //   setOpen(false);
  // }

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <div className="h-100 d-flex align-items-center">
              <h4 className=" mb-0 text-nowrap">{moment(new Date()).format("LL")}</h4>
            </div>
            {
              user.userTypeId === MERCHANT_USER_TYPE_ID ?
                <div className="h-100 d-flex align-items-center mx-3">
                  <div className='me-1' style={{ backgroundColor: `${user.isActive ? "#34c38f" : "#f46a6a"}`, width: "7px", height: "7px", borderRadius: "50%", marginBottom: "2px" }} />
                  <span className="ms-1 mb-0 text-nowrap">{user.isActive ? "Verified account" : "Unverified account"}</span>
                </div>
                : null
            }
          </div>
          <div className="d-flex align-items-center">


            <LanguageDropdown />
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
              {
                stepsData && stepsData.data && JSON.parse(stepsData.data).length > 0 ?
                  <button
                    type="button"
                    className="btn header-item noti-icon "
                    data-toggle="fullscreen"
                    onClick={() => {
                      dispatch(setSiteTourIsNotified(false));
                    }}
                  >
                    <i
                      className='bx bx-directions'
                    ></i>
                  </button>
                  : null
              }

              <a
                href={`https://cryllex.${MainService.getUrlExtension()}/contact-us`}
                target='_blank'
              >
                <button
                  type="button"
                  className="btn header-item noti-icon "
                  data-toggle="fullscreen"
                >
                  <i className='bx bx-support'></i>
                </button>
              </a>
            </div>

            <NotificationDropdown />

            <ProfileMenu />

          </div>
        </div>

      </header>

      {/* <ReactDrawer
        open={open}
        position={position}
        onClose={onDrawerClose}
      >
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer> */}
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
