import React from 'react'
import {
  Card,
  CardBody,
  Table,
} from "reactstrap"
const HedgingProfitAndTransactionFeeDetails = (props) => {

  return (
    <Card className="quick-view">
      <CardBody>
        <h5 className="mb-3 card-title">Cryllex Hedging Profit and Transaction Fee Details</h5>

        <Table className="table align-middle table-nowrap" bordered>
          <tbody>
            <tr>
              <td><h5 className="font-size-12 mb-1">Cryllex Hedging Profit (if applicable)</h5></td>
              <td><h5 className="font-size-12 mb-0 "> AMT</h5></td>
            </tr>
            <tr>
              <td><h5 className="font-size-12 mb-1">Total Transaction Fees</h5></td>
              <td><h5 className="font-size-12 mb-0 ">AMT</h5></td>
            </tr>
            <tr>
              <td><h5 className="font-size-12 mb-1">Agent Share of Transction Fee (%)</h5></td>
              <td><h5 className="font-size-12 mb-0 "> AMT %</h5></td>
            </tr>
            <tr>
              <td><h5 className="font-size-12 mb-1">Agent Net Transction Fees (<i className="bx bx-caret-down text-danger"></i>)</h5></td>
              <td><h5 className="font-size-12 mb-0 ">AMT</h5></td>
            </tr>
            <tr>
              <td><h5 className="font-size-12 mb-1">Cryllex Hedging Profit + Cryllex Net Transaction Fees</h5></td>
              <td><h5 className="font-size-12 mb-0 ">AMT</h5></td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default HedgingProfitAndTransactionFeeDetails