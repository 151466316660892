import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Button,
  Input
} from "reactstrap"
import { Table } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { ALL_VALID_FILE_TYPES, ERROR_KEY, VALID_FILE_TYPES_KEY, VALID_IMAGE_TYPES_KEY } from '../../../../Constants/MainKeys'
import { addButtonSpinner, addPageSpinner, addRightSectionSpinner, removeButtonSpinner, removePageSpinner, removeRightSectionSpinner } from '../../../../store/spinner/actions'
import AlertService from '../../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import DashboardApiService from '../../../../Services/DashboardApiService'
import moment from 'moment'
import ReactSelectOption from '../../../../components/SelectOptions/ReactSelectOption'
import NoData from '../../../../components/NoData/NoData'
import ReactPaginate from 'react-paginate'
import GetCurrentDate from '../../../../components/DateTime/GetCurrentDate'
import GetCurrentTime from '../../../../components/DateTime/GetCurrentTime'
import MainService from '../../../../Services/MainService'
import ActionButton from '../../../../components/Buttons/ActionButton'
import ActionButtonWithOvalSpinner from '../../../../components/Buttons/ActionButtonWithOvalSpinner'


const buttonSpinnerId = uuid();
const ViewRelatedParties = (props) => {

  const { t } = props;
  const dispatch = useDispatch();
  const title = "View Related Parties";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const mainService = new MainService();

  const [isForm, setIsForm] = useState(false);
  const [userPassportProofFile, setUserPassportProofFile] = useState(null);
  const [userAddressDoc, setUserAddressDoc] = useState(null);
  const [businessRegistrationDocument, setBusinessRegistrationDocument] = useState(null);
  const [regulatoryLicenseDocument, setRegulatoryLicenseDocument] = useState(null);
  const [businessAddressDocument, setBusinessAddressDocument] = useState(null);

  const [userName, setUserName] = useState('John');
  const [telephoneNumber, setTelephoneNumber] = useState('+123456789');
  const [address, setAddress] = useState('123 Main Street, Anytown, USA');
  const [credentials_1, setCredentials_1] = useState('Default Credential 1');
  const [credentials_2, setCredentials_2] = useState('Default Credential 2');
  const [legalBusinessName, setLegalBusinessName] = useState('My Business');
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState('123456789');
  const [countryOfIncorporationOrRegistration, setCountryOfIncorporationOrRegistration] = useState('USA');
  const [regulatoryLicensingNumber, setRegulatoryLicensingNumber] = useState('ABC123');
  const [businessAddress, setBusinessAddress] = useState('456 Business Avenue, City, Country');
  const [apiKeyOtcAgent, setApiKeyOtcAgent] = useState('Default OTC Agent API Key');
  const [apiKeyAgentBank, setApiKeyAgentBank] = useState('Default Agent Bank API Key');
  const [livenessTestStatus, setLivenessTestStatus] = useState(true);

  const onChange = (event, cb) => {
    cb(event.target.value)
  }

  const uploadFile = async (event, cb) => {
    if (!event.target.files.length) { return; }
    let files = [...event.target.files];
    for (let i in files) {
      const file = files[i];
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
      if (ALL_VALID_FILE_TYPES.includes(fileExtention)) {
        let fileType = null;
        if (VALID_IMAGE_TYPES_KEY.includes(fileExtention)) {
          fileType = "image";
        }
        else if (VALID_FILE_TYPES_KEY.includes(fileExtention)) {
          fileType = "file";
        }
        await mainService.readFile(file, ALL_VALID_FILE_TYPES).then(uploadedFile => {
          cb({
            fileName,
            fileType,
            file,
            uploadedFile,
            id: uuid(),
            size: file.size
          })
        }).catch(error => error && AlertService.alert("error", "Invalid file format"));
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
  };

  const onSubmit = () => {
    setButtonSpinner(buttonSpinnerId);
    setTimeout(() => {
      AlertService.alert("success", "Data saved");
      setIsForm(false);
      extractButtonSpinner(buttonSpinnerId);
      scrollTop();
    }, 2000);
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const acceptOrReject = () => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      AlertService.alert("success", "Data saved");
      window.history.back();
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Lorem ipsum"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>

                  <div className='d-flex justify-content-between flex-wrap'>
                    <h4>Agent: GameStop Co. (Client ID #00001)</h4>
                    <span>*Certified True Document</span>
                  </div>

                  <div className='ms-2 mt-4'>
                    <h5 className='bold mb-4'>Level One Requirements</h5>
                    <Row>
                      <Col md={6} className='pe-4'>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='userName' className='me-5 d-flex align-items-center m-0 opacity-75 opacity-75'>Username:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='userName'
                                  type="text"
                                  name="userName"
                                  value={userName}
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setUserName)}
                                />
                                : <span className='fw-500 font-size-15'> {userName} </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='telephoneNumber' className='me-5 d-flex align-items-center m-0 opacity-75'>Telephone Number:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='telephoneNumber'
                                  type="tel"
                                  name="telephoneNumber"
                                  value={telephoneNumber}
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setTelephoneNumber)}
                                />
                                : <span className='fw-500 font-size-15'> {telephoneNumber} </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='passport' className='me-5 d-flex align-items-center m-0 opacity-75'>User Passport/ID*:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              userPassportProofFile ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (userPassportProofFile.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={userPassportProofFile.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{userPassportProofFile.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setUserPassportProofFile(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setUserPassportProofFile)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='address' className='me-5 d-flex align-items-center m-0 opacity-75'>User Address:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='address'
                                  type="text"
                                  name="address"
                                  value={address}
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setAddress)}
                                />
                                : <span className='fw-500 font-size-15'> {address} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='addressDoc' className='me-5 d-flex align-items-center m-0 opacity-75'>User Address Document*:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              userAddressDoc ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (userAddressDoc.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={userAddressDoc.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{userAddressDoc.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setUserAddressDoc(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setUserAddressDoc)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet </span>
                            }
                          </Col>
                        </Row>

                      </Col>

                      <Col md={6} className='pe-4'>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='credentials' className='me-5 d-flex align-items-center m-0 opacity-75'>User Credentials:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <div>
                                  <input
                                    id='credentials_1'
                                    type="text"
                                    name="credentials_1"
                                    value={credentials_1}
                                    className={`form-control`}
                                    autoComplete='new-password'
                                    onChange={(event) => onChange(event, setCredentials_1)}
                                  />
                                  <input
                                    id='credentials_2'
                                    type="text"
                                    name="credentials_2"
                                    value={credentials_2}
                                    className={`form-control mt-2`}
                                    autoComplete='new-password'
                                    onChange={(event) => onChange(event, setCredentials_2)}
                                  />
                                </div>
                                : <div>
                                  <span className='fw-500 font-size-15 d-block my-2'>1) {credentials_1} </span>
                                  <span className='fw-500 font-size-15 d-block my-2'>2) {credentials_2} </span>
                                </div>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='livenessTestStatus' className='me-5 d-flex align-items-center m-0 opacity-75'>Liveness Test Status:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <div>
                                  <div className='d-flex my-1'>
                                    <Input
                                      id="Verified"
                                      name="livenessTestStatus"
                                      className="custom-checkbox m-0 d-block"
                                      type="radio"
                                      checked={livenessTestStatus}
                                      onChange={() => setLivenessTestStatus(true)}
                                    />
                                    <Label
                                      className="text-muted text-truncate ms-2 mb-0 font-size-14 cursor-pointer"
                                      htmlFor="Verified"
                                    >
                                      Verified
                                    </Label>
                                  </div>
                                  <div className='d-flex my-1'>
                                    <Input
                                      id="notVerified"
                                      name="livenessTestStatus"
                                      className="custom-checkbox m-0 d-block "
                                      type="radio"
                                      checked={!livenessTestStatus}
                                      onChange={() => setLivenessTestStatus(false)}
                                    />
                                    <Label
                                      className="text-muted text-truncate ms-2 mb-0 font-size-14 cursor-pointer"
                                      htmlFor="notVerified"
                                    >
                                      Not Verified
                                    </Label>
                                  </div>
                                </div>
                                : <span className='fw-500 font-size-15 d-block'> {livenessTestStatus ? "Verified" : "Not Verified"} </span>
                            }

                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div className='ms-2 mt-4'>
                    <h5 className='bold mb-4'>Level Two Requirements</h5>
                    <Row>
                      <Col md={6} className='pe-4'>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='legalBusinessName' className='me-5 d-flex align-items-center m-0 opacity-75'>Legal Business Name:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='legalBusinessName'
                                  type="text"
                                  value={legalBusinessName}
                                  name="legalBusinessName"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setLegalBusinessName)}
                                />
                                : <span className='fw-500 font-size-15 d-block'> {legalBusinessName} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='businessRegistrationNumber' className='me-5 d-flex align-items-center m-0 opacity-75'>Business Registration Number:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='businessRegistrationNumber'
                                  type="text"
                                  value={businessRegistrationNumber}
                                  name="businessRegistrationNumber"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setBusinessRegistrationNumber)}
                                />
                                : <span className='fw-500 font-size-15'> {businessRegistrationNumber} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='businessRegistrationDocument' className='me-5 d-flex align-items-center m-0 opacity-75'>Business Registration Document*:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              businessRegistrationDocument ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (businessRegistrationDocument.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={businessRegistrationDocument.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{businessRegistrationDocument.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setBusinessRegistrationDocument(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setBusinessRegistrationDocument)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='countryOfIncorporationOrRegistration' className='me-5 d-flex align-items-center m-0 opacity-75'>Country of Incorporation or Registration:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='countryOfIncorporationOrRegistration'
                                  type="text"
                                  value={countryOfIncorporationOrRegistration}
                                  name="countryOfIncorporationOrRegistration"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setCountryOfIncorporationOrRegistration)}
                                />
                                : <span className='fw-500 font-size-15'> {countryOfIncorporationOrRegistration} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='regulatoryLicensingNumber' className='me-5 d-flex align-items-center m-0 opacity-75'>Regulatory Licensing Number:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='regulatoryLicensingNumber'
                                  type="text"
                                  value={regulatoryLicensingNumber}
                                  name="regulatoryLicensingNumber:"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setRegulatoryLicensingNumber)}
                                />
                                : <span className='fw-500 font-size-15'> {regulatoryLicensingNumber} </span>
                            }

                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='regulatoryLicenseDocument' className='me-5 d-flex align-items-center m-0 opacity-75'>Regulatory License Document*:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              regulatoryLicenseDocument ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (regulatoryLicenseDocument.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={regulatoryLicenseDocument.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{regulatoryLicenseDocument.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setRegulatoryLicenseDocument(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setRegulatoryLicenseDocument)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='businessAddress' className='me-5 d-flex align-items-center m-0 opacity-75'>Business Address:</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='businessAddress'
                                  type="text"
                                  value={businessAddress}
                                  name="businessAddress"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setBusinessAddress)}
                                />
                                : <span className='fw-500 font-size-15'> {businessAddress} </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='businessAddressDocument' className='me-5 d-flex align-items-center m-0 opacity-75'>Business Address Document*:</label>
                          </Col>
                          <Col sm={7}>

                            {
                              businessAddressDocument ?
                                <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>

                                  {
                                    (() => {
                                      switch (businessAddressDocument.fileType) {
                                        case "image":
                                          return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                            <img src={businessAddressDocument.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                          </div>
                                        case "file":
                                          return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                            <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                            <small className='max-line-2 mt-1 word-break-break-word'>{businessAddressDocument.fileName}</small>
                                          </div>
                                        default:
                                          break;
                                      }
                                    })()
                                  }
                                  {
                                    isForm ?
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={() => setBusinessAddressDocument(null)}
                                      />
                                      : null
                                  }
                                </div>
                                : isForm ? <Button
                                  onClick={MainService.triggerUploadClick}
                                  outline
                                  color="primary"
                                  type='button'
                                >
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setBusinessAddressDocument)} />
                                  Attach file
                                </Button> : <span className='fw-500 font-size-15'>No attached file yet </span>
                            }
                          </Col>
                        </Row>

                      </Col>

                      <Col md={6} className='pe-4'>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='apiKeyOtcAgent' className='me-5 d-flex align-items-center m-0 opacity-75'>API Key (OTC → Agent): </label>
                          </Col>
                          <Col sm={7}>

                            {
                              isForm ?
                                <input
                                  id='apiKeyOtcAgent'
                                  type="text"
                                  value={apiKeyOtcAgent}
                                  name="apiKeyOtcAgent"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setApiKeyOtcAgent)}
                                />
                                : <span className='fw-500 font-size-15'> {apiKeyOtcAgent} </span>
                            }
                          </Col>
                        </Row>

                        <Row className='align-items-center my-2 py-2'>
                          <Col sm={5}>
                            <label htmlFor='apiKeyAgentBank' className='me-5 d-flex align-items-center m-0 opacity-75'>API Key (Agent → Bank):</label>
                          </Col>
                          <Col sm={7}>
                            {
                              isForm ?
                                <input
                                  id='apiKeyAgentBank'
                                  type="text"
                                  value={apiKeyAgentBank}
                                  name="apiKeyAgentBank"
                                  className={`form-control`}
                                  autoComplete='new-password'
                                  onChange={(event) => onChange(event, setApiKeyAgentBank)}
                                />
                                : <span className='fw-500 font-size-15'> {apiKeyAgentBank} </span>
                            }
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div className='mt-4 d-flex justify-content-end flex-wrap'>
                    <div className='d-flex mt-3'>
                      <Button color="success" className='ms-3 px-4' onClick={() => acceptOrReject()}>Accept</Button>
                      <Button color="danger" className='ms-3 px-4' onClick={() => acceptOrReject()}>Reject</Button>
                    </div>
                    <div className='d-flex mt-3'>
                      {
                        isForm ?
                          <ActionButtonWithOvalSpinner
                            type="button"
                            name="Submit"
                            className="btn btn-primary btn-block ms-3 px-4"
                            spinnerId={buttonSpinnerId}
                            onClick={onSubmit}
                          />
                          :
                          <Button color="primary" className='ms-3 px-4'
                            onClick={() => {
                              setIsForm(true);
                              scrollTop();
                            }}
                          >
                            Edit
                          </Button>
                      }
                      <Button color="primary" className='ms-3 px-4 ' onClick={() => window.history.back()}>Back</Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(ViewRelatedParties)