import React, { useEffect, useState } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import { addRightSectionSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import DashboardApiService from '../../../Services/DashboardApiService'
import uuid from 'react-uuid'
import { useCallback } from 'react'
import AlertService from '../../../Services/alertService'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import ApiService from '../../../Services/ApiService'

const AccountBalances = (props) => {

  const title = "Balance";
  const description = "";

  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const [balance, setBalance] = useState(null);


  useEffect(() => {
    // getBalance()
  }, [])

  const getBalance = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getBalance().then(response => {
      if (response && response.data) { }
      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem={<span>Balance <small className='font-size-10'>(In process)</small></span>}
          />
          <Row>
            <Col xl={12}>
              <Card className="mini-stats-wid">
                <CardHeader className='ps-0'>
                  <h5 className="mb-1">USD balance</h5>
                </CardHeader>
                <CardBody className='p-3'>
                  <div className="me-1 d-flex justify-content-between align-items-center">
                    <div className='w-100'>
                      <div className='d-flex justify-content-between'>
                        <span className="text-truncate d-block my-1">Currently processing</span>
                        <b className=' font-size-14'>
                          $0.00
                        </b>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span className="text-truncate d-block my-1">Funds on hold</span>
                        <b className=' font-size-14'>
                          {/* ${bala} */}
                        </b>
                      </div>
                      <hr />
                      <div className='d-flex justify-content-between'>
                        <span className="text-truncate d-block mb-1"><b>Total</b></span>
                        <b className='badge badge-soft-success font-size-14'>
                          $0.00
                        </b>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card className="mini-stats-wid my-3">
                <CardHeader className='ps-0'>
                  <h5 className="mb-1">Incoming to Cryllex</h5>
                </CardHeader>
                <CardBody className='p-3'>
                  <div className="me-1 d-flex justify-content-between align-items-center">
                    <div className='w-100'>
                      <div className='d-flex justify-content-between'>
                        <span className="text-truncate d-block my-1"><b>Total for May 1 - May 21</b></span>
                        <b className=' font-size-14'>
                          $0.00
                        </b>
                      </div>

                      <hr />
                      <div className='d-flex justify-content-between'>
                        <span className="text-truncate d-block mb-1"><b>Total overall</b></span>
                        <b className='badge badge-soft-success font-size-14'>
                          $0.00
                        </b>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card className="mini-stats-wid my-3">
                <CardHeader className='ps-0'>
                  <h5 className="mb-1">Outgoing from Cryllex</h5>
                </CardHeader>
                <CardBody className='p-3'>
                  <div className="me-1 d-flex justify-content-between align-items-center">
                    <div className='w-100'>
                      <div className='d-flex justify-content-between'>
                        <span className="text-truncate d-block my-1">
                          <b>
                            Currently on the way to your bank account
                          </b>
                          <br />
                          <p className='d-block mt-1'>These funds should arrive in your bank account soon.</p>
                        </span>
                        <b className=' font-size-14'></b>
                      </div>

                      <hr />
                      <div className='d-flex justify-content-between'>
                        <span className="text-truncate d-block mb-1"><b>Total</b></span>
                        <b className='badge badge-soft-success font-size-14'>
                          $0.00
                        </b>
                      </div>
                      <div className='d-flex justify-content-between mt-5'>
                        <span className="text-truncate d-block mb-1"><b>Recently deposited</b></span>
                        <b className='badge badge-soft-success font-size-14'></b>
                      </div>
                      <hr />
                      <div className='d-flex justify-content-between'>
                        <span className="text-truncate d-block mb-1">You have not yet had any completed payouts.</span>
                        <b className='badge badge-soft-success font-size-14'></b>
                      </div>

                    </div>
                  </div>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </div>
      </div >
    </>
  )
}

export default withRouter(AccountBalances)