import { SET_GLOBAL_CONNECTION, SET_IS_SHOW_NOTOFICATION, SET_NOTIFICATIONS, SET_NOTIFICATION, SET_NOTIFICATION_IS_NOTIFIED } from "./actionTypes";

let initialState = {
  globalConnection: null,
  isShowNotification: false,
  notifications: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_CONNECTION:
      return {
        ...state,
        globalConnection: action.payload,
      };
    case SET_IS_SHOW_NOTOFICATION:
      return {
        ...state,
        isShowNotification: action.payload,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case SET_NOTIFICATION_IS_NOTIFIED:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          notification.id === action.payload
            ? { ...notification, isNotified: true }
            : notification
        ),
      };
    default:
      return state;
  }
};

export default reducer;
