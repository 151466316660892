import React from 'react'
import {
  Button,
  Col,
  Row,
  Table
} from "reactstrap"
import MainService from '../../../../Services/MainService';

export default function LevelOneRequirements(props) {
  const { data, setZoomImagePath } = props;

  const viewProof = (path) => {
    if (MainService.getFxtensions(path) === "image") {
      setZoomImagePath(path)
    } else {
      window.open(path, "_blank")
    }
  }

  return (
    <Col sm={12}>
      <Row>
        <Col sm={6}>
          <Table bordered striped responsive className='mb-0'>
            <tbody>
              {
                data?.classificationType === 2 ? null :
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Legal Business Name
                    </th>
                    <td className='w-50'>{data.levelOne.legalBusinessName}</td>
                  </tr>
              }
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Email
                </th>
                <td className='w-50'>{data.levelOne.username}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Telephone Number
                </th>
                <td className='w-50 text-nowrap'>{data.levelOne?.phoneNumber ? MainService.formatPhoneNumber(data.levelOne.phoneNumber) : ""}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  User Credential
                </th>
                <td className='w-50'>{data.levelOne.userTypeName}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col sm={6}>
          <Table bordered striped responsive className='mb-0'>
            <tbody>

              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Primary contact passport proof
                </th>
                <td className='w-50'>
                  {
                    !data.levelOne.userPassportProofFile ? <span className='text-danger'>No attached file yet</span> :
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          viewProof(data.levelOne.userPassportProofFile.filePath)
                        }}
                      >
                        View Proof
                      </Button>
                  }
                </td>
              </tr>

              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Primary contact address proof
                </th>
                <td className='w-50'>
                  {
                    !data.levelOne.userAddressProofFile ? <span className='text-danger'>No attached file yet</span> :
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          viewProof(data.levelOne.userAddressProofFile.filePath)
                        }}
                      >
                        View Proof
                      </Button>
                  }
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Primary contact address
                </th>
                <td className='w-50'>{data.levelOne.addressRegistration}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Liveness Test Status
                </th>
                <td className='w-50'>
                  <span className={`badge badge-soft-${data.levelOne.livenessTestStatus ? "success" : "danger"} font-size-14 badge badge-pill`}>
                    {data.levelOne.livenessTestStatus ? "Verified" : "Not verified"}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Col>
  )
}
