import React from 'react'
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import { Link } from 'react-router-dom';
import NoData from '../../../../components/NoData/NoData';
const TotalOrder = ({ transactionOverview, transactionDetails }) => {

  return (
    <>
      <Card>
        {
          transactionOverview ?
            <CardBody>
              {/* <div className='mb-4 d-flex gap-3 align-items-center'> */}

              <Row>
                <Col xs="12">
                  <div>
                    {
                      !transactionOverview.cryptoAmountFormated && transactionOverview.fiatAmountFormated ?
                        <h5>Order Total: {transactionOverview.fiatAmountFormated} USD</h5>
                        : null
                    }
                    {
                      transactionOverview.cryptoAmountFormated && transactionOverview.fiatAmountFormated ?
                        <div>
                          <h5>Order Total: {transactionOverview.cryptoAmountFormated} USD</h5>
                          <p className="text-muted text-truncate mb-0">
                            $ {transactionOverview.fiatAmountFormated} (1 {transactionDetails?.token} = <span className="text-muted font-size-14">$ {transactionOverview.fiatAmountFormated / transactionOverview.cryptoAmountFormated}</span> )</p>
                        </div>
                        : null
                    }
                  </div>
                  <hr />
                </Col>
                <Col xl="12">
                  <div>
                    <div className='d-flex justify-content-between'>
                      <p className="text-muted font-size-14 text-truncate mb-1">
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                        Transaction status:
                      </p>
                      <div>
                        {
                          transactionOverview.transactionStatus && transactionOverview.transactionStatus.toLowerCase() === "confirmed" ?
                            <span className="ms-2 font-size-12 badge-soft-success badge badge-secondary badge-pill">{transactionOverview.transactionStatus}</span>
                            : <span className="font-size-12 badge-soft-info badge badge-secondary badge-pill">{transactionOverview.transactionStatus}</span>
                        }
                      </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <p className="text-muted font-size-14 text-truncate mb-1">
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                        Fiat payout status:
                      </p>
                      <div>
                        {
                          transactionOverview.fiatPayoutStatus && transactionOverview.fiatPayoutStatus.toLowerCase() === "completed" ?
                            <span className="ms-2 font-size-12 badge-soft-success badge badge-secondary badge-pill">{transactionOverview.fiatPayoutStatus}</span>
                            : <span className="font-size-12 badge-soft-warning badge badge-secondary badge-pill">{transactionOverview.fiatPayoutStatus}</span>
                        }
                      </div>
                    </div>
                  </div>
                  <hr />
                </Col>
              </Row>
              <Col>
                <div className="media">
                  <div className="me-3">
                    <h5 className="font-size-14"><i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle bx-fade-right"></i></h5>
                  </div>
                  <div className="media-body">
                    <Link to="#" className='text-underline'>View Block Explorer for Transaction ???</Link>
                  </div>
                </div>
              </Col>
            </CardBody>
            : <NoData width={150} />
        }

      </Card>
    </>
  )
}

export default TotalOrder