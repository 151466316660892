import { combineReducers } from "redux"
import Layout from "./layout/reducer"
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import Translations from "./translations/reducer"
import Spinners from "./spinner/reducer"
import Languages from "./language/reducer"
import Intro from "./intro/reducer"
import SignalR from "./signalR/reducer"

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Translations,
  Languages,
  Spinners,
  Intro,
  SignalR,
})

export default rootReducer
