import React, { useCallback, useEffect, useState } from 'react'
import uuid from 'react-uuid'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
} from "reactstrap"
import ActionButton from '../../../../components/Buttons/ActionButton'
import { AGENT_USER_TYPE_ID, ERROR_KEY, MERCHANT_USER_TYPE_ID, SUCCESS_KEY } from '../../../../Constants/MainKeys'
import AlertService from '../../../../Services/alertService'
import { addButtonSpinner, removeButtonSpinner } from '../../../../store/actions'
import { useDispatch } from 'react-redux'
import DashboardApiService from '../../../../Services/DashboardApiService'
import { withRouter } from "react-router-dom"
import { useSelector } from 'react-redux'
import CountdownTimer from '../../../../components/CountdownTimer/CountdownTimer'

const spinnerId = uuid();
const ConfirmPayoutModal = (props) => {

  const {
    setIsShowConfirmPayoutModal,
    isShowConfirmPayoutModal,
    transactionAmount,
    setTransactionAmount,
    // netValue,
    // setNetValue,
    bankAccount,
    balance,
    queryFxData,
    setQueryFxData,
    getQueryFx
  } = props;

  const dispatch = useDispatch();
  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);
  const [isPayOutSent, setIsPayOutSent] = useState(false);
  const [_remainingSeconds, _setRemainingSeconds] = useState(0);

  useEffect(() => {
    if (queryFxData && queryFxData.expireTime && queryFxData.expireTime - Date.now() > 0) {
      _setRemainingSeconds(Math.round((queryFxData.expireTime - Date.now()) / 1000))
    }
  }, [queryFxData])

  const onPayOut = (event) => {
    event && event.preventDefault();

    setButtonSpinner(spinnerId);
    const form = {
      amount: balance && balance.balance,
      payoutSystemId: 1,
      organizationBankAccountId: bankAccount.id,
      // operationUserId: user.id,
    }
    DashboardApiService.onPayOut(form).then(() => {
      AlertService.alert(SUCCESS_KEY, "Your request has been sent");
      setIsPayOutSent(true);
      extractButtonSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))

  }

  const closeModal = () => {
    setIsShowConfirmPayoutModal(false);
    // setNetValue(null);
    setQueryFxData(null);
    setTransactionAmount("");
    setIsPayOutSent(false);
    _setRemainingSeconds(0);
    if (isPayOutSent) {
      props.history.push(`/${language}/balance/payouts`)
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  // const setReciveAmount = () => {
  //   if (balance && typeof netValue === "number") {
  //     let recivedAmount = balance.balance - netValue;
  //     return `$${recivedAmount.toFixed(2)}`
  //   } else {
  //     return `$0`;
  //   }
  // }

  return (
    <Modal
      isOpen={isShowConfirmPayoutModal}
      size="md"
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        closeModal()
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            closeModal()
          }}
        >
          <p className='h4'>Transaction information</p>
        </ModalHeader>
        {
          isPayOutSent ?
            <ModalBody className='py-4'>
              <h5 className='m-0 text-center' style={{ lineHeight: "30px" }}>The Cryllex administrator will approve your request as soon as possible!</h5>
            </ ModalBody>
            : <ModalBody className='py-0'>
              {
                bankAccount ?
                  <div>
                    <Table bordered striped responsive className='mt-3'>
                      <tbody>
                        {
                          bankAccount.bankName ?
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Bank name
                              </th>
                              <td className='w-50'>{bankAccount.bankName}</td>
                            </tr>
                            : null
                        }
                        {
                          bankAccount.accountNumber ?
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Account number
                              </th>
                              <td className='w-50'>{bankAccount.accountNumber}</td>
                            </tr>
                            : null
                        }
                        {
                          bankAccount.swiftCode ?
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                SWIFT/BIC code
                              </th>
                              <td className='w-50'>{bankAccount.swiftCode}</td>
                            </tr>
                            : null
                        }
                        {
                          bankAccount.receiverName ?
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Receiver name
                              </th>
                              <td className='w-50'>{bankAccount.receiverName}</td>
                            </tr>
                            : null
                        }
                      </tbody>
                    </Table>
                    <hr />
                    <Table bordered striped responsive className='mb-0'>
                      <tbody>
                        {
                          bankAccount.countryName ?
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Country
                              </th>
                              <td className='w-50'>{bankAccount.countryName}</td>
                            </tr>
                            : null
                        }
                        {
                          bankAccount.city ?
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                City
                              </th>
                              <td className='w-50'>{bankAccount.city}</td>
                            </tr>
                            : null
                        }
                        {
                          bankAccount.bankName ?
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Bank Name
                              </th>
                              <td className='w-50'>{bankAccount.bankName}</td>
                            </tr>
                            : null
                        }
                        {
                          bankAccount.branchAddress ?
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Branch Address
                              </th>
                              <td className='w-50'>{bankAccount.branchAddress}</td>
                            </tr>
                            : null
                        }
                      </tbody>
                    </Table>
                  </div>
                  : null
              }
              <div className='d-flex justify-content-between mt-4'>
                <p className='text-muted font-size-14 mb-0'>You will receive:</p>
                <b className='h3'>
                  ${balance.balance}
                </b>
              </div>
              {
                _remainingSeconds ?
                  <div className='d-flex justify-content-between'>
                    <p className='text-muted font-size-14 mb-0'>Check rate after:</p>
                    <b className='h3'>
                      <CountdownTimer initialSeconds={_remainingSeconds} _setRemainingSeconds={_setRemainingSeconds} />
                    </b>
                  </div>
                  : null
              }
              {
                queryFxData && queryFxData.originCurrency !== queryFxData.targetCurrency ?
                  <div className='d-flex justify-content-between'>
                    <p className='text-muted font-size-14 mb-0'>Rate</p>
                    <p className='d-flex justify-content-between'> 1 {queryFxData.originCurrency} = {Number(queryFxData.exchangeRate).toFixed(2)} {queryFxData.targetCurrency}</p>
                  </div>
                  : null
              }
              {/* <div className='d-flex justify-content-between'>
                <p className='text-muted font-size-14 mb-0'> </p>
                <p className='d-flex justify-content-between'> Commision: ${netValue && netValue.toFixed(2)}</p>
              </div> */}
            </ModalBody>
        }

        <ModalFooter className='d-flex justify-content-center'>

          {
            isPayOutSent ?
              <Button
                type='button'
                className='w-100 '
                color='primary'
                onClick={closeModal}
              >
                Close
              </Button>
              :
              <div className='w-100'>
                {
                  !queryFxData || (queryFxData && _remainingSeconds) ?
                    <ActionButton
                      type="button"
                      name="Confirm"
                      buttonClassName="w-100"
                      className="shadow-sm w-100 btn btn-primary"
                      spinnerId={spinnerId}
                      onClick={onPayOut}
                    />
                    : null
                }
                {
                  queryFxData && !_remainingSeconds ?
                    <ActionButton
                      type="button"
                      name="Check rate"
                      buttonClassName="w-100"
                      className="shadow-sm w-100 btn btn-primary"
                      spinnerId={spinnerId}
                      onClick={() => getQueryFx(user.userTypeId === MERCHANT_USER_TYPE_ID ? 1 : user.userTypeId === AGENT_USER_TYPE_ID ? 2 : null, spinnerId)}
                    />
                    : null
                }

              </div>
          }
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default withRouter(ConfirmPayoutModal)