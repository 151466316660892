import React from 'react'
import {
  Col,
  Row,
  Table,
  Button
} from "reactstrap"

const LevelTwo = (props) => {

  return (
    <Col sm={12}>
      <Row>
        <Col sm={6}>
          <Table bordered striped responsive className='mb-0'>
            <tbody>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Email Adress
                </th>
                <td className='w-50'>paulanathony@gmail.com</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Email Adress Verification Status
                </th>
                <td className='w-50'>Verified</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Proof of Address Document
                </th>
                <td className='w-50'>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => { }}
                  >
                    View Proof
                  </Button>
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Veriff Verification Status
                </th>
                <td className='w-50'>
                  <td className='w-50'>
                    <span className={`badge badge-soft-success font-size-14 badge badge-pill`}>
                      Verified
                    </span>
                    or
                    <span className={`badge badge-soft-danger font-size-14 badge badge-pill`}>
                      Unverified
                    </span>
                  </td>
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Reason for Failure (is applicable)
                </th>
                <td className='w-50'>N/A</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col sm={6}>
          <Table bordered striped responsive className='mb-0'>
            <tbody>
             
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Paying Customer ID Front Side
                </th>
                <td className='w-50'>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => { }}
                  >
                    View Proof
                  </Button>
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Paying Customer ID Back Side
                </th>
                <td className='w-50'>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => { }}
                  >
                    View Proof
                  </Button>
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Paying Customer ID Biometric Data (Face Scan)
                </th>
                <td className='w-50'>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => { }}
                  >
                    View Proof
                  </Button>
                </td>
              </tr>



            </tbody>
          </Table>
        </Col>




      </Row>
    </Col>
  )
}

export default LevelTwo