import { SET_GLOBAL_CONNECTION, SET_IS_SHOW_NOTOFICATION, SET_NOTIFICATIONS, SET_NOTIFICATION, SET_NOTIFICATION_IS_NOTIFIED } from "./actionTypes";

export function setGlobalConnection(data) {
  return {
    type: SET_GLOBAL_CONNECTION,
    payload: data,
  };
};
export function setIsShowNotification(bool) {
  return {
    type: SET_IS_SHOW_NOTOFICATION,
    payload: bool,
  };
};
export function setNotifications(arr) {
  return {
    type: SET_NOTIFICATIONS,
    payload: arr,
  };
};
export function setNotification(obj) {
  return {
    type: SET_NOTIFICATION,
    payload: obj,
  };
};
export function setNotificationIsNotified(id) {
  return {
    type: SET_NOTIFICATION_IS_NOTIFIED,
    payload: id,
  }
};