import axios from 'axios';
import { DEFAULT_LANGUAGE_KEY, ERROR_KEY, LANGUAGE_KEY, OBJECT_KEY, REFRESH_TOKEN_KEY, TOKEN_KEY, USER_KEY } from '../Constants/MainKeys';
import AlertService from './alertService';


const api = axios.create({
  baseURL: process.env.REACT_APP_DASHBOARD_API_URL_KEY || "",
  withCredentials: true,
});

const identityApi = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_API_URL_KEY || "",
});

api.interceptors.request.use(async config => {
  config.headers = {
    'Authorization': localStorage.getItem(TOKEN_KEY) ? localStorage.getItem(TOKEN_KEY) : "",
    'language': localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY,
    'ngrok-skip-browser-warning': 'true',
  };
  return config;
});

api.interceptors.response.use(response => {
  // if (localStorage.getItem("refreshCount")) {
  //   localStorage.removeItem("refreshCount");
  // }
  if (response.data) return response.data;

}, error => {
  if (error.response) {
    if (error.response.status === 401) {
      return Promise.reject("401");
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
      const user = localStorage.getItem(USER_KEY);
      const token = localStorage.getItem(TOKEN_KEY);
      if (!refreshToken || !user || !token) {
        logout();
      } else {
        getUserDataByRefreshToken(refreshToken, user);
        return false;
      }
    } else if (error.response.status === 404) { // Status Code: 404
      return Promise.reject("404 Not Found");
    } else if (error.response.status === 500) { // Status Code: 500
      return Promise.reject("500 Internal Server Error");
    }
    if (typeof error.response.data === OBJECT_KEY) {
      const currentError = { ...error.response.data };
      const errors = currentError.errors?.Error;
      let errorMessages = [];
      if (errors && errors.length > 0) {
        errors.forEach((error) => {
          const errorParams = error.params;
          const paramKeys = Object.keys(errorParams);
          paramKeys.forEach((key) => {
            const value = errorParams[key];
            if (typeof value === 'string' && value.trim().length > 0) {
              errorMessages.push(value);
            }
          });
        });
      } else {
        return Promise.reject("Unrecognized error");
      }
      if (errorMessages.length) {
        showError(errorMessages);
        return Promise.reject();
      } else {
        return Promise.reject("Unrecognized error");
      }
    } else {
      return Promise.reject("Unrecognized error");
    }

  } else {
    return Promise.reject("Unrecognized error");
  }
});

const showError = (errorMessages) => {
  const filteredStrings = errorMessages.filter(item => isNaN(item));
  filteredStrings.forEach(error => {
    AlertService.alert("error", error)
  });
}

const logout = () => {
  window.location.href = `/${localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY}`;
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
}

const getUserDataByRefreshToken = (refreshToken, user) => {
  const formData = new FormData();
  formData.append(`refreshToken`, refreshToken);
  DashboardApiService.getUserDataByRefreshToken(formData).then(response => {
    if (response.data && response.data.accessToken) {
      const data = { ...response.data };
      localStorage.setItem(TOKEN_KEY, data?.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, data?.refreshToken);
      localStorage.setItem(USER_KEY, user);

      if (localStorage.getItem("refreshCount")) {
        let refreshCount = +localStorage.getItem("refreshCount");
        if (+refreshCount >= 2) {
          logout();
        }
        localStorage.setItem("refreshCount", refreshCount + 1 || 1)
      } else {
        localStorage.setItem("refreshCount", 1);
      }
      window.location.reload();

    } else {
      logout();
    }
  }).catch(error => getFail(error));
}

const getFail = (error) => {
  if (error) logout();
}

class DashboardApiService {

  // RefreshToken
  static getUserDataByRefreshToken(data) {
    return identityApi.post(`/identity/renewToken`, data)
  };

  static currentUser() {
    return api.get(`/user/current`);
  };

  static checkKey(key) {
    return api.get(`/user/accessRestorePassword?key=${key}`);
  };

  static forgotPassword(email) {
    return api.post(`/user/getRestorePasswordUrl?email=${email}`);
  };

  static restorePassword(data) {
    return api.put(`/user/restorePassword`, data);
  };

  static changeOldPassword(data) {
    return api.put(`/user/updatePassword`, data);
  };

  static deleteUserDocument(fileLibraryId, userId, type) {
    return api.delete(`/user/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static checkUserRegistration(key) {
    return api.get(`/user/confirmEmail?emailVerificationKey=${key}`);
  };

  static uploadUserDocument(data) {
    return api.post(`/user/UploadDocument`, data);
  };

  static updateProfile(data) {
    return api.put(`/user/update`, data);
  };

  static updatePassword(data) {
    return api.put(`/user/updatePassword`, data);
  };

  static createInstantPayment(data) {
    return api.post(`/merchant/instantPayment/create`, data);
  };

  static getCryptoWallets() {
    return api.get(`/cryptoWallet/list`);
  };

  static getCurrencies() {
    return api.get(`/currency/cryptoList`);
  };

  static getFiatCurrencies() {
    return api.get(`/currency/fiatList`);
  };

  static getKycLevels() {
    return api.get(`/admin/Kyc/GetLevels`);
  };

  // Agent

  static getOrganizationTransactions(orgId, pageNumber, pageSize, paymentType) {
    return api.get(`/merchant/transaction/getByOrg?orgId=${orgId}&pageNumber=${pageNumber}&pageSize=${pageSize}&paymentType=${paymentType}`);
  };

  static getOrganizationTransactionsAsAgent(pageNumber, pageSize, data, orgId) {
    return api.post(`/agent/Payout/GetAll?orgId=${orgId}&pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getOrganizationTransactionsAsAdmin(orgId, pageNumber, pageSize, data) {
    return api.post(`/admin/Transaction/GetByOrgId?orgId=${orgId}&pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getAllTransactions(pageNumber, pageSize, filter) {
    return api.post(`/admin/transaction/getall?pageNumber=${pageNumber}&pageSize=${pageSize}`, filter);
  };
  static getAllTransactionsAsPayingCustomer(pageNumber, pageSize, filter) {
    return api.post(`/????pageNumber=${pageNumber}&pageSize=${pageSize}`, filter);
  };

  // agent dashboard
  static getOrganizationsAsAgent(userId = null, data) {
    return api.post(`/agent/OrganizationAgent/GetOrganizations/${userId}`, data);
  };
  static getOrganizationsAsAdmin(userId, data) {
    return api.post(`/admin/OrganizationAgent/GetOrganizations/${userId}`, data);
  };

  static getOrgBankAccounts(orgtId) {
    return api.get(`/agent/bankAccount/GetOrgBankAccounts/${orgtId}`);
  };

  static getOrgBankAccountAsAgent(orgtId) {
    return api.get(`/agent/bankAccount/GetOrgBankAccount/${orgtId}`);
  };

  static getOrgBankAccountAsAdmin(orgtId) {
    return api.get(`/admin/bankAccount/GetOrgBankAccount/${orgtId}`);
  };

  static getOrgKycInformation(agentId, orgId) {
    return api.get(`/agent/OrganizationAgent/GetByOrganizationId?agentId=${agentId}&orgId=${orgId}`);
  };

  static removeClient(clientId, agentId) {
    return api.delete(`/agent/OrganizationAgent/Delete/${clientId}/${agentId}`);
  };

  static getClientTypes() {
    return api.get(`/agent/AgentOrganizationInvitation/CreateView`);
  };

  static createClientInvitation(data) {
    return api.post(`/agent/AgentOrganizationInvitation/Create`, data);
  };

  static getClientInvitationById(id) {
    return api.post(`/agent/AgentOrganizationInvitation/Get/${id}`);
  };

  static deleteInvitation(id) {
    return api.delete(`/agent/AgentOrganizationInvitation/Delete/${id}`);
  };

  static getClientInvitations() {
    return api.get(`/agent/AgentOrganizationInvitation/GetAll`);
  };

  static getAgentProfileAsAgent(userId) {
    return api.get(`/agent/Agent/GetProfile/${userId}`);
  };

  static getAgentInvitations(agentId, data) {
    return api.post(`/admin/AgentOrganizationInvitation/GetListByAgent/${agentId}`, data);
  };
  static getAgentProfileAsAdmin(userId) {
    return api.get(`/admin/Agent/GetProfile/${userId}`);
  };
  // end agent dashboard

  static getTransactionHistoryAsMerchant(pageNumber, pageSize, data, clientId) {
    return api.post(`/merchant/transaction/get?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getInstantPaymentsAsMerchant(pageNumber, pageSize, data) {
    return api.post(`/merchant/InstantPayment/GetInstant?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getInstantPaymentsAsAdmin(pageNumber, pageSize, data) {
    return api.post(`/admin/Transaction/GetAllInstants?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static onFilterTransactionsAsMerchant(pageNumber, pageSize, data) {
    return api.post(`/merchant/transaction/filter?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getTransactionByLocalTransactionId(localTransactionId) {
    return api.get(`/merchant/transaction/details/${localTransactionId}`);
  };

  static getPaymentSystems() {
    return api.get(`/merchant/payoutSystem/getall`);
  };

  // static getPayoutHistory(pageNumber, pageSize) {
  //   return api.post(`/merchant/payout/getall?pageNumber=${pageNumber}&pageSize=${pageSize}`, {});
  // };

  static getPayoutHistoryAsAdmin(pageNumber, pageSize, data, orgId) {
    if (orgId) {
      return api.post(`/admin/Payout/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}&orgId=${orgId}`, data);
    } else {
      return api.post(`/admin/Payout/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
    }
  };

  static getPayoutHistoryAsMerchant(pageNumber, pageSize, data, orgId) {
    if (orgId) {
      return api.post(`/merchant/Payout/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}&orgId=${orgId}`, data);
    } else {
      return api.post(`/merchant/Payout/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
    }
  };

  static getPayoutByIdAsMerchant(itemId) {
    return api.get(`/merchant/payout/get/${itemId}`);
  };

  static getPayoutByIdAsAdmin(itemId) {
    return api.get(`/admin/payout/get/${itemId}`);
  };

  static onPayOut(data) {
    return api.post(`/merchant/payout/create`, data);
  };

  static getNetValue(data) {
    return api.post(`/merchant/payout/getNetValue`, data);
  };

  static getQueryFx(typeId) {
    return api.get(`/merchant/BankAccount/QueryFx/${typeId}`);
  };

  static getAccounts() {
    return api.get(`/merchant/bankAccount/getActiveAccounts`);
  };

  static getBankAccountAsMerchant() {
    return api.get(`/merchant/bankAccount/getActiveAccount`);
  };

  static getBankAccountAsAgent() {
    return api.get(`/agent/bankAccount/getActiveAccount`);
  };

  static getAccountByType(type) {
    return api.get(`/merchant/bankAccount/getByType/${type}`);
  };

  static createAccountAsMerchant(values) {
    return api.post(`/merchant/bankAccount/create`, values);
  };

  static createAccountAsAgent(values) {
    return api.post(`/agent/bankAccount/create`, values);
  };

  static getAccountAsMerchant(id) {
    if (id) return api.get(`/merchant/bankAccount/get/${id}`);
    else return api.get(`/merchant/bankAccount/createView`);
  };

  static getAccountAsAgent(id) {
    if (id) return api.get(`/agent/bankAccount/get/${id}`);
    else return api.get(`/agent/bankAccount/createView`);
  };

  static updateOrganizationBankAccountAsMerchant(values) {
    return api.put(`/merchant/bankAccount/update`, values);
  };

  static updateOrganizationBankAccountAsAgent(values) {
    return api.put(`/agent/bankAccount/update`, values);
  };

  static updateOrganizationBankAccountAsAdmin(values) {
    return api.put(`/admin/bankAccount/update`, values);
  };

  static filterPayouts(values, pageNumber, pageSize) {
    return api.post(`/merchant/payout/filter?pageNumber=${pageNumber}&pageSize=${pageSize}`, values);
  };

  static getOrganizationData() {
    return api.get(`/merchant/OrganizationDetail/get`);
  };
  static getAgentKycData() {
    return api.get(`/agent/AgentKyc/Get`);
  };

  static getCustomers() {
    return api.get(`/merchant/customer/get`);
  };

  static getProducts() {
    return api.get(`/merchant/product/get`);
  };

  static getDashboardData() {
    return api.get(`/merchant/dashboard/index`);
  };

  static deleteAccountAsMerchant(bankAccountId) {
    return api.delete(`/merchant/bankAccount/delete/${bankAccountId}`);
  };

  static deleteAccountAsAgent(bankAccountId) {
    return api.delete(`/agent/bankAccount/delete/${bankAccountId}`);
  };

  static getUsersAsMerchant(orgId, type, pageNumber, pageSize) {
    return api.get(`/merchant/SignificantController/GetByOrg?orgId=${orgId}&type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  };

  static getUsersAsAgent(agentId, type, pageNumber, pageSize) {
    return api.get(`/agent/SignificantController/GetByAgent?agentId=${agentId}&type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  };

  static getUserByIdAsMerchant(id) {
    return api.get(`/merchant/SignificantController/Get/${id}`);
  };

  static getUserByIdAsAgent(id) {
    return api.get(`/agent/SignificantController/Get/${id}`);
  };

  static getUserByIdAsAdmin(id) {
    return api.get(`/admin/SignificantController/Get/${id}`);
  };

  static createUserAsMerchant(data) {
    return api.post(`/merchant/SignificantController/Create`, data);
  };
  static createUserAsAgent(data) {
    return api.post(`/agent/SignificantController/Create`, data);
  };

  static updateUserAsMerchant(data) {
    return api.put(`/merchant/SignificantController/Update`, data);
  };

  static updateUserAsAgent(data) {
    return api.put(`/agent/SignificantController/Update`, data);
  };

  static updateUserAsAdmin(data) {
    return api.put(`/admin/SignificantController/Update`, data);
  };

  static getUserTypesAsMerchant() {
    return api.get(`/merchant/SignificantController/GetTypes`);
  };

  static getUserTypesAsAdmin() {
    return api.get(`/admin/SignificantController/GetTypes`);
  };

  static getUserTypesAsAgent() {
    return api.get(`/agent/SignificantController/GetTypes`);
  };

  static deleteSignificantControllerDocumentAsMerchant(fileLibraryId, userId, type) {
    return api.delete(`/merchant/SignificantController/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static deleteSignificantControllerDocumentAsAdmin(fileLibraryId, userId, type) {
    return api.delete(`/admin/SignificantController/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static deleteSignificantControllerDocumentAsAgent(fileLibraryId, userId, type) {
    return api.delete(`/agent/SignificantController/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static deleteBankAccountDocumentAsMerchant(fileLibraryId, userId, type) {
    return api.delete(`/merchant/bankAccount/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static deleteBankAccountDocumentAsAgent(fileLibraryId, userId, type) {
    return api.delete(`/agent/bankAccount/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static deleteBankAccountDocumentAsAdmin(fileLibraryId, userId, type) {
    return api.delete(`/admin/bankAccount/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };

  static uploadSignificantControllerDocumentAsMerchant(data) {
    return api.post(`/merchant/SignificantController/UploadDocument`, data);
  };

  static uploadSignificantControllerDocumentAsAdmin(data) {
    return api.post(`/admin/SignificantController/UploadDocument`, data);
  };

  static uploadSignificantControllerDocumentAsAgent(data) {
    return api.post(`/agent/SignificantController/UploadDocument`, data);
  };

  static uploadBankAccountDocumentAsMerchant(data) {
    return api.post(`/merchant/bankAccount/UploadDocument`, data);
  };

  static uploadBankAccountDocumentAsAgent(data) {
    return api.post(`/agent/bankAccount/UploadDocument`, data);
  };

  static uploadBankAccountDocumentAsAdmin(data) {
    return api.post(`/admin/bankAccount/UploadDocument`, data);
  };

  static deleteSignificantControllerAsMerchant(id) {
    return api.delete(`/merchant/SignificantController/Delete/${id}`);
  };

  static deleteSignificantControllerAsAgent(id) {
    return api.delete(`/agent/SignificantController/Delete/${id}`);
  };

  static getOrganization() {
    return api.get(`/merchant/Organization/Get`);
  };

  static uploadOrganizationLogo(data) {
    return api.post(`/merchant/Organization/UploadLogo`, data);
  };

  static deleteOrganizationLogo(libraryId) {
    return api.delete(`/merchant/Organization/DeleteLogo/${libraryId}`);
  };

  static updateOrganization(data) {
    return api.put(`/merchant/Organization/Update`, data);
  };

  static updateBusinessDetail(data) {
    return api.put(`/merchant/OrganizationDetail/Update`, data);
  };

  static getOrganizationKYC(data) {
    return api.post(`/merchant/OrganizationKyc/GetByOrgId`, data);
  };

  static getAgentViewOrgKYCAsAgent(orgId) {
    return api.get(`/agent/OrganizationKyc/GetOrgKYC/${orgId}`);
  };

  static getOrgBankAccountAsAgent(orgId) {
    return api.get(`/agent/OrganizationKyc/GetOrgBankAccount/${orgId}`);
  };

  static getAgentViewOrgKYCAsAdmin(data) {
    return api.post(`/admin/OrganizationKyc/GetOrgKYCAgentView`, data);
  };

  static getAgentViewOrgKYCAsMerchant(data) {
    return api.post(`/merchant/OrganizationKyc/GetOrgKYCAgentView`, data);
  };

  static createOrganizationKYC(data) {
    return api.post(`/merchant/OrganizationKyc/Create`, data);
  };

  static uploadOrganizationKYCFile(data) {
    return api.post(`/merchant/OrganizationKyc/UploadDocument`, data);
  };

  static deleteOrganizationKYCFile(data) {
    return api.post(`/merchant/OrganizationKyc/DeleteDocument`, data);
  };

  static getOrganizationsByAdmin(pageNumber, pageSize, data) {
    return api.post(`/admin/Organization/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getOrganizationById(organizationId) {
    return api.get(`/admin/Organization/GetById/${organizationId}`);
  };

  static getAgentKycDataById(agentId) {
    return api.get(`/admin/agent/getKyc/${agentId}`);
  };

  static approveAgentInvitation(id) {
    return api.put(`/admin/AgentOrganizationInvitation/UpdateStatus/${id}`);
  };

  static deleteAgentInvitation(id) {
    return api.delete(`/agent/AgentOrganizationInvitation/Delete/${id}`);
  };

  static organizationDetailUpdateStatus(data) {
    return api.put(`/merchant/OrganizationDetail/UpdateStatus`, data);
  };

  static organizationUpdateStatusAsMerchant(data) {
    return api.put(`/merchant/Organization/UpdateStatus`, data);
  };
  static organizationUpdateStatusAsAgent(data) {
    return api.put(`/agent/Agent/UpdateStatus`, data);
  };



  //Files 
  static uploadDocumentAsMerchant(data) {
    return api.post(`/merchant/OrganizationDetail/UploadDocument`, data);
  };
  static bulkUploadDocumentAsMerchant(data) {
    return api.post(`/merchant/OrganizationDetail/BulkUploadDocument`, data);
  };
  static deleteDocumentAsMerchant(fileLibraryId, id, type) {
    return api.delete(`/merchant/OrganizationDetail/DeleteDocument?libId=${fileLibraryId}&id=${id}&type=${type}`);
  };

  static uploadDocumentAsAdmin(data) {
    return api.post(`/admin/OrganizationDetail/UploadDocument`, data);
  };
  static bulkUploadDocumentAsAdmin(data) {
    return api.post(`/admin/OrganizationDetail/BulkUploadDocument`, data);
  };
  static deleteDocumentAsAdmin(fileLibraryId, id, type) {
    return api.delete(`/admin/OrganizationDetail/DeleteDocument?libId=${fileLibraryId}&id=${id}&type=${type}`);
  };
  static approveOrganizationKYCDocument(data) {
    return api.post(`/admin/OrganizationDetail/ApproveKycProof`, data);
  };
  static approveBankAccountDocument(data) {
    return api.post(`/admin/BankAccount/ApproveKycProof`, data);
  };
  static uploadUserDocumentAsAdmin(data) {
    return api.post(`/admin/user/UploadDocument`, data);
  };
  static deleteUserDocumentAsAdmin(fileLibraryId, id, type) {
    return api.delete(`/admin/user/DeleteDocument?libId=${fileLibraryId}&id=${id}&type=${type}`);
  };
  static uploadLevelOneDocumentAsAdmin(data) {
    return api.post(`/admin/User/UploadDocument`, data);
  };
  static deleteLevelOneDocumentAsAdmin(fileLibraryId, id, type) {
    return api.delete(`/admin/User/DeleteDocument?libId=${fileLibraryId}&id=${id}&type=${type}`);
  };

  static updateOrganizationDetailOneLevel(data) {
    return api.put(`/admin/OrganizationDetail/UpdateLevelOne`, data);
  };

  static updateOrganizationDetailTwoLevel(data) {
    return api.put(`/admin/OrganizationDetail/UpdateLevelTwo`, data);
  };
  static updateOrganizationDetailLivenessTestStatus(data) {
    return api.put(`/admin/OrganizationDetail/UpdateLivenessTestStatus`, data);
  };
  static updateOrganizationStatus(data) {
    return api.put(`/admin/Organization/UpdateStatus`, data);
  };

  static updateAgentDataOneLevel(data) {
    return api.put(`/admin/AgentKyc/UpdateLevelOne`, data);
  };
  static updateAgentDataTwoLevel(data) {
    return api.put(`/admin/AgentKyc/UpdateLevelTwo`, data);
  };
  static updateAgentDataThreeLevel(data) {
    return api.put(`/admin/bankAccount/update`, data);
  };

  static updateAgentStatus(data) {
    return api.put(`/???`, data);
  };

  static updateAgentKyc(data) {
    return api.put(`/agent/AgentKyc/Update`, data);
  };

  static disabledOrEnableOrganizationStatus(data) {
    return api.put(`/admin/Organization/UpdateDisableStatus`, data);
  };

  static getAgents(pageNumber, pageSize, data) {
    return api.post(`/admin/Agent/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };

  static getAgentsListAsAdmin() {
    return api.get(`/admin/Agent/GetList`);
  };


  static uploadDocumentAsAgent(data) {
    return api.post(`/agent/AgentKyc/UploadDocument`, data);
  };
  static updateAgentProfile(data) {
    return api.put(`/agent/Agent/Update`, data);
  };
  static bulkUploadDocumentAsAgent(data) {
    return api.post(`/agent/AgentKyc/BulkUploadDocument`, data);
  };
  static deleteDocumentAsAgent(fileLibraryId, id, type) {
    return api.delete(`/agent/AgentKyc/DeleteDocument?libId=${fileLibraryId}&id=${id}&type=${type}`);
  };
  static deleteDocumentAsPayingCustomer(fileLibraryId, id, type) {
    return api.delete(`/?????libId=${fileLibraryId}&id=${id}&type=${type}`);
  };
  static updateAgentDataLivenessTestStatus(data) {
    return api.put(`/admin/AgentKyc/UpdateLivenessTestStatus`, data);
  };
  static bulkUploadAgentKycDocumentAsAdmin(data) {
    return api.post(`/admin/AgentKyc/BulkUploadDocument`, data);
  };
  static uploadAgentKycDocumentAsAdmin(data) {
    return api.post(`/admin/AgentKyc/UploadDocument`, data);
  };
  static deleteAgentKycDocumentAsAdmin(fileLibraryId, userId, type) {
    return api.delete(`/admin/AgentKyc/DeleteDocument?libId=${fileLibraryId}&id=${userId}&type=${type}`);
  };
  static updateAdminKycDataStatus(data) {
    return api.put(`/admin/agent/UpdateStatus`, data);
  };
  static disabledOrEnableAdminKycStatus(data) {
    return api.put(`/admin/agent/UpdateDisableStatus`, data);
  };
  static getAgentOrganizations(agentId, data) {
    return api.post(`/admin/OrganizationAgent/GetOrganizations/${agentId}`, data);
  };
  static updateAgentKycLevel(data) {
    return api.put(`/admin/Agent/UpdateLevel`, data);
  };
  static updateOrganizationKycLevel(data) {
    return api.put(`/admin/Organization/UpdateLevel`, data);
  };
  static updateBankAccountStatusAsAdmin(data) {
    return api.put(`/admin/bankaccount/updateStatus`, data);
  };
  static getOrganizationAttachedAgentId(organizationId) {
    return api.get(`/admin/OrganizationAgent/GetByOrganizationId/${organizationId}`);
  };
  static attachOrganizationToAgentAsAdmin(data) {
    return api.put(`/admin/OrganizationAgent/Attache`, data);
  };
  static detachAgentFromOrganizationAsAdmin(organizationId, agentId) {
    return api.delete(`/admin/OrganizationAgent/Delete?orgId=${organizationId}&agentId=${agentId}`);
  };
  static getPaymentCustomersAsAdmin(pageNumber, pageSize, data) {
    return api.post(`/admin/PaymentCustomer/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  };
  static getOrganizationWallets() {
    return api.get(`/admin/OrganizationWallet/GetAll`);
  };
  static createOrganizationWallet(data) {
    return api.post(`/admin/OrganizationWallet/Add`, data);
  };
  static updateOrganizationWallet(data) {
    return api.put(`/admin/OrganizationWallet/Update`, data);
  };
  static approvePayOut(data) {
    return api.post(`/admin/Payout/Accept`, data);
  };
  static declinePayOut(data) {
    return api.post(`/admin/Payout/Reject`, data);
  };
  static getTransactionOrganizers(transactionId) {
    return api.get(`/admin/Transaction/GetOrganizers/${transactionId}`);
  };
  static getPayingCustomer(transactionId) {
    return api.get(`/admin/PaymentCustomer/GetByTransactionId?transactionId=${transactionId}`);
  };
  static getPayingCustomerAsMerchant(transactionId) {
    return api.get(`/merchant/PaymentCustomer/GetByTransactionId?transactionId=${transactionId}`);
  };
  static getTransactionOverview(transactionId) {
    return api.get(`/admin/Transaction/Overview/${transactionId}`);
  };
  static getTransactionDetails(transactionId) {
    return api.get(`/admin/Transaction/GetDetails/${transactionId}`);
  };
  static getBalance() {
    return api.get(`/merchant/balance/get`);
  };
  static createPromotion(data) {
    return api.post(`/Promotion/Create`, data);
  };
  static updatePromotion(data) {
    return api.put(`/Promotion/update`, data);
  };
  static getPromotionData(id) {
    return api.get(`/Promotion/Get/${id}`);
  };
  static getPromotionTypes() {
    return api.get(`/Promotion/GetTypes`);
  };
  static getAllPromotions() {
    return api.get(`/Promotion/GetAll`);
  };
  static deletePromotion(id) {
    return api.delete(`/Promotion/Delete/${id}`);
  };
  static getShownPromotions(entityId, type) {
    return api.get(`/EntityPromotion/Get?entityId=${entityId}&type=${type}`);
  };
  static updatePromotionStatus(id) {
    return api.put(`/EntityPromotion/UpdateStatus?id=${id}`);
  };
  static getNotifications(data, type) {
    return api.post(`/Notification/GetPagedList?receiverType=${type}`, data);
  };
  static updateNotificationStatus(id) {
    return api.put(`/Notification/MakeRead?id=${id}`);
  };
  static getConfigByName(name) {
    return api.get(`/Config/GetByName/${name}`);
  };
  static onChangeInstantPaymentStatus(data) {
    return api.put(`/admin/Transaction/UpdateStatus?status=${data.status}&id=${data.id}`);
  };



}

export default DashboardApiService;
