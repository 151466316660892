import { SET_SITE_TOUR_DATA, SET_SITE_TOUR_DATA_IS_NOTIFIED } from "./actionTypes";

let initialState = {
  stepsData: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SITE_TOUR_DATA:
      return {
        ...state,
        stepsData: action.payload,
      };
    case SET_SITE_TOUR_DATA_IS_NOTIFIED:
      if (!state.stepsData) {
        return false;
      }
      let newStepsData = { ...state.stepsData };
      newStepsData.isNotified = action.payload;
      return {
        ...state,
        stepsData: newStepsData,
      };
    default:
      break;
  }
  return state;
};

export default reducer;