import React, { useCallback, useEffect } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Table,
  Media,
} from "reactstrap"
import { Button } from "react-bootstrap"
import { ERROR_KEY } from './../../../Constants/MainKeys'
import { addRightSectionSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import DashboardApiService from '../../../Services/DashboardApiService'
import { useState } from 'react'
import moment from 'moment'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import { Link, withRouter } from 'react-router-dom'
import SimpleBar from "simplebar-react"
import MainService from '../../../Services/MainService'
import NoData from '../../../components/NoData/NoData'

const Dashboard = (props) => {

  const dispatch = useDispatch();
  const title = "Dashboard";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [data, setData] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(5);
  const { language } = useSelector(state => state.Languages);

  const [transactions, setTransactions] = useState([]);

  const [apexFields, setApexFields] = useState(null);

  const items = [
    {
      id: 1,
      displayName: "Hourly"
    },
    {
      id: 2,
      displayName: "Daily"
    },
    {
      id: 3,
      displayName: "Weekly"
    },
    {
      id: 4,
      displayName: "Monthly"
    },
    {
      id: 5,
      displayName: "Tri-monthly"
    },
    {
      id: 6,
      displayName: "YTD"
    },
  ]

  useEffect(() => {
    setApexFields({
      options: {
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["Jan", "Mar", "Jun", "Sep", "Dec"],
        }
      },
      series: [{
        name: '',
        data: generateRandomNumbers(5)
      }],
    })
  }, []);

  useEffect(() => {
    getTransactionHistoryAsMerchant();
  }, [])

  const getTransactionHistoryAsMerchant = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    const _filter = {
      createDate: null,
      cryptoMaxAmount: null,
      cryptoMinAmount: null,
      cryptoWalletId: null,
      currencyId: null,
      fiatMaxAmount: null,
      fiatMinAmount: null,
      finishDate: null,
      searchKey: null,
      status: null,
    }
    DashboardApiService.getTransactionHistoryAsMerchant(1, 10, _filter).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data && data.paymentHistory) {
          setTransactions(data.paymentHistory);
        } else {
          setTransactions([]);
        }
      }
      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const getMonths = (currentYear) => {
    if (!currentYear) { return false; }
    let givenDateTime = JSON.stringify(currentYear);

    let createdDate = new Date(givenDateTime);
    createdDate.setDate(1);
    let currentDate = new Date();
    let dateAndYearList = [createdDate.toLocaleString('en', { month: 'long' })];

    while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
      dateAndYearList.unshift(createdDate.toLocaleString('en', {
        month: 'long',
      }));
    }
    return dateAndYearList;
  }

  const generateRandomNumbers = (length) => {
    const numbers = [];

    for (let i = 0; i < length; i++) {
      const randomNumber = Math.floor(Math.random() * (100 - 10 + 1)) + 10;
      numbers.push(randomNumber);
    }

    return numbers;
  }

  const onSelectiOptionChange = (item) => {
    setSelectedItemId(item.value);

    switch (item.value) {
      case 1:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: ["01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "00:00"],
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(24)
          }],
        })
        break;
      case 2:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(7)
          }],
        })
        break;
      case 3:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(10)
          }],
        })
        break;
      case 4:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(12)
          }],
        })
        break;
      case 5:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: ["Jan", "Mar", "Jun", "Sep", "Dec"],
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(5)
          }],
        })
        break;
      case 6:
        setApexFields({
          options: {
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: getMonths(new Date().getFullYear()).reverse(),
            }
          },
          series: [{
            name: '',
            data: generateRandomNumbers(getMonths(new Date().getFullYear()).length)
          }],
        })
        break;

      default:
        break;
    }

  }

  useEffect(() => {
    getDashbordData();
  }, [])

  const getDashbordData = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getDashboardData().then(response => {
      if (response) {
        setData({ ...response });
      }
      extractRightSectionSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <Breadcrumbs
          title="Cryllex"
          breadcrumbItem="Dashboard"
        />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="d-flex flex-wrap justify-content-between mb-3">
                      <h4 className=" me-2">{moment(new Date()).format("LL")}</h4>
                      {
                        items && items.length ?
                          <div style={{ minWidth: "200px" }}>
                            <ReactSelectOption
                              value={selectedItemId}
                              selectedValue={(() => {
                                const selectedValue = { ...items.find(data => data.id === selectedItemId) };
                                if (selectedValue) {
                                  selectedValue.label = selectedValue.displayName;
                                  selectedValue.value = selectedValue.id;
                                }
                                return selectedValue;
                              })()}
                              items={items.map(data => ({ label: data.displayName, value: data.id }))}
                              onChange={(item) => onSelectiOptionChange(item)}
                            />
                          </div>
                          : null
                      }
                    </div>
                  </Col>
                  <hr className="mb-4" />
                  <Col xl={7}>
                    {
                      apexFields ?
                        <div id="area-chart" dir="ltr" className='border'>
                          <ReactApexChart
                            options={apexFields?.options}
                            series={apexFields?.series}
                            type="area"
                            height={350}
                            className="apex-charts"
                          />
                        </div>
                        : null
                    }
                  </Col>
                  <Col xl={5}>
                    <Row className='h-100'>
                      <Col md={6}>
                        <div className='border rounded p-3 mt-xl-0 mt-3 h-100'>
                          <h5 className='m-0 text-primary  mb-3'>Quick Glance</h5>
                          <div>
                            <p className="mb-2">
                              <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary" />{" "}
                              Gross Volume
                            </p>
                            <h5>
                              $60.780 USD (this month)
                            </h5>
                          </div>
                          <div>
                            <p className="mb-2">
                              <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary" />{" "}
                              Successfull Transactions
                            </p>
                            <h5>
                              8 completed (this month)
                            </h5>
                          </div>
                          <div>
                            <p className="mb-2">
                              <i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary" />{" "}
                              Unique Paying Customers
                            </p>
                            <h5>
                              18 (total)
                            </h5>
                          </div>
                        </div>
                      </Col>
                      {
                        data ?
                          <Col md={6}>
                            <div className='border p-3 rounded mb-2 mt-xl-0 mt-3 h-100'>
                              <div className="text-muted">
                                <div className='text-center'>
                                  <h5 className='m-0 text-primary  mb-3'>Current Stablecoin Balance</h5>
                                  <div className='badge badge-soft-info p-4 w-100 '>
                                    <h4 className='text-info'> $ {data.payouts ? data.payouts : 0} </h4>
                                    <h3 className='mb-0 mt-2 text-info'>USDT</h3>
                                  </div>

                                </div>
                                <Button type='button' className='w-100 mt-3 mb-0' onClick={() => props.history.push(`/${language}/balance/payout/form`)}>
                                  Withdrawal
                                </Button>
                              </div>
                            </div>
                          </Col>
                          : null
                      }
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12}>
            <Row className='mb-5'>
              <Col lg={8} >
                <Card className='mb-0 h-100'>
                  <CardBody className='pt-0'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='h5 mt-3 mb-3'>Recent Transactions</p>
                      <Link className='text-underline' to={`/${language}/payments/transaction-history`}>
                        <span>See All transactions</span>
                      </Link>
                    </div>

                    {
                      transactions && transactions.length ? (
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr className='cursor-default'>
                              <th className='text-nowrap'>#</th>
                              <th className='text-nowrap'>Transaction ID</th>
                              <th className='text-nowrap'>Time Stamp</th>
                              <th className='text-nowrap'>Paying Customer ID</th>
                              <th className='text-nowrap'>Transaction Type</th>
                              <th className='text-nowrap'>Token</th>
                              <th className='text-nowrap'>Transaction Value</th>
                              <th className='text-nowrap'>Payout Status</th>
                              <th className='text-nowrap'>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.map((transaction, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td> <span className='max-line-1' title={transaction.transactionId}>{transaction.transactionId}</span></td>
                                  <td className='text-nowrap'>{moment(MainService.convertUTCDateToLocalDate(new Date(transaction.createDate))).format("LL HH:mm")}</td>
                                  <td>{transaction.payingCustomerFullName}</td>
                                  <td>{transaction.transactionType}</td>
                                  <td>{
                                    transaction.transactionValue.map((el, idx) => {
                                      if (el.name?.toLowerCase() === "fiat") {
                                        return <span key={idx}>{el.currency}</span>
                                      }
                                    })
                                  }</td>
                                  <td>{
                                    transaction.transactionValue.map((el, idx) => {
                                      if (el.name?.toLowerCase() === "fiat") {
                                        return <span key={idx}>${el.amount}</span>
                                      }
                                    })
                                  }</td>
                                  <td>{transaction.transactionStatus}</td>

                                  <td>
                                    <div>
                                      <Button
                                        type="button"
                                        color="primary"
                                        className='shadow-sm me-2 text-nowrap'
                                        onClick={() => {
                                          if (transaction.transactionId) {
                                            props.history.push(`/${language}/transaction/view/${transaction.transactionId}`);
                                          }
                                        }}
                                      >
                                        View Info
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      ) : (
                        <NoData width={150} />
                      )
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4} className='mt-lg-0 mt-3'>
                <Card className='mb-0 h-100'>
                  <CardBody className='pt-0'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='h5 mt-3 mb-3'>Flag Control Center</p>
                    </div>
                    <SimpleBar className='bg-white'>
                      <ul className="list-group">
                        <li className="list-group-item border-0 px-0">
                          <Media>
                            <div className="avatar-xs me-3">
                              <i className='bx bx-info-circle text-primary' style={{ fontSize: "30px" }} ></i>
                            </div>
                            <Media body>
                              <h5 className="font-size-14">Donec vitae sapien ut</h5>
                              <p className="text-muted">
                                If several languages coalesce, the grammar of the
                                resulting language
                              </p>

                              <div className="float-end">
                                <p className="text-muted mb-0">
                                  <i className="mdi mdi-account me-1" /> Joseph
                                </p>
                              </div>
                              <p className="text-muted mb-0">12 Mar, 2020</p>
                            </Media>
                          </Media>
                        </li>
                        <li className="list-group-item border-0 px-0">
                          <Media>
                            <div className="avatar-xs me-3">
                              <i className='bx bx-info-circle text-primary' style={{ fontSize: "30px" }} ></i>
                            </div>
                            <Media body>
                              <h5 className="font-size-14">
                                Cras ultricies mi eu turpis
                              </h5>
                              <p className="text-muted">
                                To an English person, it will seem like simplified
                                English, as a skeptical cambridge
                              </p>

                              <div className="float-end">
                                <p className="text-muted mb-0">
                                  <i className="mdi mdi-account me-1" /> Jerry
                                </p>
                              </div>
                              <p className="text-muted mb-0">13 Mar, 2020</p>
                            </Media>
                          </Media>
                        </li>
                        <li className="list-group-item border-0 px-0">
                          <Media>
                            <div className="avatar-xs me-3">
                              <i className='bx bx-info-circle text-primary' style={{ fontSize: "30px" }} ></i>
                            </div>
                            <Media body>
                              <h5 className="font-size-14">
                                Duis arcu tortor suscipit
                              </h5>
                              <p className="text-muted">
                                It va esser tam simplic quam occidental in fact, it va
                                esser occidental.
                              </p>

                              <div className="float-end">
                                <p className="text-muted mb-0">
                                  <i className="mdi mdi-account me-1" /> Calvin
                                </p>
                              </div>
                              <p className="text-muted mb-0">14 Mar, 2020</p>
                            </Media>
                          </Media>
                        </li>
                        <li className="list-group-item border-0 px-0">
                          <Media>
                            <div className="avatar-xs me-3">
                              <i className='bx bx-info-circle text-primary' style={{ fontSize: "30px" }} ></i>
                            </div>
                            <Media body>
                              <h5 className="font-size-14">Donec vitae sapien ut</h5>
                              <p className="text-muted">
                                If several languages coalesce, the grammar of the
                                resulting language
                              </p>

                              <div className="float-end">
                                <p className="text-muted mb-0">
                                  <i className="mdi mdi-account me-1" /> Joseph
                                </p>
                              </div>
                              <p className="text-muted mb-0">12 Mar, 2020</p>
                            </Media>
                          </Media>
                        </li>
                      </ul>
                    </SimpleBar>

                  </CardBody>
                </Card>

              </Col>
            </Row>
          </Col>


        </Row>
      </div>
    </>
  )
}

export default withRouter(Dashboard)