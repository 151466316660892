import React, { useCallback, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Button
} from "reactstrap"
import { Table } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { ERROR_KEY } from '../../../Constants/MainKeys'
import { addPageSpinner, addRightSectionSpinner, removePageSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import DashboardApiService from '../../../Services/DashboardApiService'
import { useState } from 'react'
import moment from 'moment'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import NoData from '../../../components/NoData/NoData'
import ReactPaginate from 'react-paginate'
import GetCurrentDate from '../../../components/DateTime/GetCurrentDate'
import GetCurrentTime from '../../../components/DateTime/GetCurrentTime'

const RelatedMerchants = (props) => {

  const { t } = props;
  const dispatch = useDispatch();
  const title = "Related Merchants";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language, languages } = useSelector(state => state.Languages);


  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div>
          <h2 className='mb-2 font-size-22'>
            <GetCurrentTime />
          </h2>
          <h2 className='mb-3 font-size-18'>
            <GetCurrentDate />
          </h2>
        </div>
        <Row className='mt-4'>
          <Col lg={12}>
            <Card>
              <div className='d-flex justify-content-between p-3 align-items-center flex-wrap'>
                <div>
                  <h2 className='mb-2 font-size-22'>
                    Related Merchants
                  </h2>
                  <p className='ms-3 font-size-16'>Sort by: todo</p>
                </div>
                <div>
                  <input
                    className="form-control"
                    type="search..."
                    placeholder='Search'
                    style={{ minWidth: "300px" }}
                  />
                </div>
              </div>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Reference ID </th>
                    <th>Date</th>
                    <th>Agent</th>
                    <th>Merchant / Sole Trader</th>
                    <th>Paying Customer</th>
                    <th>Txn Value</th>
                    <th>On-Chain Txn ID</th>
                    <th>Wallet</th>
                    <th>Token</th>
                    <th>Txn Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <td>#00001</td>
                    <td>04/10/23</td>
                    <td>Cryllex BVI</td>
                    <td>Chow Sing</td>
                    <td>James Jones</td>
                    <td>$800</td>
                    <td>XXXXXXXXX</td>
                    <td>Trust Wallet</td>
                    <td>BTC</td>
                    <td>Pending</td>
                    <td>
                      <div className='d-flex flex-nowrap'>
                        <Button
                          type="button"
                          color="primary"
                          className='shadow-sm me-2 nowrap'
                          onClick={() => props.history.push(`/${language}/agents/related-merchants/view-info`)}
                        >
                          View Info
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>#00002</td>
                    <td>05/25/23</td>
                    <td>Cryllex BVI</td>
                    <td>Chow Sing</td>
                    <td>William Adams</td>
                    <td>$5000</td>
                    <td>XXXXXXXXX</td>
                    <td>MetaMask</td>
                    <td>ETH</td>
                    <td>Valid</td>
                    <td>
                      <div className='d-flex flex-nowrap'>
                        <Button
                          type="button"
                          color="primary"
                          className='shadow-sm me-2 nowrap'
                          onClick={() => props.history.push(`/${language}/agents/related-merchants/view-info`)}
                        >
                          View Info
                        </Button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>#00003</td>
                    <td>06/01/23</td>
                    <td>Cryllex BVI</td>
                    <td>Chow Sing</td>
                    <td>Jaden Smith</td>
                    <td>$80</td>
                    <td>XXXXXXXXX</td>
                    <td>Argent</td>
                    <td>USDC</td>
                    <td>Rejected</td>
                    <td>
                      <div className='d-flex flex-nowrap'>
                        <Button
                          type="button"
                          color="primary"
                          className='shadow-sm me-2 nowrap'
                          onClick={() => props.history.push(`/${language}/agents/related-merchants/view-info`)}
                        >
                          View Info
                        </Button>
                      </div>
                    </td>
                  </tr>


                </tbody>
              </Table>
              <div className='p-3 d-flex justify-content-end'>
                <Row>
                  <Col>
                    <ReactPaginate
                      nextLabel={t("Next")}
                      onPageChange={() => { }}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      // pageCount={pagination.TotalPages}
                      pageCount={10}
                      previousLabel={t("Previous")}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                      // forcePage={activePageNumber}
                      forcePage={0}
                    />
                  </Col>
                </Row>
              </div>

            </Card>
          </Col>

        </Row>
      </div>
    </>
  )
}

export default withTranslation()(RelatedMerchants)