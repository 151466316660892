import React, { useCallback, useEffect } from 'react'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Button,
  Input
} from "reactstrap"
import { Table } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { ALL_VALID_FILE_TYPES, ERROR_KEY, VALID_FILE_TYPES_KEY, VALID_IMAGE_TYPES_KEY } from '../../../../Constants/MainKeys'
import { addButtonSpinner, addPageSpinner, addRightSectionSpinner, removeButtonSpinner, removePageSpinner, removeRightSectionSpinner } from '../../../../store/spinner/actions'
import AlertService from '../../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import DashboardApiService from '../../../../Services/DashboardApiService'
import { useState } from 'react'
import moment from 'moment'
import ReactSelectOption from '../../../../components/SelectOptions/ReactSelectOption'
import NoData from '../../../../components/NoData/NoData'
import ReactPaginate from 'react-paginate'
import GetCurrentDate from '../../../../components/DateTime/GetCurrentDate'
import GetCurrentTime from '../../../../components/DateTime/GetCurrentTime'
import MainService from './../../../../Services/MainService';
import ActionButtonWithOvalSpinner from '../../../../components/Buttons/ActionButtonWithOvalSpinner'

const buttonSpinnerId = uuid();
const ViewTransactions = (props) => {

  const { t } = props;
  const dispatch = useDispatch();
  const title = "View Related Parties";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const mainService = new MainService();
  const [residentialAddressDocumentation, setResidentialAddressDocumentation] = useState(null)

  const [isForm, setIsForm] = useState(false);

  const [jumioIDVerificationStatus, setJumioIDVerificationStatus] = useState(false)
  const [emailVerificationStatus, setEmailVerificationStatus] = useState(true)
  const [fullLegalName, setFullLegalName] = useState("John Doe");
  const [phoneNumber, setPhoneNumber] = useState("+1234567890");
  const [countryOfResidence, setCountryOfResidence] = useState("United States");
  const [residentialAddress, setResidentialAddress] = useState("123 Main Street");

  const onChange = (event, cb) => {
    cb(event.target.value)
  }

  const uploadFile = async (event, cb) => {
    if (!event.target.files.length) { return; }
    let files = [...event.target.files];
    for (let i in files) {
      const file = files[i];
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
      if (ALL_VALID_FILE_TYPES.includes(fileExtention)) {
        let fileType = null;
        if (VALID_IMAGE_TYPES_KEY.includes(fileExtention)) {
          fileType = "image";
        }
        else if (VALID_FILE_TYPES_KEY.includes(fileExtention)) {
          fileType = "file";
        }
        await mainService.readFile(file, ALL_VALID_FILE_TYPES).then(uploadedFile => {
          cb({
            fileName,
            fileType,
            file,
            uploadedFile,
            id: uuid(),
            size: file.size
          })
        }).catch(error => error && AlertService.alert("error", "Invalid file format"));
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
  };

  const onSubmit = () => {
    setButtonSpinner(buttonSpinnerId);
    setTimeout(() => {
      AlertService.alert("success", "Data saved");
      setIsForm(false);
      extractButtonSpinner(buttonSpinnerId);
      scrollTop();
    }, 2000);
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const acceptOrReject = () => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      AlertService.alert("success", "Data saved");
      window.history.back();
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div>
          <h2 className='mb-2 font-size-22'>
            <GetCurrentTime />
          </h2>
          <h2 className='mb-3 font-size-18'>
            <GetCurrentDate />
          </h2>
        </div>
        <Row className='mt-4'>
          <Col lg={12}>
            <Card>
              <NoData />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default withTranslation()(ViewTransactions)