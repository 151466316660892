import { takeLatest, call, put } from 'redux-saga/effects';
import AlertService from "../../Services/alertService";
import { GET_TRANSLATIONS_REQUEST, GET_TRANSLATIONS_SUCCESS } from './actionTypes';
import { addPageSpinner, removePageSpinner } from '../../store/spinner/actions';
import { ERROR_KEY } from '../../Constants/MainKeys';
import ApiService from '../../Services/ApiService';


export default function* watcher() {
  yield takeLatest(GET_TRANSLATIONS_REQUEST, getTranslations);
}

function* getTranslations(spinnerId) {
  try {
    yield put(addPageSpinner(spinnerId));
    const allTranslations = yield call(ApiService.getAllTranslations);
    if (allTranslations) {
      yield put({
        type: GET_TRANSLATIONS_SUCCESS,
        payload: [...allTranslations.data]
      });
    }
    yield put(removePageSpinner(spinnerId));
  } catch (error) {
    yield put(removePageSpinner(spinnerId));
    error && AlertService.alert((AlertService.checkMessageType(error?.respcode) || ERROR_KEY), error);
  }
}


