import React, { useState, useEffect, useRef } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import DashboardApiService from '../../../Services/DashboardApiService'
import { useCallback } from 'react'
import { removeRightSectionSpinner, addRightSectionSpinner } from '../../../store/actions'
import { ERROR_KEY, MERCHANT_USER_TYPE_ID } from '../../../Constants/MainKeys'
import AlertService from '../../../Services/alertService'
import uuid from 'react-uuid'
import {
  Card,
  CardBody,
  UncontrolledAlert,
} from "reactstrap"
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import BankAccountInformation from './BankAccountInformation'


const BankAccount = (props) => {

  const title = "Bank Account";
  const description = "";
  const { user } = useSelector(state => state.Login);
  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [bankAccount, setBankAccount] = useState(null);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    getBankAccount();
    getFiatCurrencies();
  }, [])

  const getBankAccount = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    (user.userTypeId === MERCHANT_USER_TYPE_ID ?
      DashboardApiService.getBankAccountAsMerchant() :
      DashboardApiService.getBankAccountAsAgent()).then(response => {
        if (response && response.data) {
          setBankAccount(response.data);
        }
        extractRightSectionSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId))
  }

  const getFiatCurrencies = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    DashboardApiService.getFiatCurrencies().then(response => {
      if (response && response.data) {
        setCurrencies(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          {
            bankAccount && !bankAccount.isApproved ?
              <UncontrolledAlert color="danger" role="alert">
                <i className="mdi mdi-block-helper me-2"></i>
                Attention: You can't perform any payout process with an inactive bank account. Please wait for approval from Cryllex administration.
              </UncontrolledAlert>
              : null
          }
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Bank account"
          />
          <div>
            <Card>
              <CardBody>
                <BankAccountInformation
                  bankAccount={bankAccount}
                  isShowActions={true}
                  setBankAccount={setBankAccount}
                  currencies={currencies}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(BankAccount)