import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap"
import ReactPaginate from 'react-paginate'
import NoData from '../../../components/NoData/NoData';
import DashboardApiService from '../../../Services/DashboardApiService';
import { addRightSectionSpinner, removeRightSectionSpinner } from '../../../store/actions';
import AlertService from '../../../Services/alertService';
import uuid from 'react-uuid';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_USER_TYPE_ID, ERROR_KEY } from '../../../Constants/MainKeys';
import moment from 'moment';
import MainService from '../../../Services/MainService';
import { Link } from "react-router-dom"
import HtmlHead from '../../../components/HtmlHead/HtmlHead';
import parse from 'html-react-parser';

export default function Notifications() {

  const title = "Notifications";
  const description = "";
  const pageSize = 10;
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Login);
  const { language } = useSelector(state => state.Languages);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getNotifications(1);
  }, [])

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    getNotifications(event.selected + 1);
  };

  const getNotifications = (currentPage) => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    const data = {
      pageNumber: currentPage,
      pageSize
    };
    DashboardApiService.getNotifications(data, user.userTypeId === ADMIN_USER_TYPE_ID ? 1 : 2).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setNotifications(response.data);
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content'>
        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Notifications"
          />
          <Card>
            <CardBody>

              <Row>
                {
                  notifications && notifications.length ?
                    notifications.map((item, index) => {
                      return <Col xl={3} md={4} className='p-1'><Link key={index} to={item.link ? `/${language}${item.link}` : "#"}>
                        <Card outline color="light" className="border h-100">
                          <CardHeader>
                            <h5 className="my-0 text-primary d-flex gap-2 align-items-center">
                              <div className="avatar-xs">
                                {
                                  item.icon ? parse(item.icon)
                                    : <span className="avatar-title bg-success rounded-circle font-size-16"><i className="bx bx-outline"></i></span>
                                }
                              </div>
                              {/* <i className='bx bx-info-circle text-primary' style={{ fontSize: "20px" }} ></i> */}
                              <span>{item.title}</span>
                            </h5>
                          </CardHeader>
                          <CardBody className=''>
                            <p className='card-text font-size-14 mb-1'>
                              {parse(item.text)}
                            </p>
                            <small className="text-muted">
                              <i className="mdi mdi-clock-outline"></i>&nbsp;
                              {moment(MainService.convertUTCDateToLocalDate(new Date(item.createTime))).format("lll")}
                            </small>
                          </CardBody>
                        </Card>
                      </Link></Col>
                    })
                    : <NoData />
                }
                {
                  pagination && pagination.TotalPages && pagination.TotalPages > 1 ?
                    <div className='p-3 d-flex justify-content-end'>
                      <Row>
                        <Col>
                          <ReactPaginate
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pagination.TotalPages}
                            previousLabel="Previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                            forcePage={activePageNumber}
                          />
                        </Col>
                      </Row>
                    </div>
                    : null
                }
              </Row>
            </CardBody>
          </Card>

        </div>
      </div>
    </>
  )
}
