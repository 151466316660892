import React, { useCallback, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import { Table } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { ERROR_KEY } from '../../../Constants/MainKeys'
import { addRightSectionSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import ReactPaginate from 'react-paginate'
import DashboardApiService from '../../../Services/DashboardApiService'
import uuid from 'react-uuid'
import { useEffect } from 'react'
import * as moment from 'moment'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import NoData from './../../../components/NoData/NoData';


const Products = (props) => {

  const title = "Products"
  const description = ""
  const pageSize = 20;
  const { t } = props;
  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [products, setProducts] = useState([]);
  const [activePageNumber, setActivePageNumber] = useState(0);

  useEffect(() => {
    getProducts();
  }, [])

  const getProducts = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getProducts().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setProducts(data);
      }
      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const handlePageClick = (event) => {
    const spinnerId = uuid();
    setActivePageNumber(event.selected);
    scrollTop();
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  const startIndex = activePageNumber * pageSize;
  const endIndex = startIndex + pageSize;

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Products"
          />
          <Row>
            <Col lg={12}>
              <Card className='p-3'>
                <div>
                  <div className="table-responsiv">
                    {
                      products && products.length ?
                        <Table responsive className="table table-striped mb-0">
                          <thead>
                            <tr className='cursor-default'>
                              <th>Image</th>
                              <th>Name</th>
                              <th>Cost</th>
                              <th>Discount</th>
                              <th>Description</th>
                              <th>Product Id</th>
                              <th>Code</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.slice(startIndex, endIndex).map((product, index) => {
                              return <tr key={index} className='cursor-default'>
                                <th>
                                  <p className='word-break-break-word max-line-2 m-0' >
                                    <img src={product.imageUrl} style={{ width: "50px" }} />
                                  </p>
                                </th>
                                <td>
                                  <p className='word-break-break-word max-line-2 m-0' >{product.name}</p>
                                </td>
                                <td>
                                  <p className='word-break-break-word max-line-2 m-0 nowrap'>${product.cost}</p>
                                </td>
                                <td>
                                  <p className='word-break-break-word max-line-2 m-0' >{product.discount}%</p>
                                </td>
                                <td>
                                  <p className='word-break-break-word max-line-2 m-0' >{product.description}</p>
                                </td>
                                <td>
                                  <p className='word-break-break-word max-line-2 m-0' >{product.productId}</p>
                                </td>
                                <td>
                                  <p className='word-break-break-word max-line-2 m-0' >{product.code}</p>
                                </td>
                              </tr>
                            })}
                          </tbody>
                        </Table>
                        : <NoData />
                    }
                  </div>

                </div>
                {
                  products && products.length ?
                    <div className='p-3 d-flex justify-content-end'>
                      <Row>
                        <Col>
                          <ReactPaginate
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={Math.ceil(products.length / pageSize)}
                            previousLabel="Previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            forcePage={activePageNumber}
                          />
                        </Col>
                      </Row>
                    </div>
                    : null
                }
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(Products)