import React, { useCallback, useState, useEffect, useRef } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Table,
  Label,
  UncontrolledAlert,
  Alert
} from "reactstrap"
import ConfirmPayoutModal from './Components/ConfirmPayoutModal'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/actions'
import AlertService from '../../../Services/alertService'
import { AGENT_USER_TYPE_ID, ERROR_KEY, INACTIVE_ACCOUNT_INFO_MSG, MERCHANT_USER_TYPE_ID, SPINNER_COLOR, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY } from '../../../Constants/MainKeys'
import { useDispatch, useSelector } from 'react-redux'
import DashboardApiService from '../../../Services/DashboardApiService'
import uuid from 'react-uuid'
import ActionButton from '../../../components/Buttons/ActionButton'
import PuffLoader from "react-spinners/PuffLoader"
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import moment from 'moment/moment'
import NoData from './../../../components/NoData/NoData'
import { ProgressBar } from "react-bootstrap"
import { useDebounce } from 'use-debounce'
import BeatLoader from "react-spinners/BeatLoader"
import { Link, withRouter } from "react-router-dom"
import BankAccountInformation from '../BankAccount/BankAccountInformation'

const buttonSpinnerId = uuid();
function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}


const PayoutForm = (props) => {

  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const title = "Payout form";
  const description = "";
  const { user } = useSelector(state => state.Login);
  const { language } = useSelector(state => state.Languages);
  const [isShowConfirmPayoutModal, setIsShowConfirmPayoutModal] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState("");
  const [paymentSystems, setPaymentSystems] = useState([]);
  const [netValue, setNetValue] = useState(0);
  const [isShowAmountBlockSpinner, setIsShowAmountBlockSpinner] = useState(false);
  const [selectedAccountData, setSelectedAccountData] = useState(null);
  const [debounced] = useDebounce(transactionAmount, 500)
  const [isShowNetSpinner, setIsShowNetSpinner] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState(null);

  const [bankAccount, setBankAccount] = useState(null);
  const [payoutSystemName, setPayoutSystemName] = useState("");
  const [balance, setBalance] = useState(null);
  const [queryFxData, setQueryFxData] = useState(null);

  useOutsideAlerter(wrapperRef, setZoomImagePath);

  useEffect(() => {
    getBalance()
  }, [])

  const getBalance = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getBalance().then(response => {
      if (response && response.data) {
        setBalance(response.data)
      }
    }).catch(error => getFail(error, spinnerId)).finally(() => {
      extractRightSectionSpinner(spinnerId);
    })
  }

  useEffect(() => {
    getBankAccount()
    // return () => {
    //   cancelPayout();
    // }
  }, [])


  // useEffect(() => {
  //   getNetValue(debounced);
  // }, [debounced])

  // const getPaymentSystems = () => {
  //   const spinnerId = uuid();
  //   setRightSectionSpinner(spinnerId);
  //   DashboardApiService.getPaymentSystems().then(response => {
  //     if (response && response.data) {
  //       const data = [...response.data];
  //       setPaymentSystems(data);
  //       getAccountByType(data[0]);
  //     }
  //     extractRightSectionSpinner(spinnerId);
  //   }).catch(error => getFail(error, spinnerId));
  // }

  // const onNumberChange = (event, cb) => {
  //   if (event.target.value.includes("e") || event.target.value.includes(".") || event.target.value.includes("-") || event.target.value === "0") {
  //     cb("");
  //     return false;
  //   };
  //   if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value))) {
  //     cb(event.target.value ? +event.target.value : "");
  //     return false;
  //   }
  // };

  // const getNetValue = (balance) => {
  //   if (!balance) { return false; }
  //   const form = {
  //     transactionAmount: balance,
  //     payoutSystemId: null,
  //     agentId: user.agentId
  //   }
  //   setButtonSpinner(buttonSpinnerId);
  //   DashboardApiService.getNetValue(form).then(response => {
  //     if (response && response.data) {
  //       setNetValue(response.data);
  //       setIsShowConfirmPayoutModal(true);
  //     }
  //   }).catch(error => getFail(error)).finally(() => {
  //     extractButtonSpinner(buttonSpinnerId);
  //   })
  // }

  const getQueryFx = (typeId, _buttonSpinnerId = buttonSpinnerId) => {
    if (!typeId) { return false; }
    setButtonSpinner(_buttonSpinnerId);
    DashboardApiService.getQueryFx(typeId).then(response => {
      setQueryFxData(response?.data || null);
      setIsShowConfirmPayoutModal(true);
    }).catch(error => getFail(error)).finally(() => {
      extractButtonSpinner(_buttonSpinnerId);
    })
  }

  // const getAccountByType = (paymentSystem) => {
  //   if (!paymentSystem) { return false; }
  //   setIsShowAmountBlockSpinner(true);
  //   DashboardApiService.getAccountByType(paymentSystem.accountType).then(response => {
  //     if (response && response.data) {
  //       setBankAccount(response.data)
  //     }
  //     setIsShowAmountBlockSpinner(false);
  //   }).catch(error => getFail(error))
  // }

  const getBankAccount = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getBankAccountAsMerchant().then(response => {
      if (response && response.data) {
        setBankAccount(response.data);
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  // const cancelPayout = () => {
  //   AlertService.alertConfirm(
  //     `Are you sure ?`,
  //     "",
  //     "Yes",
  //     "No"
  //   ).then(() => {
  //     props.history.push(`/${language}/balance/payouts`)
  //   })
  // }

  const checkIsActivePayout = () => {
    if (user && user.isActive && bankAccount && bankAccount.isActive && balance && balance.balance > 0) {
      return false;
    } else {
      return true;
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
    setIsShowNetSpinner(false);
  }

  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          {
            bankAccount && !bankAccount.isActive ?
              <UncontrolledAlert color="danger" role="alert">
                <i className="mdi mdi-block-helper me-2"></i>
                Attention: You can't perform any payout process with an inactive bank account. Please wait for approval from Cryllex administration.
              </UncontrolledAlert>
              : null
          }
          <Breadcrumbs
            title="Payouts"
            breadcrumbItem="Create payout"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col xl={12} className="mb-2">
              <Card className="mini-stats-wid my-1">
                <CardBody className='p-3'>
                  <div className="me-1 d-flex justify-content-between align-items-center">
                    <div className='d-flex justify-content-between w-100'>
                      <div>
                        <h5 className="mb-1">Your balance</h5>
                      </div>

                      <div className='d-flex gap-3 align-items-center'>
                        <b className=' font-size-14'>
                          {
                            balance ? `$${balance.balanceAsString}` : `$0`
                          }
                        </b>
                        <div>
                          <ActionButton
                            type="button"
                            name="Create payout"
                            className="btn-primary btn-block"
                            spinnerId={buttonSpinnerId}
                            disabled={checkIsActivePayout()}
                            onClick={() => getQueryFx(user.userTypeId === MERCHANT_USER_TYPE_ID ? 1 : user.userTypeId === AGENT_USER_TYPE_ID ? 2 : null)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {
              bankAccount ?
                <Col sm={12}>
                  {
                    user && !user.isActive ?
                      <Card>
                        <CardBody>
                          <Alert color="danger" role="alert" className='m-0'>
                            <i className="mdi mdi-block-helper me-2"></i>
                            Attention: You can't perform any payout process with an unverified account. Please wait for approval from Cryllex administration.
                          </Alert>
                        </CardBody>
                      </Card>
                      : null
                  }
                  {/* {
                    user && user.isActive ?
                      <div>
                        <p className='h4 mt-3 mb-3'>Enter transaction amount</p>
                        <Card className='position-relative'>
                          <Row>
                            <Col md={6}>
                              <CardBody>
                                <form onSubmit={(event) => {
                                  event && event.preventDefault();
                                  if (bankAccount && bankAccount.isActive) {
                                    setIsShowConfirmPayoutModal(true);
                                  }
                                }}>
                                  <div className="mb-3">
                                    <div className='d-flex justify-content-between mb-2'>
                                      <Label htmlFor="transactionAmount">
                                        Amount
                                      </Label>
                                      <span className='text-muted text-truncate'>Transaction requirement <i className='bx bxs-info-circle'></i></span>
                                    </div>
                                    <div className="mb-3 position-relative">
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span
                                            className="input-group-text"
                                          >
                                            $
                                          </span>
                                        </div>
                                        <Input
                                          id="transactionAmount"
                                          type="text"
                                          className="form-control"
                                          value={transactionAmount}
                                          onChange={(event) => onNumberChange(event, setTransactionAmount)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div>

                                    <div>
                                      <div className='d-flex justify-content-between mt-1 align-items-center'>
                                        <p className='text-muted text-truncate mb-0'>Commision:</p>
                                        <p className='text-muted text-truncate mb-0 pt-1'>
                                          {
                                            !isShowNetSpinner ?
                                              <>${netValue || 0}</>
                                              : isShowNetSpinner ?
                                                <span className='d-block'>
                                                  <BeatLoader size={5} color="#A2A5B3" />
                                                </span> : null
                                          }
                                        </p>
                                      </div>
                                      <hr />
                                      <div className='d-flex justify-content-between mt-1'>
                                        <p className='text-muted text-truncate mb-0'>You will receive:</p>
                                        <b className='h3'>${transactionAmount || 0}</b>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='d-flex mt-3'>
                                    <ActionButton
                                      type="button"
                                      name="Continue"
                                      className="btn-primary btn-block mb-2 w-100"
                                      spinnerId={buttonSpinnerId}
                                      disabled={!transactionAmount || !bankAccount || (bankAccount && !bankAccount.isActive)}
                                      onClick={() => setIsShowConfirmPayoutModal(true)}
                                    />
                                  </div>
                                </form>
                              </CardBody>
                            </Col>
                            <Col md={6}>
                              <div className='p-3'>
                                <h4 className='mb-3'>Notification</h4>
                                <p>
                                  • Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                </p>
                              </div>
                            </Col>
                          </Row>
                          {
                            isShowAmountBlockSpinner ?
                              <div className="dashboard-spinner-layer" >
                                <div className="sweet-loading">
                                  <PuffLoader size={60} color={SPINNER_COLOR} />
                                </div>
                              </div>
                              : null
                          }
                        </Card>
                      </div>
                      : <Card>
                        <CardBody>
                          <Alert color="danger" role="alert" className='m-0'>
                            <i className="mdi mdi-block-helper me-2"></i>
                            Attention: You can't perform any payout process with an unverified account. Please wait for approval from Cryllex administration.
                          </Alert>
                        </CardBody>
                      </Card>
                  } */}
                  {
                    bankAccount ?
                      <div>
                        <p className='h4 mt-3 mb-3'>Bank account information</p>
                        <Card>
                          <CardBody>
                            <BankAccountInformation
                              bankAccount={bankAccount}
                            />
                          </CardBody>
                        </Card>
                      </div>
                      : null
                  }

                </Col>
                : <Col>
                  <Card>
                    <NoData />
                    <CardBody>
                      <p className='text-center'>
                        Currently, there is no active bank account associated with your profile. &nbsp;
                        <Link className='underline' to={`/${language}/bank-account-form?redirectUrl=balance/payout/form`}>Create bank account</Link>
                      </p>
                    </CardBody>
                  </Card>
                </Col>

            }

          </Row>
          {
            isShowConfirmPayoutModal ?
              <ConfirmPayoutModal
                isShowConfirmPayoutModal={isShowConfirmPayoutModal}
                setIsShowConfirmPayoutModal={setIsShowConfirmPayoutModal}
                transactionAmount={transactionAmount}
                // netValue={netValue}
                // setNetValue={setNetValue}
                bankAccount={bankAccount}
                values={payoutSystemName === "swift" || payoutSystemName === "stripe" ? bankAccount : { email: user.email }}
                setTransactionAmount={setTransactionAmount}
                payoutSystemId={null}
                balance={balance}
                queryFxData={queryFxData}
                setQueryFxData={setQueryFxData}
                getQueryFx={getQueryFx}
              />
              : null
          }
        </div>
      </div>
    </>
  )
}

export default withRouter(PayoutForm)
