// import React, { useState } from 'react'
// import {
//   Button,
//   Card,
//   CardBody,
//   Col,
//   Label,
//   Row,
// } from "reactstrap"
// import { useDispatch, useSelector } from 'react-redux'
// import HtmlHead from '../../../components/HtmlHead/HtmlHead'
// import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
// import moment from 'moment'
// import ActionButton from '../../../components/Buttons/ActionButton'
// import uuid from 'react-uuid'
// import { addButtonSpinner } from '../../../store/actions'
// import { addRightSectionSpinner, removePageSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions';
// import DashboardApiService from './../../../Services/DashboardApiService';
// import AlertService from '../../../Services/alertService'
// import { ERROR_KEY } from '../../../Constants/MainKeys'
// import { useEffect } from 'react'
// import NoData from '../../../components/NoData/NoData'

// const buttonSpinnerId = uuid();

// const ClientKycInformation = (props) => {

//   let entityFormNumber = 1;
//   const title = "Client Database";
//   const description = "";
//   const { clientId: orgId } = props.match.params;
//   const dispatch = useDispatch()
//   const { rightSectionSpinners } = useSelector(state => state.Spinners);
//   const { user } = useSelector(state => state.Login);
//   const { language, languages } = useSelector(state => state.Languages);
//   const [clientKycInformation, setClientKycInformation] = useState(null);

//   useEffect(() => {
//     getClientKycInformation();
//     return () => {
//       setClientKycInformation(null)
//     }
//   }, [])

//   const getClientKycInformation = () => {
//     if (!orgId) { return false; }
//     const spinnerId = uuid();
//     dispatch(addRightSectionSpinner(spinnerId))
//     console.log(user);
//     const data = {
//       organizationId: orgId,
//       entityId: user.activeAgentId,
//       entityFormNumber,
//     };
//     DashboardApiService.getAgentViewOrgKYC(data).then(response => {
//       // DashboardApiService.getOrgKycInformation(user.activeAgentId, clientId).then(response => {
//       if (response && response.data) {
//         setClientKycInformation(response.data)
//       }

//     }).catch(error => getFail(error)).finally(() => {
//       dispatch(removeRightSectionSpinner(spinnerId))
//     })
//   }

//   const getFail = (error) => {
//     error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
//   }

//   return (
//     <>
//       <HtmlHead title={title} description={description} />
//       <div className='page-content position-relative min-h-100vh'>
//         <RightSectionSpinner spinner={rightSectionSpinners} />

//         <div className="d-flex flex-wrap justify-content-between mb-3">
//           <h4 className=" me-2">{moment(new Date()).format("LL")}</h4>
//         </div>

//         <h2 className="font-size-22">{`Welcome ${user.fullName}, to your Client Database.`}</h2>
//         {
//           clientKycInformation ?
//             <Row>
//               <Col lg={12}>
//                 <Row>
//                   <Col sm={12}>
//                     <Card>
//                       <CardBody>
//                         <div className='mt-2'>
//                           <h4 className="font-size-18">{`Merchant: Starbucks (Client ID #00001)`}</h4>
//                           <h5 className='mt-3'><b>Merchant KYC Information</b></h5>
//                           <Row className='mt-4'>
//                             <Col md={6}>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Legal Business Name:</p>
//                                 <p><b>{clientKycInformation.legalBusinessName}</b></p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Username</p>
//                                 <p><b>???</b></p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Telephone Number:</p>
//                                 <p><b>{clientKycInformation.phoneNumber}</b></p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>User Passport/ID*:</p>
//                                 <p><b>{clientKycInformation.userPassportID}</b></p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>User Address:</p>
//                                 <p><b>{clientKycInformation.userAddress}</b></p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>User Address Document*:</p>
//                                 <p>
//                                   <img
//                                     className='rounded border cursor-pointer'
//                                     src={clientKycInformation.userAddressFilePath}
//                                     width={150}
//                                     alt='/'
//                                   />
//                                 </p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Business Registration Number:</p>
//                                 <p><b>{clientKycInformation.businessRegistrationNumber}</b></p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Business Registration Document*:</p>
//                                 <p><b>???</b></p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Business Country of Incorporation:</p>
//                                 <p><b>{clientKycInformation.businessCountryOfIncorporation}</b></p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Business Country of Registration:</p>
//                                 <p><b>{clientKycInformation.businessCountryOfRegistration}</b></p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Regulatory Licensing Number (if any):</p>
//                                 <p><b>{clientKycInformation.businessRegulatoryLicencingNumber}</b></p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Regulatory License Document*:</p>
//                                 <p>
//                                   <img
//                                     className='rounded border cursor-pointer'
//                                     src={clientKycInformation.businessRegulatoryLicencingFilePath}
//                                     width={150}
//                                     alt='/'
//                                   />
//                                 </p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Business Address:</p>
//                                 <p><b>{clientKycInformation.businessAddress}</b></p>
//                               </div>

//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Business Address Document*:</p>
//                                 <p>
//                                   <img
//                                     className='rounded border cursor-pointer'
//                                     src={clientKycInformation.businessAddressFilePath}
//                                     width={150}
//                                     alt='/'
//                                   />
//                                 </p>
//                               </div>
//                             </Col>
//                             <Col md={6}>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Liveness Test Status:</p>
//                                 <p><b>???</b></p>
//                               </div>
//                             </Col>
//                           </Row>
//                         </div>
//                       </CardBody>
//                     </Card>
//                     <Card>
//                       <CardBody>
//                         <div className='mt-2'>
//                           <h4 className="font-size-18">{`Merchant: Starbucks (Client ID #00001)`}</h4>
//                           <h5 className='mt-3'><b>Merchant Director and Shareholder Information</b></h5>
//                           <Row className='mt-4'>

//                             <Col md={6} className='mb-2'>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Certificate of Insurance:</p>
//                                 <p><b>???</b></p>
//                               </div>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Number of Directors:</p>
//                                 <p><b>???</b></p>
//                               </div>
//                             </Col>

//                             <Col sm={12}>
//                               <Row>
//                                 {
//                                   clientKycInformation.directores && clientKycInformation.directores.length ?
//                                     clientKycInformation.directores.map((item, index) => {
//                                       return <Col key={index} md={6} className='px-4'>
//                                         <p className='bold font-size-16'>Director #{index + 1}</p>

//                                         <div className='d-flex justify-content-between mt-1'>
//                                           <p>Director Name:</p>
//                                           <p><b>{item.firstname} {item.lastname}</b></p>
//                                         </div>
//                                         <div className='d-flex justify-content-between mt-1'>
//                                           <p>Director Passport/ID*:</p>
//                                           <p><b>???</b></p>
//                                         </div>
//                                         <div className='d-flex justify-content-between mt-1'>
//                                           <p>Director Address:</p>
//                                           <p><b>{item.address}</b></p>
//                                         </div>
//                                         <div className='d-flex justify-content-between mt-1'>
//                                           <p>Director Address Document*:</p>
//                                           <p>
//                                             <img
//                                               className='rounded border cursor-pointer'
//                                               src={clientKycInformation.addressDocumentFilePath}
//                                               alt='/'
//                                               width={150}
//                                             />
//                                           </p>
//                                         </div>
//                                       </Col>
//                                     })
//                                     : null
//                                 }


//                               </Row>
//                             </Col>

//                             <Col md={6} className='mt-4 mb-2'>
//                               <div className='d-flex justify-content-between mt-1'>
//                                 <p>Number of Shareholders:</p>
//                                 <p><b>???</b></p>
//                               </div>
//                             </Col>

//                             <Col sm={12}>
//                               <Row>
//                                 {
//                                   clientKycInformation.shareholders && clientKycInformation.shareholders.length ?
//                                     clientKycInformation.shareholders.map((item, index) => {
//                                       return <Col key={index} md={6} className='px-4'>
//                                         <p className='bold font-size-16'>Shareholder #{index + 1}</p>

//                                         <div className='d-flex justify-content-between mt-1'>
//                                           <p>Shareholder Name:</p>
//                                           <p><b>{item.firstname} {item.lastname}</b></p>
//                                         </div>
//                                         <div className='d-flex justify-content-between mt-1'>
//                                           <p>Shareholder Percentage (%):</p>
//                                           <p><b>{item.percentage}</b></p>
//                                         </div>
//                                         <div className='d-flex justify-content-between mt-1'>
//                                           <p>Certificate of Incumbency*:</p>
//                                           <p>
//                                             <img
//                                               className='rounded border cursor-pointer'
//                                               src={clientKycInformation.certificateOfIncumbencyFilePath}
//                                               alt='/'
//                                               width={150}
//                                             />
//                                           </p>
//                                         </div>
//                                         <div className='d-flex justify-content-between mt-1'>
//                                           <p>Shareholder Address Document*:</p>
//                                           <p><b>???</b></p>
//                                         </div>
//                                         <div className='d-flex justify-content-between mt-1'>
//                                           <p>Address:</p>
//                                           <p><b>{item.address}</b></p>
//                                         </div>
//                                       </Col>
//                                     })
//                                     : null
//                                 }


//                               </Row>
//                             </Col>


//                           </Row>
//                         </div>
//                       </CardBody>
//                     </Card>


//                   </Col>
//                   <Col sm={12}>
//                     <div className='d-flex justify-content-end mb-4'>
//                       <Button
//                         type="button"
//                         color="primary"
//                         className='shadow-sm nowrap px-4'
//                         onClick={() => window.history.back()}
//                       >
//                         Back
//                       </Button>
//                     </div>
//                   </Col>
//                 </Row>
//               </Col>

//             </Row>
//             : <NoData />
//         }

//       </div>
//     </>
//   );
// }

// export default ClientKycInformation;

// Hron e asel vor pahenq es hatvacy





import React, { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Table,
} from "reactstrap"
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import uuid from 'react-uuid'
import { addRightSectionSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions';
import DashboardApiService from '../../../Services/DashboardApiService';
import AlertService from '../../../Services/alertService'
import { ERROR_KEY, VALID_IMAGE_TYPES_KEY } from '../../../Constants/MainKeys'
import { useEffect } from 'react'
import NoData from '../../../components/NoData/NoData'
import { useRef } from 'react'
import MainService from '../../../Services/MainService'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const ClientKycInformation = (props) => {

  let entityFormNumber = 1;
  const title = "Client Database";
  const description = "";
  const { clientId: orgId } = props.match.params;
  const dispatch = useDispatch()
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);
  const { language, languages } = useSelector(state => state.Languages);
  const [clientKycInformation, setClientKycInformation] = useState(null);
  const [zoomImagePath, setZoomImagePath] = useState(null);
  const wrapperRef = useRef(null);

  const [organizationKycData, setOrganizationKycData] = useState(null);

  useOutsideAlerter(wrapperRef, setZoomImagePath);

  useEffect(() => {
    getClientKycInformation();
  }, [])

  const getClientKycInformation = () => {
    if (!orgId) { return false; }
    const spinnerId = uuid();
    dispatch(addRightSectionSpinner(spinnerId))
    DashboardApiService.getAgentViewOrgKYCAsAgent(orgId).then(response => {
      if (response && response.data) {
        setOrganizationKycData(response.data);
      }

    }).catch(error => getFail(error)).finally(() => {
      dispatch(removeRightSectionSpinner(spinnerId))
    })
  }

  const viewProof = (path) => {
    if (!path) { return false; }
    if (MainService.getFxtensions(path) === "image") {
      setZoomImagePath(path)
    } else {
      window.open(path, "_blank")
    }
  }

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem={
              organizationKycData && organizationKycData.primaryUser && organizationKycData.primaryUser?.companyName ? `KYC | ${organizationKycData.primaryUser?.companyName}` : ""
            }
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col lg={12}>
              <Row>
                <Col sm={12}>
                  {
                    organizationKycData ?
                      <div>
                        {
                          organizationKycData.primaryUser ?
                            <div>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col md={6}>
                                      <Table bordered striped responsive className='mb-0'>
                                        <tbody>
                                          <tr>
                                            <th
                                              scope="row"
                                              style={{ width: "400px" }}
                                              className={"text-capitalize"}
                                            >
                                              Company Name
                                            </th>
                                            <td className='w-50'><span className='max-line-1' title={organizationKycData.primaryUser?.companyName}>{organizationKycData.primaryUser?.companyName}</span></td>
                                          </tr>
                                          <tr>
                                            <th
                                              scope="row"
                                              style={{ width: "400px" }}
                                              className={"text-capitalize"}
                                            >
                                              First Name
                                            </th>
                                            <td className='w-50'><span className='max-line-1' title={organizationKycData.primaryUser?.firstName}>{organizationKycData.primaryUser?.firstName}</span></td>
                                          </tr>
                                          <tr>
                                            <th
                                              scope="row"
                                              style={{ width: "400px" }}
                                              className={"text-capitalize"}
                                            >
                                              Last Name
                                            </th>
                                            <td className='w-50'><span className='max-line-1' title={organizationKycData.primaryUser?.lastName}>{organizationKycData.primaryUser?.lastName}</span></td>
                                          </tr>
                                          <tr>
                                            <th
                                              scope="row"
                                              style={{ width: "400px" }}
                                              className={"text-capitalize"}
                                            >
                                              Email
                                            </th>
                                            <td className='w-50'><span className='max-line-1' title={organizationKycData.primaryUser?.email}>{organizationKycData.primaryUser?.email}</span></td>
                                          </tr>
                                          <tr>
                                            <th
                                              scope="row"
                                              style={{ width: "400px" }}
                                              className={"text-capitalize"}
                                            >
                                              Phone Number
                                            </th>
                                            <td className='w-50'><span className='max-line-1' title={organizationKycData.primaryUser?.phone}>
                                              {MainService.formatPhoneNumber(organizationKycData.primaryUser?.phone)}
                                            </span></td>
                                          </tr>
                                          <tr>
                                            <th
                                              scope="row"
                                              style={{ width: "400px" }}
                                              className={"text-capitalize"}
                                            >
                                              Contact Document File
                                            </th>
                                            <td className='w-50'>
                                              {
                                                !organizationKycData.contactDocumentFile ? <span className='text-danger'>No attached file yet</span> :
                                                  <Button
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => {
                                                      viewProof(organizationKycData.contactDocumentFile.filePath)
                                                    }}
                                                  >
                                                    View Proof
                                                  </Button>
                                              }
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </Col>
                                    <Col md={6}>
                                      <Table bordered striped responsive className='mb-0'>
                                        <tbody>

                                          <tr>
                                            <th
                                              scope="row"
                                              style={{ width: "400px" }}
                                              className={"text-capitalize"}
                                            >
                                              Payee Country
                                            </th>
                                            <td className='w-50'><span className='max-line-1' title={organizationKycData.primaryUser?.payeeCountry}>{organizationKycData.primaryUser?.payeeCountry}</span></td>
                                          </tr>

                                          <tr>
                                            <th
                                              scope="row"
                                              style={{ width: "400px" }}
                                              className={"text-capitalize"}
                                            >
                                              Post Code
                                            </th>
                                            <td className='w-50'><span className='max-line-1' title={organizationKycData.primaryUser?.postCode}>{organizationKycData.primaryUser?.postCode}</span></td>
                                          </tr>
                                          <tr>
                                            <th
                                              scope="row"
                                              style={{ width: "400px" }}
                                              className={"text-capitalize"}
                                            >
                                              State/Province
                                            </th>
                                            <td className='w-50'><span className='max-line-1' title={organizationKycData.primaryUser?.province}>{organizationKycData.primaryUser?.province}</span></td>
                                          </tr>
                                          <tr>
                                            <th
                                              scope="row"
                                              style={{ width: "400px" }}
                                              className={"text-capitalize"}
                                            >
                                              Street Address
                                            </th>
                                            <td className='w-50'><span className='max-line-1' title={organizationKycData.primaryUser?.streetAddress}>{organizationKycData.primaryUser?.streetAddress}</span></td>
                                          </tr>
                                          {/* <td className='w-50'>
                                            {
                                              !organizationKycData.referenceFile ? <span className='text-danger'>No attached file yet</span> :
                                                <Button
                                                  color="primary"
                                                  size="sm"
                                                  onClick={() => {
                                                    viewProof(organizationKycData.referenceFile)
                                                  }}
                                                >
                                                  View Proof
                                                </Button>
                                            }
                                          </td> */}
                                          <tr>
                                            <th
                                              scope="row"
                                              style={{ width: "400px" }}
                                              className={"text-capitalize"}
                                            >
                                              Reference File
                                            </th>
                                            <td className='w-50'>
                                              {
                                                !organizationKycData.referenceFile ? <span className='text-danger'>No attached file yet</span> :
                                                  <Button
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => {
                                                      viewProof(organizationKycData.referenceFile.filePath)
                                                    }}
                                                  >
                                                    View Proof
                                                  </Button>
                                              }
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </div>
                            : null
                        }

                      </div>

                      : <Card>
                        <CardBody>
                          <NoData />
                        </CardBody>
                      </Card>

                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ClientKycInformation;
