// import PropTypes from "prop-types"
// import React, { useState } from "react"
// import { Collapse } from "reactstrap"
// import { Link, withRouter } from "react-router-dom"
// import classname from "classnames"
// import { toggleLeftmenu } from "../../store/actions"
// import { withTranslation } from "react-i18next"
// import { connect, useDispatch, useSelector } from "react-redux"
// import { ADMIN_USER_TYPE_ID } from "../../Constants/MainKeys"

// const Navbar = props => {

//   const dispatch = useDispatch()
//   const [dashboard, setdashboard] = useState(false);
//   const { user } = useSelector(state => state.Login);
//   const { language } = useSelector(state => state.Languages);

//   return (
//     <React.Fragment>
//       <div className="topnav">
//         <div className="container-fluid">
//           <nav
//             className="navbar navbar-light navbar-expand-lg topnav-menu"
//             id="navigation"
//           >
//             <Collapse
//               isOpen={props.leftMenu}
//               className="navbar-collapse w-100"
//               id="topnav-menu-content"
//             >
//               <ul className="navbar-nav horizontal-scrollbar">
//                 {/* <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/dashboard`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className='bx bxs-dashboard me-2'></i>
//                     Dashboard
//                   </Link>
//                 </li> */}

//                 {/* <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     onClick={e => {
//                       e.preventDefault()
//                       setdashboard(!dashboard)
//                     }}
//                     to="#"
//                   >
//                     <i className='bx bxs-bank me-2'></i>
//                     Account Balance
//                     <div className="arrow-down"></div>
//                   </Link>
//                   <div
//                     className={classname("dropdown-menu", { show: dashboard })}
//                   >
//                     <Link to={`/${language}/cash-balance`} className="dropdown-item" onClick={() => dispatch(toggleLeftmenu(false))}>Cash balance</Link>
//                     <Link to={`/${language}/crypto-balance`} className="dropdown-item" onClick={() => dispatch(toggleLeftmenu(false))}>Crypto balance</Link>
//                     <Link to={`/${language}/crypto-staking`} className="dropdown-item" onClick={() => dispatch(toggleLeftmenu(false))}>Crypto staking</Link>
//                     <Link to={`/${language}/account-balances`} className="dropdown-item" onClick={() => dispatch(toggleLeftmenu(false))}>All</Link>
//                   </div>
//                 </li> */}

//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/payments/transaction-history`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className='bx bx-transfer-alt me-2'></i>
//                     TXN History
//                   </Link>
//                 </li>

//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/balance/payouts`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className="bx bx-briefcase-alt-2 me-2"></i>
//                     Payouts
//                   </Link>
//                 </li>
//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/bank-account`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className="bx bxs-bank me-2"></i>
//                     Accounts
//                   </Link>
//                 </li>
//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/instant-payment`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className='bx bx-dollar-circle me-2' ></i>
//                     Instant Payment
//                   </Link>
//                 </li>



//                 {/* <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/crypto-hedging`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className='bx bx-shield me-2'></i>
//                     Crypto Hedging
//                   </Link>
//                 </li>

//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/crypto-discounts`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className='bx bxs-discount me-2' ></i>
//                     Crypto Discounts
//                   </Link>
//                 </li>

//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/crypto-refunds`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className='bx bx-shape-circle me-2'></i>
//                     Refunds
//                   </Link>
//                 </li>

//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/report-summary`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className='bx bxs-report me-2'></i>
//                     Reports Summary
//                   </Link>
//                 </li>

//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/payments-analysis`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className='bx bx-search-alt me-2' ></i>
//                     Payments analysis
//                   </Link>
//                 </li>

//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/annual-tax-forms`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className='bx bx-calculator me-2'></i>
//                     Tax & Accounting
//                   </Link>
//                 </li> */}

//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to={`/${language}/api-keys`}
//                     onClick={() => dispatch(toggleLeftmenu(false))}
//                   >
//                     <i className='bx bx-key me-2'></i>
//                     Api keys
//                   </Link>
//                 </li>

//                 {
//                   user && user.userTypeId === ADMIN_USER_TYPE_ID ?
//                     <>
//                       <li className="nav-item dropdown">
//                         <Link
//                           className="nav-link dropdown-toggle arrow-none"
//                           to={`/${language}/pages`}
//                           onClick={() => dispatch(toggleLeftmenu(false))}
//                         >
//                           <i className='bx bx-book-content me-2'></i>
//                           Pages
//                         </Link>
//                       </li>
//                     </>
//                     : null
//                 }
//               </ul>
//             </Collapse>
//           </nav>
//         </div>
//       </div>
//     </React.Fragment>
//   )
// }
// Navbar.propTypes = {
//   leftMenu: PropTypes.any,
//   location: PropTypes.any,
//   menuOpen: PropTypes.any,
//   t: PropTypes.any,
// }

// const mapStatetoProps = state => {
//   const { leftMenu } = state.Layout
//   return { leftMenu }
// }

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(Navbar))
// )



import React from "react"
import { Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { toggleLeftmenu } from "../../store/actions"
import { withTranslation } from "react-i18next"
import { connect, useDispatch, useSelector } from "react-redux"
import { ADMIN_USER_TYPE_ID } from "../../Constants/MainKeys"

const Navbar = (props) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.Login)
  const { language } = useSelector((state) => state.Languages)
  const leftMenu = useSelector((state) => state.Layout.leftMenu) // Извлекаем leftMenu напрямую

  return (
    <div className="topnav">
      <div className="container-fluid">
        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
          <Collapse isOpen={leftMenu} className="navbar-collapse w-100" id="topnav-menu-content">
            <ul className="navbar-nav horizontal-scrollbar">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to={`/${language}/payments/transaction-history`}
                  onClick={() => dispatch(toggleLeftmenu(false))}
                >
                  <i className='bx bx-transfer-alt me-2'></i>
                  TXN History
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to={`/${language}/balance/payouts`}
                  onClick={() => dispatch(toggleLeftmenu(false))}
                >
                  <i className="bx bx-briefcase-alt-2 me-2"></i>
                  Payouts
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to={`/${language}/bank-account`}
                  onClick={() => dispatch(toggleLeftmenu(false))}
                >
                  <i className="bx bxs-bank me-2"></i>
                  Accounts
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to={`/${language}/instant-payment`}
                  onClick={() => dispatch(toggleLeftmenu(false))}
                >
                  <i className='bx bx-dollar-circle me-2'></i>
                  Instant Payment
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to={`/${language}/api-keys`}
                  onClick={() => dispatch(toggleLeftmenu(false))}
                >
                  <i className='bx bx-key me-2'></i>
                  Api keys
                </Link>
              </li>

              {user && user.userTypeId === ADMIN_USER_TYPE_ID && (
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to={`/${language}/pages`}
                    onClick={() => dispatch(toggleLeftmenu(false))}
                  >
                    <i className='bx bx-book-content me-2'></i>
                    Pages
                  </Link>
                </li>
              )}
            </ul>
          </Collapse>
        </nav>
      </div>
    </div>
  )
}

export default withRouter(connect()(withTranslation()(Navbar)))
