import React, { useState, useEffect, useCallback, useRef } from 'react'
import HtmlHead from '../../../components/HtmlHead/HtmlHead';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Card,
  CardBody,
  Row,
  Label,
  Input,
  Button,
  Col,
  UncontrolledAlert,
  Alert
} from "reactstrap"
import AlertService from '../../../Services/alertService';
import MainService from '../../../Services/MainService';
import { Link } from 'react-router-dom';
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_KEY, SPINNER_COLOR, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY, allValidFileTypes, fielsLengths } from '../../../Constants/MainKeys';
import ActionButton from '../../../components/Buttons/ActionButton';
import uuid from 'react-uuid';
import PhoneInput from 'react-phone-input-2'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions';
import DashboardApiService from '../../../Services/DashboardApiService';
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption';
import { PuffLoader } from 'react-spinners';
import FileComponent from './Components/FileComponent';
import FilesComponent from './Components/FilesComponent';
import Switch from "react-switch"
import ApiService from '../../../Services/ApiService';


const spinnerId = uuid();
const buttonSpinnerId = uuid();

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function BusinessDetails(props) {

  const title = "Business KYC";
  const description = "";
  const mainService = new MainService();

  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { user } = useSelector(state => state.Login);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [isChanged, setIsChanged] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [industryLibrary, setIndustryLibrary] = useState(null);
  const [paymentMethodLibrary, setPaymentMethodLibrary] = useState(null);
  const [customerTypeLibrary, setCustomerTypeLibrary] = useState(null);
  const [natureOfBusinessLibrary, setSellTypeLibrary] = useState(null);
  const [structureLibrary, setStructureLibrary] = useState(null);
  const [typeOfEntityLibrary, setTypeOfEntityLibrary] = useState(null);
  const [zoomImagePath, setZoomImagePath] = useState(null);
  const [isShowImageLoader, setIsShowImageLoader] = useState(false)
  const [countries, setCountries] = useState([])
  const [isDisabledFields, setIsDisabledFields] = useState(false)

  useOutsideAlerter(wrapperRef, setZoomImagePath);

  const [values, setValues] = useState({
    username: "", //read only
    legalBusinessName: "",
    phoneNumber: "",
    taxId: "",
    incorporationNumber: "",
    ssnNumber: "",
    dbaName: "",
    businessCountryOfIncorporation: "",
    businessCountryOfRegistrationId: null,
    businessRegistrationNumber: "",
    businessRegisteredAddress: "",
    primaryRegulatoryLicencingNumber: "",
    customerTypeLibraryDataId: null,
    industryLibraryDataId: null,
    natureOfBusinessLibraryDataId: null,
    structureLibraryDataId: null,
    typeOfEntityLibraryDataId: null,
    isActive: false,

    province: "",
    postCode: "",
    city: "",
    paymentMethodLibraryDataId: null,
    // userAddress: "",
    // userPassportID: "",
  })

  const getOrganizationData = () => {

    setRightSectionSpinner(spinnerId);
    DashboardApiService.getOrganizationData().then(response => {
      if (response && response.data) {
        const { customerTypeLibrary: customerTypeLibrary, industryLibrary, paymentMethodLibrary, natureOfBusinessLibrary, structureLibrary, typeOfEntityLibrary, ...newState } = response.data;
        customerTypeLibrary && setCustomerTypeLibrary(customerTypeLibrary);
        industryLibrary && setIndustryLibrary(industryLibrary);
        paymentMethodLibrary && setPaymentMethodLibrary(paymentMethodLibrary);
        natureOfBusinessLibrary && setSellTypeLibrary(natureOfBusinessLibrary);
        structureLibrary && setStructureLibrary(structureLibrary);
        typeOfEntityLibrary && setTypeOfEntityLibrary(typeOfEntityLibrary);
        if (newState.files && newState.files.length) {
          const groupedFiles = newState.files.reduce((groups, file) => {
            const key = file.fileType;
            (groups[key] = groups[key] || []).push(file);
            return groups;
          }, {});
          const arrayOfArrays = Object.values(groupedFiles);
          if (arrayOfArrays && arrayOfArrays.length) {
            arrayOfArrays.forEach(item => {
              newState[MainService.getOrganizationDetailsFileNameByType(item[0].fileType)] = item;
            })
          }
        }
        delete newState.files;
        setValues(newState)
      }
      extractRightSectionSpinner(spinnerId)
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }


  const getCountries = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  useEffect(() => {
    getOrganizationData();
    getCountries();
  }, [])

  const bulkUploadFile = async (data) => {
    const { event, fieldName } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();
    formData.append("id", values.id);
    formData.append("type", MainService.getOrganizationDetailsFileType(fieldName));
    let files = [...event.target.files];
    for (let i in files) {
      let file = files[i];
      let fileName = file.name;
      let lastDotIndex = fileName.lastIndexOf('.');
      let fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';

      if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
        await mainService.readFile(file, allValidFileTypes).then(uploadedFile => {
          setIsShowImageLoader(true);
          formData.append("documents", file);
        }).catch(error => {
          error && AlertService.alert("error", "Invalid file format");
        });
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
    DashboardApiService.bulkUploadDocumentAsMerchant(formData).then(response => {
      if (values[fieldName] && Array.isArray(values[fieldName])) {
        setValues((values) => ({
          ...values,
          [fieldName]: [...values[fieldName] || [], ...response.data],
        }));
      } else {
        setValues((values) => ({
          ...values,
          [fieldName]: response.data,
        }));
      }
      AlertService.alert(SUCCESS_KEY, "Data saved")
    }).catch(error => getFail(error)).finally(() => {
      setIsShowImageLoader(false);
    })
  };

  const uploadFile = async (data) => {
    const { event, fieldName } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();
    formData.append("id", values.id);
    formData.append("type", MainService.getOrganizationDetailsFileType(fieldName));

    let files = [...event.target.files];
    let file = files[0];
    let fileName = file.name;
    let lastDotIndex = fileName.lastIndexOf('.');
    let fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
    if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
      await mainService.readFile(file, allValidFileTypes).then(uploadedFile => {
        setIsShowImageLoader(true);
        formData.append("document", file);
        DashboardApiService.uploadDocumentAsMerchant(formData).then(response => {
          setValues((values) => ({
            ...values,
            [fieldName]: response.data,
          }))
          AlertService.alert(SUCCESS_KEY, "Data saved")
        }).catch(error => getFail(error)).finally(() => {
          setIsShowImageLoader(false);
        })
      }).catch(error => {
        error && AlertService.alert("error", "Invalid file format");
      });
    } else {
      AlertService.alert("error", "Invalid file format")
      return false;
    }
  };

  const deleteDocument = (data) => {
    const { fieldName, fileLibraryId } = data;
    if (!fileLibraryId) { return false; }
    AlertService.alertConfirm(
      `Are you sure?`,
      "Do you want to delete the current file?",
      "Yes",
      "No"
    ).then(() => {
      setIsShowImageLoader(true);
      DashboardApiService.deleteDocumentAsMerchant(fileLibraryId, values.id, MainService.getOrganizationDetailsFileType(fieldName)).then(() => {
        if (Array.isArray(values[fieldName])) {
          setValues((values) => ({
            ...values,
            [fieldName]: values[fieldName].filter(item => item.fileLibraryId !== fileLibraryId),
          }))
        } else {
          setValues((values) => ({
            ...values,
            [fieldName]: null,
          }))
        }
        AlertService.alert(SUCCESS_KEY, "File deleted successfully")
      }).catch(error => getFail(error)).finally(() => {
        setIsShowImageLoader(false);
      })
    })
  }

  const onChange = (event, field, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false)
  }

  const onNumberChange = (event, fieldName, maxLength = Infinity) => {
    if (event.target.value.includes("e") || event.target.value.includes(".") || event.target.value.includes("-") || event.target.value === "0") {
      setValues(values => ({
        ...values,
        [fieldName]: "",
      }))
      return false;
    };
    if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value) >= 0 && Number(event.target.value) <= maxLength)) {
      setValues(values => ({
        ...values,
        [fieldName]: event.target.value ? +event.target.value : ""
      }))
      return false;
    }
  };

  const onPhoneNumberChange = (event, field) => {
    setValues((values) => ({
      ...values,
      [field]: event,
    }));
    setIsInvalidSubmit(false)
  }

  const onSelectOptionChange = (value, field) => {
    setValues((values) => ({
      ...values,
      [field]: value,
    }));
    setIsChanged(true);
    setIsInvalidSubmit(false)
  }

  // const onChangeIsActive = () => {
  //   AlertService.alertConfirm(
  //     `Are you sure?`,
  //     "Changing the status will affect your activity. Please ensure careful consideration before making any status adjustments.",
  //     "Yes",
  //     "No"
  //   ).then(() => {
  //     setIsDisabledFields(true)
  //     const data = {
  //       id: values.id,
  //       updaterUserId: null,
  //       status: !values.isActive,
  //     }
  //     DashboardApiService.organizationDetailUpdateStatus(data).then(() => {
  //       setValues(values => ({
  //         ...values,
  //         isActive: !values.isActive
  //       }))
  //       AlertService.alert(SUCCESS_KEY, "Data saved")
  //     }).catch(error => getFail(error)).finally(() => {
  //       setIsDisabledFields(false)
  //     })
  //   })
  // }

  const onSubmit = (event) => {
    event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    if (!values.taxId) {
      values.taxId = null;
    }
    DashboardApiService.updateBusinessDetail(values).then((response) => {
      if (response && response.data) {
        setValues((values) => ({
          ...values,
          id: response.data.id
        }))
      }
      AlertService.alert(SUCCESS_KEY, "Business KYC updated successfully")
    }).catch(error => getFail(error)).finally(() => {
      extractButtonSpinner(buttonSpinnerId)
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <section className='page-content position-relative min-h-100'>
        <HtmlHead title={title} description={description} />
        <RightSectionSpinner spinner={rightSectionSpinners} />
        {
          !values.isApproved && values.rejectMessage ?
            <UncontrolledAlert color="danger" role="alert">
              <i className="mdi mdi-block-helper me-2"></i>
              Reject message: {values.rejectMessage}
            </UncontrolledAlert>
            : null
        }
        <div className="container-fluid">
          <form onSubmit={onSubmit} onChange={() => setIsChanged(true)}>
            <Breadcrumbs
              title="Cryllex"
              breadcrumbItem="Business KYC"
            />
            <Row className="justify-content-center">
              <Card>
                <CardBody>
                  <Col>
                    <Row>
                      <Col sm={12}>
                        <Row>
                          {
                            values.cryllexId ?
                              <Col sm={12}>
                                <h4 className='card-title'>Id: {values.cryllexId}</h4>
                                <hr />
                              </Col>
                              : null
                          }
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='legalBusinessName' className='text-muted text-truncate mb-0'>Legal Business Name</Label>
                            <Input
                              id="legalBusinessName"
                              type="text"
                              placeholder='e.g. Your business name'
                              className={`form-control`}
                              value={values.legalBusinessName || ""}
                              onChange={event => onChange(event, "legalBusinessName", fielsLengths.length_120)}
                            />
                          </Col>

                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='incorporationNumber' className='text-muted text-truncate mb-0'>Incorporation number</Label>
                            <Input
                              id="incorporationNumber"
                              type="text"
                              placeholder='e.g. 0123456789'
                              className={`form-control`}
                              value={values.incorporationNumber || ""}
                              onChange={event => onChange(event, "incorporationNumber", fielsLengths.length_30)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='businessRegistrationNumber' className='text-muted text-truncate mb-0'>Business Incorporation/Registration Certificate Number</Label>
                            <Input
                              id="businessRegistrationNumber"
                              type="text"
                              placeholder='e.g. 28137611'
                              className={`form-control`}
                              value={values.businessRegistrationNumber || ""}
                              onChange={event => onChange(event, "businessRegistrationNumber", fielsLengths.length_30)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='businessRegisteredAddress' className='text-muted text-truncate mb-0'>Registered Business Address</Label>
                            <Input
                              id="businessRegisteredAddress"
                              type="text"
                              placeholder='e.g. 3053 Counts Lane, Lexington, Kentucky, 40505, United States'
                              className={`form-control`}
                              value={values.businessRegisteredAddress || ""}
                              onChange={event => onChange(event, "businessRegisteredAddress", fielsLengths.length_250)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='primaryRegulatoryLicencingNumber' className='text-muted text-truncate mb-0'>Regulatory Licensing Number/Identifier</Label>
                            <Input
                              id="primaryRegulatoryLicencingNumber"
                              type="text"
                              placeholder='e.g. 3AA01'
                              className={`form-control`}
                              value={values.primaryRegulatoryLicencingNumber || ""}
                              onChange={event => onChange(event, "primaryRegulatoryLicencingNumber", fielsLengths.length_30)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='phoneNumber' className='text-muted text-truncate mb-0'>Phone number</Label>
                            <PhoneInput
                              country={""}
                              specialLabel=""
                              value={values.phoneNumber || ""}
                              className={`custom-phone-number-input-block`}
                              onChange={(event) => onPhoneNumberChange(event, "phoneNumber")}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='taxId' className='text-muted text-truncate mb-0'>Business Tax Identification Number (or equivalent)</Label>
                            <Input
                              id="taxId"
                              placeholder='e.g. 12-2456880'
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.taxId.trim().length) ? "error-border" : ""}`}
                              value={values.taxId || ""}
                              onChange={event => onChange(event, "taxId", fielsLengths.length_25)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='postCode' className='text-muted text-truncate mb-0'>Post Code</Label>
                            <Input
                              id="postCode"
                              placeholder='e.g. 0001'
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.postCode.trim().length) ? "error-border" : ""}`}
                              value={values.postCode || ""}
                              onChange={event => onChange(event, "postCode", fielsLengths.length_25)}
                            />
                          </Col>
                          {/* Տեղափոխել եմ bank account -ի էջ և ֆոռմ */}
                          {/* {
                            paymentMethodLibrary ?
                              <Col lg={4} md={6} className="mb-4">
                                <Label htmlFor='paymentMethodLibraryDataId' className='text-muted text-truncate mb-0'>{paymentMethodLibrary.displayName}</Label>
                                {
                                  paymentMethodLibrary.libraryData && paymentMethodLibrary.libraryData.length ?
                                    <ReactSelectOption
                                      value={values.paymentMethodLibraryDataId}
                                      isSearchable={true}
                                      selectedValue={(() => {
                                        const selectedValue = { ...paymentMethodLibrary.libraryData.find(data => data.id === values.paymentMethodLibraryDataId) };
                                        if (Object.keys(selectedValue).length) {
                                          selectedValue.label = selectedValue.displayName;
                                          selectedValue.value = selectedValue.id;
                                          return selectedValue;
                                        } else {
                                          return { label: "Choose...", value: "" }
                                        }
                                      })()}
                                      items={paymentMethodLibrary.libraryData.map(data => ({ label: data.displayName, value: data.id }))}
                                      onChange={(item) => onSelectOptionChange(item.value, "paymentMethodLibraryDataId")}
                                    />
                                    : null
                                }
                              </Col>
                              : null
                          } */}
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='businessCountryOfIncorporation' className='text-muted text-truncate mb-0'>Country of Incorporation/Registration</Label>
                            {
                              countries && countries.length ?
                                <ReactSelectOption
                                  value={values.businessCountryOfRegistrationId}
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { ...countries.find(data => data.id === values.businessCountryOfRegistrationId) };
                                    if (Object.keys(selectedValue).length) {
                                      selectedValue.label = selectedValue.name;
                                      selectedValue.value = selectedValue.id;
                                      return selectedValue;
                                    } else {
                                      return { label: "Choose...", value: "" }
                                    }
                                  })()}
                                  items={countries.map(data => ({ label: data.name, value: data.id }))}
                                  onChange={(item) => onSelectOptionChange(item.value, "businessCountryOfRegistrationId")}
                                />
                                : null
                            }
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='province' className='text-muted text-truncate mb-0'>State/Province</Label>
                            <Input
                              id="province"
                              placeholder='e.g. Texas'
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.province.trim().length) ? "error-border" : ""}`}
                              value={values.province || ""}
                              onChange={event => onChange(event, "province", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='city' className='text-muted text-truncate mb-0'>City</Label>
                            <Input
                              id="city"
                              placeholder='e.g. Dallas'
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.city.trim().length) ? "error-border" : ""}`}
                              value={values.city || ""}
                              onChange={event => onChange(event, "city", fielsLengths.length_120)}
                            />
                          </Col>
                          {/* <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='countryId' className='text-muted text-truncate mb-0 d-flex'>
                              Business details status
                              <p className={`ms-2 mb-0 text-${values.isActive ? "success" : "danger"}`}>({values.isActive ? "Yes" : "No"})</p>
                            </Label>
                            <div className='d-flex align-items-center mt-1'>
                              <Switch
                                className="react-switch mt-1"
                                size={1}
                                checkedIcon={<OnIcon />}
                                uncheckedIcon={<OffIcon />}
                                checked={values.isActive}
                                disabled={isDisabledFields}
                                aria-labelledby="neat-label"
                                onChange={onChangeIsActive}
                              />
                            </div>
                          </Col> */}
                          <Col sm={12}><hr /></Col>
                          {
                            customerTypeLibrary ?
                              <Col lg={4} md={6} className="mb-4">
                                <Label htmlFor='customerTypeLibraryDataId' className='text-muted text-truncate mb-0'>{customerTypeLibrary.displayName}</Label>
                                {
                                  customerTypeLibrary.libraryData && customerTypeLibrary.libraryData.length ?
                                    <ReactSelectOption
                                      value={values.customerTypeLibraryDataId}
                                      isSearchable={true}
                                      selectedValue={(() => {
                                        const selectedValue = { ...customerTypeLibrary.libraryData.find(data => data.id === values.customerTypeLibraryDataId) };
                                        if (Object.keys(selectedValue).length) {
                                          selectedValue.label = selectedValue.displayName;
                                          selectedValue.value = selectedValue.id;
                                          return selectedValue;
                                        } else {
                                          return { label: "Choose...", value: "" }
                                        }
                                      })()}
                                      items={customerTypeLibrary.libraryData.map(data => ({ label: data.displayName, value: data.id }))}
                                      onChange={(item) => onSelectOptionChange(item.value, "customerTypeLibraryDataId")}
                                    />
                                    : null
                                }
                              </Col>
                              : null
                          }
                          {/* {
                            industryLibrary ?
                              <Col lg={4} md={6} className="mb-4">
                                <Label htmlFor='industryLibraryDataId' className='text-muted text-truncate mb-0'>{industryLibrary.displayName}</Label>
                                {
                                  industryLibrary.libraryData && industryLibrary.libraryData.length ?
                                    <ReactSelectOption
                                      value={values.industryLibraryDataId}
                                      isSearchable={true}
                                      selectedValue={(() => {
                                        const selectedValue = { ...industryLibrary.libraryData.find(data => data.id === values.industryLibraryDataId) };
                                        if (Object.keys(selectedValue).length) {
                                          selectedValue.label = selectedValue.displayName;
                                          selectedValue.value = selectedValue.id;
                                          return selectedValue;
                                        } else {
                                          return { label: "Choose...", value: "" }
                                        }
                                      })()}
                                      items={industryLibrary.libraryData.map(data => ({ label: data.displayName, value: data.id }))}
                                      onChange={(item) => onSelectOptionChange(item.value, "industryLibraryDataId")}
                                    />
                                    : null
                                }
                              </Col>
                              : null
                          } */}

                          {
                            typeOfEntityLibrary ?
                              <Col lg={4} md={6} className="mb-4">
                                <Label htmlFor='typeOfEntityLibraryDataId' className='text-muted text-truncate mb-0'>{typeOfEntityLibrary.displayName}</Label>
                                {
                                  typeOfEntityLibrary.libraryData && typeOfEntityLibrary.libraryData.length ?
                                    <ReactSelectOption
                                      value={values.typeOfEntityLibraryDataId}
                                      isSearchable={true}
                                      selectedValue={(() => {
                                        const selectedValue = { ...typeOfEntityLibrary.libraryData.find(data => data.id === values.typeOfEntityLibraryDataId) };
                                        if (Object.keys(selectedValue).length) {
                                          selectedValue.label = selectedValue.displayName;
                                          selectedValue.value = selectedValue.id;
                                          return selectedValue;
                                        } else {
                                          return { label: "Choose...", value: "" }
                                        }
                                      })()}
                                      items={typeOfEntityLibrary.libraryData.map(data => ({ label: data.displayName, value: data.id }))}
                                      onChange={(item) => onSelectOptionChange(item.value, "typeOfEntityLibraryDataId")}
                                    />
                                    : null
                                }
                              </Col>
                              : null
                          }
                          <Col sm={12}><hr /></Col>

                          {
                            isShowImageLoader ?
                              <Col sm={12}>
                                <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "150px", }}>
                                  <PuffLoader size={60} color={SPINNER_COLOR} />
                                </div>
                              </Col>
                              :
                              <Col sm={12}>
                                <Row>
                                  <Col md={4} sm={6} className='mb-3'>

                                    <FileComponent
                                      data={values}
                                      fieldName="certificateOfIncumbencyFile"
                                      labelValue="Certificate of Incumbency"
                                      setZoomImagePath={setZoomImagePath}
                                      uploadFile={uploadFile}
                                      deleteDocument={deleteDocument}
                                    />
                                  </Col>
                                  <Col md={4} sm={6} className='mb-3'>
                                    <FileComponent
                                      data={values}
                                      fieldName="certificateOfIncorporationFile"
                                      labelValue="Certificate of Incorporation"
                                      setZoomImagePath={setZoomImagePath}
                                      uploadFile={uploadFile}
                                      deleteDocument={deleteDocument}
                                    />
                                  </Col>
                                  <Col md={4} sm={6} className='mb-3'>
                                    <FileComponent
                                      data={values}
                                      fieldName="businessRegisteredAddressFile"
                                      labelValue="Business Proof of Address"
                                      setZoomImagePath={setZoomImagePath}
                                      uploadFile={uploadFile}
                                      deleteDocument={deleteDocument}
                                    />
                                  </Col>

                                  <Col md={4} sm={6} className='mb-3'>
                                    <FileComponent
                                      data={values}
                                      fieldName="businessRegistrationCertificateFile"
                                      labelValue="Business registration certificate"
                                      setZoomImagePath={setZoomImagePath}
                                      uploadFile={uploadFile}
                                      deleteDocument={deleteDocument}
                                    />
                                  </Col>
                                  <Col md={4} sm={6} className='mb-3'>
                                    <FileComponent
                                      data={values}
                                      fieldName="otherProofFile"
                                      labelValue="Other Official Documentation (all other categories)"
                                      setZoomImagePath={setZoomImagePath}
                                      uploadFile={uploadFile}
                                      deleteDocument={deleteDocument}
                                    />
                                  </Col>
                                  <Col md={4} sm={6} className='mb-3'>
                                    <FileComponent
                                      data={values}
                                      fieldName="businessTaxIdentificationFile"
                                      labelValue="Business Tax Identification Document"
                                      setZoomImagePath={setZoomImagePath}
                                      uploadFile={uploadFile}
                                      deleteDocument={deleteDocument}
                                    />
                                  </Col>
                                  <Col sm={12} className='mb-3'>
                                    <FilesComponent
                                      data={values}
                                      fieldName="businessRegulatoryLicencingNumberFile"
                                      labelValue="Primary Business Regulatory Licensing Document"
                                      setZoomImagePath={setZoomImagePath}
                                      uploadFile={bulkUploadFile}
                                      deleteDocument={deleteDocument}
                                      alertMessage="Allow multiple"
                                      alertIcon="mdi-alert-circle-outline"
                                      alertType="info"
                                      multiple={true}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={12}>
                                    <small className='fw-500 d-block mb-2'>Allowed file types {allValidFileTypes.map((item, index) => <i key={index}>{item}. </i>)}</small>
                                    <UncontrolledAlert color="warning" role="alert">
                                      <i className="mdi mdi-alert-outline me-2"></i>
                                      All documents require Certified true copies, alternatively, the documents can be visually presented through Cryllex customer support
                                    </UncontrolledAlert>
                                  </Col>
                                </Row>
                              </Col>
                          }
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <div className='d-flex justify-content-end'>
                    <ActionButton
                      type="submit"
                      name="Submit"
                      className="btn btn-primary btn-block px-4"
                      spinnerId={buttonSpinnerId}
                      disabled={!isChanged}
                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          </form>
        </div >
      </section >
    </>
  )
}
