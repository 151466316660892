
import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../../components/Spinners/RightSectionSpinner'
import Table from 'react-bootstrap/Table';
import DashboardApiService from '../../../../Services/DashboardApiService';
import uuid from 'react-uuid'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../../store/spinner/actions';
import AlertService from '../../../../Services/alertService'
import NoData from '../../../../components/NoData/NoData'
import Breadcrumbs from './../../../../components/Common/Breadcrumb'
import ReactPaginate from 'react-paginate'
import ActionButton from '../../../../components/Buttons/ActionButton'


const buttonSpinnerId = uuid();

const AgentInvitations = (props) => {

  const { agentId } = props.match.params;
  const pageSize = 10;
  const title = "Agent Invitations";
  const description = "";

  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [agentInvitations, setAgentInvitations] = useState([]);

  useEffect(() => {
    getAgentInvitations(1);
    return () => {
      setAgentInvitations([])
    }
  }, []);

  const getAgentInvitations = (pageNumber) => {
    if (!agentId) { return false; }
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    const data = {
      pageNumber,
      pageSize
    }
    DashboardApiService.getAgentInvitations(agentId, data).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setAgentInvitations(response.data);
      } else {
        setAgentInvitations([]);
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  };

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    getAgentInvitations(event.selected + 1);
  };

  const approveInvitation = (itemId) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "You want to approve current invitation.",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(`${buttonSpinnerId}_approve_${itemId}`);
      DashboardApiService.approveAgentInvitation(itemId).then(response => {
        setAgentInvitations((prevInvitations) =>
          prevInvitations.map(item =>
            item.id === itemId ? { ...item, isActive: !item.isActive } : item
          )
        );
        AlertService.alert("success", "Data saved");
      }).catch(error => AlertService.alert("error", error)).finally(() => {
        extractButtonSpinner(`${buttonSpinnerId}_approve_${itemId}`);
      })
    })
  }

  const deleteInvitation = (itemId) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "You want to delete current invitation.",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(`${buttonSpinnerId}_delete_${itemId}`);
      DashboardApiService.deleteAgentInvitation(itemId).then(() => {
        setAgentInvitations(agentInvitations.filter(item => item.id !== itemId));
        AlertService.alert("success", "Invitation link successfully deleted");
      }).catch(error => AlertService.alert("error", error)).finally(() => {
        extractButtonSpinner(`${buttonSpinnerId}_delete_${itemId}`);
      })
    })


    // deleteAgentInvitation
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);


  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Agent Invitations"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>

                    <Col sm={12}>


                      {
                        agentInvitations && agentInvitations.length ?
                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th className='text-nowrap'>Business Name</th>
                                <th className='text-nowrap'>Email</th>
                                <th className='text-norap'>Active</th>
                                <th className='text-nowrap'>Client Registered</th>
                                <th className='text-nowrap text-center'>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                agentInvitations.map((item, index) => {
                                  return <tr key={index}>
                                    <td><span className='word-break-break-word max-line-3'>{item.legalBusinessName}</span></td>
                                    <td><span className='word-break-break-word max-line-3'>{item.email}</span></td>
                                    <td>
                                      <span className={`badge badge-soft-${item?.isActive ? "success" : "danger"} font-size-14 badge badge-pill`}>
                                        {item?.isActive ? "Active" : "Inactive"}
                                      </span>
                                    </td>
                                    <td>
                                      <span className={`badge badge-soft-${item?.clientRegistered ? "success" : "danger"} font-size-14 badge badge-pill`}>
                                        {item?.clientRegistered ? "Yes" : "No"}
                                      </span>
                                    </td>
                                    <td className='text-center'>
                                      <div className='d-flex gap-2 justify-content-center'>
                                        <ActionButton
                                          type="button"
                                          name="Approve"
                                          color="success"
                                          className='px-3'
                                          disabled={item.isActive}
                                          spinnerId={`${buttonSpinnerId}_approve_${item.id}`}
                                          onClick={() => approveInvitation(item.id)}
                                        />
                                        <ActionButton
                                          type="button"
                                          name="Delete"
                                          color="danger"
                                          className='px-3'
                                          spinnerId={`${buttonSpinnerId}_delete_${item.id}`}
                                          onClick={() => deleteInvitation(item.id)}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                })
                              }
                            </tbody>
                          </Table>
                          : <NoData />
                      }

                      <div className='py-3 d-flex justify-content-end'>
                        {
                          pagination && pagination.TotalPages && pagination.TotalPages > 1 ?
                            <ReactPaginate
                              nextLabel={`Next`}
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={2}
                              pageCount={pagination.TotalPages}
                              previousLabel={`Previous`}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination mb-0"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                              forcePage={activePageNumber}
                            />
                            : null
                        }
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </div>
      </div>
    </>
  );
}

export default AgentInvitations;
