import React, { useState, useEffect, useCallback, useRef } from 'react'
import HtmlHead from '../../../components/HtmlHead/HtmlHead';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Card,
  CardBody,
  Row,
  Label,
  Input,
  Col,
  UncontrolledAlert,
} from "reactstrap"
import AlertService from '../../../Services/alertService';
import MainService from '../../../Services/MainService';
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_KEY, SPINNER_COLOR, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY, allValidFileTypes, fielsLengths } from '../../../Constants/MainKeys';
import ActionButton from '../../../components/Buttons/ActionButton';
import uuid from 'react-uuid';
import PhoneInput from 'react-phone-input-2'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions';
import DashboardApiService from '../../../Services/DashboardApiService';
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption';
import { PuffLoader } from 'react-spinners';
import FileComponent from '../../Admin-Pages/Merchant/Components/FileComponent';
import FilesComponent from '../../Admin-Pages/Merchant/Components/FilesComponent';
import ApiService from '../../../Services/ApiService';

const spinnerId = uuid();
const buttonSpinnerId = uuid();

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function PayingCustomerKycForm(props) {

  const title = "KYC Form";
  const description = "";
  const mainService = new MainService();

  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { user } = useSelector(state => state.Login);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [isChanged, setIsChanged] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState(null);
  const [isShowImageLoader, setIsShowImageLoader] = useState(false)
  const [countries, setCountries] = useState([])

  useOutsideAlerter(wrapperRef, setZoomImagePath);

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "", //read only
    emailAddressVerificationStatus: true,

    veriffVerificationStatus: false,
    reasonForFailure: "",

    // proofOfAddressDcoument: null,
    // payingCustomerIdFrontSide: null,
    // payingCustomerIdBackSide: null,
    // payingCustomerBiometricData: null,

  })

  const getOrganizationData = () => {

    setRightSectionSpinner(spinnerId);
    DashboardApiService.getAgentKycData().then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data.files && data.files.length) {
          const groupedFiles = data.files.reduce((groups, file) => {
            const key = file.fileType;
            (groups[key] = groups[key] || []).push(file);
            return groups;
          }, {});
          const arrayOfArrays = Object.values(groupedFiles);
          if (arrayOfArrays && arrayOfArrays.length) {
            arrayOfArrays.forEach(item => {
              data[MainService.getOrganizationDetailsFileNameByType(item[0].fileType)] = item;
            })
          }
        }
        delete data.files;
        setValues(data)
      }
      extractRightSectionSpinner(spinnerId)
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const getCountries = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  useEffect(() => {
    getOrganizationData();
    getCountries();
  }, [])


  const uploadFile = async (data) => {
    const { event, fieldName } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();
    formData.append("id", values.id);
    formData.append("type", MainService.getOrganizationDetailsFileType(fieldName));

    let files = [...event.target.files];
    let file = files[0];
    let fileName = file.name;
    let lastDotIndex = fileName.lastIndexOf('.');
    let fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
    if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
      await mainService.readFile(file, allValidFileTypes).then(uploadedFile => {
        setIsShowImageLoader(true);
        formData.append("document", file);
        DashboardApiService.uploadDocumentAsAgent(formData).then(response => {
          setValues((values) => ({
            ...values,
            [fieldName]: response.data,
          }))
          AlertService.alert(SUCCESS_KEY, "Data saved")
        }).catch(error => getFail(error)).finally(() => {
          setIsShowImageLoader(false);
        })
      }).catch(error => {
        error && AlertService.alert("error", "Invalid file format");
      });
    } else {
      AlertService.alert("error", "Invalid file format")
      return false;
    }
  };

  const deleteDocument = (data) => {
    const { fieldName, fileLibraryId } = data;
    if (!fileLibraryId) { return false; }
    AlertService.alertConfirm(
      `Are you sure?`,
      "Do you want to delete the current file?",
      "Yes",
      "No"
    ).then(() => {
      setIsShowImageLoader(true);
      DashboardApiService.deleteDocumentAsPayingCustomer(fileLibraryId, values.id, MainService.getOrganizationDetailsFileType(fieldName)).then(() => {
        setValues((values) => ({
          ...values,
          [fieldName]: null,
        }))
        AlertService.alert(SUCCESS_KEY, "File deleted successfully")
      }).catch(error => getFail(error)).finally(() => {
        setIsShowImageLoader(false);
      })
    })
  }

  const onChange = (event, field, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false)
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    values.agentId = user.activeAgentId;
    DashboardApiService.updateAgentKyc(values).then((response) => {
      if (response && response.data) {
        setValues((values) => ({
          ...values,
          id: response.data.id
        }))
      }
      AlertService.alert(SUCCESS_KEY, "KYC data updated successfully")
    }).catch(error => getFail(error)).finally(() => {
      extractButtonSpinner(buttonSpinnerId)
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <section className='page-content position-relative min-h-100'>
        <HtmlHead title={title} description={description} />
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          <form onSubmit={onSubmit} onChange={() => setIsChanged(true)}>
            <Breadcrumbs
              title="Cryllex"
              breadcrumbItem="KYC form"
              isShowGoBackButton={true}
              goBack={() => {
                window.history.back();
              }}
            />
            <Row className="justify-content-center">
              <Card>
                <CardBody>
                  <Col>
                    <Row>
                      <Col sm={12}>
                        <Row>
                          {
                            // values.id ?
                            isShowImageLoader ?
                              <Col sm={12}>
                                <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "150px", }}>
                                  <PuffLoader size={60} color={SPINNER_COLOR} />
                                </div>
                              </Col>
                              :
                              <Col sm={12}>
                                <Row>
                                  <Col md={4} sm={6} className='mb-3'>
                                    <FileComponent
                                      data={values}
                                      fieldName="payingCustomerIdFrontSide"
                                      labelValue="Paying Customer ID Front Side"
                                      setZoomImagePath={setZoomImagePath}
                                      uploadFile={uploadFile}
                                      deleteDocument={deleteDocument}
                                    />
                                  </Col>
                                  <Col md={4} sm={6} className='mb-3'>
                                    <FileComponent
                                      data={values}
                                      fieldName="payingCustomerIdBacktSide"
                                      labelValue="Paying Customer ID Back Side"
                                      setZoomImagePath={setZoomImagePath}
                                      uploadFile={uploadFile}
                                      deleteDocument={deleteDocument}
                                    />
                                  </Col>
                                  <Col md={4} sm={6} className='mb-3'>
                                    <FileComponent
                                      data={values}
                                      fieldName="payingCustomerBiometricData"
                                      labelValue="Paying Customer Biometric Data (Face Scan)"
                                      setZoomImagePath={setZoomImagePath}
                                      uploadFile={uploadFile}
                                      deleteDocument={deleteDocument}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={12}>
                                    <small className='fw-500 d-block mb-2'>Allowed file types {allValidFileTypes.map((item, index) => <i key={index}>{item}. </i>)}</small>
                                  </Col>
                                </Row>
                              </Col>
                            // : null
                          }

                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <div className='d-flex justify-content-end'>
                    <ActionButton
                      type="submit"
                      name="Submit"
                      className="btn btn-primary btn-block px-4"
                      spinnerId={buttonSpinnerId}
                      disabled={!isChanged}
                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          </form>
        </div >
      </section >
    </>
  )
}