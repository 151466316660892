import axios from "axios";
import { IDENTITY_GET_TOKEN_PATH_KEY } from "../Constants/RequestKeys";

const identityApi = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_API_URL_KEY || "",
});

identityApi.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  return config;
}, (err) => Promise.reject(err));

export class IdentityService {

  static login(data) {

    const formData = new URLSearchParams();
    formData.set('Username', data.username);
    formData.set('Password', data.password);
    return identityApi.post(IDENTITY_GET_TOKEN_PATH_KEY, formData.toString());
  };
};