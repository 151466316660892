import React, { useCallback, useState, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Button,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table
} from "reactstrap"
import AlertService from '../../../Services/alertService'
import { ERROR_KEY } from '../../../Constants/MainKeys'
import { addRightSectionSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import LevelOne from './components/LevelOne'
import LevelTwo from './components/LevelTwo'
import ActionButton from '../../../components/Buttons/ActionButton'
import Dropdown from 'react-bootstrap/Dropdown';


const TransactionBreakdown = (props) => {

  const title = "Transaction Breakdown";
  const description = "";
  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const pageSize = 10;
  const { language } = useSelector(state => state.Languages);
  const [customActiveTab, setCustomIconActiveTab] = useState("1");
  const [kycLevel, setKycLevel] = useState(1);
  const [kycLevels, setKycLevels] = useState([
    {
      key: 1,
      value: "KYC Level 1",
    },
    {
      key: 2,
      value: "KYC Level 2",
    },
    {
      key: 3,
      value: "KYC Level 3",
    },
  ]);


  const toggleCustom = (key) => {
    setCustomIconActiveTab(key);
  }


  const onChangeKycLevel = (item) => {
    if (!item) { return false; }
    AlertService.alertConfirm(
      `Are you sure`,
      "You want to change KYC level ?",
      "Yes",
      "No"
    ).then(() => {
      setKycLevel(item);
    })
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />

        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Transaction Breakdown"
          />
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={12}>
                  <Card className='mb-5'>
                    <CardBody>
                      <Auxiliary>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={`${customActiveTab === "1" ? "active" : ""}`}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                              <span style={{ fontSize: "14px" }}>Level one</span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={`${customActiveTab === "2" ? "active" : ""}`}
                              onClick={() => {
                                toggleCustom("2");
                              }}
                            >
                              <span style={{ fontSize: "14px" }}>Level two</span>
                            </NavLink>
                          </NavItem>

                        </Nav>
                        <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                          <TabPane tabId="1">
                            <Row>
                              <LevelOne />
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <LevelTwo />
                          </TabPane>
                        </TabContent>
                        <hr />

                        <div className='my-2 d-flex justify-content-end flex-wrap'>
                          <div className='d-flex mt-3'>
                            {
                              kycLevels && kycLevels.length ?
                                <Dropdown drop='up'>
                                  <Dropdown.Toggle tag="button" className="btn btn-warning">
                                    {
                                      kycLevels.find(level => level.key === kycLevel) ?
                                        <div className='d-flex'>
                                          {kycLevels.map((level, idx) => {
                                            if (level.key === kycLevel) {
                                              return <div key={idx}>{level.value}</div>
                                            }
                                          })}
                                          <i className="mdi mdi-chevron-up ms-2" />
                                        </div>
                                        : <div className='d-flex'>Update KYC Level <i className="mdi mdi-chevron-down ms-2" /></div>
                                    }
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {
                                      kycLevels.map((level, idx) => {
                                        return <Dropdown.Item
                                          key={idx}
                                          onClick={() => {
                                            if (kycLevel === level.key) {
                                              return false;
                                            }
                                            onChangeKycLevel(level.key)
                                          }}
                                        >
                                          {level.value}
                                        </Dropdown.Item>
                                      })
                                    }
                                  </Dropdown.Menu>
                                </Dropdown>
                                : null
                            }


                            <Auxiliary>

                              <ActionButton
                                // spinnerId={`accept_${buttonSpinnerId}`}
                                color="success"
                                className='ms-2 px-4'
                                onClick={() => { }}
                              >
                                Accept
                              </ActionButton>

                              <Button
                                color="danger"
                                className='ms-2 px-4'
                                // disabled={agentData?.kycLevel !== 3 || (agentData && agentData.isRejected)}
                                onClick={() => { }}
                              >
                                Reject
                              </Button>

                              <ActionButton
                                // color={agentData?.isDisabled ? "info" : "secondary"}
                                color={"secondary"}
                                className='ms-2 px-4'
                                onClick={() => { }}
                              >
                                Enable/Disable
                              </ActionButton>

                              <Button color="primary" className='ms-2 px-4'
                                onClick={() => { }}
                              >
                                Edit
                              </Button>

                            </Auxiliary>
                          </div>
                        </div>

                      </Auxiliary>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default TransactionBreakdown