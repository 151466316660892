import React, { useCallback, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Input,
} from "reactstrap"
import { Table } from "react-bootstrap"
import { ERROR_KEY } from '../../../Constants/MainKeys'
import { addRightSectionSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import DashboardApiService from '../../../Services/DashboardApiService'
import { useState } from 'react'
import NoData from '../../../components/NoData/NoData'
import ReactPaginate from 'react-paginate'
import { withRouter } from 'react-router-dom';
import { useDebounce } from 'use-debounce'
import MainService from '../../../Services/MainService'
import ApiService from '../../../Services/ApiService'

const AgentOrganizations = (props) => {

  const dispatch = useDispatch();

  const title = "Agent Organizations";
  const { agentId } = props.match.params;
  const description = "";
  const pageSize = 10;
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language, languages } = useSelector(state => state.Languages);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [agentOrganizations, setAgentOrganizations] = useState([])
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, 1000);
  const [countries, setCountries] = useState([])

  useEffect(() => {
    getAgentOrganizations(1);
    return () => {
      setAgentOrganizations([])
    }
  }, [debouncedSearch]);

  useEffect(() => {
    getCountries();
  }, [])

  const getCountries = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    getAgentOrganizations(event.selected + 1);
  };

  const getAgentOrganizations = (pageNumber) => {
    if (!agentId) { return false; }
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    const data = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      search: debouncedSearch,
    }
    DashboardApiService.getAgentOrganizations(agentId, data).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        setAgentOrganizations(response.data);
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Agent Organizations"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm={12}>
                      <Row className='justify-content-end'>
                        <Col sm={4}>
                          <div className='app-search px-3'>
                            <div className='position-relative'>
                              <Input
                                id="search"
                                type="search"
                                placeholder='Search'
                                className={`form-control resize-none`}
                                value={search}
                                onChange={event => setSearch(event.target.value)}
                              />
                              <span className="bx bx-search-alt"></span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12}>
                      {
                        agentOrganizations && agentOrganizations.length ?
                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th className='text-nowrap'>Logo</th>
                                <th className='text-nowrap'>Name</th>
                                <th className='text-nowrap'>Email</th>
                                <th className='text-nowrap'>Phone Number</th>
                                <th className='text-nowrap'>Classification</th>
                                <th className='text-nowrap'>Country</th>
                                <th className='text-nowrap'>Approved</th>
                                <th className='text-nowrap'>Active</th>

                                <th className='text-norap text-center'>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                agentOrganizations.map((item, index) => {
                                  return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <div className='d-flex justify-content-center align-items-center'>
                                        <img
                                          src={item.logoFilePath}
                                          alt="/"
                                          className="rounded-circle"
                                          height="50"
                                        />
                                      </div>
                                    </td>
                                    <td>{item.organizationName}</td>
                                    <td>{item.organizationEmail}</td>
                                    <td className=' text-nowrap'>{MainService.formatPhoneNumber(item.phoneNumber)}</td>
                                    <td>{item.classification}</td>
                                    <td>
                                      {
                                        countries && countries.length ?
                                          countries.map((_item, index) => {
                                            if (_item.id === item.countryId) {
                                              return <span key={index}>{_item.name}</span>
                                            }
                                          })
                                          : null
                                      }
                                    </td>
                                    <td>
                                      <span className={`badge badge-soft-${item?.isApproved ? "success" : "danger"} font-size-14 badge badge-pill`}>
                                        {item?.isApproved ? "Yes" : "No"}
                                      </span>
                                    </td>
                                    <td>
                                      <span className={`badge badge-soft-${item?.isActive ? "success" : "danger"} font-size-14 badge badge-pill`}>
                                        {item?.isActive ? "Active" : "Inactive"}
                                      </span>
                                    </td>

                                    <td>
                                      <div className='d-flex flex-nowrap'>
                                        <Button
                                          type="button"
                                          color="primary"
                                          className='shadow-sm me-2 text-nowrap'
                                          onClick={() => {
                                            props.history.push(`/${language}/organization/${item.id}`)
                                          }}
                                        >
                                          View KYC
                                        </Button>
                                        <Button
                                          type="button"
                                          color="info"
                                          className='shadow-sm me-2 text-nowrap'
                                          onClick={() => {
                                            props.history.push(`/${language}/organization/transactions/${item.id}`)
                                          }}
                                        >
                                          Transactions
                                        </Button>
                                      </div>
                                    </td>

                                  </tr>
                                })
                              }
                            </tbody>
                          </Table>
                          : <NoData />
                      }

                      <div className='py-3 d-flex justify-content-end'>
                        {
                          pagination && pagination.TotalPages && pagination.TotalPages > 1 ?
                            <ReactPaginate
                              nextLabel={`Next`}
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={2}
                              pageCount={pagination.TotalPages}
                              previousLabel={`Previous`}
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLabel="..."
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              containerClassName="pagination mb-0"
                              activeClassName="active"
                              renderOnZeroPageCount={null}
                              forcePage={activePageNumber}
                            />
                            : null
                        }
                      </div>
                    </Col>
                  </Row>
                  {/* <NoData /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withRouter(AgentOrganizations)