import { SET_SITE_TOUR_DATA, SET_SITE_TOUR_DATA_IS_NOTIFIED } from "./actionTypes";

export function setSiteTourData(data) {
  return {
    type: SET_SITE_TOUR_DATA,
    payload: data,
  };
};
export function setSiteTourIsNotified(bool) {
  return {
    type: SET_SITE_TOUR_DATA_IS_NOTIFIED,
    payload: bool,
  };
};