
// import React, { useCallback, useState, useEffect } from 'react'
// import { Link } from "react-router-dom"
// import Breadcrumbs from './../../../components/Common/Breadcrumb'
// import {
//   Card,
//   CardBody,
//   CardTitle,
//   Col,
//   Row,
//   CardHeader,
// } from "reactstrap"
// import { Table } from "react-bootstrap"
// import ReactPaginate from 'react-paginate'
// import ReactSelectOption from './../../../components/SelectOptions/ReactSelectOption'
// import { withTranslation } from "react-i18next"
// import DashboardApiService from './../../../Services/DashboardApiService';
// import AlertService from './../../../Services/alertService'
// import { ERROR_KEY, SPINNER_COLOR } from './../../../Constants/MainKeys'
// import uuid from 'react-uuid'
// import { addPageSpinner, addRightSectionSpinner, removePageSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions'
// import { useDispatch, useSelector } from 'react-redux'
// import * as moment from 'moment';
// import MainService from './../../../Services/MainService'
// import PuffLoader from "react-spinners/PuffLoader"
// import HtmlHead from './../../../components/HtmlHead/HtmlHead'
// import RightSectionSpinner from './../../../components/Spinners/RightSectionSpinner'
// import NoData from '../../../components/NoData/NoData'

// const TransactionHistory = (props) => {

//   const cryptoWallets = [
//     {
//       id: null,
//       name: "All"
//     },
//     {
//       id: 1,
//       name: "MetaMask"
//     },
//     {
//       id: 2,
//       name: "Coinbase Wallet"
//     },
//     {
//       id: 3,
//       name: "TrustWallet"
//     },
//     {
//       id: 4,
//       name: "Ledger Nano S Plus"
//     },
//     {
//       id: 5,
//       name: "Electrum"
//     },
//   ];

//   const { t } = props;
//   const title = "Transactions";
//   const description = "";
//   const WAIT_INTERVAL = 1000;
//   const pageSize = 10;
//   const dispatch = useDispatch();
//   const { rightSectionSpinners } = useSelector(state => state.Spinners);
//   const { language } = useSelector(state => state.Languages);
//   const [isShowFilter, setIsShowFilter] = useState(true);
//   const [pagination, setPagination] = useState(null);
//   const [activePageNumber, setActivePageNumber] = useState(0);
//   const [timer, setTimer] = useState(null);
//   const [transactionHistory, setTransactionHistory] = useState([]);
//   const [currencies, setCurrencies] = useState([]);
//   const [statuses, setStatuses] = useState([]);
//   const [isShowSpinner, setIsShowSpinner] = useState(false);
//   const [isShowTableSpinner, setIsShowTableSpinner] = useState(false);


//   const [values, setValues] = useState({
//     status: 0,
//     currencyId: null,
//     cryptoWalletId: null,
//     createDate: "",
//     finishDate: "",
//     cryptoMinAmount: "",
//     cryptoMaxAmount: ""
//   })

//   useEffect(() => {
//     if (localStorage.getItem("transactionHistoryPageNumber")) {
//       getTransactionHistoryAsMerchant(+localStorage.getItem("transactionHistoryPageNumber") + 1);
//       setActivePageNumber(+localStorage.getItem("transactionHistoryPageNumber"));
//       localStorage.removeItem("transactionHistoryPageNumber");
//     } else {
//       getTransactionHistoryAsMerchant(1);
//     }
//   }, []);

//   const getTransactionHistoryAsMerchant = (pageNumber, withTableSpinner) => {
//     const spinnerId = uuid();
//     if (withTableSpinner) setIsShowTableSpinner(true);
//     else setRightSectionSpinner(spinnerId);

//     DashboardApiService.getTransactionHistoryAsMerchant(pageNumber, pageSize).then(response => {//
//       if (response && response.pagination) setPagination(JSON.parse(response.pagination));
//       if (response && response.data) {
//         const data = { ...response.data };
//         if (data.paymentHistory) {
//           setTransactionHistory([...data.paymentHistory])
//         }
//         if (data.filter && data.filter.currencies) {
//           setCurrencies([{ ...{ id: null, name: "All", } }, ...data.filter.currencies])
//         }
//         if (data.filter && data.filter.statuses) {
//           setStatuses([{ ...{ key: null, value: "All", } }, ...data.filter.statuses])

//           // setStatuses([...data.filter.statuses]);
//           // data.filter.statuses.forEach(item => {
//           //   if (item.value === 5) {
//           //     let valuesCopy = { ...values };
//           //     valuesCopy.status = item.value;
//           //     setValues((values) => ({
//           //       ...values,
//           //       status: item.value,
//           //     }));
//           //     onFilter(1, valuesCopy, true)
//           //   }
//           // })
//         }
//       }
//       if (withTableSpinner) setIsShowTableSpinner(false);
//       else extractRightSectionSpinner(spinnerId);
//     }).catch(error => getFail(error, spinnerId))
//   }

//   const handlePageClick = (event) => {
//     if (values.status || values.currencyId || values.cryptoWalletId || values.createDate || values.finishDate || values.cryptoMinAmount || values.cryptoMaxAmount) {
//       onFilter(event.selected + 1, values, true);
//     } else {
//       getTransactionHistoryAsMerchant(event.selected + 1, true);
//     }
//     setActivePageNumber(event.selected);
//     localStorage.setItem("transactionHistoryPageNumber", event.selected);
//   };

//   const onSelectOptionChange = (item, name) => {
//     let valuesCopy = { ...values };
//     if (!item) { return false; }
//     setIsShowSpinner(true);
//     clearTimeout(timer);
//     setValues((values) => ({
//       ...values,
//       [name]: item.value,
//     }));
//     valuesCopy[name] = item.value;
//     const newTimer = setTimeout(() => onFilter(1, valuesCopy), WAIT_INTERVAL);
//     setTimer(newTimer);
//   }

//   const onNumberChange = (event, name) => {
//     let valuesCopy = { ...values };
//     if (typeof +event.target.value === "number" && Number(event.target.value) >= 0) {
//       clearTimeout(timer);
//       setValues((values) => ({
//         ...values,
//         [name]: +event.target.value,
//       }));
//       valuesCopy[name] = +event.target.value;
//       const newTimer = setTimeout(() => onFilter(1, valuesCopy, true), WAIT_INTERVAL);
//       setTimer(newTimer);
//     }
//   };

//   const onChange = (event, name) => {
//     let valuesCopy = { ...values };
//     clearTimeout(timer);
//     setValues((values) => ({
//       ...values,
//       [name]: event.target.value,
//     }));
//     valuesCopy[name] = event.target.value;
//     const newTimer = setTimeout(() => onFilter(1, valuesCopy, true), WAIT_INTERVAL);
//     setTimer(newTimer);
//   }

//   const onFilter = (pageNumber, values, isShowFilter) => {
//     if (isShowFilter) setIsShowSpinner(true);
//     let form = { ...values };
//     for (let i in form) {
//       if (!form[i] && i !== "status") delete form[i];
//     }
//     DashboardApiService.onFilterTransactionsAsMerchant(pageNumber, pageSize, form).then(response => {
//       if (response && response.pagination) setPagination(JSON.parse(response.pagination));
//       if (response && response.data) {
//         setTransactionHistory([...response.data]);
//         setActivePageNumber(pageNumber - 1);
//       } else {
//         setTransactionHistory([])
//         setActivePageNumber(0);
//       }
//       setIsShowSpinner(false);
//     }).catch(error => getFail(error))
//   }

//   const clearFilter = () => {
//     setValues({
//       status: null,
//       currencyId: null,
//       cryptoWalletId: null,
//       createDate: "",
//       finishDate: "",
//       cryptoMinAmount: "",
//       cryptoMaxAmount: ""
//     });
//     setActivePageNumber(0);
//     // setIsShowFilter(false);
//     getTransactionHistoryAsMerchant(1);
//   }

//   const setRightSectionSpinner = useCallback(spinner => {
//     dispatch(addRightSectionSpinner(spinner));
//   }, []);

//   const extractRightSectionSpinner = useCallback(spinner => {
//     dispatch(removeRightSectionSpinner(spinner));
//   }, []);

//   const getFail = (error, spinnerId) => {
//     error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
//     spinnerId && extractRightSectionSpinner(spinnerId);
//     setIsShowSpinner(false);
//     setIsShowTableSpinner(false);
//     localStorage.removeItem("transactionHistoryPageNumber");
//   }

//   return (
//     <>
//       <HtmlHead title={title} description={description} />
//       <div className='page-content position-relative min-h-100vh'>
//         <RightSectionSpinner spinner={rightSectionSpinners} />
//         <div className="container-fluid">
//           <Breadcrumbs
//             title="Cryllex"
//             breadcrumbItem="Transactions"
//           />
//           <Row>
//             <Col lg={12}>
//               <Row>
//                 {
//                   isShowFilter ?
//                     <Col xl={3}>
//                       <Card className='position-relative'>
//                         <CardBody>
//                           <div className='d-flex justify-content-between'>
//                             <CardTitle className="mb-4">{t("Filter by")}</CardTitle>
//                           </div>
//                           {
//                             statuses && statuses.length ?
//                               <div className="mt-2 mb-3">
//                                 <label>{t("Status")}</label>
//                                 <ReactSelectOption
//                                   value={values.status}
//                                   isSearchable={true}
//                                   selectedValue={(() => {
//                                     const selectedItem = { ...statuses.find(data => data.key === values.status) };
//                                     if (Object.keys(selectedItem).length) {
//                                       selectedItem.label = selectedItem.value;
//                                       selectedItem.value = selectedItem.key;
//                                       return selectedItem;
//                                     } else {
//                                       return { label: "All", value: null }
//                                     }
//                                   })()}
//                                   items={statuses.map(data => ({ ...data, label: data.value, value: data.key }))}
//                                   onChange={(item) => onSelectOptionChange(item, "status")}
//                                 />
//                               </div>
//                               : null
//                           }
//                           {
//                             currencies && currencies.length ?
//                               <div className="mt-2 mb-3">
//                                 <label>Cryptocurrency</label>
//                                 <ReactSelectOption
//                                   value={values.currencyId}
//                                   isSearchable={true}
//                                   selectedValue={(() => {
//                                     const selectedItem = { ...currencies.find(data => +data.id === +values.currencyId) };
//                                     if (Object.keys(selectedItem).length) {
//                                       selectedItem.label = selectedItem.name;
//                                       selectedItem.value = selectedItem.id;
//                                       return selectedItem;
//                                     } else {
//                                       return { label: "All", value: null }
//                                     }
//                                   })()}
//                                   items={currencies.map(data => ({ ...data, label: data.name, value: data.id }))}
//                                   onChange={(item) => onSelectOptionChange(item, "currencyId")}
//                                 />
//                               </div>
//                               : null
//                           }


//                           <div className="mt-2 mb-3">
//                             <label>{t("Crypto wallet")}</label>
//                             <ReactSelectOption
//                               value={values.cryptoWalletId}
//                               isSearchable={true}
//                               selectedValue={(() => {
//                                 const selectedItem = { ...cryptoWallets.find(data => +data.id === +values.cryptoWalletId) };
//                                 if (selectedItem) {
//                                   selectedItem.label = selectedItem.name;
//                                   selectedItem.value = selectedItem.id;
//                                 }
//                                 return selectedItem;
//                               })()}
//                               items={cryptoWallets.map(data => ({ ...data, label: data.name, value: data.id }))}
//                               onChange={(item) => onSelectOptionChange(item, "cryptoWalletId")}
//                             />
//                           </div>

//                           <div className="mt-2 mb-3">
//                             <p className='font-weight-500 mb-0'>Transaction Date</p>
//                             <div className='row'>
//                               <div className='col-12 col-xl-12 col-md-6 mt-2'>
//                                 <small className='mt-1 d-block'>From</small>
//                                 <input
//                                   id="createDate"
//                                   className="form-control"
//                                   // type="datetime-local"
//                                   type="date"
//                                   value={values.createDate}
//                                   onChange={(event) => onChange(event, "createDate")}
//                                 />
//                               </div>
//                               <div className='col-12 col-xl-12 col-md-6 mt-2'>
//                                 <small className='mt-1 d-block'>To</small>
//                                 <input
//                                   id="finishDate"
//                                   className="form-control"
//                                   // type="datetime-local"
//                                   type="date"
//                                   value={values.finishDate}
//                                   onChange={(event) => onChange(event, "finishDate")}
//                                 />
//                               </div>
//                             </div>
//                           </div>

//                           <div className="mt-2 mb-3">
//                             <p className='font-weight-500 mb-0'>Amount</p>
//                             <div className='row'>
//                               <div className='col-12 col-sm-6'>
//                                 <small className='mt-1 d-block'>{t("min")}</small>
//                                 <input
//                                   id="cryptoMinAmount"
//                                   className="form-control"
//                                   type="number"
//                                   value={values.cryptoMinAmount}
//                                   onChange={(event) => onNumberChange(event, "cryptoMinAmount")}
//                                   onBlur={() => clearTimeout(timer)}
//                                 />
//                               </div>
//                               <div className='col-12 col-sm-6'>
//                                 <small className='mt-1 d-block'>{t("max")}</small>
//                                 <input
//                                   id="cryptoMaxAmount"
//                                   className="form-control"
//                                   type="number"
//                                   value={values.cryptoMaxAmount}
//                                   onChange={(event) => onNumberChange(event, "cryptoMaxAmount")}
//                                   onBlur={() => clearTimeout(timer)}
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                           <div className="mt-2 mb-3">
//                             <button className="btn btn-outline-primary" type="button" onClick={clearFilter}>
//                               Clear filter
//                             </button>
//                           </div>
//                         </CardBody>
//                         {
//                           isShowSpinner ?
//                             <div className="dashboard-spinner-layer" >
//                               <div className="sweet-loading">
//                                 <PuffLoader size={80} color={SPINNER_COLOR} />
//                               </div>
//                             </div>
//                             : null
//                         }
//                       </Card>
//                     </Col>
//                     : null
//                 }

//                 <Col xl={isShowFilter ? 9 : 12}>
//                   <Card className="position-relative">
//                     <CardHeader className="bg-transparent">
//                       <div className='d-flex justify-content-between'>
//                         <h5 className="mt-2">
//                           {title}
//                         </h5>
//                         {/* <button className="btn btn-outline-primary" type="button" onClick={() => setIsShowFilter(!isShowFilter)}>
//                           {!isShowFilter ? t("Show filter") : t("Close filter")}
//                         </button> */}
//                       </div>
//                       <hr />
//                     </CardHeader>
//                     <CardBody className='pt-0'>
//                       {
//                         transactionHistory && transactionHistory.length ?
//                           <div className="table-responsive">
//                             <Table responsive className="table table-striped mb-0">
//                               <thead>
//                                 <tr className='cursor-default'>
//                                   <th>{t("Reference No.")}</th>
//                                   <th>{t("Status")}</th>
//                                   <th>{t("Fiat amount")}</th>
//                                   <th>{t("Crypto amount")}</th>
//                                   <th>{t("Date")}</th>
//                                   <th>{t("Action")}</th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {
//                                   transactionHistory.map((item, index) => <tr key={index} className='cursor-default'>
//                                     <th scope="row">{item.localTransactionId}</th>
//                                     <td><span className={`badge badge-soft-${MainService.getStatusColor(item.status)} font-size-14 badge badge-pill`}>{MainService.getStatusName(item.status, statuses)?.toLowerCase()}</span></td>
//                                     <td>{item.fiatAmount}</td>
//                                     <td>{item.cryptoAmount}&nbsp;{currencies.map(currency => currency.id === item.fiatCurrencyId ? currency.name : "")}</td>
//                                     <td className='nowrap'>{moment(MainService.convertUTCDateToLocalDate(new Date(item.createDate))).format("LL")}</td>
//                                     <td>
//                                       <Link to={`/${language}/payments/transaction-history/view/${item.localTransactionId}`} className="btn btn-primary btn-sm cursor-pointer px-3 py-1">{t("View")}</Link>
//                                     </td>
//                                   </tr>)
//                                 }
//                               </tbody>
//                             </Table>

//                           </div>
//                           : <NoData />
//                       }
//                     </CardBody>
//                     {
//                       pagination ?
//                         <div className='p-3 d-flex justify-content-end'>
//                           <Row>
//                             <Col>
//                               <ReactPaginate
//                                 nextLabel={t("Next")}
//                                 onPageChange={handlePageClick}
//                                 pageRangeDisplayed={3}
//                                 marginPagesDisplayed={2}
//                                 pageCount={pagination.TotalPages}
//                                 previousLabel={t("Previous")}
//                                 pageClassName="page-item"
//                                 pageLinkClassName="page-link"
//                                 previousClassName="page-item"
//                                 previousLinkClassName="page-link"
//                                 nextClassName="page-item"
//                                 nextLinkClassName="page-link"
//                                 breakLabel="..."
//                                 breakClassName="page-item"
//                                 breakLinkClassName="page-link"
//                                 containerClassName="pagination"
//                                 activeClassName="active"
//                                 renderOnZeroPageCount={null}
//                                 forcePage={activePageNumber}
//                               />
//                             </Col>
//                           </Row>
//                         </div>
//                         : null
//                     }
//                     {
//                       isShowSpinner || isShowTableSpinner ?
//                         <div className="dashboard-spinner-layer" >
//                           <div className="sweet-loading">
//                             <PuffLoader size={80} color={SPINNER_COLOR} />
//                           </div>
//                         </div>
//                         : null
//                     }
//                   </Card>
//                 </Col>
//               </Row>
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </>
//   )
// }

// export default withTranslation()(TransactionHistory)




// import React, { useCallback, useEffect, useState } from 'react'
// import {
//   Button,
//   Card,
//   CardBody,
//   CardTitle,
//   Col,
//   Input,
//   Label,
//   Row,
// } from "reactstrap"
// import { useDispatch, useSelector } from 'react-redux'
// import HtmlHead from '../../../components/HtmlHead/HtmlHead'
// import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
// import moment from 'moment'
// import Table from 'react-bootstrap/Table';
// import ReactPaginate from 'react-paginate'
// import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
// import uuid from 'react-uuid'
// import { addRightSectionSpinner, removeRightSectionSpinner } from '../../../store/actions'
// import DashboardApiService from '../../../Services/DashboardApiService'
// import AlertService from '../../../Services/alertService'
// import MainService from '../../../Services/MainService'
// import { Link } from "react-router-dom"
// import { useDebounce } from 'use-debounce'
// import NoData from '../../../components/NoData/NoData'
// import Breadcrumbs from './../../../components/Common/Breadcrumb'
// import { ERROR_KEY, fielsLengths } from '../../../Constants/MainKeys'

// const ClientTransactions = (props) => {

//   const title = "Client Database";
//   const description = "";
//   const pageSize = 10;
//   const dispatch = useDispatch();
//   const { clientId } = props.match.params;
//   const { rightSectionSpinners } = useSelector(state => state.Spinners);
//   const { user } = useSelector(state => state.Login);
//   const { language, languages } = useSelector(state => state.Languages);
//   const [activePageNumber, setActivePageNumber] = useState(0);
//   const [pagination, setPagination] = useState(null);
//   const [paymentHistory, setPaymentHistory] = useState([]);
//   const [isShowFilter, setIsShowFilter] = useState(false);

//   // Filter part
//   const [functions, setFunctions] = useState([]);
//   const [headers, setHeaders] = useState([]);
//   const [search, setSearch] = useState("");
//   const [_searchFieldName, _setSearchFieldName] = useState(null);
//   const [currencies, setCurrencies] = useState(null);

//   // const [filterData, setFilterData] = useState({
//   //   functionKey: null,
//   //   fieldName: null,
//   //   functionValue: "",
//   // })
//   // const [debouncedFilter] = useDebounce(filterData, 1000)
//   // const [debouncedSearch] = useDebounce(search, 1000)

//   // useEffect(() => {
//   //   if (debouncedFilter.functionKey && debouncedFilter.fieldName && debouncedFilter.functionValue) {
//   //     getOrganizationTransactionsAsAgent(1);
//   //   }
//   // }, [debouncedFilter])

//   // useEffect(() => {
//   //   getOrganizationTransactionsAsAgent(1);
//   // }, [debouncedSearch])

//   const [searchRequestModel, setSearchRequestModel] = useState(null);
//   const [filterData, setFilterData] = useState(null);
//   const [filter, setFilter] = useState({
//     searchKey: [],
//     status: [],
//     currencyId: [],
//     cryptoWalletId: [],
//     createDate: "",
//     finishDate: "",
//     fiatMinAmount: "",
//     fiatMaxAmount: "",
//     cryptoMinAmount: "",
//     cryptoMaxAmount: "",
//   })

//   useEffect(() => {
//     getOrganizationTransactionsAsAgent(1, filter);
//     getCryptoWallets();
//     getCurrencies();
//     return () => {
//       setPaymentHistory([]);
//     }
//   }, [])

//   // const handlePageClick = (event) => {
//   //   getOrganizationTransactionsAsAgent(event.selected + 1);
//   //   setActivePageNumber(event.selected);
//   // };

//   const handlePageClick = (event) => {
//     if (filter.status || filter.currencyId || filter.cryptoWalletId || filter.createDate || filter.finishDate || filter.fiatMinAmount || filter.fiatMaxAmount || filter.cryptoMinAmount || filter.cryptoMaxAmount) {
//       onFilter(event.selected + 1, filter, true);
//     } else {
//       getOrganizationTransactionsAsAgent(event.selected + 1)
//     }
//     setActivePageNumber(event.selected);
//   };

//   const getCryptoWallets = () => {
//     const spinnerId = uuid();
//     DashboardApiService.getCryptoWallets().then(response => {
//       if (response && response.data) {
//         const data = [...response.data];
//         setCryptoWallets(data);
//       }
//     }).catch(error => getFail(error, spinnerId))
//   }

//   const getCurrencies = () => {
//     const spinnerId = uuid();
//     DashboardApiService.getCurrencies().then(response => {
//       if (response && response.data) {
//         const data = [...response.data];
//         setCurrencies(data);
//       }
//     }).catch(error => getFail(error, spinnerId))
//   }

//   const onFilter = (pageNumber = 1) => {
//     let _filter = { ...filter };
//     let newSearchKey = {};
//     _filter.searchKey.forEach(item => {
//       if (item.value.filter(value => value.trim().length > 0).length) {
//         newSearchKey[item.name] = item.value.filter(value => value.trim().length > 0);
//       }
//     });
//     if (Object.keys(newSearchKey).length) {
//       _filter.searchKey = newSearchKey;
//     } else {
//       _filter.searchKey = [];
//     };

//     _filter.status = _filter.status.map(item => item.value);
//     _filter.currencyId = _filter.currencyId.map(item => item.value);
//     _filter.cryptoWalletId = _filter.cryptoWalletId.map(item => item.value);

//     setFilter(prevFilter => ({ ...prevFilter, searchKey: filter.searchKey, status: filter.status, currencyId: filter.currencyId, cryptoWalletId: filter.cryptoWalletId }));
//     getOrganizationTransactionsAsAgent(pageNumber, _filter, scrollToCurrentDivPosition);

//   }

//   // const getOrganizationTransactionsAsAgent = (pageNumber, filter) => {
//   //   const spinnerId = uuid();
//   //   dispatch(addRightSectionSpinner(spinnerId));
//   //   const filterDataCopy = { ...filterData }
//   //   filterDataCopy.functionValue = filterDataCopy.functionValue.toString();
//   //   filterDataCopy.search = search;
//   //   filterDataCopy.searchFieldName = _searchFieldName;
//   //   DashboardApiService.getOrganizationTransactionsAsAgent(clientId, pageNumber, pageSize, filterDataCopy).then(response => {
//   //     if (response && response.pagination) setPagination(JSON.parse(response.pagination));
//   //     const data = { ...response.data }
//   //     if (data.paymentHistory && data.paymentHistory.length) {
//   //       setPaymentHistory(response.data.paymentHistory);
//   //       !filterDataCopy.fieldName && setHeaders(Object.keys(response.data.paymentHistory[0]))
//   //     } else {
//   //       setPaymentHistory(response.data.paymentHistory);
//   //     }
//   //     if (data.filter?.functions) {
//   //       setFunctions(data.filter.functions)
//   //     }
//   //   }).catch(error => AlertService.alert("error", error)).finally(() => {
//   //     dispatch(removeRightSectionSpinner(spinnerId))
//   //     window.scrollTo({ top: 0, behavior: 'smooth' });
//   //   })
//   // }

//   const getOrganizationTransactionsAsAgent = (pageNumber, filter) => {
//     const spinnerId = uuid();
//     setRightSectionSpinner(spinnerId);
//     let _filter = { ...filter };
//     Object.entries(_filter).forEach(([key, item]) => {
//       if (Array.isArray(item) && !item.length) {
//         _filter[key] = null;
//       } else if (!item) {
//         if (typeof item !== "number") {
//           _filter[key] = null;
//         }
//       }
//     });

//     DashboardApiService.getOrganizationTransactionsAsAgent(pageNumber, pageSize, _filter).then(response => {
//       if (response && response.pagination) setPagination(JSON.parse(response.pagination));
//       if (response && response.data) {
//         const data = { ...response.data };
//         if (data.filter) {
//           if (data.filter.serachRequestModel) {
//             setSearchRequestModel(data.filter.serachRequestModel);
//           }
//           setFilterData(data.filter);
//         }
//         if (data && data.paymentHistory) {
//           setPaymentHistory(data.paymentHistory);
//         } else {
//           setPaymentHistory([]);
//         }
//       }

//       extractRightSectionSpinner(spinnerId);
//     }).catch(error => getFail(error, spinnerId))
//   }

//   const clearFilter = () => {
//     let newData = {
//       searchKey: [],
//       status: [],
//       currencyId: [],
//       cryptoWalletId: [],
//       createDate: "",
//       finishDate: "",
//       fiatMinAmount: "",
//       fiatMaxAmount: "",
//       cryptoMinAmount: "",
//       cryptoMaxAmount: "",
//     }
//     setFilter(newData);
//     setActivePageNumber(0);
//     getOrganizationTransactionsAsAgent(1, newData);
//   }

//   const onNumberChange = (event, fieldName) => {
//     if (event.target.value.includes("e") || event.target.value.includes(".") || event.target.value.includes("-") || event.target.value === "0") {
//       setFilterData(values => ({
//         ...values,
//         [fieldName]: "",
//       }))
//       return false;
//     };
//     if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value))) {
//       setFilterData(values => ({
//         ...values,
//         [fieldName]: event.target.value ? +event.target.value : ""
//       }))
//       return false;
//     }
//   };

//   const onSelectOptionsChange = (item, fieldName) => {
//     if (!item) { return false };
//     setFilterData(values => ({
//       ...values,
//       [fieldName]: item.value
//     }))
//   }

//   const onDateChange = (event, fieldName) => {
//     setFilter(prevFilter => ({ ...prevFilter, [fieldName]: event.target.value }))
//   }

//   // const _onSelectOptionsChange = (item, cb) => {
//   //   if (!item) { return false };
//   //   cb(item.value);
//   //   if (debouncedSearch.trim().length) {
//   //     getOrganizationTransactionsAsAgent(1)
//   //   }
//   // }

//   const onChange = (event, cb, maxLength = Infinity) => {
//     if (maxLength && maxLength < event.target.value.length) { return; }
//     cb(event.target.value);
//   }

//   const onSearchSelectOptionChange = (item) => {
//     let isExist = filter.searchKey.find(el => el.name === item.value);
//     if (isExist) { return false; }
//     let _searchKey = [...filter.searchKey];
//     _searchKey.push({ name: item.value, value: [""] })
//     setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
//   }

//   const scrollToCurrentDivPosition = (id = "") => {
//     const element = document.getElementById(id);
//     if (element) {
//       element.scrollIntoView({ behavior: 'smooth' });
//     }
//   }

//   const removeMultiItem = (index, fieldName) => {
//     let arr = [...filter[fieldName]];
//     arr = arr.filter((_, idx) => index !== idx)
//     setFilter(prevFilter => ({ ...prevFilter, [fieldName]: arr }))
//   }

//   const addSearchInput = (mainIndex) => {
//     let _searchKey = [...filter.searchKey];
//     let currentObj = _searchKey[mainIndex];
//     const emptyString = currentObj.value.find(element => element.trim() === "");
//     if (typeof emptyString !== "undefined") { return false; }
//     currentObj.value = [...currentObj.value, ""];
//     _searchKey[mainIndex] = currentObj;
//     setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
//   }

//   const onMultiSelectOptionChange = (item, fieldName) => {
//     let currentArr = [...filter[fieldName]];
//     let isExist = currentArr.find(el => el.value === item.value);
//     if (isExist) { return false; }
//     currentArr.push({ label: item.label, value: item.value })
//     setFilter((values) => ({ ...values, [fieldName]: currentArr }))
//   }

//   const removeSearchInput = (mainIndex, childIndex) => {
//     let _searchKey = [...filter.searchKey];
//     let currentObj = _searchKey[mainIndex];
//     currentObj.value = currentObj.value.filter((_, index) => index !== childIndex);
//     if (!currentObj.value.length) {
//       _searchKey = _searchKey.filter((_, idx) => idx !== mainIndex)
//     } else {
//       _searchKey[mainIndex] = currentObj;
//     }
//     setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
//   }

//   const setRightSectionSpinner = useCallback(spinner => {
//     dispatch(addRightSectionSpinner(spinner));
//   }, []);

//   const extractRightSectionSpinner = useCallback(spinner => {
//     dispatch(removeRightSectionSpinner(spinner));
//   }, []);

//   const getFail = (error) => {
//     error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
//   }

//   return (
//     <>
//       <HtmlHead title={title} description={description} />
//       <div className='page-content position-relative min-h-100vh'>
//         <RightSectionSpinner spinner={rightSectionSpinners} />


//         <div className='container-fluid'>
//           <Breadcrumbs
//             title="Cryllex"
//             breadcrumbItem="Merchant Transactions"
//             isShowGoBackButton={true}
//             goBack={() => window.history.back()}
//           />
//           <Row>
//             <Col lg={12}>
//               <Row>
//                 {/* <Col sm={12} className='mb-4'>
//                       <div className='d-flex justify-content-between align-items-center'>
//                         <div className='w-100'>
//                           <div className='fs-5'>

//                             <Row>
//                               <Col sm={12}><p className='fs-6 fw-500 mb-2 ms-1'>Filter:</p></Col>
//                               {
//                                 functions && functions.length ?
//                                   <Col sm={12} md={2} className='mb-1 px-3 pe-md-1' >
//                                     <ReactSelectOption
//                                       value={filterData.functionKey}
//                                       className="fs-6 w-100"
//                                       selectedValue={(() => {
//                                         const selectedValue = { ...functions.find(data => data.value === filterData.functionKey) };
//                                         if (Object.keys(selectedValue).length) {
//                                           selectedValue.label = selectedValue.key;
//                                           selectedValue.value = selectedValue.value;
//                                           return selectedValue;
//                                         } else {
//                                           return { label: "Choose...", value: null }
//                                         }
//                                       })()}
//                                       items={functions.map(data => ({ label: data.key, value: data.value }))}
//                                       onChange={(item) => onSelectOptionsChange(item, "functionKey")}
//                                     />
//                                   </Col>
//                                   : null
//                               }
//                               {
//                                 headers && headers.length ?
//                                   <Col sm={12} md={2} className='mb-1 px-3 px-md-1' >
//                                     <ReactSelectOption
//                                       value={filterData.fieldName}
//                                       className="fs-6 w-100"
//                                       selectedValue={(() => {
//                                         const selectedValue = headers.find(data => data === filterData.fieldName);
//                                         if (selectedValue) {
//                                           return { label: selectedValue, value: selectedValue };
//                                         } else {
//                                           return { label: "Choose...", value: null }
//                                         }
//                                       })()}
//                                       items={headers.map(data => ({ label: data, value: data }))}
//                                       onChange={(item) => onSelectOptionsChange(item, "fieldName")}
//                                     />
//                                   </Col>
//                                   : null
//                               }
//                               <Col sm={12} md={2} className='mb-1 px-3 ps-md-1'>
//                                 <Input
//                                   type="number"
//                                   placeholder='Value'
//                                   className={`form-control resize-none mb-lg-0`}
//                                   value={filterData.functionValue}
//                                   onChange={event => onNumberChange(event, "functionValue")}
//                                 />
//                               </Col>
//                               {
//                                 headers && headers.length ?
//                                   <Col sm={12} md={2} className='px-3 pe-md-1 mb-1'>
//                                     <ReactSelectOption
//                                       value={_searchFieldName}
//                                       className="fs-6 w-100"
//                                       selectedValue={(() => {
//                                         const selectedValue = headers.find(data => data === _searchFieldName);
//                                         if (selectedValue) {
//                                           return { label: selectedValue, value: selectedValue };
//                                         } else {
//                                           return { label: "Choose...", value: null }
//                                         }
//                                       })()}
//                                       items={headers.map(data => ({ label: data, value: data }))}
//                                       onChange={(item) => _onSelectOptionsChange(item, _setSearchFieldName)}
//                                     />
//                                   </Col>
//                                   : null
//                               }
//                               <Col sm={12} md={4} className='mb-2'>
//                                 <div className='app-search p-0'>
//                                   <div className='position-relative'>
//                                     <Input
//                                       id="search"
//                                       type="text"
//                                       placeholder='Search bar'
//                                       className={`form-control resize-none mb-lg-0`}
//                                       value={search}
//                                       onChange={event => onChange(event, setSearch)}
//                                     />
//                                     <span className="bx bx-search-alt"></span>
//                                   </div>
//                                 </div>
//                               </Col>
//                             </Row>
//                           </div>
//                         </div>

//                       </div>
//                     </Col> */}

//                 {
//                   filterData && isShowFilter ?
//                     <Col xl={3}>
//                       <Card id='filter' className='position-relative'>
//                         <CardBody className="pb-0">
//                           <CardTitle className="mb-4">
//                             <div className='d-flex justify-content-between align-items-center cursor-pointer'>
//                               Filter by
//                               <i
//                                 className='bx bx-x'
//                                 style={{ fontSize: "20px" }}
//                                 onClick={() => setIsShowFilter(false)}
//                               />
//                             </div>
//                             <hr />
//                           </CardTitle>
//                           <div>
//                             <div className='w-100 mb-4'>
//                               <div className='mb-3'>
//                                 {
//                                   searchRequestModel ?
//                                     <div>
//                                       <Label className='text-nowrap'>Search by:</Label>
//                                       <ReactSelectOption
//                                         isSearchable={true}
//                                         selectedValue={(() => {
//                                           const selectedValue = { label: "Select", value: "" };
//                                           return selectedValue;
//                                         })()}
//                                         items={Object.keys(searchRequestModel).map(data => ({ label: MainService.camelCaseToWords(data), value: data }))}
//                                         onChange={(item) => onSearchSelectOptionChange(item)}
//                                       />
//                                     </div>
//                                     : null
//                                 }
//                               </div>
//                               {
//                                 filter.searchKey && filter.searchKey.length ?
//                                   filter.searchKey.map((item, index) => {
//                                     return <div key={index} className='border rounded p-2 mb-2'>
//                                       <div className='d-flex justify-content-between align-items-center '>
//                                         <p className='mb-0'><b>{MainService.camelCaseToWords(item.name)}</b></p>
//                                         <Button
//                                           color='success'
//                                           style={{ padding: "3px" }}
//                                           className='d-flex justify-content-center align-items-center bg-success'
//                                           onClick={() => addSearchInput(index)}
//                                         >
//                                           <i className='bx bx-plus' style={{ fontSize: "18px" }}></i>
//                                         </Button>

//                                       </div>
//                                       <hr />
//                                       {
//                                         item.value && item.value.length ?
//                                           item.value.map((el, idx) => {
//                                             return <div key={idx} className='d-flex justify-content-between align-items-center mb-1'>
//                                               <Input
//                                                 id="search"
//                                                 type="search"
//                                                 placeholder='Search'
//                                                 className={`form-control resize-none`}
//                                                 value={el}
//                                                 onChange={event => onChange(event, index, idx, fielsLengths.length_120)}
//                                               />
//                                               <Button
//                                                 outline
//                                                 color='danger'
//                                                 style={{ padding: "5px" }}
//                                                 className='d-flex justify-content-center align-items-center ms-2'
//                                                 onClick={() => removeSearchInput(index, idx)}
//                                               >
//                                                 <i className='bx bx-trash' style={{ fontSize: "18px" }} />
//                                               </Button>
//                                             </div>
//                                           })
//                                           : null
//                                       }

//                                     </div>
//                                   })
//                                   : null
//                               }
//                             </div>
//                             <hr />
//                           </div>

//                           {
//                             filterData.statuses && filterData.statuses.length ?
//                               <div className='w-100 mb-4'>
//                                 <Label className='text-nowrap'>Status</Label>
//                                 <ReactSelectOption
//                                   isSearchable={true}
//                                   selectedValue={(() => {
//                                     const selectedValue = { label: "Select", value: "" };
//                                     return selectedValue;
//                                   })()}
//                                   items={filterData.statuses.map(data => ({ ...data, label: data.value, value: data.key }))}
//                                   onChange={(item) => onMultiSelectOptionChange(item, "status")}
//                                 />

//                                 <div>
//                                   {
//                                     filter.status && filter.status.map((item, index) => {
//                                       return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
//                                         {MainService.camelCaseToWords(item.label)}
//                                         <Button
//                                           outline
//                                           color='danger'
//                                           style={{ padding: "5px" }}
//                                           className='d-flex justify-content-center align-items-center ms-2'
//                                           onClick={() => removeMultiItem(index, "status")}
//                                         >
//                                           <i className='bx bx-trash' style={{ fontSize: "18px" }} />
//                                         </Button>
//                                       </div>
//                                     })
//                                   }
//                                 </div>
//                               </div>
//                               : null
//                           }
//                           {
//                             currencies && currencies.length ?
//                               <div className='w-100 mb-4'>
//                                 <Label className='text-nowrap'>Currency</Label>
//                                 <ReactSelectOption
//                                   isSearchable={true}
//                                   selectedValue={(() => {
//                                     const selectedValue = { label: "Select", value: "" };
//                                     return selectedValue;
//                                   })()}
//                                   items={currencies.map(data => ({ ...data, label: data.name, value: data.id }))}
//                                   onChange={(item) => onMultiSelectOptionChange(item, "currencyId")}
//                                 />

//                                 <div>
//                                   {
//                                     filter.currencyId && filter.currencyId.map((item, index) => {
//                                       return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
//                                         {item.label}
//                                         <Button
//                                           outline
//                                           color='danger'
//                                           style={{ padding: "5px" }}
//                                           className='d-flex justify-content-center align-items-center ms-2'
//                                           onClick={() => removeMultiItem(index, "currencyId")}
//                                         >
//                                           <i className='bx bx-trash' style={{ fontSize: "18px" }} />
//                                         </Button>
//                                       </div>
//                                     })
//                                   }
//                                 </div>
//                               </div>
//                               : null
//                           }
//                           {
//                             cryptoWallets && cryptoWallets.length ?
//                               <div className='w-100 mb-4'>
//                                 <Label className='text-nowrap'>Wallet</Label>
//                                 <ReactSelectOption
//                                   isSearchable={true}
//                                   selectedValue={(() => {
//                                     const selectedValue = { label: "Select", value: "" };
//                                     return selectedValue;
//                                   })()}
//                                   items={cryptoWallets.map(data => ({ ...data, label: data.name, value: data.id }))}
//                                   onChange={(item) => onMultiSelectOptionChange(item, "cryptoWalletId")}
//                                 />

//                                 <div>
//                                   {
//                                     filter.cryptoWalletId && filter.cryptoWalletId.map((item, index) => {
//                                       return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
//                                         {item.label}
//                                         <Button
//                                           outline
//                                           color='danger'
//                                           style={{ padding: "5px" }}
//                                           className='d-flex justify-content-center align-items-center ms-2'
//                                           onClick={() => removeMultiItem(index, "cryptoWalletId")}
//                                         >
//                                           <i className='bx bx-trash' style={{ fontSize: "18px" }} />
//                                         </Button>
//                                       </div>
//                                     })
//                                   }
//                                 </div>
//                               </div>
//                               : null
//                           }
//                           <div className="mt-2 mb-3">
//                             <p className='font-weight-500 mb-0'>Transaction Date</p>
//                             <div className='row'>
//                               <div className='col-12 col-xl-12 col-md-6'>
//                                 <small className='mt-1 d-block'>From</small>
//                                 <input
//                                   id="createDate"
//                                   className="form-control"
//                                   type="date"
//                                   value={filter.createDate}
//                                   onChange={(event) => onDateChange(event, "createDate")}
//                                 />
//                               </div>
//                               <div className='col-12 col-xl-12 col-md-6'>
//                                 <small className='mt-1 d-block'>To</small>
//                                 <input
//                                   id="finishDate"
//                                   className="form-control"
//                                   type="date"
//                                   min={filter.createDate ? filter.createDate : ""}
//                                   value={filter.finishDate}
//                                   onChange={(event) => onDateChange(event, "finishDate")}
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                           <div className="mt-2 mb-3">
//                             <p className='font-weight-500 mb-0'>Fiat Amount</p>
//                             <div className='row'>
//                               <div className='col-12 col-sm-6'>
//                                 <small className='mt-1 d-block'>min</small>
//                                 <input
//                                   id="fiatMinAmount"
//                                   className="form-control"
//                                   type="number"
//                                   value={filter.fiatMinAmount}
//                                   onChange={(event) => onNumberChange(event, "fiatMinAmount")}
//                                 />
//                               </div>
//                               <div className='col-12 col-sm-6'>
//                                 <small className='mt-1 d-block'>max</small>
//                                 <input
//                                   id="fiatMaxAmount"
//                                   className="form-control"
//                                   type="number"
//                                   value={filter.fiatMaxAmount}
//                                   onChange={(event) => onNumberChange(event, "fiatMaxAmount")}
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                           <div className="mt-2 mb-3">
//                             <p className='font-weight-500 mb-0'>Crypto Amount</p>
//                             <div className='row'>
//                               <div className='col-12 col-sm-6'>
//                                 <small className='mt-1 d-block'>{t("min")}</small>
//                                 <input
//                                   id="cryptoMinAmount"
//                                   className="form-control"
//                                   type="number"
//                                   value={filter.cryptoMinAmount}
//                                   onChange={(event) => onNumberChange(event, "cryptoMinAmount")}
//                                 />
//                               </div>
//                               <div className='col-12 col-sm-6'>
//                                 <small className='mt-1 d-block'>{t("max")}</small>
//                                 <input
//                                   id="cryptoMaxAmount"
//                                   className="form-control"
//                                   type="number"
//                                   value={filter.cryptoMaxAmount}
//                                   onChange={(event) => onNumberChange(event, "cryptoMaxAmount")}
//                                 />
//                               </div>
//                             </div>
//                           </div>

//                           <div className="mt-4 mb-3 d-flex gap-2 justify-content-end">
//                             <Button outline color='primary' onClick={clearFilter}>
//                               Clear filter
//                             </Button>
//                             <Button color='primary' onClick={() => onFilter()} className='px-4'>
//                               Filter
//                             </Button>
//                           </div>
//                         </CardBody>
//                         {
//                           isShowSpinner ?
//                             <div className="dashboard-spinner-layer" >
//                               <div className="sweet-loading">
//                                 <PuffLoader size={80} color={SPINNER_COLOR} />
//                               </div>
//                             </div>
//                             : null
//                         }
//                       </Card>
//                     </Col>
//                     : null
//                 }

//                 {
//                   paymentHistory && paymentHistory.length ?
//                     <Col xl={filterData && isShowFilter ? 9 : 12}>
//                       <Card>
//                         <CardTitle>
//                           <CardTitle className="mb-4">
//                             <div className='d-flex justify-content-between align-items-center'>
//                               Transactions
//                               {
//                                 !isShowFilter && filterData ?
//                                   <Button
//                                     color='primary'
//                                     size='sm'
//                                     className='px-3 d-flex align-items-end'
//                                     onClick={() => {
//                                       setIsShowFilter(true);
//                                       scrollToCurrentDivPosition("filter")
//                                     }}
//                                   >
//                                     <i className='bx bx-filter me-2' style={{ fontSize: "20px" }}></i>
//                                     Show Filter
//                                   </Button>
//                                   : null
//                               }
//                             </div>
//                             <hr />
//                           </CardTitle>
//                         </CardTitle>
//                         <CardBody>
//                           <Table striped bordered hover responsive>
//                             <thead>
//                               <tr>
//                                 <th className='text-nowrap'>Transaction Id</th>
//                                 <th className='text-nowrap'>Fiat Amount</th>
//                                 <th className='text-nowrap'>Currency</th>
//                                 <th className='text-nowrap'>Crypto Amount</th>
//                                 <th className='text-nowrap'>Crypto Wallet</th>
//                                 <th className='text-nowrap'>Payment Type</th>
//                                 <th className='text-nowrap'>Create Date</th>
//                                 <th className='text-nowrap'>Expire Date</th>
//                                 <th className='text-nowrap'>Payment Url</th>
//                                 <th className='text-nowrap'>Status</th>
//                                 <th className='text-nowrap'>Transaction Text</th>
//                                 <th className='text-nowrap text-center'>Actions</th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {
//                                 paymentHistory.map((item, index) => {
//                                   return <tr key={index}>
//                                     <td>{item.localTransactionId}</td>
//                                     <td>{item.fiatAmount}</td>
//                                     <td>{item.currency?.name}</td>
//                                     <td>{item.cryptoAmount}</td>
//                                     <td>{item.cryptoWallet}</td>
//                                     <td>{item.paymentType}</td>
//                                     <td className='text-nowrap'>{moment(MainService.convertUTCDateToLocalDate(new Date(item.createDate))).format("LLL")}</td>
//                                     <td className='text-nowrap'>{moment(MainService.convertUTCDateToLocalDate(new Date(item.expireDate))).format("LLL")}</td>
//                                     <td><Link to={item.paymentUrl} className="text-decoration-underline" target="_blank">{item.paymentUrl}</Link></td>
//                                     <td>{item.status}</td>
//                                     <td><span className='max-line-3' title={item.transactionText}>{item.transactionText}</span></td>

//                                     <td className='text-center'>
//                                       <Button
//                                         type='button'
//                                         color="primary"
//                                         className='text-nowrap'
//                                         onClick={() => { }}
//                                       >
//                                         View Info
//                                       </Button>
//                                     </td>
//                                   </tr>
//                                 })
//                               }


//                             </tbody>
//                           </Table>
//                           {
//                             pagination ?
//                               <div className='p-3 d-flex justify-content-end'>
//                                 <Row>
//                                   <Col>
//                                     <ReactPaginate
//                                       nextLabel="Next"
//                                       onPageChange={handlePageClick}
//                                       pageRangeDisplayed={3}
//                                       marginPagesDisplayed={2}
//                                       pageCount={pagination.TotalPages}
//                                       previousLabel="Previous"
//                                       pageClassName="page-item"
//                                       pageLinkClassName="page-link"
//                                       previousClassName="page-item"
//                                       previousLinkClassName="page-link"
//                                       nextClassName="page-item"
//                                       nextLinkClassName="page-link"
//                                       breakLabel="..."
//                                       breakClassName="page-item"
//                                       breakLinkClassName="page-link"
//                                       containerClassName="pagination"
//                                       activeClassName="active"
//                                       renderOnZeroPageCount={null}
//                                       forcePage={activePageNumber}
//                                     />
//                                   </Col>
//                                 </Row>
//                               </div>
//                               : null
//                           }
//                         </CardBody>
//                       </Card>

//                     </Col>
//                     : <NoData />
//                 }
//               </Row>
//             </Col>

//           </Row>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ClientTransactions;



import React, { useCallback, useState, useEffect, useRef } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Button,
  Label,
  Input
} from "reactstrap"
import { Table } from "react-bootstrap"
import ReactPaginate from 'react-paginate'
import ReactSelectOption from './../../../components/SelectOptions/ReactSelectOption'
import { withTranslation } from "react-i18next"
import DashboardApiService from '../../../Services/DashboardApiService';
import AlertService from './../../../Services/alertService'
import { ERROR_KEY, SPINNER_COLOR, fielsLengths } from './../../../Constants/MainKeys'
import uuid from 'react-uuid'
import { addRightSectionSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions'
import { useDispatch, useSelector } from 'react-redux'
import * as moment from 'moment';
import MainService from './../../../Services/MainService'
import PuffLoader from "react-spinners/PuffLoader"
import HtmlHead from './../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from './../../../components/Spinners/RightSectionSpinner'
import NoData from '../../../components/NoData/NoData'

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const ClientTransactions = (props) => {

  const { t } = props;
  const title = "Transaction History";
  const description = "";
  const pageSize = 10;
  const dispatch = useDispatch();
  const { clientId } = props.match.params;
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [isShowTableSpinner, setIsShowTableSpinner] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [cryptoWallets, setCryptoWallets] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [txnValueType, setTxnValueType] = useState("");

  const wrapperRef = useRef(null);
  const [isShowChangeTxnValuePopup, setIsShowChangeTxnValuePopup] = useState(false);
  useOutsideAlerter(wrapperRef, setIsShowChangeTxnValuePopup);

  const [searchRequestModel, setSearchRequestModel] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [filter, setFilter] = useState({
    searchKey: [],
    status: [],
    currencyId: [],
    cryptoWalletId: [],
    createDate: "",
    finishDate: "",
    fiatMinAmount: "",
    fiatMaxAmount: "",
    cryptoMinAmount: "",
    cryptoMaxAmount: "",
  })

  useEffect(() => {
    getTransactionHistoryAsMerchant(1, filter);
    getCryptoWallets();
    getCurrencies();
    return () => {
      setTransactions([]);
    }
  }, [])

  const getTransactionHistoryAsMerchant = (pageNumber, filter) => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    let _filter = { ...filter };
    Object.entries(_filter).forEach(([key, item]) => {
      if (Array.isArray(item) && !item.length) {
        _filter[key] = null;
      } else if (!item) {
        if (typeof item !== "number" && typeof item !== "boolean") {
          _filter[key] = null;
        }
      }
    });
    DashboardApiService.getTransactionHistoryAsMerchant(pageNumber, pageSize, _filter, clientId).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        const data = { ...response.data };
        if (data.filter) {
          if (data.filter.serachRequestModel) {
            setSearchRequestModel(data.filter.serachRequestModel);
          }
          setFilterData(data.filter);
        }
        if (data && data.paymentHistory) {
          setTransactions(data.paymentHistory);
        } else {
          setTransactions([]);
        }
      }

      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const getCryptoWallets = () => {
    const spinnerId = uuid();
    DashboardApiService.getCryptoWallets().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setCryptoWallets(data);
      }
    }).catch(error => getFail(error, spinnerId))
  }

  const getCurrencies = () => {
    const spinnerId = uuid();
    DashboardApiService.getCurrencies().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setCurrencies(data);
      }
    }).catch(error => getFail(error, spinnerId))
  }

  const handlePageClick = (event) => {
    if (filter.status || filter.currencyId || filter.cryptoWalletId || filter.createDate || filter.finishDate || filter.fiatMinAmount || filter.fiatMaxAmount || filter.cryptoMinAmount || filter.cryptoMaxAmount) {
      onFilter(event.selected + 1, filter, true);
    } else {
      getTransactionHistoryAsMerchant(event.selected + 1)
    }
    setActivePageNumber(event.selected);
  };

  const onNumberChange = (event, fieldName) => {
    if (event.target.value === "") {
      setFilter((values) => ({
        ...values,
        [fieldName]: "",
      }));
      return false;
    }
    if (typeof +event.target.value === "number" && Number(event.target.value) >= 0) {
      setFilter((values) => ({
        ...values,
        [fieldName]: +event.target.value,
      }));
    }
  };

  const onChange = (event, mainIndex, childIndex, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    let _searchKey = [...filter.searchKey];
    _searchKey[mainIndex].value[childIndex] = event.target.value;
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const onDateChange = (event, fieldName) => {
    setFilter(prevFilter => ({ ...prevFilter, [fieldName]: event.target.value }))
  }

  const removeSearchInput = (mainIndex, childIndex) => {
    let _searchKey = [...filter.searchKey];
    let currentObj = _searchKey[mainIndex];
    currentObj.value = currentObj.value.filter((_, index) => index !== childIndex);
    if (!currentObj.value.length) {
      _searchKey = _searchKey.filter((_, idx) => idx !== mainIndex)
    } else {
      _searchKey[mainIndex] = currentObj;
    }
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const addSearchInput = (mainIndex) => {
    let _searchKey = [...filter.searchKey];
    let currentObj = _searchKey[mainIndex];
    const emptyString = currentObj.value.find(element => element.trim() === "");
    if (typeof emptyString !== "undefined") { return false; }
    currentObj.value = [...currentObj.value, ""];
    _searchKey[mainIndex] = currentObj;
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const onFilter = (pageNumber = 1) => {
    let _filter = { ...filter };
    let newSearchKey = {};
    _filter.searchKey.forEach(item => {
      if (item.value.filter(value => value.trim().length > 0).length) {
        newSearchKey[item.name] = item.value.filter(value => value.trim().length > 0);
      }
    });
    if (Object.keys(newSearchKey).length) {
      _filter.searchKey = newSearchKey;
    } else {
      _filter.searchKey = [];
    };

    _filter.status = _filter.status.map(item => item.value);
    _filter.currencyId = _filter.currencyId.map(item => item.value);
    _filter.cryptoWalletId = _filter.cryptoWalletId.map(item => item.value);

    setFilter(prevFilter => ({ ...prevFilter, searchKey: filter.searchKey, status: filter.status, currencyId: filter.currencyId, cryptoWalletId: filter.cryptoWalletId }));
    getTransactionHistoryAsMerchant(pageNumber, _filter, scrollToCurrentDivPosition);

  }

  const clearFilter = () => {
    let newData = {
      searchKey: [],
      status: [],
      currencyId: [],
      cryptoWalletId: [],
      createDate: "",
      finishDate: "",
      fiatMinAmount: "",
      fiatMaxAmount: "",
      cryptoMinAmount: "",
      cryptoMaxAmount: "",
    }
    setFilter(newData);
    setActivePageNumber(0);
    getTransactionHistoryAsMerchant(1, newData);
  }

  const onSearchSelectOptionChange = (item) => {
    let isExist = filter.searchKey.find(el => el.name === item.value);
    if (isExist) { return false; }
    let _searchKey = [...filter.searchKey];
    _searchKey.push({ name: item.value, value: [""] })
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const scrollToCurrentDivPosition = (id = "") => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const removeMultiItem = (index, fieldName) => {
    let arr = [...filter[fieldName]];
    arr = arr.filter((_, idx) => index !== idx)
    setFilter(prevFilter => ({ ...prevFilter, [fieldName]: arr }))
  }

  const onMultiSelectOptionChange = (item, fieldName) => {
    let currentArr = [...filter[fieldName]];
    let isExist = currentArr.find(el => el.value === item.value);
    if (isExist) { return false; }
    currentArr.push({ label: item.label, value: item.value })
    setFilter((values) => ({ ...values, [fieldName]: currentArr }))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    setIsShowSpinner(false);
    setIsShowTableSpinner(false);
    localStorage.removeItem("transactionHistoryPageNumber");
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />

        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Transactions"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col lg={12}>
              <Row className='mb-4'>
                {
                  filterData && isShowFilter ?
                    <Col xl={3}>
                      <Card id='filter' className='position-relative'>
                        <CardBody className="pb-0">
                          <CardTitle className="mb-4">
                            <div className='d-flex justify-content-between align-items-center cursor-pointer'>
                              Filter by
                              <i
                                className='bx bx-x'
                                style={{ fontSize: "20px" }}
                                onClick={() => setIsShowFilter(false)}
                              />
                            </div>
                            <hr />
                          </CardTitle>
                          <div>
                            <div className='w-100 mb-4'>
                              <div className='mb-3'>
                                {
                                  searchRequestModel ?
                                    <div>
                                      <Label className='text-nowrap'>Search by:</Label>
                                      <ReactSelectOption
                                        isSearchable={true}
                                        selectedValue={(() => {
                                          const selectedValue = { label: "Select", value: "" };
                                          return selectedValue;
                                        })()}
                                        items={Object.keys(searchRequestModel).map(data => ({ label: MainService.camelCaseToWords(data), value: data }))}
                                        onChange={(item) => onSearchSelectOptionChange(item)}
                                      />
                                    </div>
                                    : null
                                }
                              </div>
                              {
                                filter.searchKey && filter.searchKey.length ?
                                  filter.searchKey.map((item, index) => {
                                    return <div key={index} className='border rounded p-2 mb-2'>
                                      <div className='d-flex justify-content-between align-items-center '>
                                        <p className='mb-0'><b>{MainService.camelCaseToWords(item.name)}</b></p>
                                        <Button
                                          color='success'
                                          style={{ padding: "3px" }}
                                          className='d-flex justify-content-center align-items-center bg-success'
                                          onClick={() => addSearchInput(index)}
                                        >
                                          <i className='bx bx-plus' style={{ fontSize: "18px" }}></i>
                                        </Button>

                                      </div>
                                      <hr />
                                      {
                                        item.value && item.value.length ?
                                          item.value.map((el, idx) => {
                                            return <div key={idx} className='d-flex justify-content-between align-items-center mb-1'>
                                              <Input
                                                id="search"
                                                type="search"
                                                placeholder='Search'
                                                className={`form-control resize-none`}
                                                value={el}
                                                onChange={event => onChange(event, index, idx, fielsLengths.length_120)}
                                              />
                                              <Button
                                                outline
                                                color='danger'
                                                style={{ padding: "5px" }}
                                                className='d-flex justify-content-center align-items-center ms-2'
                                                onClick={() => removeSearchInput(index, idx)}
                                              >
                                                <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                              </Button>
                                            </div>
                                          })
                                          : null
                                      }

                                    </div>
                                  })
                                  : null
                              }
                            </div>
                            <hr />
                          </div>

                          {
                            filterData.statuses && filterData.statuses.length ?
                              <div className='w-100 mb-4'>
                                <Label className='text-nowrap'>Status</Label>
                                <ReactSelectOption
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { label: "Select", value: "" };
                                    return selectedValue;
                                  })()}
                                  items={filterData.statuses.map(data => ({ ...data, label: data.value, value: data.key }))}
                                  onChange={(item) => onMultiSelectOptionChange(item, "status")}
                                />

                                <div>
                                  {
                                    filter.status && filter.status.map((item, index) => {
                                      return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
                                        {MainService.camelCaseToWords(item.label)}
                                        <Button
                                          outline
                                          color='danger'
                                          style={{ padding: "5px" }}
                                          className='d-flex justify-content-center align-items-center ms-2'
                                          onClick={() => removeMultiItem(index, "status")}
                                        >
                                          <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                        </Button>
                                      </div>
                                    })
                                  }
                                </div>
                              </div>
                              : null
                          }
                          {
                            currencies && currencies.length ?
                              <div className='w-100 mb-4'>
                                <Label className='text-nowrap'>Currency</Label>
                                <ReactSelectOption
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { label: "Select", value: "" };
                                    return selectedValue;
                                  })()}
                                  items={currencies.map(data => ({ ...data, label: data.name, value: data.id }))}
                                  onChange={(item) => onMultiSelectOptionChange(item, "currencyId")}
                                />

                                <div>
                                  {
                                    filter.currencyId && filter.currencyId.map((item, index) => {
                                      return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
                                        {item.label}
                                        <Button
                                          outline
                                          color='danger'
                                          style={{ padding: "5px" }}
                                          className='d-flex justify-content-center align-items-center ms-2'
                                          onClick={() => removeMultiItem(index, "currencyId")}
                                        >
                                          <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                        </Button>
                                      </div>
                                    })
                                  }
                                </div>
                              </div>
                              : null
                          }
                          {
                            cryptoWallets && cryptoWallets.length ?
                              <div className='w-100 mb-4'>
                                <Label className='text-nowrap'>Wallet</Label>
                                <ReactSelectOption
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { label: "Select", value: "" };
                                    return selectedValue;
                                  })()}
                                  items={cryptoWallets.map(data => ({ ...data, label: data.name, value: data.id }))}
                                  onChange={(item) => onMultiSelectOptionChange(item, "cryptoWalletId")}
                                />

                                <div>
                                  {
                                    filter.cryptoWalletId && filter.cryptoWalletId.map((item, index) => {
                                      return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
                                        {item.label}
                                        <Button
                                          outline
                                          color='danger'
                                          style={{ padding: "5px" }}
                                          className='d-flex justify-content-center align-items-center ms-2'
                                          onClick={() => removeMultiItem(index, "cryptoWalletId")}
                                        >
                                          <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                        </Button>
                                      </div>
                                    })
                                  }
                                </div>
                              </div>
                              : null
                          }
                          <div className="mt-2 mb-3">
                            <p className='font-weight-500 mb-0'>Transaction Date</p>
                            <div className='row'>
                              <div className='col-12 col-xl-12 col-md-6'>
                                <small className='mt-1 d-block'>From</small>
                                <input
                                  id="createDate"
                                  className="form-control"
                                  type="date"
                                  value={filter.createDate}
                                  onChange={(event) => onDateChange(event, "createDate")}
                                />
                              </div>
                              <div className='col-12 col-xl-12 col-md-6'>
                                <small className='mt-1 d-block'>To</small>
                                <input
                                  id="finishDate"
                                  className="form-control"
                                  type="date"
                                  min={filter.createDate ? filter.createDate : ""}
                                  value={filter.finishDate}
                                  onChange={(event) => onDateChange(event, "finishDate")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 mb-3">
                            <p className='font-weight-500 mb-0'>Fiat Amount</p>
                            <div className='row'>
                              <div className='col-12 col-sm-6'>
                                <small className='mt-1 d-block'>{t("min")}</small>
                                <input
                                  id="fiatMinAmount"
                                  className="form-control"
                                  type="number"
                                  value={filter.fiatMinAmount}
                                  onChange={(event) => onNumberChange(event, "fiatMinAmount")}
                                />
                              </div>
                              <div className='col-12 col-sm-6'>
                                <small className='mt-1 d-block'>{t("max")}</small>
                                <input
                                  id="fiatMaxAmount"
                                  className="form-control"
                                  type="number"
                                  value={filter.fiatMaxAmount}
                                  onChange={(event) => onNumberChange(event, "fiatMaxAmount")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 mb-3">
                            <p className='font-weight-500 mb-0'>Crypto Amount</p>
                            <div className='row'>
                              <div className='col-12 col-sm-6'>
                                <small className='mt-1 d-block'>{t("min")}</small>
                                <input
                                  id="cryptoMinAmount"
                                  className="form-control"
                                  type="number"
                                  value={filter.cryptoMinAmount}
                                  onChange={(event) => onNumberChange(event, "cryptoMinAmount")}
                                />
                              </div>
                              <div className='col-12 col-sm-6'>
                                <small className='mt-1 d-block'>{t("max")}</small>
                                <input
                                  id="cryptoMaxAmount"
                                  className="form-control"
                                  type="number"
                                  value={filter.cryptoMaxAmount}
                                  onChange={(event) => onNumberChange(event, "cryptoMaxAmount")}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-4 mb-3 d-flex gap-2 justify-content-end">
                            <Button outline color='primary' onClick={clearFilter} className='text-nowrap'>
                              Clear filter
                            </Button>
                            <Button color='primary' onClick={() => onFilter()} className='px-4'>
                              Filter
                            </Button>
                          </div>
                        </CardBody>
                        {
                          isShowSpinner ?
                            <div className="dashboard-spinner-layer" >
                              <div className="sweet-loading">
                                <PuffLoader size={80} color={SPINNER_COLOR} />
                              </div>
                            </div>
                            : null
                        }
                      </Card>
                    </Col>
                    : null
                }
                <Col xl={filterData && isShowFilter ? 9 : 12}>
                  <Card id='table' className="position-relative">

                    <CardBody>
                      <CardTitle className="mb-4">
                        <div className='d-flex justify-content-between align-items-center'>
                          Transactions
                          {
                            !isShowFilter && filterData ?
                              <Button
                                color='primary'
                                size='sm'
                                className='px-3 d-flex align-items-end'
                                onClick={() => {
                                  setIsShowFilter(true);
                                  scrollToCurrentDivPosition("filter")
                                }}
                              >
                                <i className='bx bx-filter me-2' style={{ fontSize: "20px" }}></i>
                                Show Filter
                              </Button>
                              : null
                          }
                        </div>
                        <hr />
                      </CardTitle>
                      {
                        transactions && transactions.length ? (
                          <Table striped bordered hover responsive>
                            <thead>
                              <tr>
                                <th className="text-nowrap">#</th>
                                <th className="text-nowrap">On-Chain Txn ID</th>
                                <th className="text-nowrap">Txn Date</th>
                                <th className="text-nowrap">Paying Customer</th>
                                <th className="text-nowrap">Merchant / Sole Trader</th>
                                {/* {transactions[0].transactionValue && transactions[0].transactionValue.length ? (
                                  <th className="text-nowrap position-relative">
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => setIsShowChangeTxnValuePopup(!isShowChangeTxnValuePopup)}
                                    >
                                      {txnValueType || transactions[0].transactionValue[0].name}
                                      &nbsp;
                                      <b>(<i className="bx bxs-down-arrow"></i>)</b>
                                    </span>
                                    {isShowChangeTxnValuePopup && (
                                      <div
                                        className="position-absolute shadow border rounded bg-white"
                                        style={{ top: '33px', left: '0' }}
                                        ref={wrapperRef}
                                      >
                                        {transactions[0].transactionValue.map((item, index) => (
                                          <div key={index}>
                                            <p
                                              className="mb-0 px-3 py-2 cursor-pointer"
                                              onClick={() => {
                                                setTxnValueType(item.name);
                                                setIsShowChangeTxnValuePopup(false);
                                              }}
                                            >
                                              {item.name}
                                            </p>
                                            <hr className="m-0" />
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </th>
                                ) : null} */}
                                <th className="text-nowrap">Amount</th>
                                <th className="text-nowrap">Token</th>
                                <th className="text-nowrap">Wallet</th>
                                <th className="text-nowrap">Status</th>
                                <th className="text-nowrap">Total Txn Fees</th>
                                <th className="text-nowrap">Cryllex Hedging Profit</th>
                                <th className="text-nowrap">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactions.map((transaction, index) => {
                                // const transactionValue = transaction.transactionValue;
                                // const selectedValue = transactionValue.find(item => item.name === txnValueType);
                                // const firstTransactionValue = transactionValue[0];
                                // const isFirstValueCrypto = firstTransactionValue && firstTransactionValue.name === "Crypto";
                                // const displayValue = selectedValue ?
                                //   (selectedValue.name !== "Crypto" ? `${parseFloat(selectedValue.amount).toFixed(2)} ${selectedValue.currency}` : `${selectedValue.amount} ${selectedValue.currency}`)
                                //   :
                                //   (isFirstValueCrypto ? `${firstTransactionValue.amount} ${firstTransactionValue.currency}` : `${parseFloat(firstTransactionValue.amount).toFixed(2)} ${firstTransactionValue.currency}`);

                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    {/* <td>{transaction.transactionId}</td> */}
                                    <td> <span className='max-line-1' title={transaction.transactionId}>{transaction.transactionId}</span></td>
                                    <td className='text-nowrap'>{moment(MainService.convertUTCDateToLocalDate(new Date(transaction.createDate))).format("LL HH:mm")}</td>
                                    <td>{transaction.payingCustomerFullName}</td>
                                    <td>{transaction.trader}</td>
                                    {/* <td>{displayValue}</td> */}
                                    <td>{transaction.amount}USD</td>
                                    <td>{transaction.currency ? transaction.currency.name : ""}</td>
                                    <td>{transaction.walletName}</td>
                                    <td>{transaction.transactionStatus}</td>
                                    <td>ToDo</td>
                                    <td>ToDo</td>
                                    <td>
                                      <div>
                                        <Button
                                          type="button"
                                          color="primary"
                                          className='shadow-sm me-2 text-nowrap'
                                          onClick={() => {
                                            if (transaction.transactionId) {
                                              props.history.push(`/${language}/transaction/view/${transaction.transactionId}/${transaction.organizationId}`);
                                            }
                                          }}
                                        >
                                          View Info
                                        </Button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        ) : (
                          <NoData />
                        )
                      }
                    </CardBody>
                    {
                      pagination && pagination.TotalPages && pagination.TotalPages > 1 ?
                        <div className='p-3 d-flex justify-content-end'>
                          <Row>
                            <Col>
                              <ReactPaginate
                                nextLabel={t("Next")}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pagination.TotalPages}
                                previousLabel={t("Previous")}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                                forcePage={activePageNumber}
                              />
                            </Col>
                          </Row>
                        </div>
                        : null
                    }
                    {
                      isShowSpinner || isShowTableSpinner ?
                        <div className="dashboard-spinner-layer" >
                          <div className="sweet-loading">
                            <PuffLoader size={80} color={SPINNER_COLOR} />
                          </div>
                        </div>
                        : null
                    }
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(ClientTransactions)