import React, { useCallback, useEffect } from 'react'
import Breadcrumbs from '../../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Button,
  Input
} from "reactstrap"
import { Table } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { ALL_VALID_FILE_TYPES, ERROR_KEY, VALID_FILE_TYPES_KEY, VALID_IMAGE_TYPES_KEY } from '../../../../Constants/MainKeys'
import { addButtonSpinner, addPageSpinner, addRightSectionSpinner, removeButtonSpinner, removePageSpinner, removeRightSectionSpinner } from '../../../../store/spinner/actions'
import AlertService from '../../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import DashboardApiService from '../../../../Services/DashboardApiService'
import { useState } from 'react'
import moment from 'moment'
import ReactSelectOption from '../../../../components/SelectOptions/ReactSelectOption'
import NoData from '../../../../components/NoData/NoData'
import ReactPaginate from 'react-paginate'
import GetCurrentDate from '../../../../components/DateTime/GetCurrentDate'
import GetCurrentTime from '../../../../components/DateTime/GetCurrentTime'
import MainService from '../../../../Services/MainService';
import ActionButtonWithOvalSpinner from '../../../../components/Buttons/ActionButtonWithOvalSpinner'

const buttonSpinnerId = uuid();
const ViewKYC = (props) => {

  const { t } = props;
  const dispatch = useDispatch();
  const title = "View KYC";
  const description = "";
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const mainService = new MainService();
  const [residentialAddressDocumentation, setResidentialAddressDocumentation] = useState(null)

  const [isForm, setIsForm] = useState(false);

  const [jumioIDVerificationStatus, setJumioIDVerificationStatus] = useState(false)
  const [emailVerificationStatus, setEmailVerificationStatus] = useState(true)
  const [fullLegalName, setFullLegalName] = useState("John Doe");
  const [phoneNumber, setPhoneNumber] = useState("+1234567890");
  const [countryOfResidence, setCountryOfResidence] = useState("United States");
  const [residentialAddress, setResidentialAddress] = useState("123 Main Street");

  const onChange = (event, cb) => {
    cb(event.target.value)
  }

  const uploadFile = async (event, cb) => {
    if (!event.target.files.length) { return; }
    let files = [...event.target.files];
    for (let i in files) {
      const file = files[i];
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
      if (ALL_VALID_FILE_TYPES.includes(fileExtention)) {
        let fileType = null;
        if (VALID_IMAGE_TYPES_KEY.includes(fileExtention)) {
          fileType = "image";
        }
        else if (VALID_FILE_TYPES_KEY.includes(fileExtention)) {
          fileType = "file";
        }
        await mainService.readFile(file, ALL_VALID_FILE_TYPES).then(uploadedFile => {
          cb({
            fileName,
            fileType,
            file,
            uploadedFile,
            id: uuid(),
            size: file.size
          })
        }).catch(error => error && AlertService.alert("error", "Invalid file format"));
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
  };

  const onSubmit = () => {
    setButtonSpinner(buttonSpinnerId);
    setTimeout(() => {
      AlertService.alert("success", "Data saved");
      setIsForm(false);
      extractButtonSpinner(buttonSpinnerId);
      scrollTop();
    }, 2000);
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const acceptOrReject = () => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      AlertService.alert("success", "Data saved");
      window.history.back();
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div>
          <h2 className='mb-2 font-size-22'>
            <GetCurrentTime />
          </h2>
          <h2 className='mb-3 font-size-18'>
            <GetCurrentDate />
          </h2>
        </div>
        <Row className='mt-4'>
          <Col lg={12}>
            <Card>
              <div className='m-3 p-4 border rounded'>

                <div className='d-flex justify-content-between flex-wrap'>
                  <h4>Paying Customer: James Jones (Client ID #00001)</h4>
                  <span>*Certified True Document</span>
                </div>

                <div className='ms-2 mt-4'>
                  <h5 className='bold mb-4'>Level One Requirements</h5>
                  <Row>
                    <Col md={6} className='pe-4'>

                      <Row className='align-items-center my-2'>
                        <Col sm={5}>
                          <label htmlFor='fullLegalName' className='me-5'>Full Legal Name:</label>
                        </Col>
                        <Col sm={7}>
                          {
                            isForm ?
                              <input
                                id='fullLegalName'
                                type="text"
                                value={fullLegalName}
                                name="fullLegalName"
                                className={`form-control`}
                                autoComplete='new-password'
                                onChange={(event) => onChange(event, setFullLegalName)}
                              />
                              : <span className='fw-500 font-size-15'> {fullLegalName} </span>
                          }

                        </Col>
                      </Row>

                      <Row className='align-items-center my-2'>
                        <Col sm={5}>
                          <label htmlFor='phoneNumber' className='me-5'>Phone Number:</label>
                        </Col>
                        <Col sm={7}>
                          {
                            isForm ?
                              <input
                                id='phoneNumber'
                                type="tel"
                                value={phoneNumber}
                                name="phoneNumber"
                                className={`form-control`}
                                autoComplete='new-password'
                                onChange={(event) => onChange(event, setPhoneNumber)}
                              />
                              : <span className='fw-500 font-size-15'> {phoneNumber} </span>
                          }

                        </Col>
                      </Row>

                      <Row className='align-items-center my-2'>
                        <Col sm={5}>
                          <label htmlFor='countryOfResidence' className='me-5'>Country of Residence:</label>
                        </Col>
                        <Col sm={7}>
                          {
                            isForm ?
                              <input
                                id='countryOfResidence'
                                type="text"
                                value={countryOfResidence}
                                name="countryOfResidence"
                                className={`form-control`}
                                autoComplete='new-password'
                                onChange={(event) => onChange(event, setCountryOfResidence)}
                              />
                              : <span className='fw-500 font-size-15'> {countryOfResidence} </span>
                          }

                        </Col>
                      </Row>

                      <Row className='align-items-center my-2'>
                        <Col sm={5}>
                          <label htmlFor='residentialAddress' className='me-5'>Residential Address:</label>
                        </Col>
                        <Col sm={7}>
                          {
                            isForm ?
                              <input
                                id='residentialAddress'
                                type="text"
                                value={residentialAddress}
                                name="residentialAddress"
                                className={`form-control`}
                                autoComplete='new-password'
                                onChange={(event) => onChange(event, setResidentialAddress)}
                              />
                              : <span className='fw-500 font-size-15'> {residentialAddress} </span>
                          }

                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div className='ms-2 mt-4'>
                  <h5 className='bold mb-4'>Level Two Requirements</h5>
                  <Row>
                    <Col md={6} className='pe-4'>


                      <Row className='align-items-center my-2 py-2'>
                        <Col sm={5}>
                          <label htmlFor='livenessTestStatus' className='me-5 d-flex align-items-center m-0 opacity-75'>Jumio ID Verification Status:</label>
                        </Col>
                        <Col sm={7}>
                          {
                            isForm ?
                              <div>
                                <div className='d-flex my-1'>
                                  <Input
                                    id="Verified"
                                    name="jumioIDVerificationStatus"
                                    className="custom-checkbox m-0 d-block"
                                    type="radio"
                                    checked={jumioIDVerificationStatus}
                                    onChange={() => setJumioIDVerificationStatus(true)}
                                  />
                                  <Label
                                    className="text-muted text-truncate ms-2 mb-0 font-size-14 cursor-pointer"
                                    htmlFor="Verified"
                                  >
                                    Verified
                                  </Label>
                                </div>
                                <div className='d-flex my-1'>
                                  <Input
                                    id="notVerified"
                                    name="jumioIDVerificationStatus"
                                    className="custom-checkbox m-0 d-block "
                                    type="radio"
                                    checked={!jumioIDVerificationStatus}
                                    onChange={() => setJumioIDVerificationStatus(false)}
                                  />
                                  <Label
                                    className="text-muted text-truncate ms-2 mb-0 font-size-14 cursor-pointer"
                                    htmlFor="notVerified"
                                  >
                                    Not Verified
                                  </Label>
                                </div>
                              </div>
                              : <span className='fw-500 font-size-15 d-block'> {jumioIDVerificationStatus ? "Verified" : "Not Verified"} </span>
                          }

                        </Col>
                      </Row>

                      <Row className='align-items-center my-2'>
                        <Col sm={5}>
                          <label htmlFor='residentialAddressDocumentation' className='me-5'>Residential Address Documentation*:</label>
                        </Col>
                        <Col sm={7}>
                          {
                            residentialAddressDocumentation ?
                              <div className='position-relative border rounded' style={{ width: "100px", height: "100px" }}>
                                {
                                  (() => {
                                    switch (residentialAddressDocumentation.fileType) {
                                      case "image":
                                        return <div style={{ width: "100%", height: "100%", }} className='d-flex justify-content-center align-items-center flex-column'>
                                          <img src={residentialAddressDocumentation.uploadedFile} className='rounded' style={{ width: "100%", height: "100%" }} />
                                        </div>
                                      case "file":
                                        return <div style={{ width: "100%", height: "100%", }} className='p-2  d-flex justify-content-center align-items-center flex-column'>
                                          <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                          <small className='max-line-2 mt-1 word-break-break-word'>{residentialAddressDocumentation.fileName}</small>
                                        </div>
                                      default:
                                        break;
                                    }
                                  })()
                                }
                                {
                                  isForm ?
                                    <i
                                      className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                      style={{ top: "1px", right: "1px" }}
                                      onClick={() => setResidentialAddressDocumentation(null)}
                                    />
                                    : null
                                }
                              </div>
                              : isForm ? <Button
                                onClick={MainService.triggerUploadClick}
                                outline
                                color="primary"
                                type='button'
                              >
                                <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile(event, setResidentialAddressDocumentation)} />
                                Attach file
                              </Button> : <span className='fw-500 font-size-15'>No attached file yet </span>
                          }
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className='pe-4'>

                      <Row className='align-items-center my-2 py-2'>
                        <Col sm={5}>
                          <label htmlFor='emailVerificationStatus' className='me-5 d-flex align-items-center m-0 opacity-75'>Email Verification Status:</label>
                        </Col>
                        <Col sm={7}>
                          {
                            isForm ?
                              <div>
                                <div className='d-flex my-1'>
                                  <Input
                                    id="VerifiedEmailVerificationStatus"
                                    name="emailVerificationStatus"
                                    className="custom-checkbox m-0 d-block"
                                    type="radio"
                                    checked={emailVerificationStatus}
                                    onChange={() => setEmailVerificationStatus(true)}
                                  />
                                  <Label
                                    className="text-muted text-truncate ms-2 mb-0 font-size-14 cursor-pointer"
                                    htmlFor="VerifiedEmailVerificationStatus"
                                  >
                                    Verified
                                  </Label>
                                </div>
                                <div className='d-flex my-1'>
                                  <Input
                                    id="notVerifiedEmailVerificationStatus"
                                    name="emailVerificationStatus"
                                    className="custom-checkbox m-0 d-block "
                                    type="radio"
                                    checked={!emailVerificationStatus}
                                    onChange={() => setEmailVerificationStatus(false)}
                                  />
                                  <Label
                                    className="text-muted text-truncate ms-2 mb-0 font-size-14 cursor-pointer"
                                    htmlFor="notVerifiedEmailVerificationStatus"
                                  >
                                    Not Verified
                                  </Label>
                                </div>
                              </div>
                              : <span className='fw-500 font-size-15 d-block'> {emailVerificationStatus ? "Verified" : "Not Verified"} </span>
                          }

                        </Col>
                      </Row>

                    </Col>


                  </Row>
                </div>

                <div className='mt-4 d-flex justify-content-end flex-wrap'>
                  <div className='d-flex mt-3'>
                    <Button color="success" className='ms-3 px-4' onClick={() => acceptOrReject()}>Accept</Button>
                    <Button color="danger" className='ms-3 px-4' onClick={() => acceptOrReject()}>Reject</Button>
                  </div>
                  <div className='d-flex mt-3'>
                    {
                      isForm ?
                        <ActionButtonWithOvalSpinner
                          type="button"
                          name="Submit"
                          className="btn btn-primary btn-block ms-3 px-4"
                          spinnerId={buttonSpinnerId}
                          onClick={onSubmit}
                        />
                        :
                        <Button color="primary" className='ms-3 px-4'
                          onClick={() => {
                            setIsForm(true);
                            scrollTop();
                          }}
                        >
                          Edit
                        </Button>
                    }
                    <Button color="primary" className='ms-3 px-4 ' onClick={() => window.history.back()}>Back</Button>
                  </div>
                </div>

              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default withTranslation()(ViewKYC)