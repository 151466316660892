import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "./../../Constants/MainKeys";
import { addButtonSpinner, removeButtonSpinner } from "../../store/spinner/actions";
import { useCallback } from "react";
import ActionButton from "../../components/Buttons/ActionButton";
import DashboardApiService from "../../Services/DashboardApiService";
import MainService from './../../Services/MainService';
import { withRouter } from 'react-router-dom';
import uuid from 'react-uuid';
import { loginUser } from "../../store/actions";

const buttonSpinnerId = uuid();
const RestorePassword = props => {

  const passwordMaxLength = 120;
  const dispatch = useDispatch();
  const query = new URLSearchParams(props.location.search);
  const key = query.get('key');
  const email = query.get('email');

  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const [mismatchPasswords, setMismatchPasswords] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidConfirmPassword, setIsInvalidConfirmPassword] = useState(false);

  useEffect(() => {
    checkKey(key);
  }, [key]);

  useEffect(() => {
    user && props.history.push(language);
  }, [user, language, props.history])

  const onChange = (event, field, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false);
    setMismatchPasswords(false);
  }

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);
    if (fieldName === "password") {
      changeIsinvalidFieldName(isValid, setIsInvalidPassword);
    }
    if (fieldName === "confirmPassword") {
      changeIsinvalidFieldName(isValid, setIsInvalidConfirmPassword);
    }
    if (fieldName === "password" || fieldName === "confirmPassword") {
      if (values.password.trim().length && values.confirmPassword.trim().length && (values.password !== values.confirmPassword)) {
        changeIsinvalidFieldName(false, setMismatchPasswords);
      } else {
        changeIsinvalidFieldName(true, setMismatchPasswords);
      }
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (
      email && email.trim().length &&
      values.password.trim().length &&
      values.confirmPassword.trim().length &&
      !isInvalidPassword &&
      !mismatchPasswords &&
      !isInvalidConfirmPassword
    ) {
      if (values.password !== values.confirmPassword) {
        setMismatchPasswords(true);
        return false;
      } else {
        const form = {
          key,
          password: btoa(values.password),
          confirmPassword: btoa(values.confirmPassword),
        }
        setButtonSpinner(buttonSpinnerId);
        DashboardApiService.restorePassword(form).then(response => {
          const data = {
            username: email.trim(),
            password: values.password.trim(),
          }
          extractButtonSpinner(buttonSpinnerId);
          dispatch(loginUser(data, buttonSpinnerId, props.history));
        }).catch(error => getFail(error, buttonSpinnerId))
      }
    }
  }

  const checkKey = (key) => {
    if (!key) { return false; }
    const spinnerId = uuid();
    DashboardApiService.checkKey(key).then(response => {
    }).catch(error => getFail(error, spinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Restore password | Cryllex</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Restore password</h5>
                        <p>Get your free Cryllex account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <form
                      className="form-horizontal"
                      onSubmit={onSubmit}
                    >
                      <div className="mb-3">
                        <label htmlFor='password'>Password</label>
                        <input
                          id='password'
                          name="password"
                          className={`form-control ${(isInvalidSubmit && !values.password.trim()) || mismatchPasswords || isInvalidPassword ? "error-border" : ""}`}
                          placeholder="Password"
                          type="password"
                          required
                          value={values.password}
                          onChange={event => onChange(event, "password", passwordMaxLength)}
                          onBlur={() => checkFieldsValidation(values.password, "password")}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor='confirmPassword'>Confirm password</label>
                        <input
                          id='confirmPassword'
                          name="confirmPassword"
                          className={`form-control ${(isInvalidSubmit && !values.confirmPassword.trim()) || mismatchPasswords || isInvalidConfirmPassword ? "error-border" : ""}`}
                          placeholder="Confirm password"
                          type="password"
                          required
                          value={values.confirmPassword}
                          onChange={event => onChange(event, "confirmPassword", passwordMaxLength)}
                          onBlur={() => checkFieldsValidation(values.confirmPassword, "confirmPassword")}
                        />
                      </div>
                      {
                        mismatchPasswords ?
                          <div><small className="text-danger">Password mismatch</small></div>
                          : null
                      }
                      {
                        isInvalidConfirmPassword || isInvalidPassword ?
                          <small className="text-danger">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small>
                          : null
                      }
                      <div className="mt-3 d-grid">
                        <ActionButton
                          type="submit"
                          name="Submit"
                          className="btn btn-primary btn-block mt-2"
                          spinnerId={buttonSpinnerId}
                        />
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Cryllex.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(RestorePassword);
