import React, { useState, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import MessageRingtone from "./assets/audio/iphone_msg_sent.mp3";
import { setGlobalConnection, setIsShowNotification, setNotification, setNotifications } from "./store/signalR/actions";
import { ADMIN_USER_TYPE_ID, MERCHANT_USER_TYPE_ID } from "./Constants/MainKeys";

const NOTIFY_CONNECT_KEY = "Connection";

const Listeners = props => {

	const DASHBOARD_API_URL_KEY = process.env.REACT_APP_SOCKET_URL_KEY || "";
	const token = localStorage.getItem("token");
	const dispatch = useDispatch();
	const { user } = useSelector(state => state.Login);
	const [localConnection, setLocalConnection] = useState(null);
	const { globalConnection } = useSelector(state => state.SignalR);

	useEffect(() => {
		if (user) {
			setTimeout(() => connectToSignalR(), 3000);
		}
	}, [user]);

	useEffect(() => {
		if (localConnection) {
			localConnection.on("receiveNotifications", arr => {
				if (!arr || (arr && !arr.length)) { return false; }
				dispatch(setIsShowNotification(true));
				dispatch(setNotifications(arr));
				audioStart();
			});

			localConnection.on("receiveNotification", obj => {
				console.log(obj);
				if (!obj) { return false; }
				dispatch(setIsShowNotification(true));
				dispatch(setNotification(obj));
				audioStart();
			});

			localConnection.on("disconnected", obj => {
				console.log(obj + "++++++++++++++++++++++++++++++++");

			});
		}
	}, [localConnection]);

	useEffect(() => {
		window.addEventListener("beforeunload", disconnect);
		return () => {
			window.removeEventListener("beforeunload", disconnect);
		};
	}, []);

	const disconnect = () => {
		globalConnection && globalConnection.stop();
	}

	const connectToSignalR = () => {
		if (localConnection && localConnection.state === "Connected") {
			return false;
		}
		console.log(user);

		const connection = new signalR.HubConnectionBuilder()
			.withUrl(`${DASHBOARD_API_URL_KEY}/connect?userId=${user.id}&type=${user.userTypeId === ADMIN_USER_TYPE_ID ? 1 : user.userTypeId === MERCHANT_USER_TYPE_ID ? 2 : 3}`, {
				transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling,
				withCredentials: true
			})
			.withAutomaticReconnect()
			.configureLogging(signalR.LogLevel.None)
			.build();

		connection.serverTimeoutInMilliseconds = 1000 * 60 * 10;
		connection
			.start()
			.then(() => {
				// connection.invoke(NOTIFY_CONNECT_KEY, user.id, 0).catch(err => {
				// 	console.log(err);
				// });
				if (connection) {
					setLocalConnection(connection);
					dispatch(setGlobalConnection(connection));
				}
			}).catch(error => {
				console.log(error);
			});

		connection.onclose(() => {
			reconnection(connection);
		});
	};

	const reconnection = connection => {
		if (
			connection &&
			connection.state !== "Connected" &&
			localStorage.getItem("user")
		) {
			connection
				.start()
				.then(() => {
					connection.invoke(NOTIFY_CONNECT_KEY, user.id).catch(err => {
						console.log(err);
					});
				})
				.catch(error => {
					console.log(error);
					setTimeout(() => {
						reconnection(connection);
					}, 3000);
				});
		}
	};

	const audioStart = () => {
		var audioElement = document.getElementById("audio");
		audioElement.src = MessageRingtone;
		audioElement.autoplay = true;
	};

	return <audio id="audio" className="d-none" />;
};

export default Listeners;



