import React, { useState, useEffect, useRef } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import DashboardApiService from '../../../Services/DashboardApiService'
import { useCallback } from 'react'
import { addButtonSpinner, removeButtonSpinner, removeRightSectionSpinner, addRightSectionSpinner } from '../../../store/actions'
import { AGENT_USER_TYPE_ID, ERROR_KEY, MERCHANT_USER_TYPE_ID, SPINNER_COLOR, SUCCESS_KEY, allValidFileTypes } from '../../../Constants/MainKeys'
import AlertService from '../../../Services/alertService'
import uuid from 'react-uuid'
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  UncontrolledAlert,
} from "reactstrap"
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import ActionButton from '../../../components/Buttons/ActionButton'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import MainService from '../../../Services/MainService'
import { PuffLoader } from 'react-spinners'
import PhoneInput from 'react-phone-input-2'
import FileComponent from '../Merchant/Components/FileComponent'
import ApiService from '../../../Services/ApiService'

const buttonSpinnerId = uuid();

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}


const UserForm = (props) => {

  const { userId } = props.match.params;
  const mainService = new MainService();
  const title = "Users"
  const description = "";
  const pageSize = 10;
  const dispatch = useDispatch();
  const { buttonSpinners } = useSelector(state => state.Spinners);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language, languages } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);
  const [types, setTypes] = useState([])
  const [countries, setCountries] = useState([])
  const [zoomImagePath, setZoomImagePath] = useState(null)
  const [isShowImageLoader, setIsShowImageLoader] = useState(false)
  const wrapperRef = useRef(null);
  const [isChanged, setIsChanged] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  useOutsideAlerter(wrapperRef, setZoomImagePath);


  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    email: "",
    countryId: null,
    passportId: null,
    city: "",
    nationality: "",
    phoneNumber: "",
    address: "",
    percentage: "",
    type: null,
    passportProofFile: null,
    addressProofFile: null,
  })

  useEffect(() => {
    if (userId) {
      getUserById()
    }
  }, [userId])

  useEffect(() => {
    getUserTypes();
    getCountries();
  }, [])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setZoomImagePath(null)
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const onSelectOptionChange = (item, name) => {
    if (!item) { return false; }
    setValues((values) => ({
      ...values,
      [name]: item.value,
    }));
    setIsChanged(true);
    setIsInvalidSubmit(false);
  }

  const getUserById = async () => {
    if (!userId || !user) { return false; }
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    (user.userTypeId === MERCHANT_USER_TYPE_ID ?
      DashboardApiService.getUserByIdAsMerchant(userId) :
      user.userTypeId === AGENT_USER_TYPE_ID ?
        DashboardApiService.getUserByIdAsAgent(userId) :
        DashboardApiService.getUserByIdAsAdmin(userId)).then(response => {
          if (response && response.data) {
            let data = { ...response.data };
            setValues(data)
          }
        }).catch(error => getFail(error)).finally(() => {
          extractRightSectionSpinner(spinnerId)
        })
  }

  const getUserTypes = () => {
    if (!user) { return false; }
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    (user.userTypeId === MERCHANT_USER_TYPE_ID ?
      DashboardApiService.getUserTypesAsMerchant() :
      user.userTypeId === AGENT_USER_TYPE_ID ?
        DashboardApiService.getUserTypesAsAgent() :
        DashboardApiService.getUserTypesAsAdmin()
    ).then(response => {
      if (response && response.data) {
        setTypes(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const getCountries = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const onChange = (event, fieldName, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [fieldName]: event.target.value,
    }));
    setIsInvalidSubmit(false);
  }

  const onPhoneNumberChange = (event, fieldName) => {
    setValues((values) => ({
      ...values,
      [fieldName]: event,
    }));
  }

  const onNumberChange = (event, field, maxLength = Infinity) => {
    if (event.target.value.includes("e")) { return false };
    if (+event.target.value >= 20) {
      setValues((values) => ({
        ...values,
        type: 2
      }));
    }
    if (event.target.value == 0) {
      setValues((values) => ({
        ...values,
        [field]: "",
      }));
      return false;
    };
    if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value) >= 0 && Number(event.target.value) <= maxLength)) {
      setValues((values) => ({
        ...values,
        [field]: event.target.value,
      }));
    }
  };

  const deleteDocument = (data) => {
    const { fieldName, fileLibraryId } = data;
    if (!fileLibraryId) { return false; }
    AlertService.alertConfirm(
      `Are you sure you want to delete current file ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setIsShowImageLoader(true);
      (user.userTypeId === MERCHANT_USER_TYPE_ID ?
        DashboardApiService.deleteSignificantControllerDocumentAsMerchant(fileLibraryId, userId, getFileType(fieldName)) :
        user.userTypeId === AGENT_USER_TYPE_ID ?
          DashboardApiService.deleteSignificantControllerDocumentAsAgent(fileLibraryId, userId, getFileType(fieldName)) :
          DashboardApiService.deleteSignificantControllerDocumentAsAdmin(fileLibraryId, userId, getFileType(fieldName))
      ).then(() => {
        setValues((values) => ({
          ...values,
          [fieldName]: null,
        }))
        AlertService.alert(SUCCESS_KEY, "File deleted successfully")
      }).catch(error => getFail(error)).finally(() => {
        setIsShowImageLoader(false);
      })
    })
  }

  const getFileType = (fileType) => {
    if (!fileType) { return 0; }
    switch (fileType) {
      case "passportProofFile":
        return 1;
      case "addressProofFile":
        return 2;
      default:
        break;
    }
  }

  const uploadFile = async (data) => {
    const { event, fieldName } = data;
    if (!event.target.files.length || !userId) { return false; }
    const formData = new FormData();

    let files = [...event.target.files];
    for (let i in files) {
      const file = files[i];
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';

      if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
        mainService.readFile(file, allValidFileTypes).then(uploadedFile => {
          setIsShowImageLoader(true);
          formData.append("id", values.id);
          formData.append("document", file);
          formData.append("type", getFileType(fieldName));
          (user.userTypeId === MERCHANT_USER_TYPE_ID ?
            DashboardApiService.uploadSignificantControllerDocumentAsMerchant(formData) :
            user.userTypeId === AGENT_USER_TYPE_ID ?
              DashboardApiService.uploadSignificantControllerDocumentAsAgent(formData) :
              DashboardApiService.uploadSignificantControllerDocumentAsAdmin(formData)
          ).then(response => {
            setValues((values) => ({
              ...values,
              [fieldName]: response.data,
            }))
            AlertService.alert(SUCCESS_KEY, "Data saved")
          }).catch(error => getFail(error)).finally(() => {
            setIsShowImageLoader(false);
          })

        }).catch(error => {
          error && AlertService.alert("error", "Invalid file format")
        });
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
  };

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (buttonSpinners.length) {
      return false;
    }
    if (!values.percentage || !values.countryId || !values.type && !values.firstname.trim().length || !values.lastname.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    }
    setButtonSpinner(buttonSpinnerId);
    const form = { ...values };
    Object.entries(form).forEach(([key, value]) => {
      if (key === "type" || key === "percentage" || key === "organizationId") {
        value = +value;
      }
    })
    if (userId) {
      form.id = +userId
    } else {
      delete form.id;
    }
    (
      user.userTypeId === MERCHANT_USER_TYPE_ID && userId ?
        DashboardApiService.updateUserAsMerchant(form) :
        user.userTypeId === MERCHANT_USER_TYPE_ID && !userId ?
          DashboardApiService.createUserAsMerchant(form) :
          user.userTypeId === AGENT_USER_TYPE_ID && userId ?
            DashboardApiService.updateUserAsAgent(form) :
            user.userTypeId === AGENT_USER_TYPE_ID && !userId ?
              DashboardApiService.createUserAsAgent(form) :
              DashboardApiService.updateUserAsAdmin(form)
    ).then(response => {
      if (user.userTypeId === MERCHANT_USER_TYPE_ID) {
        if (response && response.data) {
          const data = { ...response.data }
          if (!userId) {
            props.history.push(`/${language}/significant-controllers-form/${data.id}`)
          }
          setValues(data);
        }
        if (userId) {
          props.history.push(`/${language}/significant-controllers`)
        }
      } else {
        window.history.back();
      }
      AlertService.alert(SUCCESS_KEY, "Data saved")
    }).catch(error => getFail(error)).finally(() => {
      extractButtonSpinner(buttonSpinnerId)
    })

  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }


  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>

      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">

          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Significant controller"
            isShowGoBackButton={true}
            goBack={() => props.history.push(`/${language}/significant-controllers`)}
          />
          <Card>
            <CardBody>
              <form onSubmit={onSubmit} onChange={() => setIsChanged(true)}>
                <Row className='mb-3'>
                  <Col md={4} className='mb-3'>
                    <Label htmlFor='firstname' className='mb-1 cursor-pointer'>First Name*</Label>
                    <Input
                      id="firstname"
                      type="text"
                      placeholder='e.g. John'
                      className={`form-control ${isInvalidSubmit && !values.firstname ? " error-border" : ""}`}
                      value={values.firstname}
                      onChange={(event) => onChange(event, "firstname", 120)}
                    />
                  </Col>

                  <Col md={4} className='mb-3'>
                    <Label htmlFor='lastname' className='mb-1 cursor-pointer'>Last Name*</Label>
                    <Input
                      id="lastname"
                      type="text"
                      placeholder='e.g. Smith'
                      className={`form-control ${isInvalidSubmit && !values.lastname ? " error-border" : ""}`}
                      value={values.lastname}
                      onChange={(event) => onChange(event, "lastname", 120)}
                    />
                  </Col>

                  <Col md={4} className='mb-3'>
                    <Label htmlFor='middlename' className='mb-1 cursor-pointer'>Middle Name</Label>
                    <Input
                      id="middlename"
                      type="text"
                      placeholder='e.g. Alexander'
                      className="form-control"
                      value={values.middlename}
                      onChange={(event) => onChange(event, "middlename", 120)}
                    />
                  </Col>

                  <Col md={4} className='mb-3'>
                    <Label htmlFor='email' className='mb-1 cursor-pointer'>Email Address</Label>
                    <Input
                      id="email"
                      type="email"
                      placeholder='e.g. johnasmith@gmail.com'
                      className="form-control"
                      value={values.email}
                      onChange={(event) => onChange(event, "email", 120)}
                    />
                  </Col>
                  <Col md={4} className='mb-3'>
                    <Label htmlFor='passportId' className='mb-1 cursor-pointer'>Passport/ID Number</Label>
                    <Input
                      id="passportId"
                      type="text"
                      placeholder='e.g. M1234457A'
                      className="form-control"
                      value={values.passportId}
                      onChange={(event) => onChange(event, "passportId", 120)}
                    />
                  </Col>
                  <Col md={4} className='mb-3'>
                    <Label htmlFor='nationality' className='mb-1 cursor-pointer'>Nationality</Label>
                    <Input
                      id="nationality"
                      type="text"
                      placeholder='e.g. Singapore'
                      className="form-control"
                      value={values.nationality}
                      onChange={(event) => onChange(event, "nationality", 120)}
                    />
                  </Col>

                  <Col md={4} className='mb-3'>
                    <Label htmlFor='percentage' className='mb-1 cursor-pointer'>Shareholding Percentage*</Label>
                    <Input
                      id="percentage"
                      type="number"
                      placeholder='e.g. 20'
                      className={`form-control ${isInvalidSubmit && !values.percentage ? "error-border" : ""}`}
                      value={values.percentage}
                      onChange={(event) => onNumberChange(event, "percentage", 100)}
                    />
                  </Col>

                  <Col md={4} className='mb-3'>
                    <Label htmlFor='phoneNumber' className='mb-1 cursor-pointer'>Phone Number</Label>
                    <PhoneInput
                      country={""}
                      specialLabel=""
                      placeholder='+1 555 555 1223'
                      value={values.phoneNumber || ""}
                      className={`custom-phone-number-input-block`}
                      onChange={(event) => onPhoneNumberChange(event, "phoneNumber")}
                    />
                  </Col>

                  <Col md={4} className='mb-3'>
                    <Label htmlFor='address' className='mb-1 cursor-pointer'>Residential Address</Label>
                    <Input
                      id="address"
                      type="text"
                      placeholder='e.g. 24 Lorong Pisang Raja, Singapore, Singapore 597756'
                      className="form-control"
                      value={values.address}
                      onChange={(event) => onChange(event, "address", 120)}
                    />
                  </Col>

                  <Col md={4} className='mb-3'>
                    <Label htmlFor='address' className='mb-1 cursor-pointer'>Country*</Label>
                    {
                      countries && countries.length ?
                        <div>
                          <ReactSelectOption
                            value={values.countryId}
                            isSearchable={true}
                            menuPlacement="top"
                            className={`w-100 ${isInvalidSubmit && !values.countryId ? "error-border" : ""}`}
                            selectedValue={(() => {
                              const selectedItem = { ...countries.find(data => data.id === values.countryId) };
                              if (Object.keys(selectedItem).length !== 0) {
                                selectedItem.label = selectedItem.name;
                                selectedItem.value = selectedItem.id;
                                return selectedItem;
                              } else {
                                return { label: "Choose...", value: null }
                              }
                            })()}
                            items={countries.map(data => ({ ...data, label: data.name, value: data.id }))}
                            onChange={(item) => onSelectOptionChange(item, "countryId")}
                          />
                        </div>
                        : null
                    }
                  </Col>

                  <Col md={4} className='mb-3'>
                    <Label htmlFor='city' className='mb-1 cursor-pointer'>City</Label>
                    <Input
                      id="city"
                      type="text"
                      placeholder='e.g. Singapore'
                      className="form-control"
                      value={values.city}
                      onChange={(event) => onChange(event, "city", 120)}
                    />
                  </Col>

                  <Col md={4} className='mb-3'>
                    <Label className='mb-1'>Type*</Label>
                    {
                      types && types.length ?
                        <ReactSelectOption
                          value={values.type}
                          className={`w-100 ${isInvalidSubmit && !values.type ? "error-border" : ""}`}
                          selectedValue={(() => {
                            const selectedItem = { ...types.find(data => +data.key === +values.type) };
                            if (Object.keys(selectedItem).length) {
                              selectedItem.label = selectedItem.value;
                              selectedItem.value = selectedItem.key;
                              return selectedItem;
                            } else {
                              return { label: "Choose...", value: null }
                            }
                          })()}
                          items={(values.percentage && values.percentage >= 20 ? types.filter(el => el.key !== 1) : types).map(data => ({ ...data, label: data.value, value: data.key }))}
                          onChange={(item) => onSelectOptionChange(item, "type")}
                        />
                        : null
                    }
                  </Col>
                  {
                    userId ? <Col md={12}> <hr /></Col> : null
                  }

                  <Col sm={12} className='mt-3'>
                    {
                      isShowImageLoader ?
                        <Row>
                          <Col>
                            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "150px", }}>
                              <PuffLoader size={60} color={SPINNER_COLOR} />
                            </div>
                          </Col>
                        </Row>

                        : userId ?
                          <Row className='align-items-stretch'>
                            <Col md={6} className='mb-3'>
                              <FileComponent
                                data={values}
                                fieldName="addressProofFile"
                                labelValue="Proof of Address"
                                setZoomImagePath={() => setZoomImagePath(values.addressProofFile.filePath)}
                                uploadFile={uploadFile}
                                deleteDocument={deleteDocument}
                              />
                            </Col>
                            <Col md={6} className='mb-3'>
                              <FileComponent
                                data={values}
                                fieldName="passportProofFile"
                                labelValue="Passport/National ID"
                                setZoomImagePath={() => setZoomImagePath(values.passportProofFile.filePath)}
                                uploadFile={uploadFile}
                                deleteDocument={deleteDocument}
                              />
                            </Col>
                            <Col sm={12}>
                              <small className='fw-500 d-block mb-2'>Allowed file types {allValidFileTypes.map((item, index) => <i key={index}>{item}. </i>)}</small>
                              <UncontrolledAlert color="warning" role="alert">
                                <i className="mdi mdi-alert-outline me-2"></i>
                                All documents require Certified true copies, alternatively, the documents can be visually presented through Cryllex customer support
                              </UncontrolledAlert>
                            </Col>
                          </Row>
                          : null
                    }
                  </Col>
                </Row>
                <hr />
                <div className='d-flex justify-content-end'>
                  <Button
                    color="primary"
                    className='me-2 px-4'
                    outline
                    onClick={() => {
                      window.history.back()
                    }}
                  >
                    Cancel
                  </Button>
                  <ActionButton
                    type="submit"
                    name="Submit"
                    className="shadow-sm px-4 mt-21 btn btn-primary"
                    spinnerId={buttonSpinnerId}
                    disabled={!isChanged}
                  />
                </div>

              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default withRouter(UserForm)