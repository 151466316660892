import React, { useState, useEffect } from 'react'

const CountdownTimer = ({ initialSeconds = 30, _setRemainingSeconds }) => {

  const [remainingSeconds, setRemainingSeconds] = useState(initialSeconds);
  const [isRed, setIsRed] = useState(false);

  useEffect(() => {
    if (remainingSeconds <= 5) {
      setIsRed(true);
    } else {
      setIsRed(false);
    }
  }, [remainingSeconds])

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const formattedTime = formatTime(remainingSeconds);

  useEffect(() => {
    if (remainingSeconds > 0) {
      const timer = setInterval(() => {
        setRemainingSeconds((prevSeconds) => {
          if (prevSeconds <= 0) {
            clearInterval(timer);
            return 0;
          }
          return prevSeconds - 1;
        });
        _setRemainingSeconds((prevSeconds) => {
          if (prevSeconds <= 0) {
            clearInterval(timer);
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup the timer on unmount
    }
  }, [remainingSeconds]);

  console.log(remainingSeconds, "remainingSeconds");


  return remainingSeconds ?
    <div className='d-flex align-items-center'>
      <i className='bx bx-timer me-1 mb-1' style={{ color: isRed ? "#DC3545" : "#000000", fontSize: "20px" }}></i>
      <span
        style={{ fontWeight: "400", fontSize: "14px" }}
        className={`${isRed ? "text-danger" : ""}`}>
        <b>{formattedTime}</b>
      </span>
    </div> : null

}

export default CountdownTimer;
