import axios from 'axios';
import { DEFAULT_LANGUAGE_KEY, LANGUAGE_KEY, OBJECT_KEY, REFRESH_TOKEN_KEY, TOKEN_KEY, USER_KEY } from '../Constants/MainKeys';
import AlertService from './alertService';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL_KEY || "",
  withCredentials: true,
});

const identityApi = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_API_URL_KEY || "",
});

api.interceptors.request.use(async config => {
  config.headers = {
    'Authorization': localStorage.getItem(TOKEN_KEY) ? localStorage.getItem(TOKEN_KEY) : "",
    'language': localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY,
    'ngrok-skip-browser-warning': 'true',
  };
  return config;
});

api.interceptors.response.use(response => {
  // if (localStorage.getItem("refreshCount")) {
  //   localStorage.removeItem("refreshCount");
  // }
  if (response.data) return response.data;
}, error => {
  if (error.response) {
    if (error.response.status === 401) {
      // return Promise.reject("401");
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
      const user = localStorage.getItem(USER_KEY);
      const token = localStorage.getItem(TOKEN_KEY);
      if (!refreshToken || !user || !token) {
        logout();
      } else {
        getUserDataByRefreshToken(refreshToken, user);
        return false;
      }
    } else if (error.response.status === 404) { // Status Code: 404
      return Promise.reject("404 Not Found");
    } else if (error.response.status === 500) { // Status Code: 500
      return Promise.reject("500 Internal Server Error");
    }
    if (typeof error.response.data === OBJECT_KEY) {
      const currentError = { ...error.response.data };
      const errors = currentError.errors?.Error;
      let errorMessages = [];
      if (errors && errors.length > 0) {
        errors.forEach((error) => {
          const errorParams = error.params;
          const paramKeys = Object.keys(errorParams);
          paramKeys.forEach((key) => {
            const value = errorParams[key];
            if (typeof value === 'string' && value.trim().length > 0) {
              errorMessages.push(value);
            }
          });
        });
      } else {
        return Promise.reject("Unrecognized error");
      }
      if (errorMessages.length) {
        showError(errorMessages);
        return Promise.reject();
      } else {
        return Promise.reject("Unrecognized error");
      }
    } else {
      return Promise.reject("Unrecognized error");
    }

  } else {
    return Promise.reject("Unrecognized error");
  }
});

const showError = (errorMessages) => {
  const filteredStrings = errorMessages.filter(item => isNaN(item));
  filteredStrings.forEach(error => {
    AlertService.alert("error", error)
  });
}

const logout = () => {
  window.location.href = `/${localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY}`;
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
}

const getUserDataByRefreshToken = (refreshToken, user) => {
  const formData = new FormData();
  formData.append(`refreshToken`, refreshToken);
  ApiService.getUserDataByRefreshToken(formData).then(response => {
    if (response.data && response.data.accessToken) {
      const data = { ...response.data };
      localStorage.setItem(TOKEN_KEY, data?.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, data?.refreshToken);
      localStorage.setItem(USER_KEY, user);

      if (localStorage.getItem("refreshCount")) {
        let refreshCount = +localStorage.getItem("refreshCount");
        if (+refreshCount >= 2) {
          logout();
        }
        localStorage.setItem("refreshCount", refreshCount + 1 || 1)
      } else {
        localStorage.setItem("refreshCount", 1);
      }
      window.location.reload();

    } else {
      logout();
    }
  }).catch(error => getFail(error));
}

const getFail = (error) => {
  if (error) logout();
}

class ApiService {

  // RefreshToken
  static getUserDataByRefreshToken(data) {
    return identityApi.post(`/identity/renewToken`, data)
  };

  static getAllTranslations() {
    return api.get(`/translation/GetByLanguageIsoCode`);
  };

  static getLanguages() {
    return api.get(`/language/getall`);
  };

  static getPagesAsAdmin() {
    return api.get(`/page/getAllAdmin`);
  };

  static getPageById(id) {
    return api.get(`/page/getAdmin/${id}`);
  };

  static createPage(data) {
    return api.post(`/page/create`, data);
  };

  static updatePage(data) {
    return api.put(`/page/update`, data);
  };

  static deletePage(pageId) {
    return api.delete(`/page/delete/${pageId}`);
  };

  static getPageContentById(contentId) {
    return api.get(`/page/GetContent/${contentId}`);
  };

  static createPageContent(data) {
    return api.post(`/page/createcontent`, data);
  };

  static updatePageContent(data) {
    return api.put(`/page/updatecontent`, data);
  };

  static getPageContents(pageId) {
    return api.get(`/page/GetContentsByPage/${pageId}`);
  };

  static deleteContent(contentId) {
    return api.delete(`/page/deletecontent/${contentId}`);
  };

  static onChangePageContentOrder(dropContentId, dragSectionId, languageId) {
    return api.put(`/page/ChangeContentOrder/${dropContentId}/${dragSectionId}/${languageId}`);
  };

  static sendError(data) {
    return api.post(`/error/create`, data);
  };

  static organizationUpdate(formData) {
    return api.put(`/organization/update`, formData);
  };

  static getReports() {
    return api.get(`/???`);
  };

  static getPaymentMethodsByCountryId(countryId) {
    return api.get(`/CountryPaymentMothodCurrency/GetPMethodByCountry/${countryId}`);
  };

  static getHolderTypesByCountry(countryId, paymentMethodId) {
    return api.get(`/CountryPaymentMothodCurrency/getHolderTypesByCountry?countryId=${countryId}&paymentMethodId=${paymentMethodId}`);
  };

  static getFiatCurrenciesByCountryIdAndPaymentMethodId(countryId, paymentMethodId) {
    return api.get(`/CountryPaymentMothodCurrency/GetByCountryAndPMethod?countryId=${countryId}&paymentMethodId=${paymentMethodId}`);
  };

  static getCountries() {
    return api.get(`/country/GetBaseModels`);
  };

  static generateKeys(data) {
    return api.post(`/organizationToken/create`, data);
  };

  static getAllKeys() {
    return api.get(`/organizationToken/getAllByOrganization`);
  };

  static checkPassword(password, publicKey) {
    return api.post(`/organizationToken/getKey?password=${password}&publicKey=${publicKey}`);
  };



}

export default ApiService;
