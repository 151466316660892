import MetaTags from 'react-meta-tags';
import React, { useState } from "react"
import { Row, Col, Card, CardBody, Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { addButtonSpinner, removeButtonSpinner } from "../../store/actions"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import uuid from 'react-uuid';
import ActionButton from '../../components/Buttons/ActionButton';
import AlertService from '../../Services/alertService';
import { ERROR_KEY, SUCCESS_KEY } from '../../Constants/MainKeys';
import { useCallback } from 'react';
import DashboardApiService from '../../Services/DashboardApiService';

const buttonSpinnerId = uuid();

const ForgetPasswordPage = props => {

  const emailMaxLength = 120;
  const dispatch = useDispatch();
  const { language } = useSelector(state => state.Languages);
  const [email, setEmail] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);

  function onSubmit(event) {
    event && event.preventDefault();
    if (!email.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    }
    setButtonSpinner(buttonSpinnerId);
    DashboardApiService.forgotPassword(email).then(() => {
      AlertService.alert(SUCCESS_KEY, "Your request has been sent")
      setEmail("");
      extractButtonSpinner(buttonSpinnerId);
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value)
    setIsInvalidSubmit(false);
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forgot Password | Cryllex</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-white p-4">
                        <h5 className="text-white">Welcome Back !</h5>
                        <p>Sign in to continue to Cryllex.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <form
                      className="form-horizontal"
                      onSubmit={onSubmit}
                    >
                      <div className="mb-3">
                        <label htmlFor='email'>Enter email</label>
                        <input
                          id='email'
                          name="email"
                          className={`form-control ${isInvalidSubmit && !email.trim() ? "error-border" : ""}`}
                          placeholder="Enter email"
                          type="email"
                          value={email}
                          required
                          onChange={event => onChange(event, setEmail, emailMaxLength)}
                        />
                      </div>
                      <div className="mt-3 d-grid">
                        <ActionButton
                          type="submit"
                          name={`Reset`}
                          className="shadow-sm ms-auto px-4 mt-3 btn btn-primary w-100"
                          spinnerId={buttonSpinnerId}
                        />
                      </div>

                      <div className="mt-4 text-center">
                        <Link to={`/${language}/login`} className="text-muted">
                          <i className="mdi mdi-login me-1" />
                          Log In
                        </Link>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Go back to{" "}
                  <Link to={`/${language}/login`} className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} Cryllex.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgetPasswordPage)
