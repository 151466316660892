import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import NoData from "../../NoData/NoData"
import { setIsShowNotification, setNotificationIsNotified } from "../../../store/signalR/actions"
import moment from "moment"
import MainService from "../../../Services/MainService"
import DashboardApiService from "../../../Services/DashboardApiService"
import AlertService from "../../../Services/alertService"
import { ERROR_KEY } from "../../../Constants/MainKeys"
import parse from 'html-react-parser'

const NotificationDropdown = props => {
  const dispatch = useDispatch();
  const { language } = useSelector(state => state.Languages);
  const [menu, setMenu] = useState(false);
  const { isShowNotification, notifications } = useSelector(state => state.SignalR);
  const [isDisabledFields, setIsDisabledFields] = useState(false);

  const updateNotificationStatus = (event, itemId) => {
    event.preventDefault();
    event.stopPropagation();
    if (!itemId) {
      return false;
    }
    setIsDisabledFields(true);
    DashboardApiService.updateNotificationStatus(itemId).then(() => {
      dispatch(setNotificationIsNotified(itemId))
    }).catch(error => {
      getFail(error);
    }).finally(() => {
      setIsDisabledFields(false);
    })

  }

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => {
          setMenu(!menu);
          dispatch(setIsShowNotification(false));
        }}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={`bx bx-bell ${isShowNotification && notifications && notifications.find(item => !item.isNotified) ? "bx-tada" : ""}`} />
          {
            isShowNotification && notifications && notifications.find(item => !item.isNotified) ?
              <span className="badge bg-danger rounded-pill">{notifications.filter(item => !item.isNotified).length}</span>
              : null
          }
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">Notifications</h6>
              </Col>
            </Row>
          </div>
          {
            notifications && notifications.length ?
              <div
                style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                <hr className="my-0" />
                {
                  notifications.map((item, index) => {
                    return <Link
                      key={index}
                      to={item.link ? `/${language}/${item.link}`.replaceAll('\\', '/') : "#"}
                      className={`text-reset notification-item ${isDisabledFields ? "disabled" : ""}`}
                    >
                      <div className="media">
                        <div className="avatar-xs me-3">
                          {
                            item.icon ? parse(item.icon)
                              : <span className="avatar-title bg-danger rounded-circle font-size-16" style={{ width: "2rem", height: "2rem" }}><i className="bx bx-file-blank"></i></span>
                          }
                        </div>
                        <div className="media-body" style={{ opacity: item.isNotified ? 0.6 : 1 }}>
                          <h6 className="mt-0 mb-1">{item.title}</h6>
                          <div className="font-size-12 text-muted">
                            <p className="mb-1 max-line-2" title={item.text}>{parse(item.text)}</p>
                            <p className="mb-0 d-flex justify-content-between">
                              <span className="mt-1">
                                <i className="mdi mdi-clock-outline"></i>&nbsp;
                                {moment(MainService.convertUTCDateToLocalDate(new Date(item.createTime))).format("lll")}
                              </span>
                              <span
                                className="cursor-pointer "
                                onClick={(event) => {
                                  updateNotificationStatus(event, item.id)
                                }}>
                                <i className={`mdi mdi-circle text-success d-${item.isNotified ? "none" : "block"}`} style={{ fontSize: "16px" }}></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  })
                }
              </div>
              : <div className="pb-3">
                <hr className="mt-0" />
                <NoData width={100} height={100} />
              </div>
          }

          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to={`/${language}/notifications`}
              onClick={() => setMenu(false)}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              View all
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)
