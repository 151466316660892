import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import uuid from "react-uuid";
import AlertService from "../../Services/alertService";
import { ERROR_KEY, SUCCESS_KEY } from "./../../Constants/MainKeys";
import { addButtonSpinner, removeButtonSpinner } from "./../../store/spinner/actions";
import { useCallback } from "react";
import ActionButton from "../../components/Buttons/ActionButton";
import DashboardApiService from "../../Services/DashboardApiService";

const ChangePassword = props => {

  const spinnerId = uuid();
  const passwordMaxLength = 120;
  const dispatch = useDispatch();

  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [values, setValues] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const onChange = (event, field, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    setButtonSpinner(spinnerId);
    const form = {
      oldPassword: values.oldPassword,
      password: btoa(values.password),
      confirmPassword: btoa(values.confirmPassword),
    }
    DashboardApiService.changeOldPassword(form).then(() => {
      AlertService.alert(SUCCESS_KEY, "Your password successfully changed");
      setValues((values) => ({
        ...values,
        oldPassword: "",
        password: "",
        confirmPassword: "",
      }));
      extractButtonSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Change password | Cryllex</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Change password</h5>
                        <p>Get your free Cryllex account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt="/"
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <form
                      className="form-horizontal"
                      onSubmit={onSubmit}
                    >
                      <div className="mb-3">
                        <label htmlFor='password'>Old password *</label>
                        <input
                          id='oldPassword'
                          name="oldPassword"
                          className={`form-control ${isInvalidSubmit && !values.oldPassword.trim() ? "error-border" : ""}`}
                          placeholder="Old password"
                          type="password"
                          required
                          value={values.oldPassword}
                          onChange={event => onChange(event, "oldPassword", passwordMaxLength)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor='password'>New password *</label>
                        <input
                          id='password'
                          name="password"
                          className={`form-control ${isInvalidSubmit && !values.password.trim() ? "error-border" : ""}`}
                          placeholder="Password"
                          type="password"
                          required
                          value={values.password}
                          onChange={event => onChange(event, "password", passwordMaxLength)}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor='password'>Confirm password *</label>
                        <input
                          id='confirmPassword'
                          name="confirmPassword"
                          className={`form-control ${isInvalidSubmit && !values.confirmPassword.trim() ? "error-border" : ""}`}
                          placeholder="Confirm password"
                          type="password"
                          required
                          value={values.confirmPassword}
                          onChange={event => onChange(event, "confirmPassword", passwordMaxLength)}
                        />
                      </div>
                      <div className="mt-3 d-grid">
                        <ActionButton
                          type="submit"
                          name="Submit"
                          className="btn btn-primary btn-block"
                          spinnerId={spinnerId}
                        />
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Cryllex.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword;
