import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from "react-router-dom"
import { DEFAULT_LANGUAGE_KEY } from '../../Constants/MainKeys'
import ForgetPassword from '../../pages/Authentication/ForgetPassword'
import Login from '../../pages/Authentication/Login'
import Register from '../../pages/Authentication/Register'
import RestorePassword from '../../pages/Authentication/RestorePassword'

export default function UnauthMiddleware() {

  useEffect(() => {
    localStorage.removeItem("refreshCount");
  }, [])

  const { language } = useSelector(state => state.Languages);

  return (
    <Switch>
      <Route path="/:language/login" exact component={Login} />
      <Route path="/:language/register" exact component={Register} />
      <Route path="/:language/forgot-password" exact component={ForgetPassword} />
      <Route path="/:language/restore-password" exact component={RestorePassword} />
      <Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}/login`} />
    </Switch>
  )
}