import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { ADMIN_USER_TYPE_ID, AGENT_USER_TYPE_ID, MERCHANT_USER_TYPE_ID } from "../../../Constants/MainKeys"
import AlertService from "../../../Services/alertService"
import DashboardApiService from "../../../Services/DashboardApiService"
import { setSiteTourData } from './../../../store/intro/actions';

const ProfileMenu = props => {

  const dispatch = useDispatch();
  const { stepsData } = useSelector((state) => state.Intro);
  const [isShow, setIsShow] = useState(false);
  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);

  useEffect(() => {
    if (stepsData && !stepsData.isNotified) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [stepsData])

  const logout = () => {
    AlertService.alertConfirm(
      "Log out",
      "This will log you out of your account. Proceed?",
      "Yes",
      "Close"
    ).then(() => {
      props.history.push(`/${language}/logout`)
    })
  }

  useEffect(() => {
    getPromotionsByType();
  }, [user]);

  const getPromotionsByType = () => {
    if (!user || (user && user.userTypeId === ADMIN_USER_TYPE_ID)) { return false };
    DashboardApiService.getShownPromotions(user.id, 1).then(response => {
      if (response && response.data) {
        let tourData = response.data.find(item => item.type === 1);
        dispatch(setSiteTourData(tourData || null));
      }
    }).catch(error => AlertService.alert("error", error))
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isShow}
        toggle={() => {
          if (stepsData && !stepsData.isNotified) {
            return false
          } else {
            setIsShow(!isShow)
          }
        }}
        onClick={() => {
          if (stepsData && !stepsData.isNotified) {
            setIsShow(!isShow)
          } else {
            return false
          }
        }}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          {
            user && user.avatarImage ?
              <div
                style={{
                  backgroundImage: `url(${user.avatarImage?.filePath})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center"
                }}
                className="rounded-circle header-profile-user"
              />
              : <i className='bx bx-user' style={{ fontSize: "22px" }} ></i>
          }
        </DropdownToggle>
        {
          user.userTypeId === AGENT_USER_TYPE_ID ?
            <DropdownMenu className="dropdown-menu-end">
              <Link to={`#`} className="dropdown-item" style={{ cursor: "default" }}>
                {
                  user.firstname && user.lastname ?
                    <b>{user.firstname} {user.lastname}</b>
                    : <b>{user.email}</b>
                }
              </Link>
              <hr className="my-1" />
              <Link className="dropdown-item py-2" to={`/${language}/profile`}
                onClick={() => setIsShow(false)}
              >
                Profile
              </Link>
              <Link className="dropdown-item py-2" to={`/${language}/kyc`}
                onClick={() => setIsShow(false)}
              >
                KYC
              </Link>
              <Link className="dropdown-item py-2" to={`/${language}/profile-settings`}
                onClick={() => setIsShow(false)}
              >
                Account Information
              </Link>
              <div className="dropdown-divider" />
              <Link to="#" className="dropdown-item" onClick={logout}>
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span>Logout</span>
              </Link>
            </DropdownMenu>
            : user.userTypeId === MERCHANT_USER_TYPE_ID ?
              <DropdownMenu className="dropdown-menu-end">
                <Link to={`#`} className="dropdown-item" style={{ cursor: "default" }}>
                  {
                    user.firstname && user.lastname ?
                      <b>{user.firstname} {user.lastname}</b>
                      : <b>{user.email}</b>
                  }
                </Link>
                <hr className="my-1" />

                <Link className="dropdown-item py-2 " to={`/${language}/profile`}
                  onClick={() => setIsShow(false)}
                >
                  <span className="profile_tour">Profile</span>
                </Link>
                <Link className="dropdown-item  py-2" to={`/${language}/merchant/company-information`}
                  onClick={() => setIsShow(false)}
                >
                  <span className="company_information_tour">Company Information</span>
                </Link>
                <Link className="dropdown-item py-2 " to={`/${language}/merchant/business-details`}
                  onClick={() => setIsShow(false)}
                >
                  <span className="business_kyc_tour">

                    Business KYC
                  </span>
                </Link>
                {/* Tigona asel vor comment anem 14.06.2024 */}
                {/* {
                  user.isActive ?
                    <Link className="dropdown-item py-2" to={`/${language}/merchant/business-kyc`}
                      onClick={() => setMenu(false)}
                    >
                      Business KYC
                    </Link>
                    : null
                } */}

                <div className="dropdown-divider" />
                <Link to="#" className="dropdown-item" onClick={logout}>
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                  <span>Logout</span>
                </Link>
              </DropdownMenu> :
              <DropdownMenu className="dropdown-menu-end">
                <Link to={`#`} className="dropdown-item" style={{ cursor: "default" }}>
                  {
                    user.firstname && user.lastname ?
                      <b>{user.firstname} {user.lastname}</b>
                      : <b>{user.email}</b>
                  }
                </Link>
                <hr className="my-1" />

                <Link className="dropdown-item py-2" to={`/${language}/profile`}
                  onClick={() => setIsShow(false)}
                >
                  Profile
                </Link>

                <div className="dropdown-divider" />
                <Link to="#" className="dropdown-item" onClick={logout}>
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                  <span>Logout</span>
                </Link>
              </DropdownMenu>
        }

      </Dropdown>
    </React.Fragment>
  )
}

export default withRouter(ProfileMenu) 
