
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR_KEY, INACTIVE_ACCOUNT_INFO_MSG, MERCHANT_USER_TYPE_ID, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY } from '../../../Constants/MainKeys'
import {
  Row,
  Col,
  Button,
} from "reactstrap"
import { Table } from "react-bootstrap"
import moment from 'moment/moment'
import NoData from '../../../components/NoData/NoData'
import AlertService from '../../../Services/alertService'
import DashboardApiService from '../../../Services/DashboardApiService'
import { addButtonSpinner, removeButtonSpinner } from '../../../store/actions'
import uuid from 'react-uuid'
import ActionButton from '../../../components/Buttons/ActionButton'
import MainService from '../../../Services/MainService'


function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const buttonSpinnerId = uuid();

const BankAccountInformation = (props) => {


  const { bankAccount, isShowActions = false, setBankAccount, isShowStatus = true, currencies = [] } = props;

  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { user } = useSelector(state => state.Login);
  const { language } = useSelector(state => state.Languages);
  const [zoomImagePath, setZoomImagePath] = useState(null);

  useOutsideAlerter(wrapperRef, setZoomImagePath);

  const deleteAccount = (accountId) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(buttonSpinnerId);
      (user.userTypeId === MERCHANT_USER_TYPE_ID ?
        DashboardApiService.deleteAccountAsMerchant(accountId) :
        DashboardApiService.deleteAccountAsAgent(accountId)
      ).then(() => {
        setBankAccount(null);
        AlertService.alert(SUCCESS_KEY, "Bank account deleted successfully");
        extractButtonSpinner(buttonSpinnerId)
      }).catch(error => getFail(error, buttonSpinnerId))
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  }


  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>

      {
        bankAccount ?
          <Row>
            {
              isShowStatus ?
                <Col md={12}>
                  <div className="d-flex align-items-center">
                    <div className='d-flex justify-content-between w-100 flex-wrap'>
                      <div className='d-flex align-items-center'>
                        <div className='me-1' style={{ backgroundColor: `${bankAccount.isActive ? "#34c38f" : "#f46a6a"}`, width: "7px", height: "7px", borderRadius: "50%", marginBottom: "2px" }} />
                        <span className="ms-1 mb-0 text-nowrap">
                          {bankAccount.isActive ? "Active" : "Inactive"}
                        </span>
                      </div>
                      <div>
                        <span>Account name is &nbsp;</span>
                        <b>
                          {bankAccount.accountName}
                        </b>
                      </div>
                    </div>
                  </div>
                  <hr />
                </Col>
                : null
            }
            <Col md={6}>
              <Table bordered striped responsive className='mb-0'>
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Account Number
                    </th>
                    <td>{bankAccount.accountNumber}</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      SWIFT Code
                    </th>
                    <td className='w-50'>{bankAccount.swiftCode}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={6}>
              <Table bordered striped responsive className='mb-0'>
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      State/Province
                    </th>
                    <td>{bankAccount.province}</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      City
                    </th>
                    <td>{bankAccount.city}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={12}><hr /></Col>
            <Col md={6}>
              <Table bordered striped responsive className='mb-0'>
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Country
                    </th>
                    <td className='w-50'>{bankAccount.countryName}</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      {bankAccount.paymentMethodLibrary?.displayName || "Payout method"}
                    </th>
                    {
                      bankAccount.paymentMethodLibraryDataId &&
                        bankAccount.paymentMethodLibrary &&
                        bankAccount.paymentMethodLibrary.libraryData &&
                        bankAccount.paymentMethodLibrary.libraryData.length ?
                        bankAccount.paymentMethodLibrary.libraryData.map((item, index) => {
                          if (item.id === bankAccount.paymentMethodLibraryDataId) {
                            return <td key={index} className='w-50'>{item.displayName}</td>
                          }
                        })
                        : <td className='w-50'><span className='text-danger'>Not set yet</span></td>
                    }
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={6}>
              <Table bordered striped responsive className='mb-0'>
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      {bankAccount.holderTypeLibrary?.displayName || "Payout method"}
                    </th>
                    {
                      bankAccount.holderTypeLibraryDataId &&
                        bankAccount.holderTypeLibrary &&
                        bankAccount.holderTypeLibrary.libraryData &&
                        bankAccount.holderTypeLibrary.libraryData.length ?
                        bankAccount.holderTypeLibrary.libraryData.map((item, index) => {
                          if (item.id === bankAccount.holderTypeLibraryDataId) {
                            return <td key={index} className='w-50'>{item.displayName}</td>
                          }
                        })
                        : <td className='w-50'><span className='text-danger'>Not set yet</span></td>
                    }
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Currency
                    </th>
                    {
                      bankAccount.currency ?
                        <td className='w-50'>{bankAccount.currency?.name}</td>
                        : <td className='w-50'><span className='text-danger'>Not set yet</span></td>
                    }
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={12}><hr /></Col>
            <Col md={6}>
              <Table bordered striped responsive className='mb-0'>
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Bank Name
                    </th>
                    <td className='w-50'>{bankAccount.bankName}</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Bank Code
                    </th>
                    <td>{bankAccount.bankCode}</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Bank Address
                    </th>
                    <td>{bankAccount.bankAddress}</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Created
                    </th>
                    <td className='w-50'>{bankAccount.createTime ? moment(new Date(bankAccount.createTime)).format("LL HH:mm") : ""}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={6}>
              <Table bordered striped responsive className='mb-0'>
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Branch Name
                    </th>
                    <td className='w-50'>{bankAccount.branchName}</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Branch Code
                    </th>
                    <td>{bankAccount.branchCode}</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Bank Branch Address
                    </th>
                    <td>{bankAccount.branchAddress}</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{ width: "400px" }}
                      className={"text-capitalize"}
                    >
                      Last Update
                    </th>
                    <td className='w-50'>{bankAccount.updateTime ? moment(new Date(bankAccount.updateTime)).format("LL HH:mm") : ""}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={12}><hr /></Col>
            {
              bankAccount.bankAddressFile ?
                <Col md={6}>
                  <Table bordered striped responsive>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Business Bank Statement
                        </th>
                        <td className='w-50'>{
                          (() => {
                            switch (MainService.getFxtensions(bankAccount.bankAddressFile.filePath)) {
                              case "image":
                                return <img
                                  className='rounded border cursor-pointer'
                                  src={bankAccount.bankAddressFile.filePath}
                                  alt='/'
                                  width={150}
                                  onClick={() => setZoomImagePath(bankAccount.bankAddressFile.filePath)}
                                />
                              case "file":
                                return <p
                                  onClick={() => window.open(bankAccount.bankAddressFile.filePath, "_blank")}
                                >
                                  <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                </p>
                              default:
                                break;
                            }
                          })()
                        }</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                : null
            }
            {
              isShowActions && bankAccount.bankAddressFile ?
                <Col md={12}><hr /></Col>
                : null
            }
            {
              isShowActions ?
                <Col md={12}>
                  <div className='d-flex justify-content-end'>
                    <Button
                      type="button"
                      color="primary"
                      className='shadow-sm me-1'
                      onClick={() => {
                        props.history.push(`/${language}/bank-account-form/${bankAccount.id}`)
                      }}
                    >
                      Edit
                    </Button>
                    <ActionButton
                      type="button"
                      name="Delete"
                      spinnerStyle={{ maxWidth: "35px" }}
                      className="shadow-sm btn btn-danger"
                      spinnerId={buttonSpinnerId}
                      onClick={() => deleteAccount(bankAccount.id)}
                    />
                  </div>
                </Col>
                : null
            }
          </Row>
          :
          <div>
            <NoData />
            {/* {
              user.isActive ? */}
            <div>
              <p className="my-3 text-center">You haven't added your bank account details yet</p>
              <div className='d-flex justify-content-center'>
                <Button
                  type="button"
                  color="primary"
                  className='shadow-sm'
                  onClick={() => props.history.push(`/${language}/bank-account-form`)}>
                  Add bank account
                </Button>
              </div>
            </div>
            {/* : <div>
                  <p className="my-3 text-center">{INACTIVE_ACCOUNT_INFO_MSG}</p>
                </div>
            } */}
          </div>
      }


    </>
  )
}

export default withRouter(BankAccountInformation)