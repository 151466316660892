import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"
import { ERROR_KEY, SUCCESS_KEY } from '../../../Constants/MainKeys'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import DashboardApiService from '../../../Services/DashboardApiService'
import ActionButton from '../../../components/Buttons/ActionButton'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import moment from 'moment'

const buttonSpinnerId = uuid();
const PromotionForm = (props) => {

  const obj = {
    className: "",
    title: "",
    description: "",
  }

  const dispatch = useDispatch();
  const title = "Promotion Form";
  const { itemId } = props.match.params;
  const description = "";
  const { language } = useSelector(state => state.Languages);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [dragItemIndex, setDragItemIndex] = useState(null);
  const [deadline, setDeadline] = useState("");
  const [type, setType] = useState(null);
  const [types, setTypes] = useState([]);

  const [values, setValues] = useState([
    {
      className: "",
      title: "",
      description: "",
    },
  ]);

  const onChange = (event, field, index, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues(prev => prev.map((el, i) => {
      if (i === index) {
        return {
          ...el,
          [field]: event.target.value
        }
      }
      return el;
    }))
  }

  useEffect(() => {
    getPromotionData();
  }, [itemId])

  useEffect(() => {
    getPromotionTypes();
  }, [])

  const getPromotionData = () => {
    if (!itemId) { return false; }
    const spinnerId = uuid();
    DashboardApiService.getPromotionData(itemId).then(response => {
      if (response && response.data) {
        setValues(JSON.parse(response.data?.data));
        setType(response.data.type || null);
        setDeadline(response.data.deadline ? moment(response.data.deadline).format("YYYY-MM-DD") : "")
      }
    }).catch(error => getFail(error, spinnerId))
  }

  const getPromotionTypes = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getPromotionTypes().then(response => {
      if (response && response.data) {
        setTypes(response.data);
      }
    }).catch(error => getFail(error, spinnerId)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const addItem = () => {
    setValues([...values, obj])
  }

  const removeItem = (index) => {
    setValues(values.filter((_, idx) => idx !== index))
  }

  const onSubmit = event => {
    event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    const _data = {
      data: JSON.stringify(values),
      deadline,
      type,
    }
    if (itemId) {
      _data.id = +itemId
    }
    if (!_data.deadline) {
      delete _data.deadline
    }
    (itemId ? DashboardApiService.updatePromotion(_data) : DashboardApiService.createPromotion(_data)).then(() => {
      AlertService.alert(SUCCESS_KEY, "Data saved");
      props.history.push(`/${language}/promotions`);
    }).catch(error => getFail(error)).finally(() => {
      extractButtonSpinner(buttonSpinnerId);
    })
  };

  const drag = (_itemIndex) => {
    typeof _itemIndex === "number" && setDragItemIndex(_itemIndex);
  };

  const drop = (dropItemIndex) => {
    if (dropItemIndex !== dragItemIndex) {
      setValues(prevValues => {
        const updatedValues = [...prevValues];
        const draggedItem = updatedValues[dragItemIndex];
        updatedValues.splice(dragItemIndex, 1);
        updatedValues.splice(dropItemIndex, 0, draggedItem);
        return updatedValues;
      });
      setDragItemIndex(null);
    }
  }

  const onSelectOptionChange = (value, cb) => {
    cb(value);
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);


  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Promotion Form"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <div className='d-flex justify-content-between align-items-center'>
                      <span>
                        Promotion Form
                      </span>
                    </div>
                    <hr />
                  </CardTitle>
                  <div className='mb-2'>
                    <Row>
                      <Col md={6}>
                        <div className='mb-2'>
                          <Label className='text-muted text-truncate mb-0'>Deadline</Label>
                          <Input
                            type="date"
                            className="form-control"
                            value={deadline}
                            onChange={(event) => {
                              setDeadline(event.target.value)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className='mb-2'>
                          <Label className='text-muted text-truncate mb-0'>Promotion Time</Label>
                          <ReactSelectOption
                            value={type}
                            isSearchable={true}
                            selectedValue={(() => {
                              const selectedValue = { ...types.find(data => data.key === type) };
                              if (Object.keys(selectedValue).length) {
                                selectedValue.label = selectedValue.value;
                                selectedValue.value = selectedValue.key;
                                return selectedValue;
                              } else {
                                return { label: "Choose...", value: "" }
                              }
                            })()}
                            items={types.map(data => ({ label: data.value, value: data.key }))}
                            onChange={(item) => onSelectOptionChange(item.value, setType)}
                          />
                        </div>
                      </Col>
                      <Col sm={12}><hr /></Col>
                    </Row>
                  </div>
                  <form className='mb-3' onSubmit={onSubmit}>
                    {
                      values && values.length ?
                        values.map((item, index) => {
                          return <div key={index}
                            className='d-flex gap-2 drag'
                            draggable={true}
                            style={{ cursor: "move" }}
                            onDrop={() => drop(index)}
                            onDragOver={event => event.preventDefault()}
                            onDragStart={() => drag(index)}
                          >
                            <div className='d-flex align-items-start'>
                              <b style={{ fontSize: "18px" }}>{index + 1}</b>
                            </div>
                            <div className='border rounded px-3 py-2 mb-2 w-100'>
                              <Row>
                                <Col md={6}>
                                  <div className='mb-2'>
                                    <Label className='text-muted text-truncate mb-0'>Class Name</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="e.g. profile_tour"
                                      value={item.className}
                                      required={true}
                                      onChange={(event) => onChange(event, "className", index, 200)}
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className='mb-2'>
                                    <Label className='text-muted text-truncate mb-0'>Title</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="e.g. Profile"
                                      value={item.title}
                                      required={true}
                                      onChange={(event) => onChange(event, "title", index, 200)}
                                    />
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div className='mb-2'>
                                    <Label className='text-muted text-truncate mb-0'>Description</Label>
                                    <textarea
                                      rows={3}
                                      className="form-control"
                                      placeholder="e.g. Welcome to your portal Cryllex!"
                                      value={item.description}
                                      required={true}
                                      onChange={(event) => onChange(event, "description", index, 2000)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className='d-flex align-items-start'>
                              <Button
                                outline
                                color='danger'
                                style={{ padding: "5px" }}
                                className='d-flex justify-content-center align-items-center'
                                onClick={() => removeItem(index)}
                              >
                                <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                              </Button>
                            </div>
                          </div>

                        })
                        : null
                    }
                    <Col sm={12}>
                      <Button
                        color='success'
                        className='w-100 d-flex align-items-center justify-content-center'
                        onClick={addItem}
                      >
                        <i className='bx bx-plus' style={{ fontSize: "18px" }}></i>
                        <span> Add Item</span>
                      </Button>
                      <hr />
                      <div className='d-flex justify-content-end'>
                        <ActionButton
                          type="submit"
                          name="Submit"
                          className="shadow-sm ms-auto px-4 btn btn-primary"
                          spinnerId={buttonSpinnerId}
                        />
                      </div>
                    </Col>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div >
    </>
  )
}

export default PromotionForm
