import MetaTags from 'react-meta-tags';
import React, { useState, useEffect, useRef } from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import { addButtonSpinner, removeButtonSpinner, updateUserData } from "../../../store/actions"
import MainService from '../../../Services/MainService';
import AlertService from '../../../Services/alertService';
import { AGENT_USER_TYPE_ID, ERROR_KEY, MERCHANT_USER_TYPE_ID, SPINNER_COLOR, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY, allValidFileTypes, fielsLengths } from '../../../Constants/MainKeys'
import uuid from 'react-uuid'
import { useCallback } from 'react'
import ActionButton from '../../../components/Buttons/ActionButton'
import {
  Container,
  Row,
  Card,
  CardBody,
  Button,
  Nav,
  NavItem,
  NavLink,
  Col,
  TabPane,
  TabContent,
  Form,
  Label,
  Input,
  Media,
} from "reactstrap"
import DashboardApiService from '../../../Services/DashboardApiService';
import { PuffLoader } from 'react-spinners';
import PhoneInput from 'react-phone-input-2';
import BusinessDetailsFileComponent from '../../Admin-Pages/Merchant/Components/FileComponent';
import moment from 'moment';

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const spinnerId = uuid();

const PayingCustomer = props => {

  const mainService = new MainService();
  const maxFilesSize = 3; //MB
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Login);

  const [customActiveTab, setCustomIconActiveTab] = useState("1");
  const [isShowImageLoader, setIsShowImageLoader] = useState(false);
  const [isShowFileLoader, setIsShowFileLoader] = useState(false);

  const [isInvalidProfileSubmit, setIsInvalidProfileSubmit] = useState(false);
  const [isInvalidSecuritySubmit, setIsInvalidSecuritySubmit] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  useOutsideAlerter(wrapperRef, setZoomImagePath);

  const [_values, _setValues] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    addressResidence: "",
    addressRegistration: "",
    phoneNumber: "",
    mobilePhone: "",
    dob: "",
    userPassportProofFile: null,
    userApprovalFile: null,
  })



  const [values, setValues] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  })

  useEffect(() => {
    if (!user) { return false; }
    let userCopy = { ...user };
    if (userCopy.userFiles && userCopy.userFiles.length) {
      userCopy.userFiles.forEach(item => {
        userCopy[MainService.getUserFileNameByType(item.fileType)] = item;
      })
      delete userCopy.userFiles;
      dispatch(updateUserData(userCopy));
    }
    if (userCopy.dob) {
      userCopy.dob = moment(userCopy.dob).format("YYYY-MM-DD")
    }
    _setValues(userCopy);
  }, [user]);

  const uploadFile = async (data) => {
    const { event, fieldName, cb } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();

    let files = [...event.target.files];
    for (let i in files) {
      const file = files[i];
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';

      if ((fieldName === "avatarImage" ? VALID_IMAGE_TYPES_KEY : allValidFileTypes).includes(fileExtention.toLowerCase())) {
        mainService.readFile(file, (fieldName === "avatarImage" ? VALID_IMAGE_TYPES_KEY : allValidFileTypes)).then(() => {
          cb(true);
          formData.append("id", user.id);
          formData.append("document", file);
          formData.append("type", MainService.getUserFileType(fieldName));

          DashboardApiService.uploadUserDocument(formData).then(response => {
            let userCopy = { ...user };
            userCopy[fieldName] = response.data;
            dispatch(updateUserData(userCopy))
            AlertService.alert(SUCCESS_KEY, "Data saved")
          }).catch(error => getFail(error)).finally(() => {
            cb(false);
          })

        }).catch(error => {
          error && AlertService.alert("error", "Invalid file format")
        });
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
  };

  const deleteUserDocument = (data) => {
    const { fileLibraryId, fieldName, userId, cb } = data;
    if (!fileLibraryId || !userId) { return false; }
    AlertService.alertConfirm(
      `Are you sure you want to delete current file ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      cb && cb(true);
      DashboardApiService.deleteUserDocument(fileLibraryId, userId, MainService.getUserFileType(fieldName)).then(() => {
        let userCopy = { ...user };
        userCopy[fieldName] = null;
        _setValues(_values);
        dispatch(updateUserData(userCopy));
        AlertService.alert(SUCCESS_KEY, "File deleted successfully");
      }).catch(error => getFail(error)).finally(() => {
        cb && cb(false);
      })
    })
  }

  const onChange = (event, field, maxLength = null, cb) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidProfileSubmit(false);
  }


  const checkDob = (event) => {
    console.log(event.target.value);
    if (event.target.value) {
      if (new Date(event.target.value) > new Date(MainService.getToday())) {
        _setValues((values) => ({
          ...values,
          dob: MainService.getToday()
        }))
      }
      if (new Date(event.target.value) < new Date(MainService.getHundredYearsAgoStr())) {
        _setValues((values) => ({
          ...values,
          dob: MainService.getHundredYearsAgoStr()
        }))
      }
    }

  }

  const onPhoneNumberChange = (event, field, cb) => {
    cb((values) => ({
      ...values,
      [field]: event,
    }));
  }

  const toggleCustom = (key) => {
    setCustomIconActiveTab(key)
  }

  const onSubmit = (event, formType) => {
    event && event.preventDefault();
    if (formType === "profile") {
      setButtonSpinner(`${formType}_${spinnerId}`)
      DashboardApiService.updateProfile(_values).then(() => {
        dispatch(updateUserData(_values))
        AlertService.alert(SUCCESS_KEY, "Your profile has been updated successfully")
      }).catch(error => getFail(error)).finally(() => extractButtonSpinner(`${formType}_${spinnerId}`))
    } else if (formType === "security") {
      if (!values.oldPassword.trim().length || !values.password.trim().length || !values.confirmPassword.trim().length) {
        setIsInvalidSecuritySubmit(true);
        return false;
      }
      setButtonSpinner(`${formType}_${spinnerId}`);
      let valuesCopy = { ...values };
      valuesCopy.oldPassword = btoa(valuesCopy.oldPassword);
      valuesCopy.password = btoa(valuesCopy.password);
      valuesCopy.confirmPassword = btoa(valuesCopy.confirmPassword);
      DashboardApiService.updatePassword(valuesCopy).then(() => {
        AlertService.alert(SUCCESS_KEY, "Your password has been updated successfully");
        setValues({
          oldPassword: "",
          password: "",
          confirmPassword: "",
        })
      }).catch(error => getFail(error)).finally(() => extractButtonSpinner(`${formType}_${spinnerId}`))

    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return (
    <React.Fragment>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <div className="page-content">
        <MetaTags>
          <title>Profile</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Cryllex" breadcrumbItem="Profile" />
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  {
                    isShowImageLoader ?
                      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "100px", }}>
                        <PuffLoader size={40} color={SPINNER_COLOR} />
                      </div>
                      : <Media>
                        <div className="me-3">
                          {
                            user.avatarImage ?

                              <div
                                style={{
                                  backgroundImage: `url(${user.avatarImage?.filePath})`,
                                  minWidth: "6rem",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center"
                                }}
                                className="avatar-lg rounded-circle img-thumbnail"
                              />
                              : <div className='avatar-lg rounded-circle img-thumbnail d-flex align-items-center justify-content-center'>
                                <i className='bx bx-user' style={{ fontSize: "35px" }} />
                              </div>
                          }

                        </div>
                        <Media body className="align-self-center">
                          <div className="text-muted">
                            <div className='d-flex align-items-center gap-2 mb-1'>
                              <h5 className='capitalize m-0'>{user.firstname} {user.lastname}</h5>
                              <div className="h-100 d-flex align-items-center gap-2">
                                <div className='' style={{ backgroundColor: `${user.isEmailVerified ? "#34c38f" : "#f46a6a"}`, width: "7px", height: "7px", borderRadius: "50%", marginBottom: "2px" }} />
                                <span className="mb-0 text-nowrap">
                                  {
                                    user.isEmailVerified ? "Email verify" : "Email not verified"
                                  }
                                </span>
                              </div>
                            </div>
                            <p className="mb-1">{user.email}</p>
                          </div>
                          <div className="mt-2">
                            <Button
                              type="button"
                              color={`${user.avatarImage ? "danger" : "primary"}`}
                              className='px-4'
                              onClick={(event) => {
                                if (!user.avatarImage) {
                                  MainService.triggerUploadClick(event);
                                } else {
                                  deleteUserDocument({ fileLibraryId: user.avatarImage?.fileLibraryId, fieldName: "avatarImage", userId: user.id, cb: setIsShowImageLoader });
                                }
                              }}
                            >
                              {
                                !user.avatarImage ?
                                  <input type="file" id='avatarImage' className="d-none" hidden onChange={(event) => uploadFile({ event, fieldName: "avatarImage", cb: setIsShowImageLoader })} />
                                  : null
                              }
                              {
                                user.avatarImage ? "Delete avatar" : "Upload new avatar"
                              }
                            </Button>
                          </div>
                        </Media>
                      </Media>
                  }
                </CardBody>
              </Card>
            </Col>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`${customActiveTab === "1" ? "active" : ""}`}
                        onClick={() => {
                          toggleCustom("1")
                        }}
                      >
                        <span style={{ fontSize: "14px" }}>Profile</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={`${customActiveTab === "2" ? "active" : ""}`}
                        onClick={() => {
                          toggleCustom("2")
                        }}
                      >
                        <span style={{ fontSize: "14px" }}>Security</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Form
                        onSubmit={(event) => onSubmit(event, "profile")}
                        onChange={() => setIsChanged(true)}
                      >
                        <Row>

                          <Col md={4} className='mb-4'>
                            <Label htmlFor='firstname' className="text-muted text-truncate mb-0">
                              First Name
                            </Label>
                            <Input
                              id="firstname"
                              type="text"
                              placeholder='e.g. John'
                              className={`form-control`}
                              value={_values.firstname || ""}
                              onChange={(event) => onChange(event, "firstname", fielsLengths.length_120, _setValues)}
                            />
                          </Col>
                          <Col md={4} className='mb-4'>
                            <Label htmlFor='lastname' className="text-muted text-truncate mb-0">
                              Last Name
                            </Label>
                            <Input
                              id="lastname"
                              type="text"
                              placeholder='e.g. Appleseed'
                              className={`form-control`}
                              value={_values.lastname || ""}
                              onChange={(event) => onChange(event, "lastname", fielsLengths.length_120, _setValues)}
                            />
                          </Col>
                          <Col md={4} className='mb-4'>
                            <Label htmlFor='middlename' className="text-muted text-truncate mb-0">
                              Middle Name
                            </Label>
                            <Input
                              id="middlename"
                              type="text"
                              placeholder='e.g. Pierre'
                              className={`form-control`}
                              value={_values.middlename || ""}
                              onChange={(event) => onChange(event, "middlename", fielsLengths.length_120, _setValues)}
                            />
                          </Col>
                          <Col md={4} className='mb-4'>
                            <Label htmlFor='addressResidence' className="text-muted text-truncate mb-0">
                              Residential Address
                            </Label>
                            <Input
                              id="addressResidence"
                              type="text"
                              placeholder='e.g. Flat B, 15/F, 11 Hart Avenue, Tsim Sha Tsui, Hong Kong'
                              className={`form-control`}
                              value={_values.addressResidence || ""}
                              onChange={(event) => onChange(event, "addressResidence", fielsLengths.length_120, _setValues)}
                            />
                          </Col>
                          <Col md={4} className='mb-4'>
                            <Label htmlFor='representativeEmail' className="text-muted text-truncate mb-0">
                              Representative Email Address
                            </Label>
                            <Input
                              id="representativeEmail"
                              type="text"
                              placeholder='e.g. johnapp@charter.com'
                              className={`form-control`}
                              readOnly
                              value={user.email}
                              onChange={() => { }}
                            />
                          </Col>
                          <Col md={4} className='mb-4'>
                            <Label htmlFor='dob' className="text-muted text-truncate mb-0">
                              Date of Birth
                            </Label>
                            <Input
                              id="dob"
                              type="date"
                              className={`form-control`}
                              value={_values.dob || ""}
                              min={MainService.getHundredYearsAgoStr()}
                              max={MainService.getToday()}
                              onChange={(event) => onChange(event, "dob", fielsLengths.length_120, _setValues)}
                              onBlur={(event) => checkDob(event)}
                            />
                          </Col>
                          <Col md={4} className='mb-4'>
                            <Label htmlFor='phoneNumber' className='text-muted text-truncate mb-0'>Phone Number 1</Label>
                            <PhoneInput
                              country={""}
                              specialLabel=""
                              value={_values.phoneNumber || ""}
                              className={`custom-phone-number-input-block`}
                              onChange={(event) => onPhoneNumberChange(event, "phoneNumber", _setValues)}
                            />
                          </Col>
                          <Col md={4} className='mb-4'>
                            <Label htmlFor='mobilePhone' className='text-muted text-truncate mb-0'>Phone Number 2</Label>
                            <PhoneInput
                              country={""}
                              specialLabel=""
                              value={_values.mobilePhone || ""}
                              className={`custom-phone-number-input-block`}
                              onChange={(event) => onPhoneNumberChange(event, "mobilePhone", _setValues)}
                            />
                          </Col>

                          <Col sm={12}>
                            <hr />
                            {
                              isShowFileLoader ?
                                <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "100px", }}>
                                  <PuffLoader size={40} color={SPINNER_COLOR} />
                                </div>
                                : <Row>
                                  <Col md={4}>
                                    <BusinessDetailsFileComponent
                                      data={_values}
                                      fieldName="userAddressProofFile"
                                      labelValue="Proof of Address"
                                      setZoomImagePath={setZoomImagePath}
                                      uploadFile={(item) => uploadFile({ ...item, cb: setIsShowFileLoader })}
                                      deleteDocument={(item) => deleteUserDocument({ ...item, userId: _values.id, cb: setIsShowFileLoader })}
                                    />
                                  </Col>
                                </Row>
                            }
                            <hr />
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                          <ActionButton
                            type="submit"
                            name="Submit"
                            disabled={!isChanged}
                            className="btn btn-primary btn-block px-4"
                            spinnerId={`profile_${spinnerId}`}
                          />
                        </div>
                      </Form>

                    </TabPane>
                    <TabPane tabId="2">
                      <Form onSubmit={(event) => onSubmit(event, "security")}>
                        <Row>
                          <Col md={4} className='mb-4'>
                            <Label htmlFor='oldPassword' className="text-muted text-truncate mb-0">
                              Old password *
                            </Label>
                            <Input
                              id="oldPassword"
                              type="password"
                              placeholder='Old Password'
                              className={`form-control ${isInvalidSecuritySubmit && !values.oldPassword.trim().length ? "error-border" : ""}`}
                              value={values.oldPassword}
                              onChange={(event) => onChange(event, "oldPassword", fielsLengths.length_120, setValues)}
                            />
                          </Col>
                          <Col md={4} className='mb-4'>
                            <Label htmlFor='password' className="text-muted text-truncate mb-0">
                              New password *
                            </Label>
                            <Input
                              id="password"
                              type="password"
                              placeholder='New password'
                              className={`form-control ${isInvalidSecuritySubmit && !values.password.trim().length ? "error-border" : ""}`}
                              value={values.password}
                              onChange={(event) => onChange(event, "password", fielsLengths.length_120, setValues)}
                            />
                          </Col>
                          <Col md={4} className='mb-4'>
                            <Label htmlFor='confirmPassword' className="text-muted text-truncate mb-0">
                              Confirm password *
                            </Label>
                            <Input
                              id="confirmPassword"
                              type="password"
                              placeholder='Confirm Password'
                              className={`form-control ${isInvalidSecuritySubmit && !values.confirmPassword.trim().length ? "error-border" : ""}`}
                              value={values.confirmPassword}
                              onChange={(event) => onChange(event, "confirmPassword", fielsLengths.length_120, setValues)}
                            />
                          </Col>

                          <Col>
                            {
                              values.password.trim().length && values.confirmPassword.trim().length && values.password !== values.confirmPassword ?
                                <div><small className="text-danger">Password mismatch</small></div>
                                : null
                            }
                          </Col>
                        </Row>

                        <div className="mt-4 d-flex justify-content-end">
                          <ActionButton
                            type="submit"
                            name="Submit"
                            disabled={values.oldPassword.trim().length && values.password.trim().length && values.confirmPassword.trim().length && values.password === values.confirmPassword ? false : true}
                            className="btn btn-primary btn-block px-4"
                            spinnerId={`security_${spinnerId}`}
                          />
                        </div>
                      </Form>



                    </TabPane>

                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PayingCustomer
