import React, { useState, useEffect, useCallback, useRef } from 'react'
import HtmlHead from '../../../components/HtmlHead/HtmlHead';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Card,
  CardBody,
  Row,
  Label,
  Input,
  Col,
  UncontrolledAlert,
} from "reactstrap"
import AlertService from '../../../Services/alertService';
import MainService from '../../../Services/MainService';
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_KEY, SPINNER_COLOR, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY, allValidFileTypes, fielsLengths } from '../../../Constants/MainKeys';
import ActionButton from '../../../components/Buttons/ActionButton';
import uuid from 'react-uuid';
import PhoneInput from 'react-phone-input-2'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions';
import DashboardApiService from '../../../Services/DashboardApiService';
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption';
import { PuffLoader } from 'react-spinners';
import FileComponent from '../../Admin-Pages/Merchant/Components/FileComponent';
import FilesComponent from '../../Admin-Pages/Merchant/Components/FilesComponent';
import ApiService from '../../../Services/ApiService';

const spinnerId = uuid();
const buttonSpinnerId = uuid();

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function Kyc(props) {

  const title = "KYC";
  const description = "";
  const mainService = new MainService();

  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { user } = useSelector(state => state.Login);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [isChanged, setIsChanged] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState(null);
  const [isShowImageLoader, setIsShowImageLoader] = useState(false)
  const [countries, setCountries] = useState([])

  useOutsideAlerter(wrapperRef, setZoomImagePath);

  const [values, setValues] = useState({
    username: "", //read only
    legalBusinessName: "",
    phoneNumber: "",
    ssnNumber: "",
    dbaName: "",
    businessCountryOfRegistrationId: null,
    businessRegistrationNumber: "",
    businessRegisteredAddress: "",
    primaryRegulatoryLicencingNumber: "",
    legalBusinessNameUpdated: false,
    isActive: false,
  })

  const getOrganizationData = () => {

    setRightSectionSpinner(spinnerId);
    DashboardApiService.getAgentKycData().then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data.files && data.files.length) {
          const groupedFiles = data.files.reduce((groups, file) => {
            const key = file.fileType;
            (groups[key] = groups[key] || []).push(file);
            return groups;
          }, {});
          const arrayOfArrays = Object.values(groupedFiles);
          if (arrayOfArrays && arrayOfArrays.length) {
            arrayOfArrays.forEach(item => {
              data[MainService.getOrganizationDetailsFileNameByType(item[0].fileType)] = item;
            })
          }
        }
        delete data.files;
        setValues(data)
      }
      extractRightSectionSpinner(spinnerId)
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }


  const getCountries = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }


  useEffect(() => {
    getOrganizationData();
    getCountries();
  }, [])

  const bulkUploadFile = async (data) => {
    const { event, fieldName } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();
    formData.append("id", values.id);
    formData.append("type", MainService.getOrganizationDetailsFileType(fieldName));
    let files = [...event.target.files];
    for (let i in files) {
      let file = files[i];
      let fileName = file.name;
      let lastDotIndex = fileName.lastIndexOf('.');
      let fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';

      if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
        await mainService.readFile(file, allValidFileTypes).then(uploadedFile => {
          setIsShowImageLoader(true);
          formData.append("documents", file);
        }).catch(error => {
          error && AlertService.alert("error", "Invalid file format");
        });
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
    DashboardApiService.bulkUploadDocumentAsAgent(formData).then(response => {
      if (values[fieldName] && Array.isArray(values[fieldName])) {
        setValues((values) => ({
          ...values,
          [fieldName]: [...values[fieldName] || [], ...response.data],
        }));
      } else {
        setValues((values) => ({
          ...values,
          [fieldName]: response.data,
        }));
      }
      AlertService.alert(SUCCESS_KEY, "Data saved")
    }).catch(error => getFail(error)).finally(() => {
      setIsShowImageLoader(false);
    })
  };

  const uploadFile = async (data) => {
    const { event, fieldName } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();
    formData.append("id", values.id);
    formData.append("type", MainService.getOrganizationDetailsFileType(fieldName));

    let files = [...event.target.files];
    let file = files[0];
    let fileName = file.name;
    let lastDotIndex = fileName.lastIndexOf('.');
    let fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
    if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
      await mainService.readFile(file, allValidFileTypes).then(uploadedFile => {
        setIsShowImageLoader(true);
        formData.append("document", file);
        DashboardApiService.uploadDocumentAsAgent(formData).then(response => {
          setValues((values) => ({
            ...values,
            [fieldName]: response.data,
          }))
          AlertService.alert(SUCCESS_KEY, "Data saved")
        }).catch(error => getFail(error)).finally(() => {
          setIsShowImageLoader(false);
        })
      }).catch(error => {
        error && AlertService.alert("error", "Invalid file format");
      });
    } else {
      AlertService.alert("error", "Invalid file format")
      return false;
    }
  };

  const deleteDocument = (data) => {
    const { fieldName, fileLibraryId } = data;
    if (!fileLibraryId) { return false; }
    AlertService.alertConfirm(
      `Are you sure?`,
      "Do you want to delete the current file?",
      "Yes",
      "No"
    ).then(() => {
      setIsShowImageLoader(true);
      DashboardApiService.deleteDocumentAsAgent(fileLibraryId, values.id, MainService.getOrganizationDetailsFileType(fieldName)).then(() => {
        if (Array.isArray(values[fieldName])) {
          setValues((values) => ({
            ...values,
            [fieldName]: values[fieldName].filter(item => item.fileLibraryId !== fileLibraryId),
          }))
        } else {
          setValues((values) => ({
            ...values,
            [fieldName]: null,
          }))
        }
        AlertService.alert(SUCCESS_KEY, "File deleted successfully")
      }).catch(error => getFail(error)).finally(() => {
        setIsShowImageLoader(false);
      })
    })
  }

  const onChange = (event, field, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false)
  }

  const onNumberChange = (event, fieldName, maxLength = Infinity) => {
    if (event.target.value.includes("e") || event.target.value.includes(".") || event.target.value.includes("-") || event.target.value === "0") {
      setValues(values => ({
        ...values,
        [fieldName]: "",
      }))
      return false;
    };
    if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value) >= 0 && Number(event.target.value) <= maxLength)) {
      setValues(values => ({
        ...values,
        [fieldName]: event.target.value ? +event.target.value : ""
      }))
      return false;
    }
  };

  const onPhoneNumberChange = (event, field) => {
    setValues((values) => ({
      ...values,
      [field]: event,
    }));
    setIsInvalidSubmit(false)
  }

  const onSelectOptionChange = (value, field) => {
    setValues((values) => ({
      ...values,
      [field]: value,
    }));
    setIsChanged(true);
    setIsInvalidSubmit(false)
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    values.agentId = user.activeAgentId;
    DashboardApiService.updateAgentKyc(values).then((response) => {
      if (response && response.data) {
        setValues((values) => ({
          ...values,
          id: response.data.id
        }))
      }
      AlertService.alert(SUCCESS_KEY, "KYC data updated successfully")
    }).catch(error => getFail(error)).finally(() => {
      extractButtonSpinner(buttonSpinnerId)
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <section className='page-content position-relative min-h-100'>
        <HtmlHead title={title} description={description} />
        <RightSectionSpinner spinner={rightSectionSpinners} />
        {
          values.legalBusinessNameUpdated ?
            <UncontrolledAlert color="warning" role="alert">
              <i className="mdi mdi-alert-outline me-2"></i>
              The business legal name in KYC is, by default, the same as your organization's legal business name, but you can change it at any time.
            </UncontrolledAlert>
            : null
        }
        {
          !values.isApproved && values.rejectMessage ?
            <UncontrolledAlert color="danger" role="alert">
              <i className="mdi mdi-block-helper me-2"></i>
              Reject message: {values.rejectMessage}
            </UncontrolledAlert>
            : null
        }
        <div className="container-fluid">
          <form onSubmit={onSubmit} onChange={() => setIsChanged(true)}>
            <Breadcrumbs
              title="Cryllex"
              breadcrumbItem="Agent KYC"
            />
            <Row className="justify-content-center">
              <Card>
                <CardBody>
                  <Col>
                    <Row>
                      <Col sm={12}>
                        <Row>
                          {
                            values.cryllexId ?
                              <Col sm={12}>
                                <h4 className='card-title'>Id: {values.cryllexId}</h4>
                                <hr />
                              </Col>
                              : null
                          }
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='legalBusinessName' className='text-muted text-truncate mb-0'>Legal Business Name</Label>
                            <Input
                              id="legalBusinessName"
                              type="text"
                              className={`form-control`}
                              value={values.legalBusinessName || ""}
                              onChange={event => onChange(event, "legalBusinessName", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='businessCountryOfRegistrationId' className='text-muted text-truncate mb-0'>Country of registration/incorporation</Label>
                            {
                              countries && countries.length ?
                                <ReactSelectOption
                                  value={values.businessCountryOfRegistrationId}
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { ...countries.find(data => data.id === values.businessCountryOfRegistrationId) };
                                    if (Object.keys(selectedValue).length) {
                                      selectedValue.label = selectedValue.name;
                                      selectedValue.value = selectedValue.id;
                                      return selectedValue;
                                    } else {
                                      return { label: "Choose...", value: "" }
                                    }
                                  })()}
                                  items={countries.map(data => ({ label: data.name, value: data.id }))}
                                  onChange={(item) => onSelectOptionChange(item.value, "businessCountryOfRegistrationId")}
                                />
                                : null
                            }
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='businessRegistrationNumber' className='text-muted text-truncate mb-0'>Business Registration Number</Label>
                            <Input
                              id="businessRegistrationNumber"
                              type="text"
                              className={`form-control`}
                              value={values.businessRegistrationNumber || ""}
                              onChange={event => onChange(event, "businessRegistrationNumber", fielsLengths.length_30)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='businessRegisteredAddress' className='text-muted text-truncate mb-0'>Business registered address</Label>
                            <Input
                              id="businessRegisteredAddress"
                              type="text"
                              className={`form-control`}
                              value={values.businessRegisteredAddress || ""}
                              onChange={event => onChange(event, "businessRegisteredAddress", fielsLengths.length_250)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='primaryRegulatoryLicencingNumber' className='text-muted text-truncate mb-0'>Primary Regulatory Licencing Number</Label>
                            <Input
                              id="primaryRegulatoryLicencingNumber"
                              type="text"
                              className={`form-control`}
                              value={values.primaryRegulatoryLicencingNumber || ""}
                              onChange={event => onChange(event, "primaryRegulatoryLicencingNumber", fielsLengths.length_30)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='phoneNumber' className='text-muted text-truncate mb-0'>Phone number</Label>
                            <PhoneInput
                              country={""}
                              specialLabel=""
                              value={values.phoneNumber || ""}
                              className={`custom-phone-number-input-block`}
                              onChange={(event) => onPhoneNumberChange(event, "phoneNumber")}
                            />
                          </Col>

                          <Col sm={12}><hr /></Col>
                          {
                            values.id ?
                              isShowImageLoader ?
                                <Col sm={12}>
                                  <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "150px", }}>
                                    <PuffLoader size={60} color={SPINNER_COLOR} />
                                  </div>
                                </Col>
                                :
                                <Col sm={12}>
                                  <Row>
                                    <Col md={4} sm={6} className='mb-3'>
                                      <FileComponent
                                        data={values}
                                        fieldName="certificateOfIncumbencyFile"
                                        labelValue="Certificate of incumbency"
                                        setZoomImagePath={setZoomImagePath}
                                        uploadFile={uploadFile}
                                        deleteDocument={deleteDocument}
                                      />
                                    </Col>
                                    <Col md={4} sm={6} className='mb-3'>
                                      <FileComponent
                                        data={values}
                                        fieldName="certificateOfIncorporationFile"
                                        labelValue="Certificate of incorporation"
                                        setZoomImagePath={setZoomImagePath}
                                        uploadFile={uploadFile}
                                        deleteDocument={deleteDocument}
                                      />
                                    </Col>
                                    <Col md={4} sm={6} className='mb-3'>
                                      <FileComponent
                                        data={values}
                                        fieldName="businessRegisteredAddressFile"
                                        labelValue="Business registered address"
                                        setZoomImagePath={setZoomImagePath}
                                        uploadFile={uploadFile}
                                        deleteDocument={deleteDocument}
                                      />
                                    </Col>

                                    <Col md={4} sm={6} className='mb-3'>
                                      <FileComponent
                                        data={values}
                                        fieldName="businessRegistrationCertificateFile"
                                        labelValue="Business registration certificate"
                                        setZoomImagePath={setZoomImagePath}
                                        uploadFile={uploadFile}
                                        deleteDocument={deleteDocument}
                                      />
                                    </Col>
                                    <Col md={4} sm={6} className='mb-3'>
                                      <FileComponent
                                        data={values}
                                        fieldName="otherProofFile"
                                        labelValue="Other Proof of Business"
                                        setZoomImagePath={setZoomImagePath}
                                        uploadFile={uploadFile}
                                        deleteDocument={deleteDocument}
                                      />
                                    </Col>
                                    <Col md={4} sm={6} className='mb-3'>
                                      <FilesComponent
                                        data={values}
                                        fieldName="businessRegulatoryLicencingNumberFile"
                                        labelValue="Primary regulatory licenses (if applicable)"
                                        setZoomImagePath={setZoomImagePath}
                                        uploadFile={bulkUploadFile}
                                        deleteDocument={deleteDocument}
                                        alertMessage="Allow multiple"
                                        alertIcon="mdi-alert-circle-outline"
                                        alertType="info"
                                        multiple={true}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={12}>
                                      <small className='fw-500 d-block mb-2'>Allowed file types {allValidFileTypes.map((item, index) => <i key={index}>{item}. </i>)}</small>
                                      <UncontrolledAlert color="warning" role="alert">
                                        <i className="mdi mdi-alert-outline me-2"></i>
                                        All documents require Certified true copies, alternatively, the documents can be visually presented through Cryllex customer support
                                      </UncontrolledAlert>
                                    </Col>
                                  </Row>
                                </Col>
                              : null
                          }

                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <div className='d-flex justify-content-end'>
                    <ActionButton
                      type="submit"
                      name="Submit"
                      className="btn btn-primary btn-block px-4"
                      spinnerId={buttonSpinnerId}
                      disabled={!isChanged}
                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          </form>
        </div >
      </section >
    </>
  )
}