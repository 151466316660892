import React from 'react'
import {
  Card,
  CardBody,
  Media,
  Table,
} from "reactstrap"
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import NoData from '../../../../components/NoData/NoData';

const TransactionDetails = ({ transactionDetails, payignCustomer, organizers }) => {

  const { language } = useSelector(state => state.Languages);

  return (
    <>
      <Card className="quick-view">
        <CardBody>
          <h5 className="mb-3 card-title">Transaction Details</h5>
          {
            transactionDetails ?
              <div>
                <div>
                  <p className="mb-1 border p-3"><i className="mdi mdi-circle-medium align-middle text-primary me-1"></i>
                    Time Stamp: <b>{moment(new Date(transactionDetails.createDate)).format("lll")}</b>
                  </p>
                  {
                    payignCustomer ?
                      <Media className='border p-3 my-2'>
                        <Media body>
                          <div className='d-flex justify-content-between align-items-start'>
                            <h5>
                              <span className="font-size-14">Paying Customer ID: {payignCustomer?.uniqueId}</span>
                            </h5>
                            {
                              payignCustomer.id ?
                                <div className="media">
                                  <div className="me-3">
                                    <h5 className="font-size-14"><i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle bx-fade-right"></i></h5>
                                  </div>
                                  <div className="media-body">
                                    <Link to={`/${language}/transaction/breakdown/${payignCustomer.id}`} className='text-underline font-size-12'>View</Link>
                                  </div>
                                </div>
                                : null
                            }
                          </div>

                          <div className='d-flex gap-4'>
                            <p className="text-muted m-0">
                              <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2"></i>
                              {payignCustomer?.fullName}
                            </p>
                            <p className="text-muted m-0">
                              <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2"></i>
                              {payignCustomer?.country}
                            </p>
                            <p className="text-muted m-0">
                              <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2"></i>
                              {payignCustomer?.city}
                            </p>
                          </div>
                        </Media>
                      </Media>
                      : null
                  }
                  <Media className='border p-3 my-2'>
                    <Media body>
                      <h5 className="font-size-14">
                        Resoinsible Agent ID: {organizers && organizers.agentOverview && organizers.agentOverview?.uniqueId ? organizers.agentOverview?.uniqueId : "Not set"}
                      </h5>
                      <div className='d-flex gap-4'>
                        <p className="text-muted m-0">
                          <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2"></i>
                          {organizers && organizers.agentOverview && organizers.agentOverview?.legalBusinessName ? organizers.agentOverview?.legalBusinessName : "Not set"}
                        </p>
                        <p className="text-muted m-0">
                          <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2"></i>
                          {organizers && organizers.agentOverview && organizers.agentOverview?.country ? organizers.agentOverview?.country : "Not set"}
                        </p>
                        <p className="text-muted m-0">
                          <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2"></i>
                          {organizers && organizers.agentOverview && organizers.agentOverview?.city ? organizers.agentOverview?.city : "Not set"}
                        </p>
                      </div>
                    </Media>
                  </Media>
                  <Media className='border p-3 my-2'>
                    <Media body>
                      <h5 className="font-size-14">
                        Merchant/ST ID: {organizers && organizers.merchantOverview && organizers.merchantOverview?.uniqueId ? organizers.merchantOverview?.uniqueId : "Not set"}
                      </h5>
                      <div className='d-flex gap-4'>
                        <p className="text-muted m-0">
                          <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2"></i>
                          {organizers && organizers.merchantOverview && organizers.merchantOverview?.legalBusinessName ? organizers.merchantOverview?.legalBusinessName : "Not set"}
                        </p>
                        <p className="text-muted m-0">
                          <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2"></i>
                          {organizers && organizers.merchantOverview && organizers.merchantOverview?.country ? organizers.merchantOverview?.country : "Not set"}
                        </p>
                        <p className="text-muted m-0">
                          <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2"></i>
                          {organizers && organizers.merchantOverview && organizers.merchantOverview?.city ? organizers.merchantOverview?.city : "Not set"}
                        </p>
                      </div>
                    </Media>
                  </Media>
                </div>
                <Table className="table align-middle table-nowrap" bordered>
                  <tbody>
                    <tr>
                      <td><h5 className="font-size-12 mb-1">Payment Type</h5></td>
                      <td><h5 className="font-size-12 mb-0 ">{transactionDetails.paymentTypeName ? transactionDetails.paymentTypeName : "Not set"}</h5></td>
                    </tr>
                    <tr>
                      <td><h5 className="font-size-12 mb-1">Transaction Type</h5></td>
                      <td><h5 className="font-size-12 mb-0 ">{transactionDetails.paymentMethod ? transactionDetails.paymentMethod : "Not set"}</h5></td>
                    </tr>
                    <tr>
                      <td><h5 className="font-size-12 mb-1">Token</h5></td>
                      <td><h5 className="font-size-12 mb-0 ">{transactionDetails.token ? transactionDetails.token : "Not set"}</h5></td>
                    </tr>
                    <tr>
                      <td><h5 className="font-size-12 mb-1">Wallet</h5></td>
                      <td><h5 className="font-size-12 mb-0 ">{transactionDetails.wallet ? transactionDetails.wallet : "Not set"}</h5></td>
                    </tr>
                    <tr>
                      <td><h5 className="font-size-12 mb-1">Blockchain</h5></td>
                      <td><h5 className="font-size-12 mb-0 ">{transactionDetails.blockchain ? transactionDetails.blockchain : "Not set"}</h5></td>
                    </tr>
                  </tbody>
                </Table>
                <hr />
                <div className='border p-3'>
                  <div className="d-flex mb-2">
                    <i className="mdi mdi-circle-medium align-middle text-primary me-1"></i>
                    <div className='d-flex justify-content-between w-100'>
                      <p className='m-0'>
                        Paying Customer Transaction Value: (<i className="bx bx-caret-down text-danger"></i>)
                      </p>
                      <b>
                        {transactionDetails.payingCustomerTransactionValue ? transactionDetails.payingCustomerTransactionValue : "Not set"}
                      </b>
                    </div>
                  </div>
                  <div className="d-flex">
                    <i className="mdi mdi-circle-medium align-middle text-primary me-1"></i>
                    <div className='d-flex justify-content-between w-100'>
                      <p className='m-0'>
                        Merchant/ST Transaction Value: (<i className="bx bx-caret-down text-danger"></i>)
                      </p>
                      <b>
                        {transactionDetails.merchantTransactionValue ? transactionDetails.merchantTransactionValue : "Not set"}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              : <NoData width={150} />
          }
        </CardBody>

      </Card>
    </>
  )
}

export default TransactionDetails