import React, { useState } from "react"
import {
  Col,
  Card,
  Nav,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import classnames from "classnames"

//Simple bar
import SimpleBar from "simplebar-react"
import NoData from "../../../../components/NoData/NoData"
import MainService from "../../../../Services/MainService"
import moment from "moment"

const QuickView = ({ data, payignCustomer = null }) => {

  const [activeTab, setactiveTab] = useState(payignCustomer ? "1" : "2")

  return (
    <Card className="quick-view">
      <CardBody>
        <Nav pills className="bg-light rounded flex-nowrap text-center gap-1" role="tablist">
          {
            payignCustomer ?
              <NavItem className="rounded w-100">
                <NavLink
                  className={`h-100 ${classnames({ active: activeTab === "1" })}`}
                  onClick={() => {
                    setactiveTab("1")
                  }}
                >
                  Quick View Paying Customers
                </NavLink>
              </NavItem>
              : null
          }
          <NavItem className="rounded w-100">
            <NavLink
              className={`h-100 ${classnames({ active: activeTab === "2" })}`}
              onClick={() => {
                setactiveTab("2")
              }}
            >
              Quick View Responsible Agent
            </NavLink>
          </NavItem>
          <NavItem className="rounded w-100">
            <NavLink
              className={`h-100 ${classnames({ active: activeTab === "3" })}`}
              onClick={() => {
                setactiveTab("3")
              }}
            >
              Quick View Merchant/ST
            </NavLink>
          </NavItem>
        </Nav>
        {
          data ?
            <TabContent activeTab={activeTab} className="mt-4">
              {
                payignCustomer ?
                  <TabPane tabId="1" style={{ minHeight: "250px" }}>
                    <SimpleBar>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap">
                          <tbody>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Full Name:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{payignCustomer?.fullName || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Email:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{payignCustomer?.email || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Telephone Number:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{payignCustomer?.phoneNumber ? MainService.formatPhoneNumber(payignCustomer.phoneNumber) : "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Address:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{payignCustomer?.address || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Wallet Address:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{payignCustomer?.walletAddress || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">KYC Level:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{payignCustomer?.kycLevel || "Not set"}</h5></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </SimpleBar>
                  </TabPane>
                  : null
              }
              <TabPane tabId="2" style={{ minHeight: "250px" }}>
                <SimpleBar>
                  {
                    data.agentOverview ?
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap">
                          <tbody>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Legal Business Name:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.agentOverview?.legalBusinessName || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Telephone Number:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.agentOverview?.phoneNumber ? MainService.formatPhoneNumber(data.agentOverview?.phoneNumber) : "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Liveness Test Status:</h5></td>
                              <td>
                                <h5 className="font-size-12 mb-0 ">
                                  <span className={`badge badge-soft-${data.agentOverview?.livenessTestStatus ? "success" : "danger"} font-size-14 badge badge-pill`}>
                                    {data.agentOverview?.livenessTestStatus ? "Verified" : "Not verified"}
                                  </span>
                                </h5>
                              </td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Bank Account:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.agentOverview?.bankAccount || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Business Registered Address:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.agentOverview?.businessRegisteredAddress || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">KYC Level:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.agentOverview?.kycLevel || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Fiat Off-Ramp Interval:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.agentOverview?.fiatOffRampInterval || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Cutoff Time:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.agentOverview?.cutoffTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.agentOverview?.cutoffTime))).format("LL HH:mm") : "Not set"}</h5></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      : <NoData width={150} />
                  }
                </SimpleBar>
              </TabPane>

              <TabPane tabId="3" style={{ minHeight: "250px" }}>
                <SimpleBar>
                  {
                    data.merchantOverview ?

                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap">
                          <tbody>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Legal Business Name:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.merchantOverview?.legalBusinessName || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Telephone Number:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.merchantOverview?.phoneNumber ? MainService.formatPhoneNumber(data.merchantOverview?.phoneNumber) : "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Liveness Test Status:</h5></td>
                              <td>
                                <h5 className="font-size-12 mb-0 ">
                                  <span className={`badge badge-soft-${data.merchantOverview?.livenessTestStatus ? "success" : "danger"} font-size-14 badge badge-pill`}>
                                    {data.merchantOverview?.livenessTestStatus ? "Verified" : "Not verified"}
                                  </span>
                                </h5>
                              </td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Bank Account:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.merchantOverview?.bankAccount || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Business Registered Address:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.merchantOverview?.businessRegisteredAddress || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">KYC Level:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.merchantOverview?.kycLevel || "Not set"}</h5></td>
                            </tr>
                            <tr>
                              <td><h5 className="font-size-12 mb-1">Fiat Off-Ramp Interval:</h5></td>
                              <td><h5 className="font-size-12 mb-0 ">{data.merchantOverview?.fiatOffRampInterval || "Not set"}</h5></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      : <NoData width={150} />
                  }
                </SimpleBar>
              </TabPane>
            </TabContent>
            : null
        }
      </CardBody>
    </Card>
  )
}

export default QuickView
