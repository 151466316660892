import React, { useState, useRef } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Row,
  Table,
} from "reactstrap"
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import { addRightSectionSpinner, removeRightSectionSpinner } from '../../../store/actions'
import DashboardApiService from '../../../Services/DashboardApiService';
import AlertService from '../../../Services/alertService'
import { ERROR_KEY, VALID_IMAGE_TYPES_KEY } from '../../../Constants/MainKeys'
import { useEffect } from 'react'
import NoData from './../../../components/NoData/NoData';
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import moment from 'moment'
import MainService from '../../../Services/MainService'

const buttonSpinnerId = uuid();

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const ClientBankingInformation = (props) => {

  const title = "Client Database";
  const description = "";
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { clientId } = props.match.params;
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);
  const [bankAccount, setBankAccount] = useState(null);
  const [zoomImagePath, setZoomImagePath] = useState(null);

  useOutsideAlerter(wrapperRef, setZoomImagePath);

  useEffect(() => {
    getOrgBankAccount();
  }, [])

  const getOrgBankAccount = () => {
    if (!user.activeAgentId) { return false; }
    const spinnerId = uuid();
    dispatch(addRightSectionSpinner(spinnerId));
    DashboardApiService.getOrgBankAccountAsAgent(clientId).then(response => {
      if (response && response.data) {
        setBankAccount(response.data);
      }
    }).catch(error => {
      AlertService.alert(ERROR_KEY, error);
    }).finally(() => {
      dispatch(removeRightSectionSpinner(spinnerId))
    })
  }

  const viewProof = (path) => {
    if (!path) { return false; }
    if (MainService.getFxtensions(path) === "image") {
      setZoomImagePath(path)
    } else {
      window.open(path, "_blank")
    }
  }

  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />

        <div className='container-fluid'>

          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Merchant Banking Information"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Table bordered striped responsive className='mb-0'>
                        <tbody>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Account Name
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.accountName}>{bankAccount?.accountName}</span></td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Account No
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.accountNo}>{bankAccount?.accountNo}</span></td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Bank Name
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.bankName}>{bankAccount?.bankName}</span></td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Branch Name
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.branchName}>{bankAccount?.branchName}</span></td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              iBan
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.iBan}>{bankAccount?.iBan}</span></td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Swift Code
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.swiftCode}>{bankAccount?.swiftCode}</span></td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Routing Type
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.routingType}>{bankAccount?.routingType}</span></td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Routing Value
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.routingValue}>{bankAccount?.routingValue}</span></td>
                          </tr>

                        </tbody>
                      </Table>
                    </Col>
                    <Col md={6}>
                      <Table bordered striped responsive className='mb-0'>
                        <tbody>

                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Account Country
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.accountCountry}>{bankAccount?.accountCountry}</span></td>
                          </tr>

                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Bank Address
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.bankAddress}>{bankAccount?.bankAddress}</span></td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Bank Code
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.bankCode}>{bankAccount?.bankCode}</span></td>
                          </tr>

                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Branch Address
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.branchAddress}>{bankAccount?.branchAddress}</span></td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Branch Code
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.branchCode}>{bankAccount?.branchCode}</span></td>
                          </tr>

                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              City
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.city}>{bankAccount?.city}</span></td>
                          </tr>

                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              State/Province
                            </th>
                            <td className='w-50'><span className='max-line-1' title={bankAccount?.province}>{bankAccount?.province}</span></td>
                          </tr>
                          <tr>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              Bank Address proof file
                            </th>
                            <td className='w-50'>
                              {
                                !bankAccount?.bankAddressFile ? <span className='text-danger'>No attached file yet</span> :
                                  <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => {
                                      viewProof(bankAccount?.bankAddressFile.filePath)
                                    }}
                                  >
                                    View Proof
                                  </Button>
                              }
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {/* <Card>
                <CardBody>
                  <Row>
                    {
                      bankAccount ?
                        <Col sm={12}>
                          <h4 className='card-title'>Bank Account Information</h4>
                          <hr />
                          {
                            <Row className='mt-4'>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Country:</p>
                                  <p>
                                    <b>{bankAccount.countryName}</b>
                                  </p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>State/Province:</p>
                                  <p><b>{bankAccount.province}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>City:</p>
                                  <p><b>{bankAccount.city}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Bank Address:</p>
                                  <p><b>{bankAccount.bankAddress}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Bank Name:</p>
                                  <p><b>{bankAccount.bankName}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Branch Address:</p>
                                  <p><b>{bankAccount.branchAddress}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Branch Name:</p>
                                  <p><b>{bankAccount.branchName}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Bank Code:</p>
                                  <p><b>{bankAccount.bankCode}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Swift Code:</p>
                                  <p><b>{bankAccount.swiftCode}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Account Name:</p>
                                  <p><b>{bankAccount.accountName}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Account Number:</p>
                                  <p><b>{bankAccount.accountNumber}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Active:</p>
                                  <p><b>{bankAccount.isActive ? "Active" : "Inactive"}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Branch Code:</p>
                                  <p><b>{bankAccount.branchCode}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Create Time:</p>
                                  <p><b>{moment(new Date(bankAccount.createTime)).format("LL HH:mm")}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Update Time:</p>
                                  <p><b>{moment(new Date(bankAccount.updateTime)).format("LL HH:mm")}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Routing Number:</p>
                                  <p><b>{bankAccount.routingNumber}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Routing Type:</p>
                                  <p><b>{bankAccount.routingType}</b></p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className='d-flex justify-content-between mt-1'>
                                  <p>Routing Value:</p>
                                  <p><b>{bankAccount.routingValue}</b></p>
                                </div>
                              </Col>
                              {
                                bankAccount.bankAddressFile ?
                                  <Col sm={12}>
                                    <hr />
                                    <Row>
                                      <Col md={6}>
                                        <div className='d-flex justify-content-between mt-1'>
                                          <p>Bank AddressFile:</p>
                                          {
                                            (() => {
                                              switch (MainService.getFxtensions(bankAccount.bankAddressFile.filePath)) {
                                                case "image":
                                                  return <p
                                                    onClick={() => setZoomImagePath(bankAccount.bankAddressFile.filePath)}
                                                  >
                                                    <img
                                                      className='rounded border cursor-pointer'
                                                      src={bankAccount.bankAddressFile.filePath}
                                                      alt='/'
                                                      width={150}
                                                    />
                                                  </p>
                                                case "file":
                                                  return <p
                                                    onClick={() => window.open(bankAccount.bankAddressFile.filePath, "_blank")}
                                                  >
                                                    <i className='bx bx-file-blank' style={{ fontSize: "50px" }} ></i>
                                                  </p>
                                                default:
                                                  break;
                                              }
                                            })()
                                          }
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  : null
                              }
                            </Row>
                          }
                        </Col>
                        : <NoData />
                    }
                  </Row>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ClientBankingInformation;
