import React, { useState, useEffect, useCallback, useRef } from 'react'
import HtmlHead from '../../../components/HtmlHead/HtmlHead';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Card,
  CardBody,
  Row,
  Label,
  Input,
  Button,
  Col,
  UncontrolledAlert
} from "reactstrap"
import AlertService from '../../../Services/alertService';
import MainService from '../../../Services/MainService';
import { Link } from 'react-router-dom';
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import { useDispatch, useSelector } from 'react-redux';
import { ERROR_KEY, SPINNER_COLOR, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY, fielsLengths } from '../../../Constants/MainKeys';
import ActionButton from '../../../components/Buttons/ActionButton';
import uuid from 'react-uuid';
import PhoneInput from 'react-phone-input-2'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions';
import DashboardApiService from '../../../Services/DashboardApiService';
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption';
import { PuffLoader } from 'react-spinners';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import Switch from "react-switch"
import { updateUserData } from '../../../store/actions';
import OnIcon from '../../../components/OnIcon/OnIcon';
import OffIcon from '../../../components/OffIcon/OffIcon';


const spinnerId = uuid();
const buttonSpinnerId = uuid();

export default function CompanyInformation(props) {

  const title = "Company Information";
  const description = "";
  const mainService = new MainService();

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Login)
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [isChanged, setIsChanged] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [countries, setCountries] = useState([]);
  const [industries, setIndustries] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledFields, setIsDisabledFields] = useState(false);

  const [values, setValues] = useState({
    countryId: null,
    organizationName: "",
    phoneNumber: "",
    industryLibraryDataId: "",
    websiteUrl: "",
    statementDescriptor: "",
    shortenDescriptor: "",
    supportNumber: "",
    supportAddress: "",
    organizationEmail: "",
    isOrganizationActive: false,

  })

  useEffect(() => {
    getOrganization();
  }, [])

  const getOrganization = () => {
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getOrganization().then(response => {
      if (response && response.data) {
        const { countries, industries, ...newState } = response.data;
        setValues(newState);
        industries && setIndustries(industries);
        countries && setCountries(countries);
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const uploadFile = event => {
    if (!event.target.files[0]) { return; }
    const file = event.target.files[0];
    if (file) {
      mainService.readFile(file, VALID_IMAGE_TYPES_KEY).then(uploadedImage => {
        const formData = new FormData();
        formData.append("logoFile", file);
        setIsLoading(true);
        DashboardApiService.uploadOrganizationLogo(formData).then(response => {
          if (response && response.data) {
            setValues((values) => ({
              ...values,
              filePath: response.data.filePath,
              logoFileLibraryId: response.data.fileLibraryId,
            }))
          }
        }).catch(error => getFail(error)).finally(() => {
          setIsLoading(false);
        })

      }).catch(error => error && AlertService.alert("error", "Invalid file type"));
    };
  }

  const removeOrganizationLogo = (event) => {
    event && event.stopPropagation();
    AlertService.alertConfirm(
      `Are you sure?`,
      "Please confirm if you want to delete your organization's account logo.",
      "Yes",
      "No"
    ).then(() => {
      setIsLoading(true);
      DashboardApiService.deleteOrganizationLogo(values.logoFileLibraryId).then(response => {
        setValues((values) => ({
          ...values,
          filePath: "",
        }))
        AlertService.alert(SUCCESS_KEY, "Logo deleted successfully")
      }).catch(error => getFail(error)).finally(() => {
        setIsLoading(false)
      })
    })
  }

  const onChangeIsActive = () => {
    AlertService.alertConfirm(
      `Are you sure?`,
      "Changing the status will affect your activity. Please ensure careful consideration before making any status adjustments.",
      "Yes",
      "No"
    ).then(() => {
      setIsDisabledFields(true)
      const data = {
        id: values.id,
        updaterUserId: null,
        status: !values.isOrganizationActive,
      }
      DashboardApiService.organizationUpdateStatusAsMerchant(data).then(() => {
        const userCopy = { ...user };
        userCopy.isOrganizationActive = !user.isOrganizationActive
        dispatch(updateUserData(userCopy))
        AlertService.alert(SUCCESS_KEY, "Data saved")
      }).catch(error => getFail(error)).finally(() => {
        setIsDisabledFields(false)
      })
    })
  }


  const onChange = (event, field, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false)
  }

  const onPhoneNumberChange = (event, field) => {
    setValues((values) => ({
      ...values,
      [field]: event,
    }));
    setIsInvalidSubmit(false)
  }

  const onSelectOptionChange = (value, field) => {
    setValues((values) => ({
      ...values,
      [field]: value,
    }));
    setIsChanged(true);
    setIsInvalidSubmit(false)
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (
      !values.organizationName.trim().length ||
      !values.countryId
    ) {
      setIsInvalidSubmit(true);
      return false;
    }
    setButtonSpinner(buttonSpinnerId)
    DashboardApiService.updateOrganization(values).then(() => {
      AlertService.alert(SUCCESS_KEY, "Organization updated successfully");
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }).catch(error => getFail(error)).finally(() => {
      extractButtonSpinner(buttonSpinnerId);
      setIsChanged(false);
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (

    <section className='page-content position-relative min-h-100'>
      <HtmlHead title={title} description={description} />
      <RightSectionSpinner spinner={rightSectionSpinners} />
      <div className="container-fluid">
        {
          !user.isOrganizationActive ?
            <UncontrolledAlert color="danger" role="alert">
              <i className="mdi mdi-block-helper me-2"></i>
              Attention: Your company's inactive status may limit your opportunities. This status is under your control. Your account can be approved by Cryllex administration, but you may deactivate your company at any time.
            </UncontrolledAlert>
            : null
        }
        <form onSubmit={onSubmit} onChange={() => setIsChanged(true)}>
          <div>
            <Breadcrumbs
              title="Cryllex"
              breadcrumbItem="Company Information"
            />
            <Row className="justify-content-center">
              <Card>
                <CardBody>
                  <Col>
                    <Row>
                      <Col md={3} className='text-center'>
                        {
                          isLoading ?
                            <Auxiliary>
                              <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "100px", }}>
                                <PuffLoader size={50} color={SPINNER_COLOR} />
                              </div>
                              <hr />
                            </Auxiliary>
                            :
                            <div className='my-4'>
                              <div>
                                {
                                  !values.filePath ?
                                    <div>
                                      <i className='bx bx-image text-muted' style={{ fontSize: "80px" }}></i>
                                    </div>
                                    : <div className='position-relative m-auto' style={{ width: "100px", height: "100px" }}>
                                      <div
                                        style={{ width: "100%", height: "100%", }}
                                        className='d-flex justify-content-center align-items-center flex-column'
                                      >
                                        <img src={values.filePath} className='img-thumbnail rounded-circle' style={{ width: "100%", height: "100%" }} />
                                      </div>
                                      <i
                                        className='bx bx-x position-absolute font-size-20 text-danger bg-white rounded cursor-pointer border'
                                        style={{ top: "1px", right: "1px" }}
                                        onClick={removeOrganizationLogo}
                                      />
                                    </div>
                                }
                                {
                                  !values.filePath ?
                                    <Button
                                      onClick={MainService.triggerUploadClick}
                                      type="button"
                                      color="primary"
                                      outline
                                      className='mt-2'
                                    >
                                      <input type="file" id='avatarImage' className="d-none" hidden onChange={uploadFile} />
                                      Upload Logo
                                    </Button>
                                    :
                                    null
                                }
                                <h4 className='p-3'>{values.organizationName}</h4>
                              </div>
                            </div>
                        }
                      </Col>
                      <Col md={9}>
                        <Row>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='organizationName' className='text-muted text-truncate mb-0'>Company Name*</Label>
                            <Input
                              id="organizationName"
                              placeholder='e.g. The Charter Company Inc.'
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.organizationName.trim().length) ? "error-border" : ""}`}
                              value={values.organizationName || ""}
                              onChange={event => onChange(event, "organizationName", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='organizationEmail' className='text-muted text-truncate mb-0'>Company Email Address</Label>
                            <Input
                              id="organizationEmail"
                              placeholder='e.g. main@charter.com '
                              type="text"
                              className={`form-control`}
                              value={values.organizationEmail || ""}
                              onChange={event => onChange(event, "organizationEmail", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='websiteUrl' className='text-muted text-truncate mb-0'>Website URL</Label>
                            <Input
                              id="websiteUrl"
                              placeholder='e.g. thechartercompany.com'
                              type="text"
                              className={`form-control`}
                              value={values.websiteUrl || ""}
                              onChange={event => onChange(event, "websiteUrl", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='phoneNumber' className='text-muted text-truncate mb-0'>Primary Phone Number</Label>
                            <PhoneInput
                              country={""}
                              specialLabel=""
                              value={values.phoneNumber || ""}
                              className={`custom-phone-number-input-block ${(isInvalidSubmit && !values.phoneNumber.trim().length) ? "error-border" : ""}`}
                              onChange={(event) => onPhoneNumberChange(event, "phoneNumber")}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='supportNumber' className='text-muted text-truncate mb-0'>Secondary Phone Number</Label>
                            <PhoneInput
                              country={""}
                              specialLabel=""
                              value={values.supportNumber || ""}
                              className={`custom-phone-number-input-block ${(isInvalidSubmit && !values.supportNumber.trim().length) ? "error-border" : ""}`}
                              onChange={(event) => onPhoneNumberChange(event, "supportNumber")}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='supportAddress' className='text-muted text-truncate mb-0'>Primary Business Address</Label>
                            <Input
                              id="supportAddress"
                              placeholder='e.g. 2708 Catherine Drive, Sod, West Virginia, United States'
                              type="text"
                              className={`form-control resize-none`}
                              value={values.supportAddress || ""}
                              onChange={event => onChange(event, "supportAddress", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='countryId' className='text-muted text-truncate mb-0'>Country*</Label>
                            {
                              countries && countries.length ?
                                <ReactSelectOption
                                  value={values.countryId}
                                  isSearchable={true}
                                  className={`${(isInvalidSubmit && !values.countryId) ? "error-border" : ""}`}
                                  selectedValue={(() => {
                                    const selectedValue = { ...countries.find(data => data.id === values.countryId) };
                                    if (Object.keys(selectedValue).length) {
                                      selectedValue.label = selectedValue.name;
                                      selectedValue.value = selectedValue.id;
                                      return selectedValue;
                                    } else {
                                      return { label: "Choose...", value: "" }
                                    }
                                  })()}
                                  items={countries.map(data => ({ label: data.name, value: data.id }))}
                                  onChange={(item) => onSelectOptionChange(item.value, "countryId")}
                                />
                                : null
                            }
                          </Col>
                          {
                            industries ?
                              <Col lg={4} md={6} className="mb-4">
                                <Label htmlFor='industryLibraryDataId' className='text-muted text-truncate mb-0'>{industries.displayName}</Label>
                                {
                                  industries.libraryData && industries.libraryData.length ?
                                    <ReactSelectOption
                                      value={values.industryLibraryDataId}
                                      isSearchable={true}
                                      className={`${(isInvalidSubmit && !values.industryLibraryDataId) ? "error-border" : ""}`}
                                      selectedValue={(() => {
                                        const selectedValue = { ...industries.libraryData.find(data => data.id === values.industryLibraryDataId) };
                                        if (Object.keys(selectedValue).length) {
                                          selectedValue.label = selectedValue.displayName;
                                          selectedValue.value = selectedValue.id;
                                          return selectedValue;
                                        } else {
                                          return { label: "Choose...", value: "" }
                                        }
                                      })()}
                                      items={industries.libraryData.map(data => ({ label: data.displayName, value: data.id }))}
                                      onChange={(item) => onSelectOptionChange(item.value, "industryLibraryDataId")}
                                    />
                                    : null
                                }
                              </Col>
                              : null
                          }

                          <Col lg={4} md={6} className="mb-4">
                            <Label htmlFor='countryId' className='text-muted text-truncate mb-0 d-flex'>
                              Organization Status
                              <p className={`ms-2 mb-0 text-${user.isOrganizationActive ? "success" : "danger"}`}>({user.isOrganizationActive ? "Active" : "Inactive"})</p>
                            </Label>
                            <div className='d-flex align-items-center mt-1'>
                              <Switch
                                className="react-switch mt-1"
                                size={1}
                                checkedIcon={<OnIcon />}
                                uncheckedIcon={<OffIcon />}
                                checked={user.isOrganizationActive}
                                disabled={isDisabledFields}
                                aria-labelledby="neat-label"
                                onChange={onChangeIsActive}
                              />
                            </div>
                          </Col>
                          <Col lg={12} className="mb-4">
                            <Label htmlFor='shortenDescriptor' className='text-muted text-truncate mb-0'>Company Description</Label>
                            <Input
                              id="shortenDescriptor"
                              type="textarea"
                              placeholder='e.g. Tell us something about your business...'
                              rows="4"
                              className={`form-control resize-none`}
                              value={values.shortenDescriptor || ""}
                              onChange={event => onChange(event, "shortenDescriptor", fielsLengths.length_2000)}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <div className='d-flex justify-content-end'>
                    <ActionButton
                      type="submit"
                      name="Submit"
                      className="btn btn-primary btn-block px-4"
                      spinnerId={buttonSpinnerId}
                      disabled={
                        !isChanged ||
                        !values.organizationName?.trim().length ||
                        !values.countryId
                      }

                    />
                  </div>
                </CardBody>
              </Card>
            </Row>
          </div>

        </form>
      </div>
    </section>

  )
}
