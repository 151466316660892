import React, { useCallback, useEffect, useRef } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledAlert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap"
import Dropdown from 'react-bootstrap/Dropdown';
import { Table } from "react-bootstrap"
import { ERROR_KEY, SUCCESS_KEY, allValidFileTypes, fielsLengths } from '../../../Constants/MainKeys'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import DashboardApiService from '../../../Services/DashboardApiService'
import { useState } from 'react'
import NoData from '../../../components/NoData/NoData'
import MainService from '../../../Services/MainService'
import ActionButton from '../../../components/Buttons/ActionButton'
import LevelOneRequirementsForm from './components/LevelOneRequirementsForm'
import LevelOneRequirements from './components/LevelOneRequirements'
import LevelTwoRequirementsForm from './components/LevelTwoRequirementsForm'
import LevelTwoRequirements from './components/LevelTwoRequirements'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import LevelThreeRequirements from './components/LevelThreeRequirements'
import LevelThreeRequirementsForm from './components/LevelThreeRequirementsForm'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import ApiService from '../../../Services/ApiService';

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const buttonSpinnerId = uuid();

const AgentKYC = (props) => {

  const { agentId } = props.match.params;

  const dispatch = useDispatch();
  const title = "Agent KYC";
  const description = "";
  const { buttonSpinners } = useSelector(state => state.Spinners);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);
  const mainService = new MainService();
  const wrapperRef = useRef(null);
  const [zoomImagePath, setZoomImagePath] = useState(null);
  useOutsideAlerter(wrapperRef, setZoomImagePath);
  const [isForm, setIsForm] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [customActiveTab, setCustomIconActiveTab] = useState(localStorage.getItem("activeTab") || "1");
  const [agentData, setAgentData] = useState(null);
  const [_agentData, _setAgentData] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isDisabledFields, setIsDisabledFields] = useState(false);
  const [kycLevels, setKycLevels] = useState([]);
  const [toggleCollapse, setToggleCollapse] = useState(false);
  const [confirmLoaderId, setConfirmLoaderId] = useState(null);

  const [isShowRejectModal, setIsShowRejectModal] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");

  const [currencies, setCurrencies] = useState([]);
  const [countrySpinnerArr, setCountrySpinnerArr] = useState([]);
  const [payoutMethods, setPayoutMethods] = useState([]);

  useEffect(() => {
    getAgentKycDataById(agentId)
  }, [agentId])

  useEffect(() => {
    getCountries();
    getKycLevels();
  }, [])

  const getCountries = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  useEffect(() => {
    if (agentData) {
      let kycData = [...kycLevels];
      if (agentData && !agentData.levelOne) {
        kycData = kycData.filter(item => item.key !== 1)
      }
      if (!agentData.levelTwo) {
        kycData = kycData.filter(item => item.key !== 2)
      }
      if (!agentData.levelThree) {
        kycData = kycData.filter(item => item.key !== 3)
      }
      setKycLevels(kycData)
    }
  }, [agentData])

  const getKycLevels = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    DashboardApiService.getKycLevels().then(response => {
      if (response && response.data) {
        setKycLevels(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  // const confirmProof = (data) => {
  //   const { fileObj, fieldName } = data;
  //   let entityId = agentData[fileObj]?.id;
  //   let fileLibraryId = agentData[fileObj][fieldName].fileLibraryId || null;
  //   if (confirmLoaderId === fileLibraryId) {
  //     return false;
  //   }
  //   AlertService.alertConfirm(
  //     `Are you sure?`,
  //     "Confirming the document makes it legal for Cryllex and partner agents.",
  //     "Yes",
  //     "No"
  //   ).then(() => {
  //     setConfirmLoaderId(fileLibraryId);
  //     let _data = { entityId: entityId, fileLibraryId: fileLibraryId };
  //     DashboardApiService.approveOrganizationKYCDocument(_data).then(response => {
  //       let _obj = agentData[fileObj][fieldName];
  //       _obj.isActive = response.data || false;
  //       setAgentData((values) => ({
  //         ...values,
  //         [fileObj]: {
  //           ...values[fileObj],
  //           [fieldName]: _obj,
  //         }
  //       }));
  //       AlertService.alert(SUCCESS_KEY, "File confirmed successfully")
  //     }).catch(error => getFail(error)).finally(() => {
  //       setConfirmLoaderId(null);
  //     })
  //   })
  // }

  const getAgentKycDataById = (agentId) => {
    if (!agentId) { return false; }
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getAgentKycDataById(agentId).then(response => {
      if (response && response.data) {
        let data = { ...response.data }
        if (response.data.levelTwo && data.levelTwo.files && data.levelTwo.files.length) {
          const groupedFiles = data.levelTwo.files.reduce((groups, file) => {
            const key = file.fileType;
            (groups[key] = groups[key] || []).push(file);
            return groups;
          }, {});
          const arrayOfArrays = Object.values(groupedFiles);
          if (arrayOfArrays && arrayOfArrays.length) {
            arrayOfArrays.forEach(item => {
              data.levelTwo[MainService.getOrganizationDetailsFileNameByType(item[0].fileType)] = item;
            })
          }
        }
        if (data.levelOne.userFiles && data.levelOne.userFiles.length) {
          data.levelOne.userFiles.forEach(item => {
            data.levelOne[MainService.getUserFileNameByType(item.fileType)] = item;
          })
        }
        setAgentData(data);
        _setAgentData(data);

      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId);
    })
  }

  const onChange = (event, obj, fieldName, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setAgentData((values) => ({
      ...values,
      [obj]: {
        ...values[obj],
        [fieldName]: event.target.value,
      }
    }));
  }

  const _onChange = (event, cb, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value)
    setIsInvalidSubmit(false);
  }

  const onNumberChange = (event, obj, fieldName, maxLength = Infinity) => {
    if (event.target.value.includes("e")) { return false };
    if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value) >= 0 && Number(event.target.value) <= maxLength)) {
      setAgentData((values) => ({
        ...values,
        [obj]: {
          ...values[obj],
          [fieldName]: event.target.value,
        }
      }));
    }
  };

  const onSelectOptionChange = (value, obj, fieldName) => {
    setAgentData((values) => ({
      ...values,
      [obj]: {
        ...values[obj],
        [fieldName]: value,
      }
    }));
  }

  // const onCheckboxChange = (event, obj, fieldName) => {
  //   setAgentData((values) => ({
  //     ...values,
  //     [obj]: {
  //       ...values[obj],
  //       [fieldName]: event.target.checked,
  //     }
  //   }));
  // }



  useEffect(() => {
    if (agentData && agentData.levelThree) {
      if (agentData?.levelThree?.countryId) {
        getPaymentMethodsByCountryId(agentData?.levelThree?.countryId);
      }
    }
  }, [agentData && agentData?.levelThree && agentData?.levelThree?.countryId])

  const getPaymentMethodsByCountryId = (countryId) => {
    if (!countryId) { return false; }
    const spinnerId = uuid();
    setCountrySpinnerArr(prevState => [...prevState, spinnerId])
    ApiService.getPaymentMethodsByCountryId(countryId).then(response => {
      if (response && response.data) {
        setPayoutMethods(response.data)
      } else {
        setPayoutMethods([]);
      }
    }).catch(error => getFail(error)).finally(() => {
      setCountrySpinnerArr(countrySpinnerArr.filter(item => item !== spinnerId))
    })
  }

  useEffect(() => {
    if (agentData && agentData.levelThree) {
      if (agentData?.levelThree?.countryId && agentData.levelThree.paymentMethodLibraryDataId) {
        getFiatCurrencies(agentData?.levelThree?.countryId, agentData.levelThree.paymentMethodLibraryDataId);
      }
    }

  }, [agentData && agentData?.levelThree && agentData?.levelThree?.countryId, agentData && agentData?.levelThree && agentData.levelThree.paymentMethodLibraryDataId])

  const getFiatCurrencies = (countryId, paymentMethodId) => {
    if (!countryId || !paymentMethodId) { return false; }
    const spinnerId = uuid();
    setCountrySpinnerArr(prevState => [...prevState, spinnerId])
    ApiService.getFiatCurrenciesByCountryIdAndPaymentMethodId(countryId, paymentMethodId).then(response => {
      if (response && response.data) {
        setCurrencies(response.data);
      } else {
        setCurrencies([]);
      }
    }).catch(error => getFail(error)).finally(() => {
      setCountrySpinnerArr(countrySpinnerArr.filter(item => item !== spinnerId))
    })
  }

  const onSwitcherChange = (bool, obj, fieldName) => {
    AlertService.alertConfirm(
      `Are you sure?`,
      "Do you want to update agent data liveness test status.",
      "Yes",
      "No"
    ).then(() => {
      let data = {
        id: +agentData[obj]?.id || null,
        updaterUserId: null,
        status: bool,
      }
      setIsDisabledFields(true);
      DashboardApiService.updateAgentDataLivenessTestStatus(data).then(response => {
        setAgentData((values) => ({
          ...values,
          [obj]: {
            ...values[obj],
            [fieldName]: bool,
          }
        }));
        AlertService.alert(SUCCESS_KEY, "Agent data liveness test status updated successfully")
      }).catch(error => getFail(error)).finally(() => {
        setIsDisabledFields(false);
      })
    })
  }

  const onStatusSwitcherChange = (bool, obj, fieldName) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "You want to update bank account status.",
      "Yes",
      "No"
    ).then(() => {
      let data = {
        id: +agentData[obj]?.id || null,
        updaterUserId: null,
        status: bool,
        entityType: 2,
      }
      setIsDisabledFields(true);
      DashboardApiService.updateBankAccountStatusAsAdmin(data).then(response => {
        setAgentData((values) => ({
          ...values,
          [obj]: {
            ...values[obj],
            [fieldName]: bool,
          }
        }));
        _setAgentData((values) => ({
          ...values,
          [obj]: {
            ...values[obj],
            [fieldName]: bool,
          }
        }));
        AlertService.alert(SUCCESS_KEY, "Bank account status updated successfully")
      }).catch(error => getFail(error)).finally(() => {
        setIsDisabledFields(false);
      })
    })
  }

  const uploadFile = async (data) => {
    const { event, obj, fieldName, checkFiltType, method, id } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();

    let files = [...event.target.files];
    let file = files[0];
    const fileName = file.name;
    const lastDotIndex = fileName.lastIndexOf('.');
    const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
    if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
      formData.append("id", id);
      formData.append("document", file);
      formData.append("type", checkFiltType(fieldName));
      method(formData).then(response => {
        setAgentData((values) => ({
          ...values,
          [obj]: {
            ...values[obj],
            [fieldName]: response.data,
          }
        }));
        AlertService.alert(SUCCESS_KEY, "Data saved")
      }).catch(error => getFail(error)).finally(() => {
        //
      })
    } else {
      AlertService.alert("error", "Invalid file format")
      return false;
    }

  };

  const bulkUploadFile = async (data) => {
    const { event, fieldName, checkFiltType, method, obj, id } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("type", checkFiltType(fieldName));
    let files = [...event.target.files];
    for (let i in files) {
      let file = files[i];
      let fileName = file.name;
      let lastDotIndex = fileName.lastIndexOf('.');
      let fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';

      if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
        await mainService.readFile(file, allValidFileTypes).then(uploadedFile => {
          formData.append("documents", file);
        }).catch(error => {
          error && AlertService.alert("error", "Invalid file format");
        });
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
    method(formData).then(response => {
      let _agentData = { ...agentData };
      _agentData[obj][fieldName] = [..._agentData[obj][fieldName] || [], ...response.data];
      setAgentData(_agentData)
      AlertService.alert(SUCCESS_KEY, "Data saved")
    }).catch(error => getFail(error)).finally(() => { })
  };

  const deleteDocument = (data) => {
    const { fieldName, fileObj, fileLibraryId, getFileType, method, id } = data;
    if (!fileLibraryId || !getFileType) { return false; }
    AlertService.alertConfirm(
      `Are you sure you want to delete current file ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      (method ? method(fileLibraryId, id, getFileType(fieldName)) : DashboardApiService.deleteAgentKycDocumentAsAdmin(fileLibraryId, id, getFileType(fieldName))).then(() => {
        if (Array.isArray(agentData[fileObj][fieldName])) {
          setAgentData((values) => ({
            ...values,
            [fileObj]: {
              ...values[fileObj],
              [fieldName]: agentData[fileObj][fieldName].filter(item => item.fileLibraryId !== fileLibraryId),
            }
          }));
        } else {
          setAgentData((values) => ({
            ...values,
            [fileObj]: {
              ...values[fileObj],
              [fieldName]: null,
            }
          }));
        }
        AlertService.alert(SUCCESS_KEY, "File deleted successfully")
      }).catch(error => getFail(error)).finally(() => {
        //
      })
    })
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const onChangeKycLevel = (item, fieldName) => {
    if (!item) { return false; }
    AlertService.alertConfirm(
      `Are you sure`,
      "You want to change KYC level ?",
      "Yes",
      "No"
    ).then(() => {
      setIsDisabledFields(true);
      const data = {
        id: agentData.id,
        updaterUserId: null,
        kycLevel: item
      }
      DashboardApiService.updateAgentKycLevel(data).then(() => {
        setAgentData((values) => ({
          ...values,
          [fieldName]: item
        }))
        AlertService.alert(SUCCESS_KEY, "Agent KYC level updated successfully")
      }).catch(error => getFail(error)).finally(() => {
        setIsDisabledFields(false)
      })
    })
  }

  const accept = (spinnerId) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      const data = {
        id: agentData.id,
        updaterUserId: null,
        status: true
      }
      DashboardApiService.updateAdminKycDataStatus(data).then(response => {
        AlertService.alert(SUCCESS_KEY, "Data saved");
        setAgentData((values) => ({
          ...values,
          isApproved: true,
          isRejected: false
        }))
      }).catch(error => getFail(error)).finally(() => {
        extractButtonSpinner(spinnerId)
      })
    })
  }

  const reject = (spinnerId) => {
    if (!rejectMessage.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    }
    setButtonSpinner(spinnerId);
    const data = {
      id: agentData.id,
      updaterUserId: null,
      status: false,
      rejectMessage,
    }
    DashboardApiService.updateAdminKycDataStatus(data).then(response => {
      AlertService.alert(SUCCESS_KEY, "Data saved");
      setAgentData((values) => ({
        ...values,
        isApproved: false,
        isRejected: true
      }))
      setRejectMessage("");
    }).catch(error => getFail(error)).finally(() => {
      extractButtonSpinner(spinnerId)
    }).finally(() => {
      setIsShowRejectModal(false);
    })
  }

  const disabledOrEnable = (spinnerId) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      const data = {
        id: agentData.id,
        updaterUserId: null,
        status: !agentData.isDisabled
      }
      DashboardApiService.disabledOrEnableAdminKycStatus(data).then(response => {
        AlertService.alert(SUCCESS_KEY, "Data saved");
        setAgentData((values) => ({
          ...values,
          isDisabled: !agentData.isDisabled
        }))
      }).catch(error => getFail(error)).finally(() => {
        extractButtonSpinner(spinnerId)
      })
    })
  }

  const onPhoneNumberChange = (value, obj, fieldName) => {
    setAgentData((values) => ({
      ...values,
      [obj]: {
        ...values[obj],
        [fieldName]: value
      }
    }));
  }

  const toggleCustom = (key) => {
    setCustomIconActiveTab(key);
    localStorage.setItem("activeTab", key);
  }

  const getData = () => {
    if (!customActiveTab) { return null; }
    switch (customActiveTab) {
      case "1":
        return agentData.levelOne;
      case "2":
        return agentData.levelTwo;
      case "3":
        return agentData.levelThree;
      default:
        break;
    }
  }

  const updateForm = (event) => {
    event && event.preventDefault();
    setButtonSpinner(buttonSpinnerId);
    (
      +customActiveTab === 1 ?
        DashboardApiService.updateAgentDataOneLevel(getData()) :
        +customActiveTab === 2 ?
          DashboardApiService.updateAgentDataTwoLevel(getData()) :
          +customActiveTab === 3 ?
            DashboardApiService.updateAgentDataThreeLevel(getData()) : () => { }
    ).then(response => {
      AlertService.alert("success", "Data saved");
      agentId && getAgentKycDataById(agentId)
    }).catch(error => getFail(error)).finally(() => {
      setIsForm(false);
      extractButtonSpinner(buttonSpinnerId);
    })
  }

  const onDisabledEdirtButton = (data) => {
    if (!data) { return true; }
    if ((data.levelOne && +customActiveTab === 1) || (data.levelTwo && +customActiveTab === 2) || (data.levelThree && +customActiveTab === 3)) {
      return false;
    } else {
      return true;
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          {
            agentData ?
              <div>
                <UncontrolledAlert color={agentData.isApproved ? "success" : "danger"} role="alert">
                  {
                    agentData.isApproved ?
                      "Current agent has received approval from the Cryllex administration."
                      :
                      "Current agent has not been approved by the Cryllex administration."
                  }
                </UncontrolledAlert>

                <UncontrolledAlert color={agentData.isDisabled ? "danger" : "success"} role="alert">
                  {
                    agentData.isDisabled ?
                      "Current agent has been disabled by the Cryllex administration."
                      :
                      "Current agent has been enabled by the Cryllex administration."
                  }
                </UncontrolledAlert>
              </div>
              : null
          }


          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Agent KYC"
            isShowGoBackButton={true}
            goBack={() => {
              localStorage.removeItem("activeTab");
              window.history.back();
            }}
          />
          <Row>
            <Col lg={12}>
              <Card className='mb-5'>
                <CardBody>
                  {
                    agentData ?
                      <Auxiliary>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                          {
                            agentData.levelOne ?
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={`${customActiveTab === "1" ? "active" : ""}`}
                                  disabled={buttonSpinners.length ? true : false}
                                  onClick={() => {
                                    toggleCustom("1");
                                    setIsForm(false);
                                  }}
                                >
                                  <span style={{ fontSize: "14px" }}>Level one</span>
                                </NavLink>
                              </NavItem>
                              : null
                          }
                          {
                            agentData.levelTwo ?
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={`${customActiveTab === "2" ? "active" : ""}`}
                                  disabled={buttonSpinners.length ? true : false}
                                  onClick={() => {
                                    toggleCustom("2");
                                    setIsForm(false);
                                  }}
                                >
                                  <span style={{ fontSize: "14px" }}>Level two</span>
                                </NavLink>
                              </NavItem>
                              : null
                          }
                          {
                            agentData.levelThree || agentData.significantControllers ?
                              agentData.classificationType === 2 ? null :
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={`${customActiveTab === "3" ? "active" : ""}`}
                                    disabled={buttonSpinners.length ? true : false}
                                    onClick={() => {
                                      toggleCustom("3");
                                      setIsForm(false);
                                    }}
                                  >
                                    <span style={{ fontSize: "14px" }}>Level three</span>
                                  </NavLink>
                                </NavItem>
                              : null
                          }
                        </Nav>
                        <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                          <TabPane tabId="1">
                            {
                              agentData.levelOne ?
                                <Row>
                                  {
                                    isForm ?
                                      <LevelOneRequirementsForm
                                        data={agentData}
                                        onChange={onChange}
                                        onSwitcherChange={onSwitcherChange}
                                        uploadFile={uploadFile}
                                        onPhoneNumberChange={onPhoneNumberChange}
                                        setZoomImagePath={setZoomImagePath}
                                        deleteDocument={deleteDocument}
                                        isDisabledFields={isDisabledFields}
                                        // confirmProof={confirmProof}
                                        confirmLoaderId={confirmLoaderId}
                                      />
                                      : <LevelOneRequirements
                                        data={agentData}
                                        setZoomImagePath={setZoomImagePath}
                                      />
                                  }
                                </Row>
                                : <NoData />
                            }
                          </TabPane>
                          <TabPane tabId="2">
                            {
                              isForm ?
                                <LevelTwoRequirementsForm
                                  data={agentData}
                                  onChange={onChange}
                                  uploadFile={uploadFile}
                                  bulkUploadFile={bulkUploadFile}
                                  setZoomImagePath={setZoomImagePath}
                                  onSelectOptionChange={onSelectOptionChange}
                                  deleteDocument={deleteDocument}
                                  countries={countries}
                                  // confirmProof={confirmProof}
                                  confirmLoaderId={confirmLoaderId}
                                />
                                : <LevelTwoRequirements
                                  data={agentData}
                                  setZoomImagePath={setZoomImagePath}
                                />
                            }
                          </TabPane>

                          <TabPane tabId="3">
                            {
                              (agentData.significantControllers && agentData.significantControllers.length) || agentData.levelThree ?
                                <div>
                                  {
                                    agentData.significantControllers && agentData.significantControllers.length && !isForm ?
                                      <div>
                                        <h5 className='bold my-3'>Significant controllers</h5>
                                        <Table striped bordered hover responsive>
                                          <thead>
                                            <tr className='cursor-default'>
                                              <th className="text-nowrap">Full name</th>
                                              <th className="text-nowrap">Email</th>
                                              <th className="text-nowrap">Phone Number</th>
                                              <th className="text-nowrap">Type</th>
                                              <th className="text-nowrap">Percentage</th>
                                              <th className="text-nowrap text-center">Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              agentData.significantControllers.map((item, index) => {
                                                return <tr key={index} className='cursor-default'>
                                                  <td className='fw-500'>{item.fullName}</td>
                                                  <td className='fw-500'>{item.email}</td>
                                                  <td className='fw-500 text-nowrap'>{MainService.formatPhoneNumber(item.phoneNumber)}</td>
                                                  <td className='fw-500'>{item.typeName}</td>
                                                  <td className='fw-500'>{item.percentage}%</td>
                                                  <td>
                                                    <div className='d-flex justify-content-center'>
                                                      <Button
                                                        type="button"
                                                        color="primary"
                                                        className='shadow-sm me-1'
                                                        onClick={() => { props.history.push(`/${language}/significant-controllers-form/${item.id}`) }}
                                                      >
                                                        Submit
                                                      </Button>
                                                    </div>
                                                  </td>
                                                </tr>
                                              })
                                            }
                                          </tbody>
                                        </Table>
                                        {
                                          agentData.levelThree ?
                                            <hr />
                                            : null
                                        }
                                      </div>
                                      : null
                                  }
                                  {
                                    agentData.levelThree ?
                                      <Row>
                                        {
                                          isForm ?
                                            <LevelThreeRequirementsForm
                                              data={agentData}
                                              onChange={onChange}
                                              uploadFile={uploadFile}
                                              setZoomImagePath={setZoomImagePath}
                                              onSelectOptionChange={onSelectOptionChange}
                                              deleteDocument={deleteDocument}
                                              onNumberChange={onNumberChange}
                                              countries={countries}
                                              onSwitcherChange={onStatusSwitcherChange}
                                              isDisabledFields={isDisabledFields}
                                              // confirmProof={confirmProof}
                                              confirmLoaderId={confirmLoaderId}
                                              currencies={currencies}

                                              payoutMethods={payoutMethods}
                                              countrySpinnerArr={countrySpinnerArr}
                                            />
                                            : <div>
                                              <h5 className='bold my-3'>Bank account</h5>
                                              <LevelThreeRequirements
                                                data={agentData}
                                                setZoomImagePath={setZoomImagePath}
                                                countries={countries}
                                                currencies={currencies}
                                                payoutMethods={payoutMethods}

                                              />
                                            </div>
                                        }
                                      </Row>
                                      : null
                                  }
                                </div>
                                : <NoData />
                            }
                          </TabPane>

                        </TabContent>
                        <hr />
                        <div className='my-2 d-flex justify-content-end flex-wrap'>
                          <div className='d-flex mt-3'>
                            {
                              kycLevels && kycLevels.length ?
                                <Dropdown drop='up' disabled={isDisabledFields}>
                                  <Dropdown.Toggle tag="button" className="btn btn-warning">
                                    {
                                      kycLevels.find(level => level.key === agentData?.kycLevel) ?
                                        <div className='d-flex'>
                                          {kycLevels.map((level, idx) => {
                                            if (level.key === agentData?.kycLevel) {
                                              return <div key={idx}>{level.value}</div>
                                            }
                                          })}
                                          <i className="mdi mdi-chevron-up ms-2" />
                                        </div>
                                        : <div className='d-flex'>Update KYC Level <i className="mdi mdi-chevron-down ms-2" /></div>
                                    }
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {
                                      kycLevels.map((level, idx) => {
                                        return <Dropdown.Item
                                          key={idx}
                                          onClick={() => {
                                            if (agentData?.kycLevel === level.key) {
                                              return false;
                                            }
                                            onChangeKycLevel(level.key, "kycLevel")
                                          }}
                                        >
                                          {level.value}
                                        </Dropdown.Item>
                                      })
                                    }
                                  </Dropdown.Menu>
                                </Dropdown>
                                : null
                            }
                            {
                              isForm ?
                                <div className='d-flex'>
                                  <Button
                                    color="primary"
                                    className='mx-2 px-4'
                                    outline
                                    disabled={isDisabledFields}
                                    onClick={() => {
                                      setIsForm(false);
                                      setAgentData(_agentData)
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <ActionButton
                                    type="button"
                                    name="Submit"
                                    className="btn btn-primary btn-block px-4"
                                    spinnerId={buttonSpinnerId}
                                    disabled={isDisabledFields}
                                    onClick={(event) => updateForm(event)}
                                  />
                                </div> :
                                <Auxiliary>

                                  {/* <ActionButton
                                    spinnerId={`accept_${buttonSpinnerId}`}
                                    color="success"
                                    className='ms-2 px-4'
                                    disabled={agentData && agentData.isApproved}
                                    onClick={() => accept(`accept_${buttonSpinnerId}`)}
                                  >
                                    Accept
                                  </ActionButton>

                                  <Button
                                    color="danger"
                                    className='ms-2 px-4'
                                    disabled={agentData && agentData.isRejected}
                                    onClick={() => setIsShowRejectModal(true)}
                                  >
                                    Reject
                                  </Button>

                                  <ActionButton
                                    spinnerId={`disabled_${buttonSpinnerId}`}
                                    color={agentData?.isDisabled ? "info" : "secondary"}
                                    className='ms-2 px-4'
                                    onClick={() => disabledOrEnable(`disabled_${buttonSpinnerId}`)}
                                  >
                                    {
                                      agentData?.isDisabled ? "Enable" : "Disable"
                                    }

                                  </ActionButton> */}


                                  <ActionButton
                                    spinnerId={`accept_${buttonSpinnerId}`}
                                    color="success"
                                    className='ms-2 px-4'
                                    disabled={agentData?.kycLevel !== 3 || (agentData && agentData.isApproved)}
                                    onClick={() => accept(`accept_${buttonSpinnerId}`)}
                                  >
                                    Accept
                                  </ActionButton>

                                  <Button
                                    color="danger"
                                    className='ms-2 px-4'
                                    disabled={agentData?.kycLevel !== 3 || (agentData && agentData.isRejected)}
                                    onClick={() => setIsShowRejectModal(true)}
                                  >
                                    Reject
                                  </Button>

                                  <ActionButton
                                    spinnerId={`disabled_${buttonSpinnerId}`}
                                    color={agentData?.isDisabled ? "info" : "secondary"}
                                    className='ms-2 px-4'
                                    disabled={agentData?.kycLevel !== 3}
                                    onClick={() => disabledOrEnable(`disabled_${buttonSpinnerId}`)}
                                  >
                                    {agentData?.isDisabled ? "Enable" : "Disable"}
                                  </ActionButton>



                                  <Button color="primary" className='ms-2 px-4'
                                    onClick={() => {
                                      setIsForm(true);
                                      scrollTop();
                                    }}
                                    disabled={onDisabledEdirtButton(agentData)}
                                  >
                                    Edit
                                  </Button>
                                </Auxiliary>
                            }
                          </div>
                        </div>
                      </Auxiliary>
                      : <div>
                        <NoData />
                        <p className='text-center'><b>Current agent doesn't have KYC.</b></p>
                      </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>





        <Modal
          isOpen={isShowRejectModal}
          size="lg"
          role="dialog"
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setIsShowRejectModal(!isShowRejectModal)
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                setIsShowRejectModal(!isShowRejectModal)
              }}
            >
              Reject
            </ModalHeader>
            <ModalBody>
              <form>
                <Label htmlFor='rejectMessage'>Rejection message</Label>
                <Input
                  id="rejectMessage"
                  type="textarea"
                  value={rejectMessage}
                  rows={4}
                  autoFocus
                  style={{ resize: "none" }}
                  placeholder='Please enter your rejection message'
                  className={`form-control ${isInvalidSubmit && !rejectMessage.trim().length ? "error-border" : ""}`}
                  onChange={(event) => _onChange(event, setRejectMessage, fielsLengths.length_2000)}
                />
              </form>
            </ModalBody>
            <ModalFooter>
              <div className='d-flex justify-content-end gap-2'>
                <Button
                  color="primary"
                  className='px-4'
                  outline
                  disabled={isDisabledFields}
                  onClick={() => {
                    setIsShowRejectModal(false);
                    setRejectMessage("");
                  }}
                >
                  Cancel
                </Button>
                <ActionButton
                  spinnerId={`reject_${buttonSpinnerId}`}
                  color="primary"
                  className='px-4'
                  onClick={() => reject(`reject_${buttonSpinnerId}`)}
                >
                  Save
                </ActionButton>
              </div>
            </ModalFooter>
          </div>
        </Modal>




      </div>
    </>
  )
}

export default AgentKYC