import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import moment from 'moment'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import uuid from 'react-uuid'
import ActionButton from '../../../components/Buttons/ActionButton'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/actions'
import DashboardApiService from '../../../Services/DashboardApiService'
import AlertService from '../../../Services/alertService'
import Auxiliary from '../../../hoc/auxiliary/auxiliary'
import Breadcrumbs from './../../../components/Common/Breadcrumb'

const buttonSpinnerId = uuid();

const ClientTransactionView = (props) => {

  const title = "Client Database";
  const description = "";
  const { invitationId } = props.match.params;
  const dispatch = useDispatch()
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);
  const { language, languages } = useSelector(state => state.Languages);
  const [clientTypes, setClientTypes] = useState([]);
  const [invitationData, setInvitationData] = useState(null);

  const [values, setValues] = useState({
    clientTypeLibDataId: null,
    legalBusinessName: "",
    email: "",
    agentId: user.activeAgentId
  })

  useEffect(() => {
    getClientTypes();
    return () => {
      setClientTypes([])
    }
  }, [])

  // useEffect(() => {
  //   if (invitationId) {
  //     getClientInvitationById(invitationId)
  //   }
  // }, [invitationId])

  // const getClientInvitationById = (invitationId) => {
  //   const spinnerId = uuid();
  //   dispatch(addRightSectionSpinner(spinnerId));
  //   DashboardApiService.getClientInvitationById(invitationId).then(response => {
  //     if (response && response.data) {
  //       setInvitationData(response.data)
  //     }
  //   }).catch(error => {
  //     AlertService.alert("error", error);
  //     props.history.push(`/${language}/client-invitations`)
  //   }).finally(() => {
  //     dispatch(removeRightSectionSpinner(spinnerId))
  //   })
  // }

  const onSelectOptionChange = (item, name) => {
    if (!item) { return false; }
    setValues((values) => ({
      ...values,
      [name]: item.value,
    }));
  }

  const onChange = (event, name, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [name]: event.target.value,
    }));
  }

  const getClientTypes = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    DashboardApiService.getClientTypes().then(response => {
      if (response && response.data && response.data.libraryData) {
        setClientTypes(response.data.libraryData)
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!values.clientTypeLibDataId || !values.legalBusinessName.trim().length || !values.email.trim().length) {
      return false;
    }
    setButtonSpinner(buttonSpinnerId)
    DashboardApiService.createClientInvitation(values).then((response) => {
      AlertService.alert("success", "Data saved")
      props.history.push(`/${language}/client-invitations`)
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      extractButtonSpinner(buttonSpinnerId)
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);


  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Invite organization"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col sm={12}>
              <Card>
                <CardBody>
                  <form onSubmit={onSubmit}>
                    <Row >
                      <Col>
                        <Row className='mb-3'>
                          <Col md={6}>
                            <Label className='mb-1'>Client Type*</Label>
                          </Col>
                          <Col md={6}>
                            {
                              clientTypes && clientTypes.length ?
                                invitationData ?
                                  <p>
                                    <b>
                                      {
                                        clientTypes && clientTypes.map(item => {
                                          if (item.id === values.clientTypeLibDataId) {
                                            return item.displayName;
                                          }
                                        })
                                      }
                                    </b>
                                  </p>
                                  : <ReactSelectOption
                                    value={values.clientTypeLibDataId}
                                    className="w-100"
                                    selectedValue={(() => {
                                      const selectedItem = { ...clientTypes.find(data => +data.id === +values.clientTypeLibDataId) };
                                      if (Object.keys(selectedItem).length) {
                                        selectedItem.label = selectedItem.displayName;
                                        selectedItem.value = selectedItem.id;
                                        return selectedItem;
                                      } else {
                                        return { label: "Choose...", value: null }
                                      }
                                    })()}
                                    items={clientTypes.map(data => ({ ...data, label: data.displayName, value: data.id }))}
                                    onChange={(item) => onSelectOptionChange(item, "clientTypeLibDataId")}
                                  />
                                : null
                            }
                          </Col>
                        </Row>

                        <Row className='mb-3'>
                          <Col md={6}>
                            <Label htmlFor='legalBusinessName' className='mb-1 cursor-pointer'>Organization Name*</Label>
                          </Col>
                          <Col md={6}>
                            {
                              invitationData ?
                                <p><b>{values.legalBusinessName}</b></p>
                                : <Input
                                  id="legalBusinessName"
                                  type="text"
                                  className="form-control"
                                  value={values.legalBusinessName}
                                  onChange={(event) => onChange(event, "legalBusinessName", 120)}
                                />
                            }

                          </Col>
                        </Row>

                        <Row className='mb-3'>
                          <Col md={6}>
                            <Label htmlFor='email' className='mb-1 cursor-pointer'>Email Address*</Label>
                          </Col>
                          <Col md={6}>
                            {
                              invitationData ?
                                <p><b>{invitationData.email}</b></p>
                                : <Input
                                  id="email"
                                  type="email"
                                  className="form-control"
                                  value={values.email}
                                  onChange={(event) => onChange(event, "email", 120)}
                                />
                            }
                          </Col>
                        </Row>
                        {
                          invitationData ?
                            <Auxiliary>
                              <Row className='mb-3'>
                                <Col md={6}>
                                  <Label className='mb-1'>Your Agent Referral Code:</Label>
                                </Col>
                                <Col md={6}>
                                  <p><b>{invitationData.agentReferalCode}</b></p>
                                </Col>
                              </Row>
                              <Row className='mb-3'>
                                <Col md={6}>
                                  <Label className='mb-1'>Your Agent Invite Link:</Label>
                                </Col>
                                <Col md={6}>
                                  <p><b>{invitationData.agentInviteURL}</b></p>
                                </Col>
                              </Row>

                            </Auxiliary>
                            : null
                        }


                        <div className='d-flex justify-content-end gap-3 mt-3'>
                          {
                            invitationData ?
                              <Auxiliary>
                                <Button
                                  type='button'
                                  color="primary"
                                  className='px-4'
                                  onClick={() => {
                                    navigator.clipboard.writeText(invitationData.agentInviteURL);
                                    AlertService.alert("success", "The invitation link has been copied.")
                                  }}
                                >
                                  Copy Invite Link
                                </Button>

                                <Button
                                  type='button'
                                  color="primary"
                                  className='px-4'
                                  onClick={() => { }}
                                >
                                  Send Invite Link
                                </Button>
                              </Auxiliary>
                              : <ActionButton
                                type="submit"
                                name="Create"
                                color="primary"
                                disabled={!values.clientTypeLibDataId || !values.legalBusinessName.trim().length || !values.email.trim().length}
                                className="shadow-sm mt-3 btn btn-primary px-4"
                                spinnerId={buttonSpinnerId}
                              />
                          }
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ClientTransactionView;
