import React, { useCallback, useState, useEffect } from 'react'
import {
  Col,
  Row,
  Table
} from "reactstrap"

const LevelOne = (props) => {

  return (
    <Col sm={12}>
      <Row>
        <Col sm={6}>
          <Table bordered striped responsive className='mb-0'>
            <tbody>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  First Name
                </th>
                <td className='w-50'>Paul</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Last Name
                </th>
                <td className='w-50'>Anthony</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Wallet Address
                </th>
                <td className='w-50'>
                  <span style={{ wordBreak: "break-all" }}>0xE9eFD4F63Cb1060E592415F7F0cef294F48B8899</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col sm={6}>
          <Table bordered striped responsive className='mb-0'>
            <tbody>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Telephone Number
                </th>
                <td className='w-50'>+1 (323) 787-9992</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "400px" }}
                  className={"text-capitalize"}
                >
                  Telephone Number/SMS Verification Status
                </th>
                <td className='w-50'>
                  <span className={`badge badge-soft-success font-size-14 badge badge-pill`}>
                    Verified
                  </span>
                  or
                  <span className={`badge badge-soft-danger font-size-14 badge badge-pill`}>
                    Unverified
                  </span>
                </td>
              </tr>


            </tbody>
          </Table>
        </Col>




      </Row>
    </Col>
  )
}

export default LevelOne