import $ from 'jquery';
import parsePhoneNumber from 'libphonenumber-js'
// import { INVALID_TIME_KEY, VALID_TIME_KEY } from '../Constants/mainKeys';
import * as moment from "moment";
import { VALID_IMAGE_TYPES_KEY } from '../Constants/MainKeys';

// const INVALID_TIME_KEY = "Invalid time";
// const VALID_TIME_KEY = "Valid time";

class MainService {

  static triggerUploadClick(event) {
    let inputElem = $($(event.target).find('input:file'))[0];
    if (!inputElem) {
      inputElem = $($(event.target.parentElement).find('input:file'))[0];
    }
    $(inputElem).trigger('click');
  }

  // readFile(file, validFileTypes = null) {
  //   return new Promise((resolve, reject) => {
  //     let reader = new FileReader();
  //     const fileName = file.name;
  //     const lastDotIndex = fileName.lastIndexOf('.');
  //     const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
  //     const isValid = validFileTypes.find(data => data === fileExtention);
  //     !isValid && reject(true);
  //     reader.readAsDataURL(file);
  //     reader.onloadend = () => {
  //       resolve(reader.result);
  //     }
  //   });
  // }

  async readFile(file, validFileTypes = null, progressCallback) {
    const CHUNK_SIZE = 1024 * 1024 * 10000; // 10000 MB -> 10GB
    let offset = 0;
    const fileSize = file.size;
    const chunks = [];

    // Validate file extension
    const fileName = file.name;
    const lastDotIndex = fileName.lastIndexOf('.');
    const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : '';
    const isValid = validFileTypes.includes(fileExtention.toLowerCase());
    if (!isValid) {
      throw new Error("Invalid file extension");
    }

    while (offset < fileSize) {
      const end = Math.min(offset + CHUNK_SIZE, fileSize);
      const chunk = await this.readChunk(file, offset, end, progressCallback);
      chunks.push(chunk);
      offset = end;
    }

    const fileBlob = new Blob(chunks, { type: file.type });
    const fileUrl = URL.createObjectURL(fileBlob);
    return fileUrl;
  }

  async readChunk(file, start, end, cb) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const blob = file.slice(start, end);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error); // Pass the error to reject()
      reader.readAsArrayBuffer(blob);
      if (cb) {
        reader.onprogress = function (data) {
          if (data.lengthComputable) {
            var progress = parseInt(((data.loaded / data.total) * 100), 10);
            cb(progress)
          }
        }
      }
    });
  }

  changeDateFormat(data) {
    if (!data) { return ''; }
    const newData = new Date(data);
    const year = newData.getUTCFullYear();
    const month = this.checkNumberLength(newData.getMonth() + 1);
    const day = this.checkNumberLength(newData.getDate());
    return `${year}-${month}-${day}`;
  }

  checkNumberLength(number) {
    return number && number.toString().length === 1 ? `0${number}` : number;
  }

  static isValidDate(date) {
    const dateFormat = 'YYYY-MM-DD';
    const toDateFormat = moment(new Date(date)).format(dateFormat);
    return moment(toDateFormat, dateFormat, true).isValid();
  }

  static isValidTime(time) {
    const timeFormat = 'HH:mm';
    return moment(time, timeFormat, true).isValid();
  }

  static isValidDateTime(date) {
    const dateFormat = 'mm-dd-yyyy hh:mm';
    const toDateFormat = moment(new Date(date)).format(dateFormat);
    return moment(toDateFormat, dateFormat, true).isValid();
  }

  static convertUTCDateToLocalDate(date) {
    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();

    date.setHours(hours - offset);
    return date;
    // return moment(date).local().format('YYYY-MM-DD HH:mm:ss');
  }

  static convertToValidDateFormat(date) {
    return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss');
  }

  static validateDate(date) {
    var q = new Date();
    var today = new Date(q.getFullYear(), q.getMonth(), q.getDate(), q.getHours(), q.getMinutes(), q.getSeconds());
    var mydate = new Date(date);
    if (mydate >= today) {
      return true
    } else {
      return false
    }
  }

  // static checkOnlyTime = (startTime, endTime) => {
  //   var t = new Date(),
  //     d = t.getDate(),
  //     m = t.getMonth() + 1,
  //     y = t.getFullYear();
  //   var d1 = new Date(m + "/" + d + "/" + y + " " + startTime);
  //   var d2 = new Date(m + "/" + d + "/" + y + " " + endTime);
  //   var t1 = d1.getTime();
  //   var t2 = d2.getTime();
  //   if (t1 < t2)
  //     if (t1 < t2) {
  //       return VALID_TIME_KEY;
  //     } else {
  //       return INVALID_TIME_KEY;
  //     }
  // }

  static validateOnlyDate(date) {
    var q = new Date();
    var today = new Date(q.getFullYear(), q.getMonth(), q.getDate());
    var mydate = new Date(date);
    if (mydate >= today) {
      return true
    } else {
      return false
    }
  }

  static checkDates(firstDate, secondDate) {
    if (!firstDate || !secondDate) { return; }
    if (new Date(firstDate).getTime() < new Date(secondDate).getTime()) {
      return true;
    } else return false;
  }

  static checkTime(firstTime, secondTime) {
    if (firstTime > secondTime)
      alert("Time 1 is later than time 2");
    else
      alert("Time 2 is later than time 1");
  }

  static checkDatesMoreOrEqual(firstDate, secondDate) {
    if (!firstDate || !secondDate) { return; }
    if (new Date(firstDate).getTime() <= new Date(secondDate).getTime()) {
      return true;
    } else return false;
  }

  static isValidField(fieldValue, fieldName) {
    if (fieldName === "firstName" || fieldName === "lastName" || fieldName === "firstname" || fieldName === "lastname" || fieldName === "name") {
      const idValid = /^[A-Za-z\u0400-\u04FF]{1,100}(\-?)[A-Za-z\u0400-\u04FF]{1,100}$/;
      return idValid.test(fieldValue);
    }
    if (fieldName === "email" || fieldName === "organizationEmail") {
      // const idValid = /^[A-z,0-9,]{1,30}\d*@[A-z,0-9,]*.[A-z]*$/;
      const idValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
      return idValid.test(fieldValue);
    }
    if (fieldName === "password" || fieldName === "confirmPassword" || fieldName === "newPassword") {
      // const idValid = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/;
      const idValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]([^\s@]+){6,}$/;
      return idValid.test(fieldValue);
    }
  }

  static getDownloadUrl(url) {
    if (!url) { return; }
    if (url.includes("?")) {
      return `${url.split("?")[0]}/download`
    } else {
      return `${url}/download`
    }
  }

  static getUrlExtension() {
    return window.location.hostname.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
  }

  static getFxtensions = (path) => {
    if (!path) { return false; }
    const parts = path.split('.');
    const extension = parts[parts.length - 1];
    if (VALID_IMAGE_TYPES_KEY.includes(extension)) {
      return "image"
    } else {
      return "file"
    }
  }

  static getStatusColor(status) {
    if (typeof status === "number") {
      switch (+status) {
        case 0:
          // NEW
          return "primary"
        case 1:
          // HASH_SET
          return "dark"
        case 2:
          // AWAITING
          return "warning"
        case 3:
          // ERROR
          return "danger"
        case 4:
          // REJECTED
          return "denger"
        case 5:
          // SUCCESS
          return "success"
        case 6:
          // CANCELLED
          return "danger"
        case 7:
          // TIMEOUT
          return "warning"
        default:
          return "info"
      }
    } else {
      if (!status) { return "" }
      switch (status.trim().toUpperCase()) {
        case "PAID":
          return "success"
        case "PANDING":
          return "warning"
        case "PENDING":
          return "warning"
        case "DECLINE":
          return "danger"
        default:
          return "info"
      }
    }
  }

  static getTransactionStatusColor(status) {
    switch (+status) {
      case 1:
        return "warning"
      case 2:
        return "success"
      case 3:
        return "danger"
      default:
        return "info"
    }
  }
  static getTransactionStatusName(status) {
    switch (+status) {
      case 1:
        return "Pending"
      case 2:
        return "Approved"
      case 3:
        return "Rejected"
      default:
        return ""
    }
  }

  static getUserFileType(fileName) {
    if (!fileName) { return 0; }
    switch (fileName) {
      case "avatarImage":
        return 1;
      case "userPassportProofFile":
        return 2;
      case "userApprovalFile":
        return 3;
      case "userAddressProofFile":
        return 4;
      default:
        break;
    }
  }

  static getBankAddressFileType(fileName) {
    if (!fileName) { return 0; }
    switch (fileName) {
      case "bankAddressFile":
        return 1;
      default:
        break;
    }
  }

  static getUserFileNameByType(fileType) {
    if (!fileType) { return ""; }
    switch (fileType) {
      case 1:
        return "avatarImage";
      case 2:
        return "userPassportProofFile";
      case 3:
        return "userApprovalFile";
      case 4:
        return "userAddressProofFile";
      default:
        break;
    }
  }

  static getOrganizationDetailsFileType(fileName) {
    if (!fileName) { return 0; }
    switch (fileName) {
      case "certificateOfIncumbencyFile":
        return 1;
      case "certificateOfIncorporationFile":
        return 2;
      case "businessRegisteredAddressFile":
        return 3;
      case "businessRegulatoryLicencingNumberFile": //bulk
        return 4;
      case "businessRegistrationCertificateFile":
        return 5;
      case "otherProofFile":
        return 6;
      case "businessTaxIdentificationFile":
        return 7;
      default:
        break;
    }
  }

  static getOrganizationDetailsFileNameByType(fileType) {
    if (!fileType) { return ""; }
    switch (fileType) {
      case 1:
        return "certificateOfIncumbencyFile";
      case 2:
        return "certificateOfIncorporationFile";
      case 3:
        return "businessRegisteredAddressFile";
      case 4:
        return "businessRegulatoryLicencingNumberFile"; //bulk
      case 5:
        return "businessRegistrationCertificateFile";
      case 6:
        return "otherProofFile";
      case 7:
        return "businessTaxIdentificationFile";
      default:
        break;
    }
  }

  static getClassificationName(classification) {
    if (!classification) { return ""; }
    switch (classification) {
      case 1:
        return "MERCHANT";
      case 2:
        return "SOLE TRADER";
      case 3:
        return "AGENT";
      case 4:
        return "PAYING CUSTOMER";
      default:
        break;
    }
  }

  static getStatusName(statusId, statuses) {
    if (typeof statusId !== "number" || !statuses.length) { return ""; }
    if (statuses && statuses.length) {
      return statuses.find(status => status.value === statusId)?.key;
    } else {
      return ""
    }
  }

  static getFileExtention(path) {
    if (!path) { return ""; }
    let arr = path.split(".");
    if (arr && arr.length) {
      return arr[arr.length - 1]
    } else {
      return "";
    }

  }

  // static formatPhoneNumber(phoneNumber) {
  //   if (!phoneNumber || (phoneNumber && typeof phoneNumber !== "string")) { return ""; }
  //   const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  //   const hasCountryCode = cleaned.startsWith('+');
  //   let countryCode = '';
  //   let mainNumber = cleaned;
  //   if (!hasCountryCode) {
  //     countryCode = '+ (' + mainNumber.substring(0, 3) + ') ';
  //     mainNumber = mainNumber.substring(3);
  //   }
  //   const formattedNumber = countryCode + mainNumber.replace(/(\d{3})(?=\d{3})/, '$1-').replace(/(\d{3})(?=\d{3})/, '$1-').replace(/(\d{3})(?=\d+)/, '$1-');
  //   return formattedNumber;
  // }


  static camelCaseToWords(camelCaseString) {
    if (!camelCaseString) return "";
    let words = camelCaseString.replace(/([A-Z])/g, ' $1');
    words = words.charAt(0).toUpperCase() + words.slice(1);
    return words;
  }

  static formatPhoneNumber(_phoneNumber = "+12133734253") {
    if (!_phoneNumber) { return ""; }
    const phoneNumber = parsePhoneNumber(_phoneNumber.includes("+") ? _phoneNumber : `+${_phoneNumber}`);
    if (!phoneNumber && _phoneNumber) {
      return _phoneNumber;
    } else {
      return phoneNumber.formatInternational()
    }
  }

  //   const today = new Date();
  //   const todayStr = today.toISOString().split('T')[0];

  //   const hundredYearsAgo = new Date();
  //   hundredYearsAgo.setFullYear(today.getFullYear() - 100);
  // const hundredYearsAgoStr = hundredYearsAgo.toISOString().split('T')[0];

  static getToday() {
    const today = new Date();
    const todayStr = today.toISOString().split('T')[0];
    return todayStr;
  }

  static getHundredYearsAgoStr() {
    const today = new Date();
    const hundredYearsAgo = new Date();
    hundredYearsAgo.setFullYear(today.getFullYear() - 100);
    const hundredYearsAgoStr = hundredYearsAgo.toISOString().split('T')[0];
    return hundredYearsAgoStr;
  }

}

export default MainService;