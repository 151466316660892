import "./assets/scss/theme.scss"
import React, { useEffect, useCallback, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router } from "react-router-dom"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import PageSpinner from './components/Spinners/PageSpinner'
import UnauthMiddleware from "./routes/middleware/UnauthMiddleware"
import Authmiddleware from "./routes/middleware/Authmiddleware"
import { withRouter } from "react-router-dom"
import ErrorHandling from "./ErrorHandling"
import { AGENT_USER_TYPE_ID } from "./Constants/MainKeys"
import ChooseActiveAgentModal from "./pages/Agent-Pages/Components/ChooseActiveAgentModal"
import { updateUserData } from "./store/actions"
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SiteTourComponent from "./SiteTourComponent"
import Listeners from "./Listeners"


const App = (props) => {
  useEffect(() => { // todo: hanel
    const iframe = document.body.querySelector('iframe');
    if (iframe) {
      iframe.remove();
    }
  }, []);

  const dispatch = useDispatch()
  const { user, token } = useSelector(state => state.Login);
  // const { language } = useSelector(state => state.Languages);
  // const { translations } = useSelector(state => state.Translations);
  const { pageSpinners } = useSelector(state => state.Spinners);
  const layout = useSelector(state => state.Layout);
  const Layout = getLayout();
  const [isShowChooseActiveAgentModal, setIsShowChooseActiveAgentModal] = useState(false);

  useEffect(() => {
    if (user && user.agentDashbaords && user.agentDashbaords.length && user.userTypeId === AGENT_USER_TYPE_ID && !user.activeAgentId) {
      if (user.agentDashbaords.length === 1) {
        const userCopy = { ...user }
        userCopy.activeAgentId = user.agentDashbaords[0].id;
        dispatch(updateUserData(userCopy));
      } else {
        setIsShowChooseActiveAgentModal(true)
      }
    } else {
      setIsShowChooseActiveAgentModal(false)
    }
  }, [user])

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  return (
    <React.Fragment>
      <Router>
        {
          user ?
            <Layout>
              <Authmiddleware token={token} />
            </Layout>
            :
            <NonAuthLayout>
              <UnauthMiddleware />
            </NonAuthLayout>
        }
      </Router>
      <PageSpinner spinner={pageSpinners} />
      {/* <ErrorHandling /> */}

      {
        isShowChooseActiveAgentModal ?
          <ChooseActiveAgentModal agents={user.agentDashbaords} />
          : null
      }
      <ToastContainer
        theme="light"
      // limit={2} //Limit the number of toast displayed at the same time
      // autoClose={5000} 
      />
      <SiteTourComponent />
      <Listeners />
    </React.Fragment>
  )
}

export default withRouter(App)
