import React, { useCallback } from 'react'
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import { Link } from 'react-router-dom';
import NoData from '../../../../components/NoData/NoData';
import ActionButton from '../../../../components/Buttons/ActionButton';
import uuid from 'react-uuid';
import { addButtonSpinner, removeButtonSpinner } from '../../../../store/actions';
import AlertService from '../../../../Services/alertService';
import { useDispatch } from 'react-redux';
import DashboardApiService from '../../../../Services/DashboardApiService';
import MainService from '../../../../Services/MainService';

const buttonSpinnerId = uuid();

const TotalOrder = ({ transactionOverview, transactionDetails, setTransactionDetails }) => {

  const dispatch = useDispatch();

  const onChangeInstantPaymentStatus = (spinnerId, status) => {
    AlertService.alertConfirm(
      `Are you sure?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      DashboardApiService.onChangeInstantPaymentStatus({ status: status, id: transactionDetails.id }).then(() => {
        let updatedData = { ...transactionDetails };
        updatedData.instantPaymentStatus = status;
        setTransactionDetails && setTransactionDetails(updatedData);
        AlertService.alert("success", "Data saved");
      }).catch(error => getFail(error)).finally(() => {
        extractButtonSpinner(spinnerId);
      })
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
  }


  return (
    <>
      <Card>
        {
          transactionOverview ?
            <CardBody>
              {/* <div className='mb-4 d-flex gap-3 align-items-center'> */}
              {/* <div className="d-flex align-items-center mb-3">
                <div className="avatar-xs me-3">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                    <i className="mdi mdi-ethereum"></i>
                  </span>
                </div>
                <h5 className="font-size-14 mb-0">Ethereum ???</h5>
              </div> */}
              <Row>
                <Col xs="12">
                  <div>

                    {
                      !transactionOverview.cryptoAmountFormated && transactionOverview.fiatAmountFormated ?
                        <h5>Order Total: {transactionOverview.fiatAmountFormated} USD</h5>
                        : null
                    }

                    {
                      transactionOverview.cryptoAmountFormated && transactionOverview.fiatAmountFormated ?
                        <div>
                          <h5>Order Total: {transactionOverview.cryptoAmountFormated} USD</h5>
                          <p className="text-muted text-truncate mb-0">
                            $ {transactionOverview.fiatAmountFormated} (1 {transactionDetails?.token} = <span className="text-muted font-size-14">$ {transactionOverview.fiatAmountFormated / transactionOverview.cryptoAmountFormated}</span> )</p>
                        </div>
                        : null
                    }
                  </div>
                  <hr />
                </Col>
                <Col xl="12">
                  <div>
                    <div className='d-flex justify-content-between'>
                      <p className="text-muted font-size-14 text-truncate mb-1">
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                        Transaction status:
                      </p>
                      <div>
                        {
                          transactionOverview.transactionStatus && transactionOverview.transactionStatus.toLowerCase() === "confirmed" ?
                            <span className="ms-2 font-size-12 badge-soft-success badge badge-secondary badge-pill">{transactionOverview.transactionStatus}</span>
                            : <span className="font-size-12 badge-soft-info badge badge-secondary badge-pill">{transactionOverview.transactionStatus}</span>
                        }
                      </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <p className="text-muted font-size-14 text-truncate mb-1">
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                        Fiat payout status:
                      </p>
                      <div>
                        {
                          transactionOverview.fiatPayoutStatus && transactionOverview.fiatPayoutStatus.toLowerCase() === "completed" ?
                            <span className="ms-2 font-size-12 badge-soft-success badge badge-secondary badge-pill">{transactionOverview.fiatPayoutStatus}</span>
                            : <span className="font-size-12 badge-soft-warning badge badge-secondary badge-pill">{transactionOverview.fiatPayoutStatus}</span>
                        }
                      </div>
                    </div>
                    {
                      transactionDetails && transactionDetails.paymentType === 1 ?
                        <div className='d-flex justify-content-between'>
                          <p className="text-muted font-size-14 text-truncate mb-1">
                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                            Instant payment status:
                          </p>
                          <div>
                            <span className={`ms-2 font-size-12 badge badge-secondary badge-pill badge-soft-${MainService.getTransactionStatusColor(transactionDetails.instantPaymentStatus)}`}>
                              {MainService.getTransactionStatusName(transactionDetails.instantPaymentStatus)}
                            </span>
                          </div>
                        </div>
                        : null
                    }
                  </div>
                  <hr />
                </Col>
                <Col xl="12">
                  <div>
                    {/* <div className='d-flex justify-content-between'>
                      <p className="text-muted font-size-14 text-truncate mb-1">
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                        Agent bank ID:
                      </p>
                      <div>
                        <span className="ms-2 font-size-12 badge-soft-primary badge badge-secondary badge-pill">1254587AS5254 ???</span>
                      </div>
                    </div> */}
                    <div className='d-flex justify-content-between'>
                      <p className="text-muted font-size-14 text-truncate mb-1">
                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                        Merchant bank ID:
                      </p>
                      <div>
                        <span className="ms-2 font-size-12 badge-soft-primary badge badge-secondary badge-pill">{transactionOverview.bankAccount}</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                </Col>
              </Row>
              {/* <div>
            <h5 className="text-primary">Order Total: 0.03 ETH</h5>
            <h6>(112.53 USD at 1 ETH = 3751 USD)</h6>
          </div> */}
              {/* </div> */}

              {/* <div className="text-muted">
            <p className='mb-1 fw-500 d-flex align-items-end'>
              Transaction Status: Confirmed and Pending (<i className='bx bx-check text-success fw-bold' style={{ fontSize: "22px", marginBottom: "2px" }}></i>)
            </p>
            <p className='mb-1 fw-500 d-flex align-items-end'>
              Transaction Status: Complete (<i className='bx bx-x text-danger fw-bold' style={{ fontSize: "22px" }}></i>)
            </p>
            <p className="mb-1">
              <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
              AgentBankID: XXXXXXXXXXX
            </p>
            <p className="mb-1">
              <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
              MerchantBankID: XXXXXXXXXXX
            </p>

          </div> */}
              <Col>
                <div className="media">
                  <div className="me-3">
                    <h5 className="font-size-14"><i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle bx-fade-right"></i></h5>
                  </div>
                  <div className="media-body">
                    <Link to="#" className='text-underline'>View Block Explorer for Transaction ???</Link>
                  </div>
                </div>
              </Col>
              {
                transactionDetails && transactionDetails.paymentType === 1 && transactionDetails.instantPaymentStatus === 1 ?
                  <Col>
                    <hr />
                    <div className='d-flex justify-content-end'>
                      <ActionButton
                        spinnerId={`approve_${buttonSpinnerId}`}
                        color="success"
                        name="Approve"
                        className='ms-2 px-4 text-nowrap w-100'
                        onClick={() => onChangeInstantPaymentStatus(`approve_${buttonSpinnerId}`, 2)}
                      />
                      <ActionButton
                        spinnerId={`reject_${buttonSpinnerId}`}
                        color="danger"
                        name="Reject"
                        className='ms-2 px-4 text-nowrap  w-100'
                        onClick={() => onChangeInstantPaymentStatus(`reject_${buttonSpinnerId}`, 3)}
                      />
                    </div>
                  </Col>
                  : null
              }
            </CardBody>
            : <NoData width={150} />
        }

      </Card>
    </>
  )
}

export default TotalOrder