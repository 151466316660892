import React, { useState, useEffect, useRef } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import DashboardApiService from '../../../Services/DashboardApiService'
import { useCallback } from 'react'
import { addButtonSpinner, removeButtonSpinner, removeRightSectionSpinner, addRightSectionSpinner, updateUserData } from '../../../store/actions'
import { AGENT_USER_TYPE_ID, ERROR_KEY, MERCHANT_USER_TYPE_ID, SPINNER_COLOR, SUCCESS_KEY, VALID_IMAGE_TYPES_KEY, allValidFileTypes, fielsLengths } from '../../../Constants/MainKeys'
import AlertService from '../../../Services/alertService'
import uuid from 'react-uuid'
import {
  Row,
  Col,
  Card,
  CardBody,
  Label, Input, Button, Alert, UncontrolledAlert,
} from "reactstrap"
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import ActionButton from '../../../components/Buttons/ActionButton'
import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption'
import MainService from '../../../Services/MainService'
import { PuffLoader } from 'react-spinners'
import FileComponent from '../Merchant/Components/FileComponent'
import ApiService from '../../../Services/ApiService'

const buttonSpinnerId = uuid();
const modalSpinnerId = uuid();

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const BankAccountForm = (props) => {
  const query = new URLSearchParams(props.location.search);
  const redirectUrl = query.get('redirectUrl');

  const mainService = new MainService()
  const { accountId } = props.match.params;
  const wrapperRef = useRef(null);
  const title = "Bank Account form"
  const description = "";
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.Login);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isShowImageLoader, setIsShowImageLoader] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState(null);


  const [countrySpinnerArr, setCountrySpinnerArr] = useState([]);
  const [countries, setCountries] = useState([]);
  const [payoutMethods, setPayoutMethods] = useState([]);
  const [holderTypes, setHolderTypes] = useState([]);
  const [currencies, setCurrencies] = useState([]);


  useOutsideAlerter(wrapperRef, setZoomImagePath);

  const [values, setValues] = useState({
    accountNumber: "",
    accountName: "",
    bankName: "",
    bankAddress: "",
    bankCode: "",
    swiftCode: "",
    branchAddress: "",
    branchCode: "",
    branchName: "",
    // routingNumber: "",
    // routingValue: "",
    routingType: "",
    countryId: "",
    province: "",
    city: "",
    currencyId: null,
    paymentMethodLibraryDataId: null,
  })

  useEffect(() => {
    getCountries();
  }, [])

  const getCountries = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId)
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data)
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  useEffect(() => {
    getAccount();
  }, [accountId])

  useEffect(() => {
    if (values.countryId) {
      getPaymentMethodsByCountryId(values.countryId);
    }
  }, [values.countryId])

  const getPaymentMethodsByCountryId = (countryId) => {
    if (!countryId) { return false; }
    const spinnerId = uuid();
    setCountrySpinnerArr(prevState => [...prevState, spinnerId])
    ApiService.getPaymentMethodsByCountryId(countryId).then(response => {
      if (response && response.data) {
        setPayoutMethods(response.data)
      } else {
        setPayoutMethods([]);
      }
    }).catch(error => getFail(error)).finally(() => {
      setCountrySpinnerArr(countrySpinnerArr.filter(item => item !== spinnerId))
    })
  }

  const getHolderTypesByCountry = (countryId, paymentMethodId) => {
    if (!countryId) { return false; }
    const spinnerId = uuid();
    setCountrySpinnerArr(prevState => [...prevState, spinnerId])
    ApiService.getHolderTypesByCountry(countryId, paymentMethodId).then(response => {
      if (response && response.data) {
        setHolderTypes(response.data)
      } else {
        setHolderTypes([]);
      }
    }).catch(error => getFail(error)).finally(() => {
      setCountrySpinnerArr(countrySpinnerArr.filter(item => item !== spinnerId))
    })
  }

  useEffect(() => {
    if (values.countryId && values.paymentMethodLibraryDataId) {
      getHolderTypesByCountry(values.countryId, values.paymentMethodLibraryDataId);
      getFiatCurrencies(values.countryId, values.paymentMethodLibraryDataId);
    }
  }, [values.countryId, values.paymentMethodLibraryDataId])

  const getFiatCurrencies = (countryId, paymentMethodId) => {
    if (!countryId || !paymentMethodId) { return false; }
    const spinnerId = uuid();
    setCountrySpinnerArr(prevState => [...prevState, spinnerId])
    ApiService.getFiatCurrenciesByCountryIdAndPaymentMethodId(countryId, paymentMethodId).then(response => {
      if (response && response.data) {
        setCurrencies(response.data);
      } else {
        setCurrencies([]);
      }
    }).catch(error => getFail(error)).finally(() => {
      setCountrySpinnerArr(countrySpinnerArr.filter(item => item !== spinnerId))
    })
  }

  const getAccount = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    (user.userTypeId === MERCHANT_USER_TYPE_ID ?
      DashboardApiService.getAccountAsMerchant(accountId || null) :
      DashboardApiService.getAccountAsAgent(accountId || null))
      .then(response => {
        if (response && response.data) {
          const data = { ...response.data }
          setValues(data);
        }
        extractRightSectionSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId))
  }

  const getInvalidField = () => {
    if (
      !values.accountNumber ||
      !values.accountName ||
      !values.bankName ||
      !values.bankAddress ||
      !values.bankCode ||
      !values.swiftCode ||
      !values.branchAddress ||
      !values.branchCode ||
      !values.branchName ||
      // !values.routingNumber ||
      // !values.routingType ||
      // !values.routingValue ||
      !values.countryId ||
      !values.province ||
      !values.city
    ) {
      return true;
    } else {
      return false;
    }
  }

  const onChange = (event, field, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((values) => ({
      ...values,
      [field]: event.target.value,
    }));
    setIsInvalidSubmit(false)
  }

  const onSelectOptionChange = (value, field) => {
    if (field === "countryId") {
      setValues((values) => ({
        ...values,
        paymentMethodLibraryDataId: null,
        currencyId: null,
      }));
    } else if (field === "paymentMethodLibraryDataId") {
      setValues((values) => ({
        ...values,
        currencyId: null,
      }));
    }
    setValues((values) => ({
      ...values,
      [field]: value,
    }));
    setIsChanged(true);
    setIsInvalidSubmit(false)
  }

  // const onNumberChange = (event, field, maxLength = Infinity) => {
  //   if (event.target.value.includes("e") || event.target.value.includes(".") || event.target.value.includes("-") || event.target.value === "0") {
  //     setValues((values) => ({
  //       ...values,
  //       [field]: "",
  //     }));
  //     return false;
  //   };
  //   if (event.target.value === '' || (typeof +event.target.value === "number" && Number(event.target.value) >= 0 && Number(event.target.value) < maxLength)) {
  //     setValues((values) => ({
  //       ...values,
  //       [field]: event.target.value ? +event.target.value : "",
  //     }));
  //     return false;
  //   }
  //   setIsInvalidSubmit(false)
  // };

  const getFileType = (fileType) => {
    if (!fileType) { return 0; }
    switch (fileType) {
      case "bankAddressFile":
        return 1;
      default:
        break;
    }
  }

  const uploadFile = async (data) => {
    const { event, fieldName } = data;
    if (!event.target.files.length) { return false; }
    const formData = new FormData();

    let files = [...event.target.files];
    for (let i in files) {
      const file = files[i];
      const fileName = file.name;
      const lastDotIndex = fileName.lastIndexOf('.');
      const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';

      if (allValidFileTypes.includes(fileExtention.toLowerCase())) {
        mainService.readFile(file, allValidFileTypes).then(uploadedFile => {
          setIsShowImageLoader(true);
          formData.append("id", values.id);
          formData.append("document", file);
          formData.append("type", getFileType(fieldName));
          (user.userTypeId === MERCHANT_USER_TYPE_ID ?
            DashboardApiService.uploadBankAccountDocumentAsMerchant(formData) :
            DashboardApiService.uploadBankAccountDocumentAsAgent(formData)).then(response => {
              setValues((values) => ({
                ...values,
                [fieldName]: response.data,
              }))
              AlertService.alert(SUCCESS_KEY, "Data saved")
            }).catch(error => getFail(error)).finally(() => {
              setIsShowImageLoader(false);
            })

        }).catch(error => {
          error && AlertService.alert("error", "Invalid file format")
        });
      } else {
        AlertService.alert("error", "Invalid file format")
        return false;
      }
    }
  };

  const deleteDocument = (data) => {
    const { fieldName, fileLibraryId } = data;
    if (!fileLibraryId) { return false; }
    AlertService.alertConfirm(
      `Are you sure you want to delete current file ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setIsShowImageLoader(true);
      (user.userTypeId === MERCHANT_USER_TYPE_ID ?
        DashboardApiService.deleteBankAccountDocumentAsMerchant(fileLibraryId, values.id, getFileType(fieldName)) :
        DashboardApiService.deleteBankAccountDocumentAsAgent(fileLibraryId, values.id, getFileType(fieldName))).then(() => {
          setValues((values) => ({
            ...values,
            [fieldName]: null,
          }))
          AlertService.alert(SUCCESS_KEY, "File deleted successfully")
        }).catch(error => getFail(error)).finally(() => {
          setIsShowImageLoader(false);
        })
    })
  }

  const scrollMaxDown = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  const onSubmit = (event) => {
    event && event.preventDefault();
    setButtonSpinner(modalSpinnerId);
    const valuesCopy = { ...values };
    // delete valuesCopy.countries;
    if (getInvalidField()) {
      setIsInvalidSubmit(true);
      return false;
    }
    setButtonSpinner(buttonSpinnerId);
    // valuesCopy.routingNumber = valuesCopy.routingNumber.toString();
    (user.userTypeId === MERCHANT_USER_TYPE_ID ?
      accountId ? DashboardApiService.updateOrganizationBankAccountAsMerchant(valuesCopy) : DashboardApiService.createAccountAsMerchant(valuesCopy) :
      user.userTypeId === AGENT_USER_TYPE_ID ?
        accountId ? DashboardApiService.updateOrganizationBankAccountAsAgent(valuesCopy) : DashboardApiService.createAccountAsAgent(valuesCopy) : null
    ).then((response) => {
      if (response && response.data) {
        const userCopy = { ...user };
        userCopy.isActive = false;
        dispatch(updateUserData(userCopy));
        if (redirectUrl) {
          props.history.push(`/${language}/${redirectUrl}`)
        } else {
          props.history.push(`/${language}/bank-account-form/${response.data.id}`)
        }
        // setValues(response.data);
      }
      setTimeout(() => {
        scrollMaxDown();
      }, 500);
      AlertService.alert(SUCCESS_KEY, `Your account has been successfully ${accountId ? "updated" : "created"}`)
      extractButtonSpinner(buttonSpinnerId)
    }).catch(error => getFail(error, buttonSpinnerId))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
    spinnerId && extractButtonSpinner(spinnerId);
  }

  return (
    <>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <section className='page-content position-relative min-h-100'>
        <HtmlHead title={title} description={description} />
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className="container-fluid">
          {
            values && !values.isApproved ?
              <UncontrolledAlert color="danger" role="alert">
                <i className="mdi mdi-block-helper me-2"></i>
                Attention: You can't perform any payout process with an inactive bank account. Please wait for approval from Cryllex administration.
              </UncontrolledAlert>
              : null
          }
          {
            accountId ?
              <UncontrolledAlert color="warning" role="alert">
                <i className="mdi mdi-alert-outline me-2"></i>
                After updating your bank account, its status has become inactive until approved by a Cryllex administrator. Please note that you will be unable to perform any actions with an inactive bank account.
              </UncontrolledAlert>
              : null
          }
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Bank account form"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <form onSubmit={onSubmit} onChange={() => setIsChanged(true)}>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm={12}>
                        <Row>

                          <Col lg={12}>
                            <Row>
                              <Col lg={4} md={6} className="mb-4">
                                <Label htmlFor='accountName' className='text-muted text-truncate mb-0'>Account Name*</Label>
                                <Input
                                  id="accountName"
                                  type="text"
                                  placeholder='e.g. Cryllex'
                                  className={`form-control ${(isInvalidSubmit && !values.accountName?.trim().length) ? "error-border" : ""}`}
                                  value={values.accountName || ""}
                                  onChange={event => onChange(event, "accountName", fielsLengths.length_120)}
                                />
                              </Col>
                              <Col lg={12}>
                                <UncontrolledAlert color="warning" role="alert" className='mt-3 mb-0'>
                                  <i className="mdi mdi-alert-outline me-2"></i>
                                  Attention: Your legal business name is linked to your bank account, and any changes made to
                                  the bank account name will automatically update your business name as well.
                                </UncontrolledAlert>
                              </Col>

                            </Row>
                          </Col>
                          <Col sm={12}><hr /></Col>
                          <Col lg={3} md={6} className="mb-4">
                            <Label htmlFor='accountNumber' className={`text-muted text-truncate mb-0`}>Account Number*</Label>
                            <Input
                              id="accountNumber"
                              type="text"
                              placeholder='e.g. 10987654321'
                              className={`form-control ${(isInvalidSubmit && !values.accountNumber?.trim().length) ? "error-border" : ""}`}
                              value={values.accountNumber || ""}
                              onChange={event => onChange(event, "accountNumber", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={3} md={6} className="mb-4">
                            <Label htmlFor='swiftCode' className='text-muted text-truncate mb-0'>SWIFT Code*</Label>
                            <Input
                              id="swiftCode"
                              type="text"
                              className={`form-control ${(isInvalidSubmit && !values.swiftCode?.trim().length) ? "error-border" : ""}`}
                              placeholder='e.g. AAAA-BB-CC-DDD (BOFAUS6NGFX) '
                              value={values.swiftCode || ""}
                              onChange={event => onChange(event, "swiftCode", fielsLengths.length_120)}
                            />
                          </Col>
                        </Row>
                        <hr className='mt-0' style={{ marginBottom: countrySpinnerArr && countrySpinnerArr.length ? 0 : "" }} />
                        <Row>
                          {
                            countrySpinnerArr && countrySpinnerArr.length ?
                              <Col sm={12}>
                                <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "100px", }}>
                                  <PuffLoader size={50} color={SPINNER_COLOR} />
                                </div>
                              </Col>

                              : <Col sm={12}>
                                <Row>
                                  <Col lg={3} md={6} className="mb-4">
                                    <Label htmlFor='countryId' className='text-muted text-truncate mb-0'>Country*</Label>
                                    {
                                      countries && countries.length ?
                                        <ReactSelectOption
                                          value={values.countryId}
                                          isSearchable={true}
                                          className={`${(isInvalidSubmit && !values.countryId) ? "error-border" : ""}`}
                                          selectedValue={(() => {
                                            const selectedValue = { ...countries.find(data => data.id === values.countryId) };
                                            if (Object.keys(selectedValue).length) {
                                              selectedValue.label = selectedValue.name;
                                              selectedValue.value = selectedValue.id;
                                              return selectedValue;
                                            } else {
                                              return { label: "Choose...", value: "" }
                                            }
                                          })()}
                                          items={countries.map(data => ({ label: data.name, value: data.id }))}
                                          onChange={(item) => onSelectOptionChange(item.value, "countryId")}
                                        />
                                        : null
                                    }
                                  </Col>
                                  {
                                    payoutMethods && payoutMethods.length ?
                                      <Col lg={3} md={6} className="mb-4">
                                        <Label htmlFor='paymentMethodLibraryDataId' className='text-muted text-truncate mb-0'>Payout method*</Label>
                                        <ReactSelectOption
                                          value={values.paymentMethodLibraryDataId}
                                          isSearchable={true}
                                          className={`${(isInvalidSubmit && !values.paymentMethodLibraryDataId) ? "error-border" : ""}`}
                                          selectedValue={(() => {
                                            const selectedValue = { ...payoutMethods.find(data => data.id === values.paymentMethodLibraryDataId) };
                                            if (Object.keys(selectedValue).length) {
                                              selectedValue.label = selectedValue.displayName;
                                              selectedValue.value = selectedValue.id;
                                              return selectedValue;
                                            } else {
                                              return { label: "Choose...", value: "" }
                                            }
                                          })()}
                                          items={payoutMethods.map(data => ({ label: data.displayName, value: data.id }))}
                                          onChange={(item) => onSelectOptionChange(item.value, "paymentMethodLibraryDataId")}
                                        />
                                      </Col>
                                      : null
                                  }
                                  {
                                    holderTypes && holderTypes.length ?
                                      <Col lg={3} md={6} className="mb-4">
                                        <Label htmlFor='holderTypeLibraryDataId' className='text-muted text-truncate mb-0'>Holder type*</Label>
                                        <ReactSelectOption
                                          value={values?.holderTypeLibraryDataId}
                                          isSearchable={true}
                                          selectedValue={(() => {
                                            const selectedValue = { ...holderTypes.find(item => item.id === values?.holderTypeLibraryDataId) };
                                            if (Object.keys(selectedValue).length) {
                                              selectedValue.label = selectedValue.displayName;
                                              selectedValue.value = selectedValue.id;
                                              return selectedValue;
                                            } else {
                                              return { label: "Choose...", value: "" }
                                            }
                                          })()}
                                          items={holderTypes.map(data => ({ label: data.displayName, value: data.id }))}
                                          onChange={(item) => onSelectOptionChange(item.value, "holderTypeLibraryDataId")}
                                        />
                                      </Col>
                                      : null
                                  }
                                  {
                                    currencies && currencies.length ?
                                      <Col lg={3} md={6} className="mb-4">
                                        <Label htmlFor='currencyId' className='text-muted text-truncate mb-0'>Currencies*</Label>
                                        <ReactSelectOption
                                          value={values.currencyId}
                                          isSearchable={true}
                                          // menuPlacement="auto"
                                          className={`${(isInvalidSubmit && !values.currencyId) ? "error-border" : ""}`}
                                          selectedValue={(() => {
                                            const selectedValue = { ...currencies.find(data => data.id === values.currencyId) };
                                            if (Object.keys(selectedValue).length) {
                                              selectedValue.label = selectedValue.name;
                                              selectedValue.value = selectedValue.id;
                                              return selectedValue;
                                            } else {
                                              return { label: "Choose...", value: "" }
                                            }
                                          })()}
                                          items={currencies.map(data => ({ label: data.name, value: data.id }))}
                                          onChange={(item) => onSelectOptionChange(item.value, "currencyId")}
                                        />
                                      </Col>
                                      : null
                                  }
                                </Row>
                              </Col>
                          }


                        </Row>
                        <hr className='mt-0' />
                        <Row>
                          <Col lg={3} md={6} className="mb-4">
                            <Label htmlFor='bankName' className='text-muted text-truncate mb-0'>Bank Name*</Label>
                            <Input
                              id="bankName"
                              type="text"
                              placeholder='e.g. Bank of America'
                              className={`form-control ${(isInvalidSubmit && !values.bankName?.trim().length) ? "error-border" : ""}`}
                              value={values.bankName || ""}
                              onChange={event => onChange(event, "bankName", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={3} md={6} className="mb-4">
                            <Label htmlFor='bankAddress' className='text-muted text-truncate mb-0'>Bank Address*</Label>
                            <Input
                              id="bankAddress"
                              type="text"
                              placeholder='e.g. Bank of America, N.A., 222 Broadway, New York, NY 10038, United States'
                              className={`form-control ${(isInvalidSubmit && !values.bankAddress?.trim().length) ? "error-border" : ""}`}
                              value={values.bankAddress || ""}
                              onChange={event => onChange(event, "bankAddress", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={3} md={6} className="mb-4">
                            <Label htmlFor='bankCode' className='text-muted text-truncate mb-0'>Bank Code*</Label>
                            <Input
                              id="bankCode"
                              type="text"
                              placeholder='e.g. 055'
                              className={`form-control ${(isInvalidSubmit && !values.bankCode?.trim().length) ? "error-border" : ""}`}
                              value={values.bankCode || ""}
                              onChange={event => onChange(event, "bankCode", fielsLengths.length_120)}
                            />
                          </Col>

                          <Col lg={3} md={6} className="mb-4">
                            <Label htmlFor='branchAddress' className='text-muted text-truncate mb-0'>Bank Branch Address*</Label>
                            <Input
                              id="branchAddress"
                              type="text"
                              placeholder='e.g. 315 Montgomery Street, San Francisco, California 94104, United States'
                              className={`form-control ${(isInvalidSubmit && !values.branchAddress?.trim().length) ? "error-border" : ""}`}
                              value={values.branchAddress || ""}
                              onChange={event => onChange(event, "branchAddress", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={3} md={6} className="mb-4">
                            <Label htmlFor='branchCode' className='text-muted text-truncate mb-0'>Branch Code*</Label>
                            <Input
                              id="branchCode"
                              type="text"
                              placeholder='e.g. GFX'
                              className={`form-control ${(isInvalidSubmit && !values.branchCode?.trim().length) ? "error-border" : ""}`}
                              value={values.branchCode || ""}
                              onChange={event => onChange(event, "branchCode", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={3} md={6} className="mb-4">
                            <Label htmlFor='branchName' className='text-muted text-truncate mb-0'>Branch Name*</Label>
                            <Input
                              id="branchName"
                              type="text"
                              placeholder='e.g. San Francisco Branch'
                              className={`form-control ${(isInvalidSubmit && !values.branchName?.trim().length) ? "error-border" : ""}`}
                              value={values.branchName || ""}
                              onChange={event => onChange(event, "branchName", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={3} md={6} className="mb-4">
                            <Label htmlFor='province' className='text-muted text-truncate mb-0'>State/Province*</Label>
                            <Input
                              id="province"
                              type="text"
                              placeholder='e.g. California'
                              className={`form-control ${(isInvalidSubmit && !values.province?.trim().length) ? "error-border" : ""}`}
                              value={values.province || ""}
                              onChange={event => onChange(event, "province", fielsLengths.length_120)}
                            />
                          </Col>
                          <Col lg={3} md={6} className="mb-4">
                            <Label htmlFor='city' className='text-muted text-truncate mb-0'>City*</Label>
                            <Input
                              id="city"
                              type="text"
                              placeholder='e.g. Oakland'
                              className={`form-control ${(isInvalidSubmit && !values.city?.trim().length) ? "error-border" : ""}`}
                              value={values.city || ""}
                              onChange={event => onChange(event, "city", fielsLengths.length_120)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          {
                            values.id ?
                              isShowImageLoader ?
                                <Col sm={12}>
                                  <div className='d-flex justify-content-center align-items-center' style={{ minHeight: "150px", }}>
                                    <PuffLoader size={60} color={SPINNER_COLOR} />
                                  </div>
                                </Col>
                                :
                                <Col sm={12}>
                                  <hr />
                                  <Row>
                                    <Col md={3} sm={6} className='mb-3'>
                                      <FileComponent
                                        data={values}
                                        fieldName="bankAddressFile"
                                        labelValue="Business Bank Statement*"
                                        setZoomImagePath={setZoomImagePath}
                                        uploadFile={uploadFile}
                                        deleteDocument={deleteDocument}
                                      />
                                    </Col>
                                    <Col sm={12}>
                                      <small className='fw-500 d-block mb-2'>Allowed file types {allValidFileTypes.map((item, index) => <i key={index}>{item}. </i>)}</small>
                                      <UncontrolledAlert color="warning" role="alert">
                                        <i className="mdi mdi-alert-outline me-2"></i>
                                        All documents require Certified true copies, alternatively, the documents can be visually presented through Cryllex customer support
                                      </UncontrolledAlert>
                                    </Col>
                                  </Row>
                                </Col>
                              : null
                          }
                        </Row>
                      </Col>
                    </Row>
                    <div className='d-flex justify-content-end'>
                      <ActionButton
                        type="submit"
                        name="Submit"
                        className="btn btn-primary btn-block px-4"
                        spinnerId={buttonSpinnerId}
                        disabled={!isChanged}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </form>
        </div>
      </section>
    </>
  )
}

export default withRouter(BankAccountForm)