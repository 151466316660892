// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { Steps, Hints } from 'intro.js-react';
// import 'intro.js/introjs.css';

// const IntroComponent = () => {
//   const {classNames} = useSelector((state) => state.Intro);
//   const [steps, setSteps] = useState([]);
//   const [stepsEnabled, setStepsEnabled] = useState(false);
//   const [hintsEnabled, setHintsEnabled] = useState(true);

//   useEffect(() => {
//     const newSteps = classNames.map((className, index) => ({
//       element: `.${className}`,
//       intro: `Step ${index + 1}`,
//     }));
//     setSteps(newSteps);
//   }, [classNames]);

//   const toggleSteps = () => {
//     setStepsEnabled((prev) => !prev);
//   };

//   const toggleHints = () => {
//     setHintsEnabled((prev) => !prev);
//   };

//   const onExit = () => {
//     setStepsEnabled(false);
//   };

//   return (
//     <div>
//       <Steps
//         enabled={stepsEnabled}
//         steps={steps}
//         initialStep={0}
//         onExit={onExit}
//       />
//       <Hints enabled={hintsEnabled} hints={[]} />

//       <div className="controls">
//         <button onClick={toggleSteps}>Toggle Steps</button>
//         <button onClick={toggleHints}>Toggle Hints</button>
//       </div>
//     </div>
//   );
// };

// export default IntroComponent;




import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import DashboardApiService from './Services/DashboardApiService';
import { setSiteTourIsNotified } from './store/intro/actions';
import AlertService from './Services/alertService';

const SiteTourComponent = () => {
  const dispatch = useDispatch();
  const { stepsData } = useSelector((state) => state.Intro);

  const [steps, setSteps] = useState([]);
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);

  useEffect(() => {
    if (stepsData && stepsData.data && JSON.parse(stepsData.data).length > 0 && !stepsData.isNotified) {
      const newSteps = JSON.parse(stepsData.data).map((step, index) => ({
        element: `.${step.className}`,
        intro: step.description,
        title: step.title,
      }));
      setSteps(newSteps);
      setTimeout(() => {
        setStepsEnabled(true);
      }, 500);
    }
  }, [stepsData]);

  const onExit = (id) => {
    DashboardApiService.updatePromotionStatus(id).then(() => {
      dispatch(setSiteTourIsNotified(true));
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setStepsEnabled(false);
      dispatch(setSiteTourIsNotified(true));
    })
  };

  return (
    <div>
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={stepNumber}
        onExit={() => onExit(stepsData.id)}
      />
    </div>
  );
};

export default SiteTourComponent;
