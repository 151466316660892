import React, { useState, useEffect, useCallback, useRef } from 'react'
import HtmlHead from '../../../components/HtmlHead/HtmlHead';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Table,
  Button,
  Card,
  CardBody,
} from "reactstrap"
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import { useDispatch, useSelector } from 'react-redux';
import NoData from '../../../components/NoData/NoData';


function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function PayingCustomerKyc(props) {

  const title = "KYC";
  const description = "";
  const { language } = useSelector(state => state.Languages);
  const { user } = useSelector(state => state.Login);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [zoomImagePath, setZoomImagePath] = useState(null);

  useOutsideAlerter(wrapperRef, setZoomImagePath);



  return (

    <div>
      <div className={`zoom-image-modal ${!zoomImagePath ? "d-none" : ""}`}>
        <img
          src={zoomImagePath ? zoomImagePath : ""}
          ref={wrapperRef}
        />
        <i className='bx bx-x close-icon'></i>
      </div>
      <section className='page-content position-relative min-h-100'>
        <HtmlHead title={title} description={description} />
        <RightSectionSpinner spinner={rightSectionSpinners} />
        {
          user ?
            <div className="container-fluid">
              <Breadcrumbs
                title="Cryllex"
                breadcrumbItem="KYC"
              />

              <Row>
                <Col lg={12}>
                  <Card className='mb-5'>
                    <CardBody>
                      <Col sm={12}>
                        <Row>
                          <Col sm={6}>
                            <Table bordered striped responsive className='mb-0'>
                              <tbody>
                                <tr>
                                  <th
                                    scope="row"
                                    style={{ width: "400px" }}
                                    className={"text-capitalize"}
                                  >
                                    First Name
                                  </th>
                                  <td className='w-50'>{user.firstname || "Not set"}</td>
                                </tr>
                                <tr>
                                  <th
                                    scope="row"
                                    style={{ width: "400px" }}
                                    className={"text-capitalize"}
                                  >
                                    Last Name
                                  </th>
                                  <td className='w-50'>{user.lastname || "Not set"}</td>
                                </tr>
                                <tr>
                                  <th
                                    scope="row"
                                    style={{ width: "400px" }}
                                    className={"text-capitalize"}
                                  >
                                    Email
                                  </th>
                                  <td className='w-50'>{user.email || "Not set"}</td>
                                </tr>
                                <tr>
                                  <th
                                    scope="row"
                                    style={{ width: "400px" }}
                                    className={"text-capitalize"}
                                  >
                                    Proof of Address Document
                                  </th>
                                  <td className='w-50'>
                                    {
                                      user.userAddressProofFile ?
                                        <Button
                                          color="primary"
                                          size="sm"
                                          onClick={() => setZoomImagePath(user.userAddressProofFile.filePath)}
                                        >
                                          View Proof
                                        </Button>
                                        : "Not set"
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                          <Col sm={6}>
                            <Table bordered striped responsive className='mb-0'>
                              <tbody>
                                <tr>
                                  <th
                                    scope="row"
                                    style={{ width: "400px" }}
                                    className={"text-capitalize"}
                                  >
                                    Veriff Verification Status
                                  </th>
                                  <td className='w-50'>

                                    <span className={`badge badge-soft-success font-size-14 badge badge-pill`}>
                                      Verified
                                    </span>
                                    or
                                    <span className={`badge badge-soft-danger font-size-14 badge badge-pill`}>
                                      Unverified
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    scope="row"
                                    style={{ width: "400px" }}
                                    className={"text-capitalize"}
                                  >
                                    Reason for Failure
                                  </th>
                                  <td className='w-50'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    scope="row"
                                    style={{ width: "400px" }}
                                    className={"text-capitalize"}
                                  >
                                    Paying Customer ID Front Side
                                  </th>
                                  <td className='w-50'>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => { }}
                                    >
                                      View Proof
                                    </Button>
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    scope="row"
                                    style={{ width: "400px" }}
                                    className={"text-capitalize"}
                                  >
                                    Paying Customer ID Back Side
                                  </th>
                                  <td className='w-50'>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => { }}
                                    >
                                      View Proof
                                    </Button>
                                  </td>
                                </tr>
                                <tr>
                                  <th
                                    scope="row"
                                    style={{ width: "400px" }}
                                    className={"text-capitalize"}
                                  >
                                    Paying Customer Biometric Data (Face Scan)
                                  </th>
                                  <td className='w-50'>
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => { }}
                                    >
                                      View Proof
                                    </Button>
                                  </td>
                                </tr>

                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col sm={12} className='mt-3'>
                          <div className='d-flex justify-content-end'>
                            <Button color="primary" className='ms-2 px-4'
                              onClick={() => props.history.push(`/${language}/paying-customer/kyc-form`)}
                            >
                              Edit
                            </Button>
                          </div>
                        </Col> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>


            </div >
            : <NoData />
        }

      </section >
    </div>

  )
}