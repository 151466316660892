import React, { useCallback, useState, useEffect } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import { ERROR_KEY } from '../../../Constants/MainKeys'
import { addRightSectionSpinner, removePageSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import DashboardApiService from '../../../Services/DashboardApiService'
import uuid from 'react-uuid'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import ApiService from '../../../Services/ApiService'

const Reports = (props) => {
  const title = "Reports";
  const description = "";
  const { t } = props;
  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [reports, setReports] = useState([])

  const options = {
    chart: {
      id: 'apexchart-example',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: []
    }
  };

  const series = [{
    name: 'series-1',
    data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
  }]

  const options1 = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#f1b44c", "#556ee6", "#FF0000",],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  const series1 = [
    {
      name: "Previous",
      data: [30, 11, 22, 18, 32, 23, 58, 45, 30, 36, 15, 34],
    },
    {
      name: "Current",
      data: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26],
    },
    {
      name: "Next",
      data: [24, 36, 12, 14, 16, 18, 60, 65, 70, 80, 70, 65],
    },
  ]

  const options2 = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#f1b44c", "#556ee6",],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
      ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  const series2 = [
    {
      name: "Previous",
      data: [30, 11, 22, 18, 32],
    },
    {
      name: "Current",
      data: [18, 21, 45, 36, 65],
    },
  ]

  const options3 = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#556ee6", "#00FFE5",],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  const series3 = [
    {
      name: "Current",
      data: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26],
    },
    {
      name: "Next",
      data: [24, 36, 12, 14, 16, 18, 60, 65, 70, 80, 70, 65],
    },
  ]

  const options4 = {
    chart: {
      id: 'apexchart-example',
      toolbar: {
        show: false,
      },
    },
    colors: ["#FF0000",],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: []
    }
  };

  const series4 = [{
    name: 'series-1',
    data: [30, 40, 35, 20, 49, 60, 50, 91, 86]
  }]

  const series5 = [
    {
      name: "series1",
      data: [31, 40, 36, 51, 49, 72, 69, 56, 68, 82, 68, 76],
    },
  ];

  const options5 = {
    chart: {
      toolbar: "false",
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    colors: ["#556ee6"],
    stroke: {
      curve: "smooth",
      width: 3,
    },
  };

  const options6 = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#f1b44c", "#556ee6",],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  const series6 = [
    {
      name: "Previous",
      data: [30, 11, 22, 18, 32, 30, 11, 22, 18, 32, 45, 40],
    },
    {
      name: "Current",
      data: [18, 21, 45, 36, 65, 18, 21, 45, 36, 65, 18, 67],
    },
  ]

  const options7 = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#556ee6", "#32FF73",],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  const series7 = [
    {
      name: "Current",
      data: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26],
    },
    {
      name: "Next",
      data: [24, 36, 12, 14, 16, 18, 60, 65, 70, 80, 70, 65],
    },
  ]

  useEffect(() => {
    // getReports()
  }, [])

  const getReports = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    ApiService.getReports().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setReports(data);
      }
      extractRightSectionSpinner(spinnerId)
    }).catch(error => getFail(error, spinnerId))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }


  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <Breadcrumbs
          title="Cryllex"
          breadcrumbItem={<span>Reports <small className='font-size-10'>(In process)</small></span>}
        />
        <Row>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="">
                      <h5 className="card-title me-2">{t("Transaction Summary")}</h5>
                      <p className='mb-3'>
                        Overview of all transactions processed within a specified time period. It includes details such as transaction ID, date, amount, payment method, status (successful, failed, pending), and customer information.
                      </p>
                    </div>
                  </Col>
                  <hr className="mb-4" />
                  <Col>
                    <div id="area-chart" dir="ltr" className='border'>
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height={350}
                        className="apex-charts"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div>
                      <h5 className="card-title me-2">{t("Settlement Report")}</h5>
                      <p className='mb-3'>
                        Information about the funds transferred from the payment provider to your bank account. It typically includes settlement dates, settlement amounts, fees deducted, and any adjustments or refunds made.
                      </p>
                    </div>
                  </Col>
                  <hr className="mb-4" />
                  <Col>
                    <div id="area-chart-2" dir="ltr" className='border'>
                      <ReactApexChart
                        options={options1}
                        series={series1}
                        type="area"
                        height={350}
                        className="apex-charts"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div>
                      <h5 className="card-title me-2">{t("Refunds Report")}</h5>
                      <p className='mb-3'>
                        This report provides details of all refunded transactions, including refund amounts, dates, and the associated original transactions. It helps you track refund activity and understand the impact on your revenue.
                      </p>
                    </div>
                  </Col>
                  <hr className="mb-4" />
                  <Col>
                    <div id="area-chart-3" dir="ltr" className='border'>
                      <ReactApexChart
                        options={options3}
                        series={series3}
                        type="area"
                        height={350}
                        className="apex-charts"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div>
                      <h5 className="card-title me-2">{t("Sales Report")}</h5>
                      <p className='mb-3'>
                        This report gives you insights into your sales performance, including the total sales volume, the number of successful transactions, average transaction value, and sales trends over a specific period. It may also provide breakdowns by product or service categories.
                      </p>
                    </div>
                  </Col>
                  <hr className="mb-4" />
                  <Col>
                    <div id="area-chart-4" dir="ltr" className='border'>
                      <ReactApexChart
                        options={options2}
                        series={series2}
                        type="area"
                        height={350}
                        className="apex-charts"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="">
                      <h5 className="card-title me-2">{t("Fee Analysis Report")}</h5>
                      <p className='mb-3'>
                        This report breaks down the fees associated with your payment processing, including transaction fees, interchange fees, currency conversion fees, and any other applicable charges. It helps you understand the costs and evaluate the impact on your profitability.
                      </p>
                    </div>
                  </Col>
                  <hr className="mb-4" />
                  <Col>
                    <div id="area-chart-5" dir="ltr" className='border'>
                      <ReactApexChart
                        options={options6}
                        series={series6}
                        type="area"
                        height={350}
                        className="apex-charts"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="">
                      <h5 className="card-title me-2">{t("Chargebacks Report")}</h5>
                      <p className='mb-3'>
                        If your business experiences chargebacks (disputed transactions), this report will provide information about chargeback instances, including the transaction details, chargeback reason, status, and any actions taken to resolve the disputes.
                      </p>
                    </div>
                  </Col>
                  <hr className="mb-4" />
                  <Col>
                    <div id="area-chart-6" dir="ltr" className='border'>
                      <ReactApexChart
                        options={options5}
                        series={series5}
                        type="area"
                        height={350}
                        className="apex-charts"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>


          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div>
                      <h5 className="card-title me-2">{t("Customer Analytics Report")}</h5>
                      <p className='mb-3'>
                        Some payment providers offer reports that provide insights into customer behavior and demographics. These reports can include information such as customer location, purchasing patterns, average customer lifetime value, and other metrics that help you understand and segment your customer base.
                      </p>
                    </div>
                  </Col>
                  <hr className="mb-4" />
                  <Col>
                    <div id="area-chart-7" dir="ltr" className='border'>
                      <ReactApexChart
                        options={options7}
                        series={series7}
                        type="area"
                        height={350}
                        className="apex-charts"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default withTranslation()(Reports)