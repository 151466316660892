import React from 'react'
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap"
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import moment from 'moment'
import ActionButton from '../../../components/Buttons/ActionButton'
import uuid from 'react-uuid'
import { addButtonSpinner, removeButtonSpinner } from '../../../store/actions'
import DashboardApiService from '../../../Services/DashboardApiService'
import AlertService from '../../../Services/alertService'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import { Link } from 'react-router-dom'

const buttonSpinnerId = uuid();

const CurrentClientDatabase = (props) => {

  const title = "Client Database";
  const description = "";
  const { clientId } = props.match.params;
  const dispatch = useDispatch()
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { user } = useSelector(state => state.Login);
  const { language, languages } = useSelector(state => state.Languages);

  const removeClient = () => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      dispatch(addButtonSpinner(buttonSpinnerId));
      DashboardApiService.removeClient(clientId, user.activeAgentId).then(response => {
        AlertService.alert("success", "Data saved");
        props.history.push(`/${language}/home`)
      })
        .catch(error => AlertService.alert("error", error))
        .finally(() => {
          dispatch(removeButtonSpinner(buttonSpinnerId))
        })
    })

  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />

        {/* <div className="d-flex flex-wrap justify-content-between mb-3">
          <h4 className=" me-2">{moment(new Date()).format("LL")}</h4>
        </div> */}
        {/* <div className={`page-title-box d-sm-flex align-items-center justify-content-between`}>
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-size-22">{`Welcome ${user.fullName}, to your Client Database.`}</h4>
          </div>
        </div> */}
        <div className='container-fluid'>

          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Organization"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col lg={12}>
              <Row>
                <Col md={6} lg={4}>
                  <Card body>
                    <CardTitle className="mt-0">KYC</CardTitle>
                    <CardText>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    </CardText>
                    <Link
                      to={`/${language}/client-database-kyc/${clientId}`}
                      className="btn btn-primary "
                    >
                      View
                    </Link>
                  </Card>
                </Col>
                <Col md={6} lg={4}>
                  <Card body>
                    <CardTitle className="mt-0">Bank Account</CardTitle>
                    <CardText>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    </CardText>
                    <Link
                      to={`/${language}/client-database-banking-information/${clientId}`}
                      className="btn btn-primary "
                    >
                      View
                    </Link>
                  </Card>
                </Col>
                <Col md={6} lg={4}>
                  <Card body>
                    <CardTitle className="mt-0">Transactions</CardTitle>
                    <CardText>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    </CardText>
                    <Link
                      to={`/${language}/client-database-transactions/${clientId}`}
                      className="btn btn-primary "
                    >
                      View
                    </Link>
                  </Card>
                </Col>
                {/* <Col md={6} lg={3}>
                  <Card body>
                    <CardTitle className="mt-0">Performance Breakdown</CardTitle>
                    <CardText>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    </CardText>
                    <Link
                      to={`#`}
                      className="btn btn-primary "
                    >
                      View
                    </Link>
                  </Card>
                </Col> */}
                <Col sm={12}>
                  <div className='d-flex justify-content-end'>
                    <Button
                      type="button"
                      color="danger"
                      className='shadow-sm nowrap px-4'
                      onClick={() => removeClient()}
                    >
                      Remove Client
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>

          </Row>
        </div>
      </div>
    </>
  );
}

export default CurrentClientDatabase;
