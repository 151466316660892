import React, { useCallback, useState } from 'react'
import NoData from '../../../../components/NoData/NoData'
import {
  Card,
  Table,
  CardBody,
  Row,
  Col,
  Button,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import BankAccountInformation from '../../BankAccount/BankAccountInformation'
import { useDispatch } from 'react-redux';
import Auxiliary from '../../../../hoc/auxiliary/auxiliary';
import MainService from '../../../../Services/MainService';
import moment from 'moment';
import { ADMIN_USER_TYPE_ID, fielsLengths } from '../../../../Constants/MainKeys';
import ActionButton from '../../../../components/Buttons/ActionButton';
import uuid from 'react-uuid';
import AlertService from '../../../../Services/alertService';
import DashboardApiService from '../../../../Services/DashboardApiService';
import { addButtonSpinner, removeButtonSpinner } from '../../../../store/actions';


const buttonSpinnerId = uuid();

export default function PayOutDataTable({ data, currencies, user, getPayoutById }) {

  const dispatch = useDispatch();
  const [isShowDeclineModal, setIsShowDeclineModal] = useState(false);
  const [declineMessage, setDeclineMessage] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState("");

  const getCurrency = (currencyId) => {
    if (!currencyId || !currencies?.length) return "";
    const currentCurrency = currencies.find(item => item.id === currencyId);
    return currentCurrency ? currentCurrency.name : "";
  };

  const approvePayOut = () => {
    if (!data) { return false; }
    AlertService.alertConfirm(
      `Are you sure ?`,
      "You want to approve the current payment.",
      "Yes",
      "No"
    ).then(() => {
      console.log(data);

      setButtonSpinner(buttonSpinnerId);
      DashboardApiService.approvePayOut({ id: data.id }).then(() => {
        AlertService.alert("success", "The current payment has been successfully approved.");
        getPayoutById && getPayoutById();
      }).catch(error => AlertService.alert("error", error)).finally(() => {
        extractButtonSpinner(buttonSpinnerId);
      })
    })
  }

  const declinePayOut = (spinnerId) => {
    if (!data) { return false; }
    if (!declineMessage.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    }
    setButtonSpinner(spinnerId);
    DashboardApiService.declinePayOut().then(() => {
      AlertService.alert("success", "The current payment has been successfully declined.");
      getPayoutById && getPayoutById();
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      extractButtonSpinner(spinnerId);
      cancel();
    })
  }

  const _onChange = (event, cb, maxLength = Infinity) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value)
    setIsInvalidSubmit(false);
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const cancel = () => {
    setIsShowDeclineModal(false);
    setDeclineMessage("");
  }


  return data ?
    <Auxiliary>



      <Modal
        isOpen={isShowDeclineModal}
        size="lg"
        role="dialog"
        className="exampleModal"
        tabIndex="-1"
        toggle={cancel}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={cancel}
          >
            Decline
          </ModalHeader>
          <ModalBody>
            <form>
              <Label htmlFor='declineMessage'>Decline message</Label>
              <Input
                id="declineMessage"
                type="textarea"
                value={declineMessage}
                rows={4}
                autoFocus
                style={{ resize: "none" }}
                placeholder='Please enter your decline message'
                className={`form-control ${isInvalidSubmit && !declineMessage.trim().length ? "error-border" : ""}`}
                onChange={(event) => _onChange(event, setDeclineMessage, fielsLengths.length_2000)}
              />
            </form>
          </ModalBody>
          <ModalFooter>
            <div className='d-flex justify-content-end gap-2'>
              <Button
                color="primary"
                className='px-4'
                outline
                onClick={cancel}
              >
                Cancel
              </Button>
              <ActionButton
                spinnerId={`reject_${buttonSpinnerId}`}
                color="primary"
                className='px-4'
                onClick={() => declinePayOut(`reject_${buttonSpinnerId}`)}
              >
                Save
              </ActionButton>
            </div>
          </ModalFooter>
        </div>
      </Modal>




      {
        user.userTypeId === ADMIN_USER_TYPE_ID ?
          <Card className="mini-stats-wid my-1">
            <CardBody>
              <Row>
                <Col md={6}>
                  <Table bordered striped responsive className='mb-0'>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Local Transaction Id
                        </th>
                        <td className='w-50'><span className='max-line-1' title={data.localTransactionId}>{data.localTransactionId}</span></td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Amount
                        </th>
                        <td className='w-50'> <span className='d-flex justify-content-between flex-wrap'>{data.amount} {getCurrency(data.currencyId)} <span>(Total: {data.amount + data.commission} {getCurrency(data.currencyId)})</span></span></td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Total Commission
                        </th>
                        <td className='w-50'>{data.commission} {getCurrency(data.currencyId)}</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Local Commission
                        </th>
                        <td className='w-50'>{data.localCommission} {getCurrency(data.currencyId)}</td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Notified
                        </th>
                        <td className='w-50'>
                          <span className={`badge badge-soft-${data?.notified ? "success" : "danger"} font-size-14 badge badge-pill`}>
                            {data?.notified ? "Yes" : "No"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Notification Time
                        </th>
                        <td className='w-50'>{
                          data.notificationTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.notificationTime))).format("LL H:mm") : null
                        }</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Processed
                        </th>
                        <td className='w-50'>
                          <span className={`badge badge-soft-${data?.processed ? "success" : "danger"} font-size-14 badge badge-pill`}>
                            {data?.processed ? "Yes" : "No"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Operation Time
                        </th>
                        <td className='w-50'>{
                          data.operationTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.operationTime))).format("LL H:mm") : <span className='text-danger'>Not operated yet</span>
                        }</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={6}>
                  <Table bordered striped responsive className='mb-0'>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Status
                        </th>
                        <td className='w-50'>
                          <span className={`badge badge-soft-${MainService.getStatusColor(data.statusName)} font-size-14 badge badge-pill`}>{data.statusName}</span>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Status Update Time
                        </th>
                        <td className='w-50'>{
                          data.statusUpdateTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.statusUpdateTime))).format("LL H:mm") : <span className='text-danger'>Not updated yet</span>
                        }</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Calculated
                        </th>
                        <td className='w-50'>
                          <span className={`badge badge-soft-${data?.calculated ? "success" : "danger"} font-size-14 badge badge-pill`}>
                            {data?.calculated ? "Yes" : "No"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Calculated Amount
                        </th>
                        <td className='w-50'>{data.calculatedAmount} {getCurrency(data.currencyId)}</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Calculated Cleared Amount
                        </th>
                        <td className='w-50'>{data.calculatedClearedAmount} {getCurrency(data.currencyId)}</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Calculation Time
                        </th>
                        <td className='w-50'>
                          {
                            data.calculationTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.calculationTime))).format("LL H:mm") : <span className='text-danger'>Not calculated yet</span>
                          }
                        </td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Create Time
                        </th>
                        <td className='w-50'>{
                          data.createTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.createTime))).format("LL H:mm") : null
                        }</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Decline Message
                        </th>
                        <td className='w-50'>{data.declineMessage}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col sm={12} className='mt-4'>
                  <div className='d-flex justify-content-end'>

                    <ActionButton
                      spinnerId={buttonSpinnerId}
                      color="success"
                      name="Approve"
                      className='ms-2 px-4'
                      onClick={approvePayOut}
                    />

                    <Button
                      color="danger"
                      className='ms-2 px-4'
                      onClick={() => setIsShowDeclineModal(true)}
                    >
                      Decline
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          : <Card className="mini-stats-wid my-1">
            <CardBody>
              <Row>
                <Col md={6}>
                  <Table bordered striped responsive className='mb-0'>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Local Transaction Id
                        </th>
                        {/* <td className='w-50'>{data.localTransactionId}</td> */}
                        <td className='w-50'><span className='max-line-1' title={data.localTransactionId}>{data.localTransactionId}</span></td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Amount
                        </th>
                        <td className='w-50'> <span className='d-flex justify-content-between flex-wrap'>{data.amount} {getCurrency(data.currencyId)} <span>(Total: {data.amount + data.commission} {getCurrency(data.currencyId)})</span></span></td>
                      </tr>

                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Commission
                        </th>
                        <td className='w-50'>{data.commission} {getCurrency(data.currencyId)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col md={6}>
                  <Table bordered striped responsive className='mb-0'>
                    <tbody>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Status
                        </th>
                        <td className='w-50'>
                          <span className={`badge badge-soft-${MainService.getStatusColor(data.statusName)} font-size-14 badge badge-pill`}>{data.statusName}</span>
                        </td>
                      </tr>



                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Create Time
                        </th>
                        <td className='w-50'>{
                          data.createTime ? moment(MainService.convertUTCDateToLocalDate(new Date(data.createTime))).format("LL H:mm") : null
                        }</td>
                      </tr>
                      <tr>
                        <th
                          scope="row"
                          style={{ width: "400px" }}
                          className={"text-capitalize"}
                        >
                          Decline Message
                        </th>
                        <td className='w-50'>{data.declineMessage}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
      }
      {
        data.organizationBankAccount ?
          <div>
            <p className='h4 mt-3 mb-3'>Bank account information</p>
            <Card>
              <CardBody>
                <BankAccountInformation
                  bankAccount={data.organizationBankAccount}
                  isShowStatus={false}
                  currencies={currencies}
                />
              </CardBody>
            </Card>
          </div>

          : null
      }
    </Auxiliary>
    : <Card>
      <CardBody>
        <NoData />
        <p className='text-center'>
          No Data
        </p>
      </CardBody>
    </Card>
}
