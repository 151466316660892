// import React, { useCallback, useState, useEffect } from 'react'
// import Breadcrumbs from './../../../components/Common/Breadcrumb'
// import {
//   Card,
//   CardBody,
//   Col,
//   Row,
//   CardHeader,
//   Modal,
//   Input,
//   Label,
//   Button,
// } from "reactstrap"
// import { Table } from "react-bootstrap"
// import * as moment from "moment";
// import AlertService from '../../../Services/alertService';
// import { addButtonSpinner, removeButtonSpinner, addRightSectionSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions';
// import uuid from 'react-uuid';
// import { INACTIVE_ACCOUNT_INFO_MSG, SUCCESS_KEY } from '../../../Constants/MainKeys';
// import DashboardApiService from '../../../Services/DashboardApiService';
// import { useDispatch, useSelector } from 'react-redux';
// import ActionButton from '../../../components/Buttons/ActionButton';
// import MainService from '../../../Services/MainService';
// import ReactPaginate from 'react-paginate';
// import { Link } from 'react-router-dom';
// import HtmlHead from './../../../components/HtmlHead/HtmlHead';
// import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner';
// import NoData from '../../../components/NoData/NoData';
// import ReactSelectOption from '../../../components/SelectOptions/ReactSelectOption';


// const buttonSpinnerId = uuid();
// export default function InstantPayment(props) {

//   const title = "Instant Payment"
//   const description = ""
//   const pageSize = 10;
//   const maxAmount = 1000000000;
//   const transactionTextMaxCount = 200;
//   let spinnerColor = "#556EE6";
//   const dispatch = useDispatch();
//   const { rightSectionSpinners } = useSelector(state => state.Spinners);
//   const { user } = useSelector(state => state.Login);
//   const [isShowInstantPaymentForm, setIsShowInstantPaymentForm] = useState(false);
//   const [pagination, setPagination] = useState(null);
//   const [activePageNumber, setActivePageNumber] = useState(0);
//   const [transactionHistory, setTransactionHistory] = useState([]);
//   const [statuses, setStatuses] = useState([]);

//   const [amount, setAmount] = useState("");
//   const [transactionText, setTransactionText] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [endTime, setEndTime] = useState("");
//   const [selectedStatusId, setSelectedStatusId] = useState(null);

//   const copyLink = (link) => {
//     if (!link) { return false; }
//     navigator.clipboard.writeText(link);
//     AlertService.alert(SUCCESS_KEY, "Copied");
//   }

//   const onNumberChange = (event, cb, maxLength = null) => {
//     if (typeof +event.target.value === "number" && Number(event.target.value) >= 0 && Number(event.target.value) <= maxLength) {
//       cb(+event.target.value ? +event.target.value : "");
//     }
//   };

//   const onChange = (event, cb, maxLength = null) => {
//     if (maxLength && maxLength < event.target.value.length) { return; }
//     cb(event.target.value);
//   }

//   const onSubmit = (event) => {
//     event.preventDefault();
//     setButtonSpinner(buttonSpinnerId);
//     const data = {
//       amount,
//       transactionText,
//       ExpireDateString: endDate,
//       ExpireTimeString: endTime,
//       timeZoneOffset: Number(new Date().getTimezoneOffset() / 60)
//     }
//     DashboardApiService.createInstantPayment(data).then(response => {
//       if (response && response.data) {
//         // setTransactionHistory(prevHistory => [{ ...response.data }, ...prevHistory]);
//         resetFields();
//         AlertService.alert(SUCCESS_KEY, "Instant payment created successfully");
//         getInstantPayments(1);
//       }
//       extractButtonSpinner(buttonSpinnerId);
//     }).catch(error => getFail(error, buttonSpinnerId));
//   }

//   const resetFields = () => {
//     setAmount("");
//     setTransactionText("");
//     setEndDate("");
//     setEndTime("");
//   }

//   const handlePageClick = (event) => {
//     getInstantPayments(event.selected + 1)
//     setActivePageNumber(event.selected);
//   };

//   useEffect(() => {
//     getInstantPayments(1);
//   }, []);

//   const getToday = () => {
//     var today = new Date();
//     var dd = today.getDate();
//     var mm = today.getMonth() + 1; //January is 0!
//     var yyyy = today.getFullYear();
//     if (dd < 10) {
//       dd = '0' + dd
//     }
//     if (mm < 10) {
//       mm = '0' + mm
//     }
//     return today = yyyy + '-' + mm + '-' + dd;
//   }

//   const getInstantPayments = (pageNumber) => {
//     const spinnerId = uuid();
//     setRightSectionSpinner(spinnerId);
//     DashboardApiService.getTransactionHistoryAsMerchant(pageNumber, pageSize, 1).then(response => {
//       if (response && response.pagination) setPagination(JSON.parse(response.pagination));
//       if (response && response.data) {
//         const data = { ...response.data };
//         if (data.filter.statuses) {
//           setStatuses([...data.filter.statuses]);
//         }
//         if (data.paymentHistory) {
//           setTransactionHistory([...data.paymentHistory])
//         }
//       }
//       extractRightSectionSpinner(spinnerId);
//     }).catch(error => getFail(error, spinnerId))
//   }

//   const setButtonSpinner = useCallback(spinner => {
//     dispatch(addButtonSpinner(spinner));
//   }, []);

//   const extractButtonSpinner = useCallback(spinner => {
//     dispatch(removeButtonSpinner(spinner));
//   }, []);

//   const setRightSectionSpinner = useCallback(spinner => {
//     dispatch(addRightSectionSpinner(spinner));
//   }, []);

//   const extractRightSectionSpinner = useCallback(spinner => {
//     dispatch(removeRightSectionSpinner(spinner));
//   }, []);

//   const getFail = (error, spinnerId) => {
//     error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
//     spinnerId && extractRightSectionSpinner(spinnerId);
//     spinnerId && extractButtonSpinner(spinnerId);
//   }

//   return (
//     <>
//       <HtmlHead title={title} description={description} />
//       <div className='page-content position-relative min-h-100vh'>
//         <RightSectionSpinner spinner={rightSectionSpinners} />
//         <div className="container-fluid">
//           <Breadcrumbs
//             title="Cryllex"
//             breadcrumbItem="Instant payment"
//           />
//           <Row>
//             <Col xl={12}>
//               {
//                 user.isActive ?
//                   <Card className='position-relative p-3'>

//                     <CardHeader className="bg-transparent p-0">
//                       <div className='d-md-flex align-items-end justify-content-between'>
//                         <p className='text-md-start text-center m-0'>
//                           You can make an instant payment link by clicking create.
//                           <br />
//                           You will get a link that you can forward or publish.
//                         </p>
//                         <div className='mt-md-0 mt-3 d-md-block d-flex align-items-center justify-content-center'>
//                           <button
//                             className="btn btn-outline-primary d-flex align-items-center nowrap"
//                             type="button"
//                             onClick={() => setIsShowInstantPaymentForm(!isShowInstantPaymentForm)}
//                           >
//                             <i className='bx bx-plus' ></i>
//                             <span className='ms-1'>Create Instant payment</span>
//                           </button>
//                         </div>
//                       </div>
//                     </CardHeader>

//                     {
//                       isShowInstantPaymentForm ?
//                         <CardBody className='p-0'>
//                           <hr />
//                           <div className="mb-3">
//                             {/* <div className='d-flex justify-content-between mb-2'>
//                           <span className='text-muted text-truncate'></span>
//                           <span className='text-muted text-truncate'>Transaction requirement <i className='bx bxs-info-circle'></i></span>
//                         </div> */}
//                             <form
//                               onSubmit={onSubmit}
//                               className='row flex-column'
//                             >
//                               <div className='col-12 col-md-6'>
//                                 <Label htmlFor='transactionText pb-0'>Transaction Text</Label>
//                                 <Input
//                                   id="transactionText"
//                                   type="textarea"
//                                   value={transactionText}
//                                   rows={3}
//                                   placeholder=''
//                                   className="form-control text-muted me-2 resize-none"
//                                   onChange={(event) => onChange(event, setTransactionText, transactionTextMaxCount)}
//                                 />
//                               </div>

//                               {/* <div className='position-relative col-12 col-md-6 mt-3'>
//                                 <div className='text-muted instant-payment-amount-icon'>
//                                   <i className='bx bx-dollar font-size-18'></i>
//                                 </div>
//                                 <Label htmlFor='transactionText pb-0'>Amount</Label>
//                                 <Input
//                                   id="basicpill-firstname-input1"
//                                   type="number"
//                                   value={amount}
//                                   placeholder=''
//                                   className="form-control text-muted instant-payment-amount-input me-2"
//                                   onChange={(event) => onNumberChange(event, setAmount, maxAmount)}
//                                 />
//                               </div> */}

//                               <div className="mb-3 position-relative col-12 col-md-6 mt-3">
//                                 <Label htmlFor='transactionText pb-0'>Amount</Label>
//                                 <div className="input-group">
//                                   <div className="input-group-prepend">
//                                     <span
//                                       className="input-group-text"

//                                     >
//                                       $
//                                     </span>
//                                   </div>
//                                   <Input
//                                     id="transactionAmount"
//                                     type="text"
//                                     className="form-control"
//                                     value={amount}
//                                     onChange={(event) => onNumberChange(event, setAmount, maxAmount)}
//                                   />
//                                 </div>
//                               </div>

//                               <div className='position-relative col-12 col-md-6 mt-3'>
//                                 <div className='row'>
//                                   <div className='col-12 col-md-6'>
//                                     <Label htmlFor='transactionText pb-0'>Expiration</Label>
//                                     <Input
//                                       id="transactionText"
//                                       type="date"
//                                       value={endDate}
//                                       min={getToday()}
//                                       className="form-control text-muted me-2 resize-none"
//                                       onChange={(event) => onChange(event, setEndDate, transactionTextMaxCount)}
//                                     />
//                                   </div>

//                                   <div className='col-12 col-md-6'>
//                                     <Label htmlFor='transactionText pb-0'>&nbsp;</Label>
//                                     <Input
//                                       id="transactionText"
//                                       type="time"
//                                       value={endTime}
//                                       disabled={!endDate}
//                                       className="form-control text-muted me-2 resize-none"
//                                       onChange={(event) => onChange(event, setEndTime, transactionTextMaxCount)}
//                                     />
//                                   </div>
//                                 </div>
//                               </div>

//                               <div className='col-12 col-md-6 d-flex justify-content-end align-items-center mt-4'>
//                                 <Button type="button" outline color="secondary" className='shadow-sm me-2' onClick={() => setIsShowInstantPaymentForm(false)}>
//                                   Cancel
//                                 </Button>
//                                 <ActionButton
//                                   type="submit"
//                                   name="Create"
//                                   className="btn btn-primary cursor-pointer nowrap px-4"
//                                   spinnerId={buttonSpinnerId}
//                                   spinnerColor={spinnerColor}
//                                   disabled={amount ? false : true}
//                                 />
//                               </div>
//                             </form>
//                           </div>
//                         </CardBody>
//                         : null
//                     }
//                   </Card>
//                   : null
//               }
//               <Card className='p-3'>
//                 <div className='mt-2 mb-3 d-flex flex-wrap justify-content-between'>
//                   <h5>Instant Payment History</h5>
//                   {
//                     statuses && statuses.length ?
//                       <div style={{ minWidth: "200px" }}>
//                         <Label className='text-muted text-truncate mb-0'>Filter by Status</Label>
//                         <ReactSelectOption
//                           value={selectedStatusId}
//                           selectedValue={(() => {
//                             const selectedValue = { ...statuses.find(data => data.value === selectedStatusId) };
//                             if (Object.keys(selectedValue).length) {
//                               selectedValue.label = selectedValue.key.toLowerCase();
//                               selectedValue.value = selectedValue.value;
//                               return selectedValue;
//                             } else {
//                               return { label: "Choose...", value: null }
//                             }
//                           })()}
//                           items={statuses.map(data => ({ label: data.key.toLowerCase(), value: data.value }))}
//                           onChange={(item) => setSelectedStatusId(item.value)}
//                         />
//                       </div>
//                       : null
//                   }
//                 </div>
//                 <hr />
//                 <div>
//                   {
//                     transactionHistory && transactionHistory.length ?
//                       <>
//                         <div className="table-responsiv">
//                           <Table responsive className="table table-striped mb-0">
//                             <thead>
//                               <tr className='cursor-default'>
//                                 <th className='text-nowrap'>Transaction text</th>
//                                 <th className='text-nowrap'>Fiat amount</th>
//                                 <th className='text-nowrap'>Status</th>
//                                 <th className='text-nowrap'>Create date</th>
//                                 <th className='text-nowrap'>Expire date</th>
//                                 <th className='text-nowrap'>Payment Url</th>
//                                 <th className='text-nowrap'>Action</th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {
//                                 transactionHistory.map((item, index) => {
//                                   return <tr key={index} className='cursor-default'>
//                                     <th>
//                                       <p className='word-break-break-word max-line-3 m-0' title={item.paymentUrl}>{item.transactionText}</p>
//                                     </th>
//                                     <th>
//                                       <p className='word-break-break-word max-line-3 m-0' title={item.paymentUrl}>${item.fiatAmount}</p>
//                                     </th>
//                                     <th className='nowrap'><span className={`badge badge-soft-${MainService.getStatusColor(item.status)} font-size-14 badge badge-pill`}>{MainService.getStatusName(item.status, statuses)?.toLowerCase()}</span></th>
//                                     <th className='nowrap'>{moment(MainService.convertUTCDateToLocalDate(new Date(item.createDate))).format("LL HH:mm")}</th>
//                                     <th className='nowrap'>{moment(MainService.convertUTCDateToLocalDate(new Date(item.expireDate))).format("LL HH:mm")}</th>
//                                     <th>
//                                       <p className='word-break-break-word max-line-3 m-0' title={item.paymentUrl}>{item.paymentUrl || item.localTransactionId}</p>
//                                     </th>
//                                     <td>
//                                       <Link
//                                         to={`#`}
//                                         className="btn btn-primary btn-sm cursor-pointer px-3 py-2 text-nowrap"
//                                         onClick={() => copyLink(item.paymentUrl)}
//                                       >
//                                         Copy Url
//                                       </Link>
//                                     </td>
//                                   </tr>
//                                 })
//                               }

//                             </tbody>
//                           </Table>
//                         </div>
//                       </>
//                       : <div>
//                         <NoData />
//                         {
//                           !user.isActive ?
//                             <p className="my-3 text-center">{INACTIVE_ACCOUNT_INFO_MSG}</p>
//                             : null
//                         }
//                       </div>
//                   }

//                 </div>
//                 {
//                   pagination ?
//                     <div className='p-3 d-flex justify-content-end'>
//                       <Row>
//                         <Col>
//                           <ReactPaginate
//                             nextLabel="Next"
//                             onPageChange={handlePageClick}
//                             pageRangeDisplayed={3}
//                             marginPagesDisplayed={2}
//                             pageCount={pagination.TotalPages}
//                             previousLabel="Previous"
//                             pageClassName="page-item"
//                             pageLinkClassName="page-link"
//                             previousClassName="page-item"
//                             previousLinkClassName="page-link"
//                             nextClassName="page-item"
//                             nextLinkClassName="page-link"
//                             breakLabel="..."
//                             breakClassName="page-item"
//                             breakLinkClassName="page-link"
//                             containerClassName="pagination"
//                             activeClassName="active"
//                             renderOnZeroPageCount={null}
//                             forcePage={activePageNumber}
//                           />
//                         </Col>
//                       </Row>
//                     </div>
//                     : null
//                 }
//               </Card>
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </>
//   )
// }




import React, { useCallback, useState, useEffect, useRef } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Button,
  Label,
  Input,
  UncontrolledAlert,
} from "reactstrap"
import { Table } from "react-bootstrap"
import ReactPaginate from 'react-paginate'
import ReactSelectOption from './../../../components/SelectOptions/ReactSelectOption'
import { withTranslation } from "react-i18next"
import DashboardApiService from '../../../Services/DashboardApiService';
import AlertService from './../../../Services/alertService'
import { SUCCESS_KEY, fielsLengths } from './../../../Constants/MainKeys'
import uuid from 'react-uuid'
import { addRightSectionSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions'
import { useDispatch, useSelector } from 'react-redux'
import * as moment from 'moment';
import MainService from './../../../Services/MainService'
import HtmlHead from './../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from './../../../components/Spinners/RightSectionSpinner'
import NoData from '../../../components/NoData/NoData'
import { Link } from 'react-router-dom'
import InstantPaymentForm from './InstantPaymentForm'

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const InstantPayment = (props) => {

  const { t } = props;
  const title = "Instant Payment";
  const description = "";
  const pageSize = 10;
  const dispatch = useDispatch();
  const { language } = useSelector(state => state.Languages);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [_isShowFilter, _setIsShowFilter] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [cryptoWallets, setCryptoWallets] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [txnValueType, setTxnValueType] = useState("");
  const [maxAmount, setMaxAmount] = useState("");

  const wrapperRef = useRef(null);
  const [isShowChangeTxnValuePopup, setIsShowChangeTxnValuePopup] = useState(false);
  useOutsideAlerter(wrapperRef, setIsShowChangeTxnValuePopup);

  const [searchRequestModel, setSearchRequestModel] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [filter, setFilter] = useState({
    searchKey: [],
    status: [],
    currencyId: [],
    cryptoWalletId: [],
    createDate: "",
    finishDate: "",
    fiatMinAmount: "",
    fiatMaxAmount: "",
    cryptoMinAmount: "",
    cryptoMaxAmount: "",
  })

  useEffect(() => {
    getInstantPaymentsAsMerchant(1, filter, _setIsShowFilter);
    getCryptoWallets();
    getCurrencies();
    getConfigByName();
    return () => {
      setTransactions([]);
    }
  }, [])

  const copyLink = (link) => {
    if (!link) { return false; }
    navigator.clipboard.writeText(link);
    AlertService.alert(SUCCESS_KEY, "Copied");
  }

  const getInstantPaymentsAsMerchant = (pageNumber, filter, showFilterCb) => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    let _filter = { ...filter };
    Object.entries(_filter).forEach(([key, item]) => {
      if (Array.isArray(item) && !item.length) {
        _filter[key] = null;
      } else if (!item) {
        if (typeof item !== "number" && typeof item !== "boolean") {
          _filter[key] = null;
        }
      }
    });
    DashboardApiService.getInstantPaymentsAsMerchant(pageNumber, pageSize, _filter, true).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      if (response && response.data) {
        const data = { ...response.data };
        if (data.filter) {
          if (data.filter.serachRequestModel) {
            setSearchRequestModel(data.filter.serachRequestModel);
          }
          setFilterData(data.filter);
        }
        if (data && data.paymentHistory && data.paymentHistory.length) {
          setTransactions(data.paymentHistory);
          showFilterCb && showFilterCb(true);
        } else {
          setTransactions([]);
          if (showFilterCb) {
            showFilterCb(false);
            setIsShowFilter(false);
          }
        }
      }

      extractRightSectionSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const getCryptoWallets = () => {
    const spinnerId = uuid();
    DashboardApiService.getCryptoWallets().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setCryptoWallets(data);
      }
    }).catch(error => getFail(error, spinnerId))
  }

  const getConfigByName = () => {
    const spinnerId = uuid();
    DashboardApiService.getConfigByName("INSTANT_PAYMENT_MAX_AMOUNT").then(response => {
      if (response && response.data) {
        setMaxAmount(response.data);
      }
    }).catch(error => getFail(error, spinnerId))
  }

  const getCurrencies = () => {
    const spinnerId = uuid();
    DashboardApiService.getFiatCurrencies().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setCurrencies(data);
      }
    }).catch(error => getFail(error, spinnerId))
  }

  const handlePageClick = (event) => {
    if (filter.status || filter.currencyId || filter.cryptoWalletId || filter.createDate || filter.finishDate || filter.fiatMinAmount || filter.fiatMaxAmount || filter.cryptoMinAmount || filter.cryptoMaxAmount) {
      onFilter(event.selected + 1, filter, true);
    } else {
      getInstantPaymentsAsMerchant(event.selected + 1)
    }
    setActivePageNumber(event.selected);
  };

  const onNumberChange = (event, fieldName) => {
    if (event.target.value === "") {
      setFilter((values) => ({
        ...values,
        [fieldName]: "",
      }));
      return false;
    }
    if (typeof +event.target.value === "number" && Number(event.target.value) >= 0) {
      setFilter((values) => ({
        ...values,
        [fieldName]: +event.target.value,
      }));
    }
  };

  const onChange = (event, mainIndex, childIndex, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    let _searchKey = [...filter.searchKey];
    _searchKey[mainIndex].value[childIndex] = event.target.value;
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const onDateChange = (event, fieldName) => {
    setFilter(prevFilter => ({ ...prevFilter, [fieldName]: event.target.value }))
  }

  const removeSearchInput = (mainIndex, childIndex) => {
    let _searchKey = [...filter.searchKey];
    let currentObj = _searchKey[mainIndex];
    currentObj.value = currentObj.value.filter((_, index) => index !== childIndex);
    if (!currentObj.value.length) {
      _searchKey = _searchKey.filter((_, idx) => idx !== mainIndex)
    } else {
      _searchKey[mainIndex] = currentObj;
    }
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const addSearchInput = (mainIndex) => {
    let _searchKey = [...filter.searchKey];
    let currentObj = _searchKey[mainIndex];
    const emptyString = currentObj.value.find(element => element.trim() === "");
    if (typeof emptyString !== "undefined") { return false; }
    currentObj.value = [...currentObj.value, ""];
    _searchKey[mainIndex] = currentObj;
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const onFilter = (pageNumber = 1) => {
    let _filter = { ...filter };
    let newSearchKey = {};
    _filter.searchKey.forEach(item => {
      if (item.value.filter(value => value.trim().length > 0).length) {
        newSearchKey[item.name] = item.value.filter(value => value.trim().length > 0);
      }
    });
    if (Object.keys(newSearchKey).length) {
      _filter.searchKey = newSearchKey;
    } else {
      _filter.searchKey = [];
    };
    _filter.status = _filter.status.map(item => item.value);
    _filter.currencyId = _filter.currencyId.map(item => item.value);
    _filter.cryptoWalletId = _filter.cryptoWalletId.map(item => item.value);
    setFilter(prevFilter => ({ ...prevFilter, searchKey: filter.searchKey, status: filter.status, currencyId: filter.currencyId, cryptoWalletId: filter.cryptoWalletId }));
    getInstantPaymentsAsMerchant(pageNumber, _filter, scrollToCurrentDivPosition);
  }

  const clearFilter = () => {
    let newData = {
      searchKey: [],
      status: [],
      currencyId: [],
      cryptoWalletId: [],
      createDate: "",
      finishDate: "",
      fiatMinAmount: "",
      fiatMaxAmount: "",
      cryptoMinAmount: "",
      cryptoMaxAmount: "",
    }
    setFilter(newData);
    setActivePageNumber(0);
    getInstantPaymentsAsMerchant(1, newData);
  }

  const onSearchSelectOptionChange = (item) => {
    let isExist = filter.searchKey.find(el => el.name === item.value);
    if (isExist) { return false; }
    let _searchKey = [...filter.searchKey];
    _searchKey.push({ name: item.value, value: [""] })
    setFilter(prevFilter => ({ ...prevFilter, searchKey: _searchKey }))
  }

  const scrollToCurrentDivPosition = (id = "") => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const removeMultiItem = (index, fieldName) => {
    let arr = [...filter[fieldName]];
    arr = arr.filter((_, idx) => index !== idx)
    setFilter(prevFilter => ({ ...prevFilter, [fieldName]: arr }))
  }

  const onMultiSelectOptionChange = (item, fieldName) => {
    let currentArr = [...filter[fieldName]];
    let isExist = currentArr.find(el => el.value === item.value);
    if (isExist) { return false; }
    currentArr.push({ label: item.label, value: item.value })
    setFilter((values) => ({ ...values, [fieldName]: currentArr }))
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || "error"), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />

        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Transactions"
          />
          <Row>

            <Col lg={12}>
              <Row className='mb-4'>
                <InstantPaymentForm clearFilter={clearFilter} />
                {
                  maxAmount ?
                    <Col sm={12}>
                      <UncontrolledAlert color={"info"} role="alert">
                        Payments exceeding <b>{Number(maxAmount).toFixed(2)} USD</b> require prior approval from the Cryllex administration. Once the payment is reviewed and approved, a payment link will be generated and made available for you to proceed.
                      </UncontrolledAlert>
                    </Col>
                    : null
                }

                {
                  filterData && isShowFilter ?
                    <Col xl={3}>
                      <Card id='filter' className='position-relative'>
                        <CardBody className="pb-0">
                          <CardTitle className="mb-4">
                            <div className='d-flex justify-content-between align-items-center cursor-pointer'>
                              Filter by
                              <i
                                className='bx bx-x'
                                style={{ fontSize: "20px" }}
                                onClick={() => setIsShowFilter(false)}
                              />
                            </div>
                            <hr />
                          </CardTitle>
                          <div>
                            <div className='w-100 mb-4'>
                              <div className='mb-3'>
                                {
                                  searchRequestModel ?
                                    <div>
                                      <Label className='text-nowrap'>Search by:</Label>
                                      <ReactSelectOption
                                        isSearchable={true}
                                        selectedValue={(() => {
                                          const selectedValue = { label: "Select", value: "" };
                                          return selectedValue;
                                        })()}
                                        items={Object.keys(searchRequestModel).map(data => ({ label: MainService.camelCaseToWords(data), value: data }))}
                                        onChange={(item) => onSearchSelectOptionChange(item)}
                                      />
                                    </div>
                                    : null
                                }
                              </div>
                              {
                                filter.searchKey && filter.searchKey.length ?
                                  filter.searchKey.map((item, index) => {
                                    return <div key={index} className='border rounded p-2 mb-2'>
                                      <div className='d-flex justify-content-between align-items-center '>
                                        <p className='mb-0'><b>{MainService.camelCaseToWords(item.name)}</b></p>
                                        <Button
                                          color='success'
                                          style={{ padding: "3px" }}
                                          className='d-flex justify-content-center align-items-center bg-success'
                                          onClick={() => addSearchInput(index)}
                                        >
                                          <i className='bx bx-plus' style={{ fontSize: "18px" }}></i>
                                        </Button>

                                      </div>
                                      <hr />
                                      {
                                        item.value && item.value.length ?
                                          item.value.map((el, idx) => {
                                            return <div key={idx} className='d-flex justify-content-between align-items-center mb-1'>
                                              <Input
                                                id="search"
                                                type="search"
                                                placeholder='Search'
                                                className={`form-control resize-none`}
                                                value={el}
                                                onChange={event => onChange(event, index, idx, fielsLengths.length_120)}
                                              />
                                              <Button
                                                outline
                                                color='danger'
                                                style={{ padding: "5px" }}
                                                className='d-flex justify-content-center align-items-center ms-2'
                                                onClick={() => removeSearchInput(index, idx)}
                                              >
                                                <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                              </Button>
                                            </div>
                                          })
                                          : null
                                      }

                                    </div>
                                  })
                                  : null
                              }
                            </div>
                            <hr />
                          </div>

                          {
                            filterData.statuses && filterData.statuses.length ?
                              <div className='w-100 mb-4'>
                                <Label className='text-nowrap'>Status</Label>
                                <ReactSelectOption
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { label: "Select", value: "" };
                                    return selectedValue;
                                  })()}
                                  items={filterData.statuses.map(data => ({ ...data, label: data.value, value: data.key }))}
                                  onChange={(item) => onMultiSelectOptionChange(item, "status")}
                                />

                                <div>
                                  {
                                    filter.status && filter.status.map((item, index) => {
                                      return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
                                        {MainService.camelCaseToWords(item.label)}
                                        <Button
                                          outline
                                          color='danger'
                                          style={{ padding: "5px" }}
                                          className='d-flex justify-content-center align-items-center ms-2'
                                          onClick={() => removeMultiItem(index, "status")}
                                        >
                                          <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                        </Button>
                                      </div>
                                    })
                                  }
                                </div>
                              </div>
                              : null
                          }
                          {/* {
                            currencies && currencies.length ?
                              <div className='w-100 mb-4'>
                                <Label className='text-nowrap'>Currency</Label>
                                <ReactSelectOption
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { label: "Select", value: "" };
                                    return selectedValue;
                                  })()}
                                  items={currencies.map(data => ({ ...data, label: data.name, value: data.id }))}
                                  onChange={(item) => onMultiSelectOptionChange(item, "currencyId")}
                                />

                                <div>
                                  {
                                    filter.currencyId && filter.currencyId.map((item, index) => {
                                      return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
                                        {item.label}
                                        <Button
                                          outline
                                          color='danger'
                                          style={{ padding: "5px" }}
                                          className='d-flex justify-content-center align-items-center ms-2'
                                          onClick={() => removeMultiItem(index, "currencyId")}
                                        >
                                          <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                        </Button>
                                      </div>
                                    })
                                  }
                                </div>
                              </div>
                              : null
                          } */}
                          {/* {
                            cryptoWallets && cryptoWallets.length ?
                              <div className='w-100 mb-4'>
                                <Label className='text-nowrap'>Wallet</Label>
                                <ReactSelectOption
                                  isSearchable={true}
                                  selectedValue={(() => {
                                    const selectedValue = { label: "Select", value: "" };
                                    return selectedValue;
                                  })()}
                                  items={cryptoWallets.map(data => ({ ...data, label: data.name, value: data.id }))}
                                  onChange={(item) => onMultiSelectOptionChange(item, "cryptoWalletId")}
                                />

                                <div>
                                  {
                                    filter.cryptoWalletId && filter.cryptoWalletId.map((item, index) => {
                                      return <div key={index} className='d-flex justify-content-between align-items-center border rounded p-2 mt-1'>
                                        {item.label}
                                        <Button
                                          outline
                                          color='danger'
                                          style={{ padding: "5px" }}
                                          className='d-flex justify-content-center align-items-center ms-2'
                                          onClick={() => removeMultiItem(index, "cryptoWalletId")}
                                        >
                                          <i className='bx bx-trash' style={{ fontSize: "18px" }} />
                                        </Button>
                                      </div>
                                    })
                                  }
                                </div>
                              </div>
                              : null
                          } */}
                          <div className="mt-2 mb-3">
                            <p className='font-weight-500 mb-0'>Transaction Date</p>
                            <div className='row'>
                              <div className='col-12 col-xl-12 col-md-6'>
                                <small className='mt-1 d-block'>From</small>
                                <input
                                  id="createDate"
                                  className="form-control"
                                  type="date"
                                  value={filter.createDate}
                                  onChange={(event) => onDateChange(event, "createDate")}
                                />
                              </div>
                              <div className='col-12 col-xl-12 col-md-6'>
                                <small className='mt-1 d-block'>To</small>
                                <input
                                  id="finishDate"
                                  className="form-control"
                                  type="date"
                                  min={filter.createDate ? filter.createDate : ""}
                                  value={filter.finishDate}
                                  onChange={(event) => onDateChange(event, "finishDate")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 mb-3">
                            <p className='font-weight-500 mb-0'>Fiat Amount</p>
                            <div className='row'>
                              <div className='col-12 col-sm-6'>
                                <small className='mt-1 d-block'>{t("min")}</small>
                                <input
                                  id="fiatMinAmount"
                                  className="form-control"
                                  type="number"
                                  value={filter.fiatMinAmount}
                                  onChange={(event) => onNumberChange(event, "fiatMinAmount")}
                                />
                              </div>
                              <div className='col-12 col-sm-6'>
                                <small className='mt-1 d-block'>{t("max")}</small>
                                <input
                                  id="fiatMaxAmount"
                                  className="form-control"
                                  type="number"
                                  value={filter.fiatMaxAmount}
                                  onChange={(event) => onNumberChange(event, "fiatMaxAmount")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 mb-3">
                            <p className='font-weight-500 mb-0'>Crypto Amount</p>
                            <div className='row'>
                              <div className='col-12 col-sm-6'>
                                <small className='mt-1 d-block'>{t("min")}</small>
                                <input
                                  id="cryptoMinAmount"
                                  className="form-control"
                                  type="number"
                                  value={filter.cryptoMinAmount}
                                  onChange={(event) => onNumberChange(event, "cryptoMinAmount")}
                                />
                              </div>
                              <div className='col-12 col-sm-6'>
                                <small className='mt-1 d-block'>{t("max")}</small>
                                <input
                                  id="cryptoMaxAmount"
                                  className="form-control"
                                  type="number"
                                  value={filter.cryptoMaxAmount}
                                  onChange={(event) => onNumberChange(event, "cryptoMaxAmount")}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-4 mb-3 d-flex gap-2 justify-content-end">
                            <Button outline color='primary' onClick={clearFilter} className='text-nowrap'>
                              Clear filter
                            </Button>
                            <Button color='primary' onClick={() => onFilter()} className='px-4'>
                              Filter
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    : null
                }
                <Col xl={filterData && isShowFilter ? 9 : 12}>
                  <Card id='table' className="position-relative">

                    <CardBody className="pb-0">
                      <CardTitle className="mb-4">
                        <div className='d-flex justify-content-between align-items-center'>
                          Instant Payment History
                          {
                            !isShowFilter && filterData && _isShowFilter ?
                              <Button
                                color='primary'
                                size='sm'
                                className='px-3 d-flex align-items-end'
                                onClick={() => {
                                  setIsShowFilter(true);
                                  scrollToCurrentDivPosition("filter")
                                }}
                              >
                                <i className='bx bx-filter me-2' style={{ fontSize: "20px" }}></i>
                                Show Filter
                              </Button>
                              : null
                          }
                        </div>
                        <hr />
                      </CardTitle>
                      {
                        transactions && transactions.length ? (
                          <Table striped bordered hover responsive>
                            <thead>
                              <tr className='cursor-default'>
                                <th className='text-nowrap'>Transaction text</th>
                                <th className='text-nowrap'>Amount</th>
                                <th className='text-nowrap'>Payment Status</th>
                                <th className='text-nowrap'>Type</th>
                                <th className='text-nowrap'>Status</th>
                                <th className='text-nowrap'>Create date</th>
                                <th className='text-nowrap'>Expire date</th>
                                <th className='text-nowrap'>Payment Url</th>
                                <th className='text-nowrap'>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactions.map((transaction, index) => {
                                return (
                                  <tr key={index} className='cursor-default'>
                                    <th>
                                      <p className='word-break-break-word max-line-3 m-0' title={transaction.paymentUrl}>{transaction.transactionText}</p>
                                    </th>
                                    <th>
                                      {transaction.amount} USD
                                    </th>
                                    <th className='nowrap'>
                                      <span className={`badge badge-soft-${MainService.getStatusColor(transaction.status)} font-size-14 badge badge-pill`}>
                                        {transaction.transactionStatus?.toLowerCase()}
                                      </span>
                                    </th>
                                    <th className='nowrap'>
                                      {transaction.transactionType?.toLowerCase()}
                                    </th>
                                    <th className='nowrap'>
                                      <span className={`badge badge-soft-${MainService.getTransactionStatusColor(transaction.instantPaymentStatus)} font-size-14 badge badge-pill`}>
                                        {MainService.getTransactionStatusName(transaction.instantPaymentStatus)}
                                      </span>
                                    </th>
                                    <th className='nowrap'>
                                      {transaction.createDate ? moment(MainService.convertUTCDateToLocalDate(new Date(transaction.createDate))).format("LL HH:mm") : ""}
                                    </th>
                                    <th className='nowrap'>
                                      {transaction.expireDate ? moment(MainService.convertUTCDateToLocalDate(new Date(transaction.expireDate))).format("LL HH:mm") : ""}
                                    </th>
                                    <th>
                                      <p className='word-break-break-word max-line-3 m-0' title={transaction.paymentUrl}>{transaction.paymentUrl || transaction.localTransactionId}</p>
                                    </th>
                                    <td>
                                      <div className='d-flex gap-2'>
                                        <Link
                                          to={`/${language}/transaction/view/${transaction.transactionId}`}
                                          className="btn btn-primary btn-sm cursor-pointer px-3 py-2 text-nowrap w-100"
                                        >
                                          View
                                        </Link>
                                        {
                                          transaction.paymentUrl ?
                                            <Link
                                              to={`#`}
                                              className="btn btn-success btn-sm cursor-pointer px-3 py-2 text-nowrap"
                                              onClick={() => copyLink(transaction.paymentUrl)}
                                            >
                                              Copy Url
                                            </Link>
                                            : null
                                        }
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        ) : (
                          <NoData />
                        )
                      }
                    </CardBody>
                    {
                      pagination ?
                        <div className='p-3 d-flex justify-content-end'>
                          <Row>
                            <Col>
                              <ReactPaginate
                                nextLabel={t("Next")}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pagination.TotalPages}
                                previousLabel={t("Previous")}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                                forcePage={activePageNumber}
                              />
                            </Col>
                          </Row>
                        </div>
                        : null
                    }
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(InstantPayment)