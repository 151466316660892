export const TOKEN_KEY = 'token';
export const LANGUAGE_KEY = 'language';
export const LANGUAGES_KEY = 'languages';
export const DEFAULT_LANGUAGE_KEY = 'en';
export const USER_KEY = 'user';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const TRANSLATE_KEYS = 'translatekeys';
export const OBJECT_KEY = 'object';
export const STRING_KEY = 'string';
export const INFO_KEY = "info";
export const ERROR_KEY = 'error';
export const SUCCESS_KEY = "success";
export const WARNING_KEY = "warning";
export const INVALID_USERNAME_OR_PASSWORD = "invalid_username_or_password";
export const NUMBER_KEY = "number";
export const SPINNER_COLOR = "#1081E8";


export const IMAGE_KEY = 'image';
export const FILE_KEY = 'file';
export const VIDEO_KEY = 'video';

export const ALL_VALID_FILE_TYPES = ['jpeg', 'png', 'jpg', 'pdf', 'txt', 'doc', 'docx', 'rtf', 'xls', 'xlsx', 'JPEG', 'PNG', 'JPG', 'PDF', 'TXT', 'DOC', 'DOCX', 'RTF', 'XLS', 'XLSX', 'svg', 'SVG'];
export const VALID_IMAGE_TYPES_KEY = ['jpeg', 'png', 'jpg', 'JPEG', 'PNG', 'JPG'];
export const VALID_FILE_TYPES_KEY = ['pdf', 'txt', 'doc', 'docx', 'rtf', 'xls', 'xlsx', 'PDF', 'TXT', 'DOC', 'DOCX', 'RTF', 'XLS', 'XLSX', 'svg', 'SVG'];
export const VALID_VIDEO_TYPES_KEY = ['mp4'];
export const allValidFileTypes = ['pdf', 'jpg', 'jpeg', 'png'];

export const ADMIN_USER_TYPE_ID = 1;
export const MERCHANT_USER_TYPE_ID = 2;
export const AGENT_USER_TYPE_ID = 6;
export const DEVELOPER_USER_TYPE_ID = 7;
export const PAYING_CUSTOMER_USER_TYPE_ID = 5;

export const fielsLengths = {
  length_4: 4,
  length_6: 6,
  length_25: 25,
  length_30: 30,
  length_32: 32,
  length_120: 120,
  length_250: 250,
  length_2000: 2000,
  length_9999: 9999,
  length_1000000000: 1000000000,
}

export const INACTIVE_ACCOUNT_INFO_MSG = "Your account is pending verification from Cryllex. Please await activation status.";