import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from './../../../components/Common/Breadcrumb'
import {
  Col,
  Row,
} from "reactstrap"

import DashboardApiService from '../../../Services/DashboardApiService';
import AlertService from './../../../Services/alertService'
import { ERROR_KEY } from './../../../Constants/MainKeys'
import uuid from 'react-uuid'
import { addRightSectionSpinner, removeRightSectionSpinner } from './../../../store/spinner/actions'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from './../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from './../../../components/Spinners/RightSectionSpinner'
import TotalOrder from './Components/TotalOrder';
import QuickView from './Components/QuickView';
import TransactionDetails from './Components/TransactionDetails';
import HedgingProfitAndTransactionFeeDetails from './Components/HedgingProfitAndTransactionFeeDetails';

const CurrentTransactionsOverview = (props) => {

  const { transactionId, organizationId } = props.match.params;
  const title = "Transaction Overview";
  const description = "";
  const dispatch = useDispatch();
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const { language } = useSelector(state => state.Languages);
  const [organizers, setOrganizers] = useState(null);
  const [transactionOverview, setTransactionOverview] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [payignCustomer, setPayignCustomer] = useState(null);

  useEffect(() => {
    getTransactionOrganizers();
    getTransactionOverview();
    getTransactionDetails();
    getPayingCustomer();
  }, [transactionId, organizationId])

  const getTransactionOrganizers = () => {
    if (!organizationId) { return false };
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getTransactionOrganizers(organizationId).then(response => {
      if (response && response.data) {
        setOrganizers(response.data)
      }
    }).catch(error => AlertService.alert(ERROR_KEY, error)).finally(() => {
      extractRightSectionSpinner(spinnerId);
    });
  }

  const getPayingCustomer = () => {
    if (!transactionId) { return false };
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getPayingCustomer(transactionId).then(response => {
      if (response && response.data) {
        setPayignCustomer(response.data)
      }

    }).catch(error => AlertService.alert(ERROR_KEY, error)).finally(() => {
      extractRightSectionSpinner(spinnerId);
    });
  }

  const getTransactionOverview = () => {
    if (!transactionId) { return false };
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getTransactionOverview(transactionId).then(response => {
      if (response && response.data) {
        setTransactionOverview(response.data)
      }
    }).catch(error => AlertService.alert(ERROR_KEY, error)).finally(() => {
      extractRightSectionSpinner(spinnerId);
    });
  }

  const getTransactionDetails = () => {
    if (!transactionId) { return false };
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getTransactionDetails(transactionId).then(response => {
      if (response && response.data) {
        setTransactionDetails(response.data)
      }
    }).catch(error => AlertService.alert(ERROR_KEY, error)).finally(() => {
      extractRightSectionSpinner(spinnerId);
    });
  }

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractRightSectionSpinner(spinnerId);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />

        <div className="container-fluid">
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Transaction Overview"
            isShowGoBackButton={true}
            goBack={() => window.history.back()}
          />
          <Row>
            <Col lg={12}>
              <Row className='mb-4'>
                <Col xl={5}>
                  <Row>
                    <Col sm={12}>
                      <TotalOrder transactionOverview={transactionOverview} transactionDetails={transactionDetails} setTransactionDetails={setTransactionDetails} />
                    </Col>
                    <Col sm={12}>
                      <TransactionDetails
                        transactionDetails={transactionDetails}
                        payignCustomer={payignCustomer}
                        organizers={organizers}
                      />
                    </Col>
                  </Row>

                </Col>
                <Col xl={7}>
                  <Row>
                    <Col sm={12}>
                      <QuickView data={organizers} payignCustomer={payignCustomer} />
                    </Col>
                    <Col sm={12}>
                      <HedgingProfitAndTransactionFeeDetails />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default CurrentTransactionsOverview