import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap"
import { ERROR_KEY } from '../../../Constants/MainKeys'
import { addButtonSpinner, addRightSectionSpinner, removeButtonSpinner, removeRightSectionSpinner } from '../../../store/spinner/actions'
import AlertService from '../../../Services/alertService'
import { useDispatch, useSelector } from 'react-redux'
import HtmlHead from '../../../components/HtmlHead/HtmlHead'
import RightSectionSpinner from '../../../components/Spinners/RightSectionSpinner'
import uuid from 'react-uuid'
import NoData from '../../../components/NoData/NoData'
import DashboardApiService from '../../../Services/DashboardApiService'
import ActionButton from '../../../components/Buttons/ActionButton'
import moment from 'moment'


const buttonSpinnerId = uuid();
const Promotions = (props) => {

  const dispatch = useDispatch();
  const title = "Promotions";
  const description = "";
  const { language } = useSelector(state => state.Languages);
  const { rightSectionSpinners } = useSelector(state => state.Spinners);
  const [promotions, setPromotions] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    getAllPromotions();
    getPromotionTypes();
  }, [])

  const getAllPromotions = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getAllPromotions().then(response => {
      if (response && response.data) {
        setPromotions(response.data);
      }
    }).catch(error => getFail(error)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const getPromotionTypes = () => {
    const spinnerId = uuid();
    setRightSectionSpinner(spinnerId);
    DashboardApiService.getPromotionTypes().then(response => {
      if (response && response.data) {
        setTypes(response.data);
      }
    }).catch(error => getFail(error, spinnerId)).finally(() => {
      extractRightSectionSpinner(spinnerId)
    })
  }

  const setPromotionTypes = (item) => {
    if (!types.length || !item) {
      return ""
    }
    return types.find(el => el.key === item.type)?.value
  }

  const deletePromotion = (itemId, spinnerId) => {
    AlertService.alertConfirm(
      `Are you sure ?`,
      "",
      "Yes",
      "No"
    ).then(() => {
      setButtonSpinner(spinnerId);
      DashboardApiService.deletePromotion(itemId).then(() => {
        setPromotions(promotions.filter(item => item.id !== itemId));
        AlertService.alert("success", "The promotion deleted successfully");
      }).catch(error => getFail(error)).finally(() => {
        extractButtonSpinner(spinnerId)
      })
    })
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const setRightSectionSpinner = useCallback(spinner => {
    dispatch(addRightSectionSpinner(spinner));
  }, []);

  const extractRightSectionSpinner = useCallback(spinner => {
    dispatch(removeRightSectionSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className='page-content position-relative min-h-100vh'>
        <RightSectionSpinner spinner={rightSectionSpinners} />
        <div className='container-fluid'>
          <Breadcrumbs
            title="Cryllex"
            breadcrumbItem="Promotions"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <div className='d-flex justify-content-between align-items-center'>
                      <span>
                        Promotions
                      </span>
                      <Button
                        type="button"
                        color="primary"
                        size='sm'
                        outline
                        className='shadow-sm text-nowrap me-2'
                        onClick={() => props.history.push(`/${language}/promotion-form`)}
                      >
                        Create Promotion
                      </Button>
                    </div>
                    <hr />
                  </CardTitle>
                  {
                    promotions && promotions.length ?
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th className='text-nowrap'>Type</th>
                            <th className='text-nowrap'>Title</th>
                            <th className='text-nowrap'>Deadline</th>
                            <th className='text-nowrap text-center'>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            promotions.map((item, index) => {
                              return <tr key={index}>
                                <td style={{ width: "50px" }}>{index + 1}</td>
                                <td>{setPromotionTypes(item)}</td>
                                <td>{JSON.parse(item.data)[0].title}</td>
                                <td>{item.deadline ? moment(new Date(item.deadline)).format("ll") : <span className='text-danger'>Not yet</span>}</td>
                                <td style={{ width: "200px" }}>
                                  <div className='d-flex flex-nowrap'>
                                    <Button
                                      type="button"
                                      color="primary"
                                      className='shadow-sm text-nowrap me-2'
                                      onClick={() => props.history.push(`/${language}/promotion-form/${item.id}`)}
                                    >
                                      Edit
                                    </Button>
                                    <ActionButton
                                      type="button"
                                      name="Delete"
                                      color="danger"
                                      className='px-3'
                                      spinnerId={`${buttonSpinnerId}_${item.id}`}
                                      onClick={() => deletePromotion(item.id, `${buttonSpinnerId}_${item.id}`)}
                                    />
                                  </div>
                                </td>
                              </tr>

                            })
                          }

                        </tbody>
                      </Table>
                      : <NoData />
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div >
    </>
  )
}

export default Promotions