import { call, put, takeLatest } from "redux-saga/effects"
import { AUTH_FAIL, AUTH_SUCCESS, CURRENT_USER_SUCCESS, LOGIN_USER, LOGOUT_USER, LOGOUT_USER_SUCCESS, SOCIAL_LOGIN } from "./actionTypes"
import { apiError } from "./actions"
import ResponseService from "../../../Services/ResponseService"
import { IdentityService } from "../../../Services/IdentityService"
import { DEFAULT_LANGUAGE_KEY, ERROR_KEY, INVALID_USERNAME_OR_PASSWORD, TOKEN_KEY, USER_KEY } from "./../../../Constants/MainKeys"
import AlertService from "../../../Services/alertService"
import { addButtonSpinner, removeButtonSpinner } from './../../spinner/actions';
import DashboardApiService from "../../../Services/DashboardApiService"

function* authSaga() {
  yield takeLatest(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeLatest(LOGOUT_USER, logoutUser)
}

function* loginUser(action) {
  const { spinnerId, history } = action.payload;
  try {
    yield put(addButtonSpinner(spinnerId));
    const formData = { ...action.payload.data };
    const token = yield call(IdentityService.login, formData);
    yield call(ResponseService.responseCheckIdentity, token);
    yield put({
      type: AUTH_SUCCESS,
      payload: { token }
    });
    const user = yield call(DashboardApiService.currentUser);
    if (user && user.data) {
      yield put({
        type: CURRENT_USER_SUCCESS,
        payload: user.data,
      });
    } else {
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(TOKEN_KEY);
    }

    yield put(removeButtonSpinner(spinnerId));
    history.push(`/${localStorage.getItem("language") ? localStorage.getItem("language") : DEFAULT_LANGUAGE_KEY}/dashboard`);
  } catch (error) {
    if (error === INVALID_USERNAME_OR_PASSWORD) {
      yield put({
        type: AUTH_FAIL,
        payload: error,
      });
    } else {
      error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    }
    yield put(removeButtonSpinner(spinnerId));
  };
};

function* logoutUser({ payload: { history } }) {
  try {
    yield put({
      type: LOGOUT_USER_SUCCESS,
    });
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) { }


export default authSaga
